import {Component, OnInit} from '@angular/core';
import {ShopType} from "../../../code/interface/shop";
import {HttpClient} from "@angular/common/http";
import {InviteService} from "../../../code/service/invite/invite.service";
import {WechatService} from "../../../code/service/wechat/wechat.service";
import {Router} from "@angular/router";
import {ToastService} from "ng-zorro-antd-mobile";

@Component({
    selector: 'app-pay',
    templateUrl: './pay.component.html',
    styleUrls: ['./pay.component.less']
})
export class PayComponent implements OnInit {

    shopType: ShopType[] = [
        {
            title: '网店',
            content: '(实体店商品线上发货)',
            protocolName: '商家入驻协议',
            protocolId: 1,
            buttonText: '限时100元入驻'
        },
        {
            title: '地摊',
            content: '(地摊商品线上发货)',
            protocolName: '商家入驻协议',
            protocolId: 2,
            buttonText: '限时0元入驻'
        }
    ];

    value: number = 0;
    checkboxStatus: boolean = false;

    constructor(
        private http: HttpClient,
        private invite: InviteService,
        private wechat: WechatService,
        private router: Router,
        private toast: ToastService
    ) {
    }

    ngOnInit(): void {
        // 微信支付前刷新
        if (window.location.href.split('#')[0] !== window['wechatConfigUrl']) {
            window.location.reload()
        }
    }

    submit(): void {
        if (this.checkboxStatus) {
            this.http.post('/advert/api/geyou/paybysettlemerchant', {
                trade_type: this.wechat.isWechatUa ? 1 : 2,
                open_id: localStorage.getItem('openId'),
                settle_type: this.shopType[this.value].protocolId,
                inviteCode: this.invite.getId()
            }).subscribe((res: { data }) => {
                if (res.data.hasOwnProperty('timestamp')) {
                    this.wechat.pay(res.data, res.data.order_no, () => {
                        this.router.navigateByUrl('/merchant/success', {replaceUrl: true}).then()
                    })
                } else {
                    this.router.navigateByUrl('/merchant/success', {replaceUrl: true}).then()
                }
            })
        } else {
            this.toast.info('请阅读并同意商家入驻协议')
        }
    }

}
