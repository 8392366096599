<div class="order-pay" *ngIf="paidudata&&concealpage">
    <app-loading-com *ngIf="showloading"></app-loading-com>
    <div class="order-info">
        <div class="lable">待支付</div>
        <div class="lable2">￥<label>{{unpaidinfo.amount_to_be_paid}}</label></div>
        <!-- 高端区抢购 -->
        <div class="order-item" *ngIf="unpaidinfo.oldtype">
            <div class="choiceblue flex">
                <div class="flex">
                    <div class="changeicon " [ngClass]="{changeicon:changblue,changeiconno:!changblue}"
                        (click)="changblueclick()"></div>
                    <div class="text">使用蓝滴咚贝抵扣</div>
                </div>
                <input placeholder="0" [(ngModel)]="bulunumber" value="" type="number" id="imgtalk"
                    oninput="value=value.replace(/[^0-9-]+/,'');if(value<=0)value='';if(value>this.tobepaid)value=this.tobepaid;"
                    (ngModelChange)="modelChange($event)">
            </div>
            <div class="text2">(蓝贝余额：{{bluescallop}}枚)</div>
            <div class="choiceblue flex">
                <div class="flex">
                    <div class="changeicon " [ngClass]="{changeicon:changNpMoney,changeiconno:!changNpMoney}" (click)="npMoneyClick()"></div>
                    <div class="text">使用易场钱包余额支付</div>
                </div>
            </div>
            <div class="text2">(可用余额：¥{{npMoney}}<label *ngIf="buckleNpMoney">，已抵扣：¥{{buckleNpMoney}}</label>)</div>
            <div class="choiceblue flex">
                <div class="flex">
                    <div class="changeicon " [ngClass]="{changeicon:changmoney,changeiconno:!changmoney}" (click)="moneyclick()"></div>
                    <div class="text">使用商圈钱包余额支付</div>
                </div>
            </div>
            <div class="text2">(可用余额：¥{{nowmoney}}<label *ngIf="deductionmoney">，已抵扣：¥{{deductionmoney}}</label>)</div>
        </div>
        <!-- 新金呗---组团购 -->
        <div class="order-item" *ngIf="!unpaidinfo.oldtype&&!unpaidinfo.newtype&&unpaidinfo.type!=='compete'&&unpaidinfo.type!=='ydpurch'">
            <div class="choiceblue flex">
                <div class="flex">
                    <div class="changeicon " [ngClass]="{changeicon:changblue,changeiconno:!changblue}"
                        (click)="changblueclick()"></div>
                    <div class="text">使用蓝滴咚贝抵扣</div>
                </div>
                <input placeholder="0" [(ngModel)]="bulunumber" type="number" id="imgtalk"
                    oninput="value=value.replace(/[^0-9-]+/,'');if(value<=0)value='';if(value>this.tobepaid)value=this.tobepaid;"
                    (ngModelChange)="modelChange($event)">
            </div>
            <div class="text2">(蓝贝余额：{{bluescallop}}枚)</div>
            <div class="choiceblue flex">
                <div class="flex">
                    <div class="changeicon " [ngClass]="{changeicon:changmoney,changeiconno:!changmoney}" (click)="moneyclick()"></div>
                    <div class="text">使用商圈钱包余额支付</div>
                </div>
            </div>
            <div class="text2">(可用余额：¥{{nowmoney}}<label *ngIf="deductionmoney">，已抵扣：¥{{deductionmoney}}</label>)</div>
        </div>
        <!-- 体验区抢购 -->
        <div class="order-item" *ngIf="unpaidinfo.newtype&&!unpaidinfo.iscompete">
            <div class="choiceblue flex">
                <div class="flex">
                    <div class="changeicon " [ngClass]="{changeicon:changmoney,changeiconno:!changmoney}" (click)="moneyclick()"></div>
                    <div class="text">使用易场钱包余额支付</div>
                </div>
            </div>
            <div class="text2">(可用余额：¥{{nowmoney?nowmoney:0}}<label *ngIf="deductionmoney">，已抵扣：¥{{deductionmoney}}</label>)</div>
        </div>
        <div *ngIf="unpaidinfo.type">
            <!-- 竞抢区 -->
            <div class="order-item" *ngIf="unpaidinfo.type==='compete'">
                <div class="choiceblue flex">
                    <div class="flex">
                        <div class="changeicon " [ngClass]="{changeicon:changmoney,changeiconno:!changmoney}" (click)="moneyclick()"></div>
                        <div class="text">使用竞抢区余额支付</div>
                    </div>
                </div>
                <div class="text2">(可用余额：¥{{nowmoney}}<label *ngIf="deductionmoney">，已抵扣：¥{{deductionmoney}}</label>)</div>
            </div>
            <!-- 易点商城 -->
            <div class="order-item" *ngIf="unpaidinfo.type==='ydpurch'">
                <div class="choiceblue flex">
                    <div class="flex">
                        <div class="changeicon " [ngClass]="{changeicon:changmoney,changeiconno:!changmoney}" (click)="moneyclick()"></div>
                        <div class="text">使用易场钱包余额支付</div>
                    </div>
                </div>
                <div class="text2">(可用余额：¥{{nowmoney}}<label *ngIf="deductionmoney">，已抵扣：¥{{deductionmoney}}</label>)</div>
            </div>
        </div>
        <div class="allprice">还需现金支付：<label>¥{{actuallymoney}}</label></div>
    </div>
    <!-- 支付环境显示 -->
    <div class="pay-type" *ngIf="environment==='app'">
        <div class="pay-type-item" *ngFor="let item of payitemarr;let index = index">
            <div class="flexiitem" *ngIf="item.env==='app'">
                <div class="pay-item">
                    <img [src]="item.imgurl" />
                    <div class="text">{{item.name}}</div>
                </div>
                <div class="changeicon" [ngClass]="{changeicon:item.pitch,changeiconno:!item.pitch}"
                    (click)="choosePayclick(item)"></div>
            </div>
        </div>
    </div>
    <div class="pay-type" *ngIf="environment==='h5'">
        <div class="pay-type-item" *ngFor="let item of payitemarr;let index = index">
            <div class="flexiitem" *ngIf="item.env==='h5'">
                <div class="pay-item">
                    <img [src]="item.imgurl" />
                    <div class="text">{{item.name}}</div>
                </div>
                <div class="changeicon" [ngClass]="{changeicon:item.pitch,changeiconno:!item.pitch}"
                    (click)="choosePayclick(item.type)"></div>
            </div>
        </div>
    </div>
    <div class="homepage-button" *ngIf="!unpaidinfo.showtobepaidorder">
        <div class="tab flex single " [ngClass]="{orange: unpaidinfo.type==='newgold'}" (click)="gosucceed()">
            支付
        </div>
    </div>
    <div class="homepage-button" *ngIf="unpaidinfo.showtobepaidorder">
        <div class="tab flex single " [ngClass]="{orange: unpaidinfo.type==='newgold'}" style="opacity: 0.3;" *ngIf="showtobepaidordershow!==0">
            支付({{showtobepaidordershow}})
        </div>
        <div class="tab flex single" [ngClass]="{orange: unpaidinfo.type==='newgold'}" (click)="gosucceed()" *ngIf="showtobepaidordershow===0">
            支付
        </div>
    </div>
</div>
<!-- 支付完成弹窗 -->
<div class="pay-commodel" *ngIf="askedtopay">
    <div class="pay-commodel-item">
        <div class="commodel-item">
            <div class="item-info">
                请在微信或支付宝内完成支付，如您已支付成功，请点击"已完成支付"按钮
            </div>
            <div class="button-pay">
                <div class="cancel" (click)="paySucceed()">取消</div>
                <div class="complete" (click)="paySucceed()">已完成支付</div>
            </div>
        </div>
    </div>
</div>