<div class="shopdetails" *ngIf="routedetails">
    <div class="head">
        <div class="banner-view">
            <Carousel *ngIf="routedetails.imgs.length>0" [infinite]="true" [dots]="false"
                style='height: 100vw;object-fit: cover;' (afterChange)="onBannerIndexChange($event)">
                <CarouselSlide dotPosition='top' dots *ngFor="let item of routedetails.imgs">
                    <img [src]="item+'?x-oss-process=image/resize,w_800,h_800,m_lfit'" alt=""
                        style='height: 100vw;object-fit: cover;'>
                </CarouselSlide>
            </Carousel>
            <div class="dots-all" *ngIf="dotsdata.length>1">
                <div class="dots">
                    <div *ngFor="let item of dotsdata;let index = index"
                        [ngClass]="{active:bannerIndex==index,noactive:bannerIndex!==index}"></div>
                </div>
            </div>
        </div>
        <div class="share" (click)="goDetailsshare()" *ngIf="!iswechat">
            <img src="https://base-assets.ddgeyou.com/multiactive/homeshare.png" />
        </div>
    </div>
    <div class="title-info">
        <div class="identifying"><img src="https://base-assets.ddgeyou.com/yidianshop/ydgivedetailstitle.png" /></div>
        <div class="all">
            <div class="shoptitle omits">{{routedetails.goods_name}}</div>
            <ng-template [ngIf]="routedetails.status!=3">
                <div *ngIf="routedetails.stock>0" class="tip-text">限购{{routedetails.purchase_limit}}件</div>
                <div *ngIf="0>=routedetails.stock && routedetails.status!=1" class="tip-not">库存不足</div>
            </ng-template>
            <div class="shop-label" *ngIf="routedetails.buy_give_point>0||routedetails.tickets>0">购买即送
                <div *ngIf="routedetails.buy_give_point>0">{{routedetails.buy_give_point}}易点</div>
                <div class="plus" *ngIf="routedetails.buy_give_point>0&&routedetails.tickets>0"> + </div>
                <div *ngIf="routedetails.tickets>0">{{routedetails.tickets}}
                    <span *ngIf="routedetails.tickets_type===1">消费点券</span>
                    <span *ngIf="routedetails.tickets_type===2">旅游点券</span>
                </div>
            </div>
            <div class="priceinfo">
                <div class="price-current">{{routedetails.selling_price}}<label>元</label></div>
                <div class="original" *ngIf="routedetails.scribing_price>routedetails.selling_price">原价: <label>{{routedetails.scribing_price}}</label></div>
            </div>
            <div class="otherinfo">
                <div class="sales">销量：{{routedetails.sales}}</div>
            </div>
        </div>
    </div>
    <div class="shop-info">
        <div class="header-title">商品详情</div>
        <div class="detail-view">
            <div class="content" [innerHTML]="routedetails.details"></div>
        </div>
    </div>
    <div class="shop-footer">
        <ng-template [ngIf]="routedetails.status==3">
            <div class="shop-footer-all disabled">
                <div class="purchase">已结束</div>
            </div>
        </ng-template>
        <ng-template [ngIf]="routedetails.status==1">
            <div class="shop-footer-all disabled">
                <div class="purchase">即将开始</div>
            </div>
        </ng-template>
        <ng-template [ngIf]="routedetails.status==2">
            <div *ngIf="routedetails.purchase_limit_status==1" class="shop-footer-all disabled">
                <div class="purchase">已达上限</div>
            </div>
            <ng-template [ngIf]="routedetails.purchase_limit_status!=1">
                <div *ngIf="0>=routedetails.stock" class="shop-footer-all disabled">
                    <div class="purchase">已售罄</div>
                </div>
                <div *ngIf="routedetails.stock>0" class="shop-footer-all" (click)="gocomPurch()">
                    <div class="pointlabel"><label *ngIf="routedetails.buy_give_point>0">送{{routedetails.buy_give_point}}易点</label></div>
                    <div class="purchase">立即购买</div>
                </div>
            </ng-template>
        </ng-template>
    </div>
</div>