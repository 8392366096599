<div class="container">
    <p class="title">简单3步完成入驻</p>
    <div class="item flex align-center justify-between"
         [ngClass]="{active: step === 0}">
        <p class="text">1. 支付入驻费用100元</p>
        <p class="link align-center"
           *ngIf="step === 0"
           (click)="pay()">
            去支付
            <Icon [type]="'right'" color="#006AEF" size="16"></Icon>
        </p>
        <p class="link success align-center"
           *ngIf="step > 0">
            已支付
            <Icon [type]="'check-circle'"
                  style="margin-left: 4px;"
                  color="#10B955"
                  size="xs"></Icon>
        </p>
    </div>
    <div class="item flex align-center justify-between"
         [ngClass]="{active: step === 1}">
        <p class="text">2. 实名认证</p>
        <p class="link flex align-center"
           *ngIf="step === 1"
           (click)="showModal()">
            去认证
            <Icon [type]="'right'" color="#006AEF"></Icon>
        </p>
        <p class="link success align-center"
           *ngIf="step > 1">
            已认证
            <Icon [type]="'check-circle'"
                  style="margin-left: 4px;"
                  color="#10B955"
                  size="xs"></Icon>
        </p>
    </div>
    <div class="item flex align-center justify-between"
         [ngClass]="{active: step === 2}">
        <p class="text">3.导游认证</p>
        <p class="link flex align-center"
           *ngIf="step === 2"
           (click)="showModal()">
            去认证
            <Icon [type]="'right'" color="#006AEF"></Icon>
        </p>
        <p class="link success align-center"
           *ngIf="step > 2">
            已认证
            <Icon [type]="'check-circle'"
                  style="margin-left: 4px;"
                  color="#10B955"
                  size="xs"></Icon>
        </p>
    </div>
</div>
