<div class="order-pay" *ngIf="paidudata&&concealpage">
    <app-loading-com  *ngIf="showloading"></app-loading-com>
    <div class="order-info">
        <div class="lable">待支付</div>
        <div class="lable2">￥<label>{{unpaidinfo.amount_to_be_paid}}</label></div>
        <div class="order-item">
            <div class="choiceblue flex">
                <div class="flex">
                    <div class="changeicon "[ngClass]="{changeicon:changmoney,changeiconno:!changmoney}" (click)="moneyclick()"></div>
                    <div class="text">使用新手钱包余额支付</div>
                </div>
            </div>
            <div class="text2">(可用：¥{{nowmoney?nowmoney:0}}<label *ngIf="deductionmoney">，已抵扣：¥{{deductionmoney}}</label>)</div>
        </div>
        <div class="allprice">还需现金支付：<label>¥{{actuallymoney}}</label></div>
        <div class="pay-type" *ngIf="environment==='app'">
            <div class="choiceblue flex">
                <div class="flex">
                    <div class="changeicon " [ngClass]="{changeicon:changtype===2,changeiconno:changtype!==2}" (click)="choosePayclick(2)"></div>
                    <div class="text">支付宝</div>
                </div>
            </div>
            <div class="choiceblue flex">
                <div class="flex">
                    <div class="changeicon "[ngClass]="{changeicon:changtype===5,changeiconno:changtype!==5}" (click)="choosePayclick(5)"></div>
                    <div class="text">支付宝快付</div>
                </div>
            </div>
            <div class="choiceblue flex">
                <div class="flex">
                    <div class="changeicon "[ngClass]="{changeicon:changtype===4,changeiconno:changtype!==4}" (click)="choosePayclick(4)"></div>
                    <div class="text">微信快付</div>
                </div>
            </div>
        </div>
        <div class="pay-type" *ngIf="environment==='h5'">
            <div class="choiceblue flex">
                <div class="flex">
                    <div class="changeicon "[ngClass]="{changeicon:changtype===1,changeiconno:changtype!==1}" (click)="choosePayclick(1)"></div>
                    <div class="text">微信</div>
                </div>
            </div>
        </div>
    </div>
    <div class="homepage-button" *ngIf="!unpaidinfo.showtobepaidorder">
        <div class="tab flex single" (click)="gosucceed()" >
            支付
        </div>
    </div>
    <div class="homepage-button"  *ngIf="unpaidinfo.showtobepaidorder">
        <div class="tab flex single" style="opacity: 0.3;" *ngIf="showtobepaidordershow!==0">
            支付({{showtobepaidordershow}})
        </div>
        <div class="tab flex single" (click)="gosucceed()" *ngIf="showtobepaidordershow===0">
            支付
        </div>
    </div>
</div>
<div class="pay-commodel" *ngIf="askedtopay">
    <div class="pay-commodel-item">
        <div class="commodel-item">
            <div class="item-info">
                请在微信或支付宝内完成支付，如您已支付成功，请点击"已完成支付"按钮
            </div>
            <div class="button-pay">
                <div class="cancel" (click)="paySucceed()">取消</div>
                <div class="complete" (click)="paySucceed()">已完成支付</div>
            </div>
        </div>
    </div>
</div>