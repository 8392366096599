<div class="container">
    <router-outlet #outlet="outlet" (activate)="prepareRoute(outlet)"></router-outlet>

    <!-- 下载按钮 -->
    <a class="download-button flex align-center justify-center"
       *ngIf="showDownload&&nowsell!=='uniapp'&&auth.isLogin"
       href="https://assets.ddgeyou.com/index.html">
        <img src="https://base-assets.ddgeyou.com/logo2021.6.22.png" alt="">
        <p>下载APP</p>
    </a>
    <a class="download-button flex align-center justify-center"
       *ngIf="showDownload&&nowsell==='uniapp'"
       href="https://assets.ddgeyou.com/ycindex.html">
        <img src="https://base-assets.ddgeyou.com/easygame/yichangicon.png" alt="" style="border-radius: 50%;"/>
        <p>下载APP</p>
    </a>
</div>
