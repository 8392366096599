import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Output,EventEmitter} from '@angular/core';
import { Input } from '@angular/core';
import { Router } from '@angular/router';
import { ToastService } from 'ng-zorro-antd-mobile';

@Component({
  selector: 'app-grouppopmodel',
  templateUrl: './grouppopmodel.component.html',
  styleUrls: ['./grouppopmodel.component.less']
})
export class GroupPopModelComponent implements OnInit {
  @Output() private outer = new EventEmitter()
  @Input() data

  constructor(
    private router: Router,
    private _toast: ToastService,
    private http: HttpClient
  ){}
  // 是否显示弹窗
  isShow = false
  groupList=[]
  // 已选择
  groupCheckNum= 0
  // 总额度
  groupPrice= 0
  // 是否到底加载
  isDown = false
  // 分页参数
  pageData = {
    page:1,//页码
    page_size:20,// 分页条数
    total_page:1// 最大分页数
  }

  ngOnInit(): void {
    this.getDataList();
  }

  // 监听滚动条事件
  onWindowScroll(e){
    const scrollTop = e.target.scrollTop,
    clientHeight = e.target.clientHeight,
    scrollHeight = e.target.scrollHeight
    const isDown = scrollTop + clientHeight + 40 > scrollHeight
    if(isDown){
      setTimeout(()=>{
        if(this.isDown)
          this.gpushRouteList()
      },500)
    }
  }

  // 是否到底
  gpushRouteList() {
    if(this.pageData.page>=this.pageData.total_page){
      this.pageData.page=this.pageData.total_page;
      return
    }
    this.isDown = false
    this.pageData.page++;
    this.getDataList();
  }

  getDataList(){
    this.http.post('/travel/api/getcanpickupgrouporder', {
      ...this.pageData
    }).subscribe(
      (res: { data }) => {
        this.isDown = true;
        this.pageData.total_page = res.data.total_page;
        res.data.list.map(item=>{
          item.isCheck = false
        })
        this.groupList = [...this.groupList,...res.data.list];
      }
    )
  }
  
  popShow(){
    this.isShow = true
  }

  popClose(){
    this.isShow = false
  }

  itemCheck(item){
    item.isCheck = !item.isCheck
    let checkList = this.groupList.filter(i=>{
      if(i.isCheck) return i
    })
    this.groupCheckNum = checkList.length
    this.groupPrice = checkList.reduce((pre: number, counts: any) =>
      this.subtract(pre, counts.pickup_worth,1),
    0)
  }

  // 合并取货
  subClick(){
    let checkList = this.groupList.filter(i=>{
      if(i.isCheck) return i
    })
    if(checkList.length==0){
      this._toast.show('请选择!');
      return
    }
    window.localStorage.setItem('pickup_info', '')
    let nos = checkList.map(i=>i.order_no)
    let data = {
      order_nos:nos,
      pickup_worth:this.groupPrice,
      pickup_area:'group_pickup'
    }
    let param = encodeURI(JSON.stringify(data))
    this.router.navigate(['/groupbook/pickup-list'], {
      queryParams: {
        param: param
      },
    }).then()
  }

  // 判断obj是否为一个整数
  isInteger(obj: any): boolean {
    return Math.floor(obj) === obj
  }

  /*
   * 将一个浮点数转成整数，返回整数和倍数。如 3.14 >> 314，倍数是 100
   * @param floatNum {number} 小数
   * @return {object}
   *   {times:100, num: 314}
   */
  toInteger(floatNum: any) {
    let ret = { times: 1, num: 0 }
    if (this.isInteger(floatNum)) {
      ret.num = floatNum
      return ret
    }
    let strfi = floatNum + ''
    let dotPos = strfi.indexOf('.')
    let len = strfi.substring(dotPos + 1).length
    let times = Math.pow(10, len)
    let intNum = parseInt((floatNum * times + 0.5) + '', 10)
    ret.times = times
    ret.num = intNum
    return ret
  }

  /*
   * 核心方法，实现加减乘除运算，确保不丢失精度
   * 思路：把小数放大为整数（乘），进行算术运算，再缩小为小数（除）
   */
  subtract(a, b,op) {
    let o1 = this.toInteger(a)
    let o2 = this.toInteger(b)
    let n1 = o1.num  // 3.25+3.153
    let n2 = o2.num
    let t1 = o1.times
    let t2 = o2.times
    let max = t1 > t2 ? t1 : t2
    let result = null
    switch (op) {
      // 加减需要根据倍数关系来处理
      case 1://加
        if (t1 === t2) { // 两个小数倍数相同
            result = n1 + n2
        } else if (t1 > t2) {
            // o1 小数位 大于 o2
            result = n1 + n2 * (t1 / t2)
        } else {  // o1小数位小于 o2
            result = n1 * (t2 / t1) + n2
        }
        return result / max
      case 2://减
        if (t1 === t2) {
            result = n1 - n2
        } else if (t1 > t2) {
            result = n1 - n2 * (t1 / t2)
        } else {
            result = n1 * (t2 / t1) - n2
        }
        return result / max
      case 3://乘
        result = (n1 * n2) / (t1 * t2)
        return result
      case 4://除
        result = (n1 / n2) * (t2 / t1)
        return result
    }
  }

}
