<div class="container_box" *ngIf="jsonData">
   <div class="share_img">
        <img src="https://base-assets.ddgeyou.com/yichang/share_title.png" />
   </div>
   <div class="share_tip">
        <img src="https://base-assets.ddgeyou.com/yichang/share_tip.png" />
    </div>
    <div class="share_text padding_top">易场APP甄选好物套装，抢购自用超值，</div>
    <div class="share_text padding_bot">转售享补贴，兑换得惊喜，抢到绝不亏!</div>
    <div class="share_tag">
        <img src="https://base-assets.ddgeyou.com/yichang/share_tag.png" />
    </div>
    <div class="share_tips">易场超值套装，每周一三五晚7:30准时开抢，新用户可享1单免抢资格，数量有限抢单吧~</div>
    <div class="share_footer">
        <div class="share_btn" *ngIf="jsonData.is_open" (click)="orderClick()">
            <span>免抢购下单</span>
            <div class="share_btn_tip">每人仅限1单</div>
        </div>
        <div class="share_btn_stop" *ngIf="!jsonData.is_open">活动已结束</div>
    </div>

    <div class="popup">
        <div [class]="{'mask':showDown}"></div>
        <div class="popup_box" [class]="{'on': showDown}">
            <div class="popup_title">
                <div class="popup_title_text">免抢资格仅限新用户哦~</div>
                <img src="https://base-assets.ddgeyou.com/yichang/share_close1.png" (click)="showDown=false" />
            </div>
            <div class="popup_content">
                <div class="popup_img"><img src="https://base-assets.ddgeyou.com/yichang/share_phone.png" /></div>
                <div class="popup_text">立即下载易场APP查看订单，参加转售补贴和大奖兑换吧~</div>
                <div class="popup_btn" (click)="downApp()">下载易场APP</div>
                <div class="popup_tip">
                    <span>系统支持:</span>
                    <img src="https://base-assets.ddgeyou.com/yichang/icon_android.png" />
                    <span class="popup_tip_text">Android</span>
                    <img src="https://base-assets.ddgeyou.com/yichang/icon_ios.png" />
                    <span class="popup_tip_text">IOS</span>
                </div>
            </div>
        </div>
        <div [class]="{'mask':showPopup}"></div>
        <div class="popup_box popup_bg" [class]="{'on': showPopup}">
            <div class="popup_title">
                <div class="popup_title_text">你有<span>1</span>次免抢下单机会<span>(3选1)</span></div>
                <img src="https://base-assets.ddgeyou.com/yichang/share_close.png" (click)="showPopup=false"  />
            </div>
            <div class="popup_order">
                <div class="popup_tip">说明：购买易场套装后可在取货专区挑选等值商品；凭套装参与易场指定活动还能得转售补贴等多项福利。</div>
                <div class="popup_item" *ngFor="let item of jsonData.package_price;let index = index">
                    <div class="popup_item_text">{{ item }}元套装</div>
                    <div class="popup_item_btn" (click)="buyClick(item)">抢单</div>
                </div>
            </div>
        </div>
    </div>

    <a class="download-button flex align-center justify-center"
       *ngIf="isLogin"
       href="https://assets.ddgeyou.com/ycindex.html">
        <img src="https://base-assets.ddgeyou.com/easygame/yichangicon.png" alt="" style="border-radius: 5px;"/>
        <p>下载APP</p>
    </a>
</div>