import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ExceptionComponent } from "./exception/exception.component";
import { CutComponent } from "./activity/talk/cut.component";
import { AuthGuard } from "../code/auth/auth.guard";
import { OrderComponent } from "./shop/order/order.component";
import { ConfirmComponent } from "./travel/confirm/confirm.component";
import { PayComponent } from "./merchant/pay/pay.component";
import { GuidePayComponent } from './guide/pay/pay.component';
import { TravelAgencyPayComponent } from './travelAgency/pay/pay.component';
import { DetailComponent } from "./videogoods/detail/detail.component";
import { OrderPayComponent } from "./groupbook/order-pay/order-pay.component";
import { SeckorderPayComponent } from "./newuserseckill/seckorder-pay/seckorder-pay.component";
import { H5orderPayComponent } from "./groupbook/h5order-pay/h5order-pay.component";
import { OrderListComponent } from "./groupbook/order-list/order-list.component";
import { GroupbookDetailsComponent } from "./groupbook/groupbook-details/groupbook-details.component";
import { CompetehomeComponent } from './multiactive/competehome/competehome.component';
import { NewuserSpecialComponent } from "./newuserseckill/community/newuser-special/newuser-special.component";
import { NewuserintroduceComponent } from "./newuserseckill/community/newuserintroduce/newuserintroduce.component";
import { GolddetailsComponent } from './newgoldactive/golddetails/golddetails.component';
import { MultiaactivehomeComponent } from './multiactive/multiaactivehome/multiaactivehome.component';
import { ProductdetailsComponent } from './multiactive/productdetails/productdetails.component';
import { OrderConfirmComponent } from './ydstoremall/order-confirm/order-confirm.component';
import { YdgivedetailsComponent } from './ydstoremall/ydgivedetails/ydgivedetails.component';
import { NewYdGiveDetailsComponent } from './ydstoremall/newydgivedetails/newydgivedetails.component';
import { DetailsComponent } from './cstore/order-child/details/details.component';
import { OrderpayComponent as BadOrderpayComponent } from './cstore/order-child/order-pay/order-pay.component';
import { OrderPayComponent as EarnOrderPayComponent } from './buyingarea/home-child/order-pay/order-pay.component';
import { OrderPayComponent as HerbalZoneOrderPayComponent } from './herbalzone/home-child/order-pay/order-pay.component';
import { OrderPayComponent as SimpleHerbalZoneOrderPayComponent } from './simpleherbalzone/home-child/order-pay/order-pay.component';
import { ShareComponent } from './activity/yichang/share/share.component';
import { OrderSplitpayComponent } from './simpleherbalzone/home-child/order-split-pay/order-split-pay.component';
import { OrderPayComponent as TravelloopOrderPayComponent } from './travelloop/home-child/order-pay/order-pay.component';
import { BuyTicketComponent as EntityStoreBuyTicketComponent } from './simpleherbalzone/entity-child/buy-ticket/buy-ticket.component';
import { StoreEditComponent as EntityStoreEditComponent } from './simpleherbalzone/entity-child/store-edit/store-edit.component';

const routes: Routes = [
    {
        path: '',
        children: [
            {
                path: '',
                redirectTo: 'exception/404',
                pathMatch: 'full'
            },
            {
                path: 'exception/:code',
                component: ExceptionComponent,
            },
            {
                path: 'activity',
                loadChildren: () => import('./activity/activity.module').then(m => m.ActivityModule),
                canActivate: [AuthGuard],
                canActivateChild: [AuthGuard],
            },
            {
                path: 'merchant',
                loadChildren: () => import('./merchant/merchant.module').then(m => m.MerchantModule)
            },
            {
                path: 'user',
                loadChildren: () => import('./user/user.module').then(m => m.UserModule),
                canActivate: [AuthGuard],
                canActivateChild: [AuthGuard],
            },
            {
                path: 'shop',
                loadChildren: () => import('./shop/shop.module').then(m => m.ShopModule),
                canActivate: [AuthGuard],
                canActivateChild: [AuthGuard],
            },
            {
                path: 'goldactive',
                loadChildren: () => import('./goldactive/goldactive.module').then(m => m.GoldactiveModule),
                canActivate: [AuthGuard],
                canActivateChild: [AuthGuard],
            }, {
                path: 'golddetails',
                component: GolddetailsComponent,
                data: {
                    shareUrl: true,
                    shareFriend: {
                        title: '金贝换购商城',
                        shareContent: '金贝换购商城'
                    },
                    shareTimeLine: {
                        title: '金贝换购商城',
                    }
                }
            },
            {
                path: 'groupbook',
                loadChildren: () => import('./groupbook/groupbook.module').then(m => m.GroupbookModule),
                canActivate: [AuthGuard],
                canActivateChild: [AuthGuard],
            },
            {
                path: 'newuserseckill',
                loadChildren: () => import('./newuserseckill/newuserseckill.module').then(m => m.NewuserseckillModule),
                canActivate: [AuthGuard],
                canActivateChild: [AuthGuard],
            },
            {
                path: 'travelcard',
                loadChildren: () => import('./travelcard/travelcard.module').then(m => m.TravelcardModule),
                canActivate: [AuthGuard],
                canActivateChild: [AuthGuard],
            },
            {
                path: 'greenactive',
                loadChildren: () => import('./greenactive/greenactive.module').then(m => m.GreenactiveModule),
                canActivate: [AuthGuard],
                canActivateChild: [AuthGuard],
            },
            {
                path: 'multiactive',
                loadChildren: () => import('./multiactive/multiactive.module').then(m => m.MultiactiveModule),
                canActivate: [AuthGuard],
                canActivateChild: [AuthGuard],
            },
            {
                path: 'multiaactivehome',
                component: MultiaactivehomeComponent,
            },
            {
                path: 'productdetails',
                component: ProductdetailsComponent,
            },
            {
                path: 'ydstoremall',
                loadChildren: () => import('./ydstoremall/ydstoremall.module').then(m => m.YdstoremallModule),
                canActivate: [AuthGuard],
                canActivateChild: [AuthGuard],
            },{
                path: 'ydorder-confirm',
                component: OrderConfirmComponent,
                data: {
                    shareUrl: true,
                    shareFriend: {
                        title: '订单确认',
                        shareContent: '订单确认'
                    },
                    shareTimeLine: {
                        title: '订单确认',
                    }
                }
            },{
                path: 'ydgivedetails',
                component: YdgivedetailsComponent,
                data: {
                    shareUrl: true,
                    shareFriend: {
                        title: '订单确认',
                        shareContent: '订单确认'
                    },
                    shareTimeLine: {
                        title: '订单确认',
                    }
                }
            },
            {
                path: 'newydgivedetails',
                component: NewYdGiveDetailsComponent,
                data: {
                    shareUrl: true,
                    shareFriend: {
                        title: '购物送易点',
                        shareContent: '购物送易点'
                    },
                    shareTimeLine: {
                        title: '购物送易点',
                    }
                }
            },
            {
                path: 'videogoods',
                loadChildren: () => import('./videogoods/videogoods.module').then(m => m.VideoGoodsModule),
                canActivate: [AuthGuard],
                canActivateChild: [AuthGuard],
            },
            {
                path: 'guide',
                loadChildren: () => import('./guide/guide.module').then(m => m.GuideModule),
                canActivate: [AuthGuard],
                canActivateChild: [AuthGuard],
            },
            {
                path: 'order',
                component: OrderComponent,
                data: { auth: true },
                canActivate: [AuthGuard]
            },
            {
                path: 'merchant-pay',
                component: PayComponent,
                data: { auth: true },
                canActivate: [AuthGuard]
            },
            {
                path: 'guide-pay',
                component: GuidePayComponent,
                // data: {auth: true},
                canActivate: [AuthGuard]
            },
            {
                path: 'travel-agency-pay',
                component: TravelAgencyPayComponent,
                // data: {auth: true},
                // canActivate: [AuthGuard]
            },
            {
                path: 'travel-agency',
                loadChildren: () => import('./travelAgency/travel-agency.module').then(m => m.TravelAgencyModule)
            },
            {
                path: 'travel',
                loadChildren: () => import('./travel/travel.module').then(m => m.TravelModule),
                canActivate: [AuthGuard],
                canActivateChild: [AuthGuard],
            },
            {
                path: 'travel-order-pay',
                component: ConfirmComponent,
                data: { auth: true },
                canActivate: [AuthGuard]
            },
            {
                path: 'preferential',
                loadChildren: () => import('./preferential/preferential.module').then(m => m.PreferentialModule),
                canActivate: [AuthGuard],
                canActivateChild: [AuthGuard],
            },
            {
                path: 'newgoldactive',
                loadChildren: () => import('./newgoldactive/newgoldactive.module').then(m => m.NewgoldactiveModule),
                canActivate: [AuthGuard],
                canActivateChild: [AuthGuard],
            },
            {
                path: 'videodetail',
                component: DetailComponent,
                data: { noDetach: true }
            },
            {
                path: 'order-pay',
                component: OrderPayComponent,
                data: { noDetach: true }
            },
            {
                path: 'seckorder-pay',
                component: SeckorderPayComponent,
                data: { noDetach: true }
            },
            {
                path: 'h5order-pay',
                component: H5orderPayComponent,
                data: {
                    shareUrl: true,
                    shareFriend: {
                        title: '购买成功',
                        shareContent: '购买成功'

                    },
                    shareTimeLine: {
                        title: '购买成功',
                    }
                }
            },
            {
                path: 'order-list',
                component: OrderListComponent,
                data: {
                    shareUrl: true,
                    shareFriend: {
                        title: '订单列表',
                        shareContent: '订单列表'
                    },
                    shareTimeLine: {
                        title: '订单列表',
                    }
                }
            },
            {
                path: 'groupbookdetails',
                component: GroupbookDetailsComponent,
                data: {
                    shareUrl: true,
                    shareFriend: {
                        title: '组团购详情',
                        shareContent: '组团购详情'
                    },
                    shareTimeLine: {
                        title: '组团详情',
                    }
                }
            },
            {
                path: 'newuser-special',
                component: NewuserSpecialComponent,
                data: {
                    shareUrl: true,
                    shareFriend: {
                        title: '新手福利专场',
                        shareContent: '新手福利专场'
                    },
                    shareTimeLine: {
                        title: '新手福利专场',
                    }
                }
            },
            {
                path: 'newuserintroduce',
                component: NewuserintroduceComponent,
                data: {
                    shareUrl: true,
                    shareFriend: {
                        title: '介绍页',
                        shareContent: '介绍页'
                    },
                    shareTimeLine: {
                        title: '介绍页',
                    }
                }
            },
            {
                path: 'competehome',
                component: CompetehomeComponent,
                data: {
                    shareUrl: true,
                    shareFriend: {
                        title: 'h5竞抢页',
                        shareContent: 'h5竞抢页'
                    },
                    shareTimeLine: {
                        title: 'h5竞抢页',
                    }
                }
            },
            {
                path: 'cstore',
                loadChildren: () => import('./cstore/cstore.module').then(m => m.CstoreModule),
                canActivate: [AuthGuard],
                canActivateChild: [AuthGuard],
            },
            {
                path: 'cstoredetails/:goodsId',
                component: DetailsComponent,
                data: {
                    shareUrl: true,
                    shareFriend: {
                        title: '详情',
                        shareContent: '详情'
                    },
                    shareTimeLine: {
                        title: '详情'
                    }
                }
            },
            {
                path: 'cstoredetails/:goodsId/:configId',
                component: DetailsComponent,
                data: {
                    shareUrl: true,
                    shareFriend: {
                        title: '详情',
                        shareContent: '详情'
                    },
                    shareTimeLine: {
                        title: '详情'
                    }
                }
            },
            {
                path: 'badorderpay',
                component: BadOrderpayComponent,
                data: { noDetach: true }
            },
            {
                path: 'center',
                loadChildren: () => import('./operationscenter/operationscenter.module').then(m => m.OperationsCenterModule),
                canActivate: [AuthGuard],
                canActivateChild: [AuthGuard],
            },
            {
                path: 'buyingarea',
                loadChildren: () => import('./buyingarea/buyingarea.module').then(m => m.BuyingAreaModule),
                canActivate: [AuthGuard],
                canActivateChild: [AuthGuard],
            },
            {
                path: 'earnorderpay', // 抢购专区支付页面
                component: EarnOrderPayComponent,
                data: { noDetach: true }
            },
            {
                path: 'herbalzone',
                loadChildren: () => import('./herbalzone/herbalzone.module').then(m => m.HerbalZoneModule),
                canActivate: [AuthGuard],
                canActivateChild: [AuthGuard],
            },
            {
                path: 'herbalzonepay', // 草本专区支付页面
                component: HerbalZoneOrderPayComponent,
                data: { noDetach: true }
            },
            {
                path: 'simpleherbalzone',
                loadChildren: () => import('./simpleherbalzone/simpleherbalzone.module').then(m => m.SimpleHerbalZoneModule),
                canActivate: [AuthGuard],
                canActivateChild: [AuthGuard],
            },
            {
                path: 'simpleherbalzonepay', // 消费循环支付页面
                component: SimpleHerbalZoneOrderPayComponent,
                data: { noDetach: true }
            },
            {
                path: 'yichangshare',
                component: ShareComponent
            },
            {
                path: 'simpleherbalzonesplitpay', // 消费循环分笔支付页面
                component: OrderSplitpayComponent
            },
            {
                path: 'travelloop', // 旅游循环页面
                loadChildren: () => import('./travelloop/travelloop.module').then(m => m.TravelLoopModule),
                canActivate: [AuthGuard],
                canActivateChild: [AuthGuard],
            },
            {
                path: 'travellooppay', // 旅游循环支付页面
                component: TravelloopOrderPayComponent
            },
            {
                path: 'buyticket', // 购买易券
                component: EntityStoreBuyTicketComponent
            },
            {
                path: 'storeedit', // 商户入驻
                component: EntityStoreEditComponent
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forRoot([...routes,{
        path: '**',component: ExceptionComponent,
    }])],
    exports: [RouterModule]
})
export class RoutesRoutingModule {
}
