import { Component, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router'
import { HttpClient } from "@angular/common/http";
import { ToastService } from 'ng-zorro-antd-mobile';
import { Title } from '@angular/platform-browser';
import { InviteService } from 'src/app/code/service/invite/invite.service';
import { PlatformService } from 'src/app/code/service/platform/platform.service';
import { AuthService } from 'src/app/code/service/auth/auth.service';
import { WechatService } from 'src/app/code/service/wechat/wechat.service';
import { ComputedService } from 'src/app/code/service/computed/computed.service'

type orderInfo = {
  order_no: string,
  body: string,
  pay_type: number,
  paySignData: string
};

@Component({
  selector: 'app-order-pay',
  templateUrl: './order-pay.component.html',
  styleUrls: ['./order-pay.component.less']
})
export class OrderpayComponent implements OnInit {


  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    private _toast: ToastService,
    private title: Title,
    private invite: InviteService,
    private platform: PlatformService,
    private auth: AuthService,
    private wechat: WechatService,
    private computed: ComputedService,
    private ngZone: NgZone,
  ) {
    title.setTitle('分笔支付');
  }

  isAndroid = false
  isIphoneX = false
  // 待支付总额
  totalPrice = 0
  // 转义后的总额
  totalPriceText = null
  // 可选择的支付金额
  moneyList = [10000,20000,50000]
  maxInputVal: any = 50000
  // 手动输入的值
  inputMoney = null
  // 选择的支付金额--默认都不选 0-2对应moneyList下标 3为手动输入
  selectIndex = -1
  // 选择的支付方式--默认都不选 0和合资产 后续为动态支付方式
  payWayIndex = -1
  // 是否选择蓝贝
  selectBlue = false
  // 可用蓝贝
  usableBlue: any = 0
  // 用于抵扣的蓝贝数
  buckleBlue = 0
  // 输入的蓝贝数
  inputBlue = null
  // 是否选择余额
  selectMoney = false
  // 可用钱包余额
  usableMoney = 0
  // 用于抵扣的钱包余额
  buckleMoney = 0
  // 需要支付的总数
  payTotalMoney: any = 0
  // 支付类型数组
  payWayArr = []
  // 弹窗参数
  popdata: any = {}
  // 防抖
  isSubmit = true
  // 主订单号
  order_no = null
  // 子订单号
  sub_order_no = null
  // 平台环境
  envPlatform = 'app'


  ngOnInit(): void {
    this.isIphoneX = this.platform.CheckIsIphoneX()
    this.isAndroid = this.platform.CheckIsAndroid()
    this.order_no = this.route.snapshot.queryParams?.order_no
    this.nowEnvironment()
    this.getinvestorderpayableprice()
    this.getPlatformMoney()
    if (this.platform.CheckIsAndroid()) {
      // 根据不同页面判断获取不同的支付方式
      this.getPayEnv('android')
    } else {
      this.getPayEnv('ios')
    }
    // 支付成功和失败的回调
    const w = window as any;
    w.notifyWebPaySuccess = this.notifyWebPaySuccess.bind(this)
    w.notifyWebPayError = this.notifyWebPayError.bind(this)
    w.wechatSeverPaySuccess = this.wechatSeverPaySuccess.bind(this)// 走微信支付的回调
  }

  ngOnDestroy(): void {
    const w = window as any;
    delete w.notifyWebPaySuccess;
    delete w.notifyWebPayError;
    delete w.wechatSeverPaySuccess;
  }

  notifyWebPaySuccess(){}
  notifyWebPayError(){}
  wechatSeverPaySuccess(){}

  //当前环境判断
  nowEnvironment() {
    this.platform.check(
      null,
      () => this.appEnvironment(),
      () => this.appEnvironment(),
      () => this.h5Environment()
    );
  }
  // app环境
  appEnvironment() {
    this.envPlatform = 'app'
  }
  //h5环境
  h5Environment() {
    this.envPlatform = 'h5'
  }

  // 金额数量选择
  moneyClick(index,num){
    if(num>this.totalPrice)return
    this.selectIndex = index
    this.payTotalMoney = num
    this.payWayIndex = -1
    this.resetSelect()
  }

  // 选择手动输入
  inputClick(){
    this.selectIndex = 3
    document.getElementById('payInput').focus()
    this.payWayIndex = -1
    this.payTotalMoney = Number(this.inputMoney)||0
    this.resetSelect()
  }

  // 重置参数
  resetSelect(){
    this.selectBlue = false
    this.selectMoney = false
    this.inputBlue = null
    this.buckleMoney = 0
    this.buckleBlue = 0
  }

  // 手动输入值变更
  modelMoneyChange(e){
    if(e)
      e = this.inputMoney = e.toString().replace(/[^\d.]/g, "").replace(/\.{2,}/g, ".").replace(".", "$#$").replace(/\./g, "").replace("$#$", ".").replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3')
    if (Number(e) > Number(this.maxInputVal)) {
      (document.getElementById("payInput") as HTMLInputElement).value = this.maxInputVal
      this.payTotalMoney = this.inputMoney = Number(this.maxInputVal)
    }else
      this.payTotalMoney = Number(e)
  }

  // 数值转换
  toThousands(num) {
    let result = '', counter = 0,endNum = '';
    num = (num || 0).toString();
    if(num.indexOf('.')!=-1){
      endNum = num.substring(num.indexOf('.'))
      num = num.substring(0,num.indexOf('.'))
    }
    for (let i = num.length - 1; i >= 0; i--) {
        counter++
        result = num.charAt(i) + result
        if (!(counter % 3) && i != 0) { result = ',' + result }
    }
    return result+endNum;
  }

  // 选择支付方式
  paywayClick(index){
    if(index==0 && this.payTotalMoney > this.computed.subtract(this.usableBlue,this.usableMoney,1)){
      this._toast.show('和合商圈资产不足请重新修改支付金额')
      return
    }
    this.resetSelect()
    this.payWayIndex = index
  }

  // 获取投资订单应付金额
  getinvestorderpayableprice(){
    if(!this.order_no)return
    this.http.post('/cshop/api/getinvestorderpayableprice', {order_no:this.order_no}).subscribe(
      (res: { data }) => {
        this.totalPrice = res.data.amount_to_be_paid
        this.totalPriceText = this.toThousands(this.totalPrice)
        this.maxInputVal = this.totalPrice<this.maxInputVal?this.totalPrice:this.maxInputVal
      }
    )
  }

  // 获取平台可用余额
  getPlatformMoney(){
    this.http.post('/cshop/api/getuserplatformcurrency', {}).subscribe(
      (res: { data }) => {
        this.usableMoney = res.data.money
        this.usableBlue = res.data.blue_scallop
      }
    )
  }

  // 获取支付环境
  getPayEnv(e){
    this.http.post('/cshop/api/getpayenv', { platform: e }).subscribe(
      (res: { data }) => {
        this.payWayArr = res.data
      }
    )
  }

  // 蓝贝值输入变更
  modelBlueChange(e){
    if(Number(e) >= Number(this.payTotalMoney) && Number(this.payTotalMoney) < Number(this.usableBlue)){
      (document.getElementById("blueInput") as HTMLInputElement).value = Math.floor(this.payTotalMoney)+''
      this.inputBlue = Math.floor(this.payTotalMoney)
    }
    if (Number(e) >= Number(this.usableBlue) && Number(this.payTotalMoney) >= Number(this.usableBlue)) {
      (document.getElementById("blueInput") as HTMLInputElement).value = this.usableBlue
      this.inputBlue = this.usableBlue
    }
    this.deduction()
  }

  // 选择蓝贝
  blueClick(){
    this.selectBlue = !this.selectBlue
    this.deduction()
  }

  // 选择余额
  surplusClick(){
    this.selectMoney = !this.selectMoney
    this.deduction()
  }

  // 抵扣的公共方法
  deduction() {
    this.buckleBlue = this.selectBlue?Number(this.inputBlue?this.inputBlue:0):0//抵扣蓝贝数
    if(this.selectMoney){// 钱包余额
      const maxMoney = this.computed.subtract(this.payTotalMoney,this.buckleBlue,2)
      this.buckleMoney = this.usableMoney>maxMoney?maxMoney:this.usableMoney
    }else{
      this.buckleMoney = 0
    }
  }

  // 立即支付
  payClick(){
    if(this.selectIndex==-1||!this.payTotalMoney){
      this._toast.show('请选择或填写支付金额')
      return
    }
    if(this.payWayIndex==-1){
      this._toast.show('请选择支付方式')
      return
    }
    if(this.payWayIndex==0&&this.payTotalMoney > this.computed.subtract(this.buckleBlue,this.buckleMoney,1)){
      this._toast.show('和合商圈资产不足请选择足够金额后提交')
      return
    }
    if(!/^(?:0|[1-9]\d*)(\.[0-9]{0,2})?$/.test(this.payTotalMoney.toString())){
      this._toast.show('请输入正确格式的金额')
      return
    }
    if(this.payWayIndex){
      this.popdata = {
        isShow: true,
        data: 1,//查看订单
        text: '请在微信或支付宝内完成支付，如您已支付成功，请点击“完成支付”按钮',
        okText: '完成支付',
        isClose: true,
        isCancal: true,
        isCancalWide: true,
        isTwoTheme: true
      }
    }
    this.platformPay()
  }

  // 弹窗确认返回
  getReturnData(data) {
    this.popdata.isShow = false
    if (data === -1){//跳转订单页
      this.router.navigate(['/cstore/order'], {queryParams: { index: 1},replaceUrl: true}).then()
    } else if (data === 1) {
      this.queryOrderStatus()
    }
  }

  // 区分平台支付
  platformPay(){
    if(this.isSubmit){
      this.isSubmit = false
      setTimeout(() => {
        this.isSubmit = true
      }, 2000)
      this.platform.check(
        null,
        () => this.appPay(),
        () => this.appPay(),
        () => this.jsapiPay()
      )
    }
  }

  // app支付
  appPay(){
    if (this.order_no) {
      const dataparam = {
        order_no: this.order_no,
        pay_type: this.payWayIndex,
        client_type: 3,
        this_amount: this.payTotalMoney,
        blue_scallop_amount: this.selectBlue ? this.buckleBlue : 0,
        wallet_balance_amount: this.selectMoney ? this.buckleMoney : 0
      }
      this.http.post('/cshop/api/investorderpaymentsignature', dataparam).subscribe((res: { code: number, data: orderInfo, pay_type, msg: any }) => {
        if (res.code === 0) {// pay_type是支付为0，不跳支付
          if (res.data.pay_type === 0) {
            this.router.navigate(['/cstore/payresult'], {queryParams: { order_no: this.order_no },replaceUrl: true}).then()
          } else {
            this.sub_order_no = res.data.order_no
            if (res.data.pay_type === 2 || res.data.pay_type === 10) {//支付宝/直付通支付宝原生支付
              this.platform.check(
                null,
                () => window['android'].aliPayOther(res.data.order_no, res.data.body),
                () => window['webkit'].messageHandlers.aliPayOther.postMessage(JSON.stringify(res.data)),
                null
              );
            } else {
              this.platform.check(
                null,
                () => window['android'].aliPayFast(res.data.order_no, res.data.paySignData),
                () => window['webkit'].messageHandlers.aliPayFast.postMessage(JSON.stringify(res.data)),
                null
              );
            }
          }
        } else if (res.code === 4711) {
          setTimeout(() => {
            history.go(-1)
          }, 1000);
        }
      });
    }
  }

  // h5支付
  jsapiPay(){
    if (this.order_no) {
      const dataparam = {
        order_no: this.order_no,
        pay_type: this.payWayIndex,
        client_type: 1,
        this_amount: this.payTotalMoney,
        blue_scallop_amount: this.selectBlue ? this.buckleBlue : 0,
        wallet_balance_amount: this.selectMoney ? this.buckleMoney : 0,
        open_id: localStorage.getItem('openId')
      }
      this.http.post('/cshop/api/investorderpaymentsignature', dataparam).subscribe((res: { code: number, data: orderInfo }) => {
        if (res.data.pay_type === 0) {//全额抵扣
          this.router.navigate(['/cstore/payresult'], {queryParams: { order_no: this.order_no },replaceUrl: true}).then()
        }else{// 支付
          this.sub_order_no = res.data.order_no
          if(res.data.pay_type===1){
            this.wechat.pay(res.data, res.data.order_no, () => {
              if (window.localStorage.getItem('paymentstatus')) {// 微信取消支付
                window.localStorage.removeItem('paymentstatus');
                this.ngZone.run(() => this.router.navigateByUrl('/cstore/order', { queryParams: { index: 1 },replaceUrl: true })).then()
              } else {// 微信h5支付--->跳转成功无法带参数
                this.ngZone.run(() => this.router.navigateByUrl(`/cstore/payresult?order_no=${this.order_no}`, { replaceUrl: true })).then()
              }
            })
          }
        }
      })
    }
  }

  // 发起支付后查询订单支付状态
  queryOrderStatus(){
    this.http.post('/cshop/api/getinvestsuborderstatusafterinitiatepayment', { sub_order_no: this.sub_order_no }).subscribe(
      (res: { data }) => {
        if(res.data.pay_status){
          this._toast.show('支付成功')
          this.router.navigate(['/cstore/payresult'], {queryParams: { order_no: this.order_no },replaceUrl: true}).then()
        }else{
          this._toast.fail('支付失败')
          this.router.navigate(['/cstore/order'], {queryParams: { index: 1},replaceUrl: true}).then()
        }
      }
    );
  }

}
