import { HttpClient } from '@angular/common/http';
import { Component, NgZone, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from 'ng-zorro-antd-mobile';
import { ComputedService } from 'src/app/code/service/computed/computed.service';
import { PlatformService } from 'src/app/code/service/platform/platform.service';
import { WechatService } from 'src/app/code/service/wechat/wechat.service';

type orderInfo = {
  order_no: string,
  body: string,
  pay_type: number,
  paySignData: string
};

@Component({
  selector: 'app-order-pay',
  templateUrl: './order-pay.component.html',
  styleUrls: ['./order-pay.component.less']
})
export class OrderPayComponent implements OnInit {

  constructor(
    private title: Title,
    private router: Router,
    private route: ActivatedRoute,
    private _toast: ToastService,
    private http: HttpClient,
    private platform: PlatformService,
    private computed: ComputedService,
    private wechat: WechatService,
    private ngZone: NgZone,
  ) {
    title.setTitle('支付订单');
  }

  // 平台环境
  envPlatform = 'app'
  // 待支付金额
  toPaidMoney = 0
  // 可用和合钱包余额
  usableMoney;
  // 可用蓝贝
  usableBlue;
  // 可用蓝贝
  usableTravelBlue;
  // 用于抵扣的蓝贝数
  buckleBlue
  // 用于抵扣的钱包余额
  buckleMoney = 0
  // 用于抵扣的旅游蓝贝数
  buckleTravelBlue
  // 还需支付金额
  requiredMoney
  // 是否选择蓝贝
  selectBlue = false
  // 是否选择余额
  selectMoney = false
  // 是否选择旅游蓝贝
  selectTravelBlue = false
  // 选择的支付方式--默认0
  payWayIndex = 0
  // 支付类型数组
  payWayArr = []
  // 弹窗参数
  popData: any = {}
  // 防抖
  isSubmit = true
  // 订单号
  orderNo = null
  // 订单类型 0秒杀 1超值挂卖购买
  orderType = 0

  resultPath = ''

  cancelPath = ''
  // 选择的蓝贝数
  blue_scallop_amount = 0
  // 选择的钱包余额
  moxibustion_wallet_amount = 0
  // 选择的旅游蓝贝数
  travel_blue_scallop_amount = 0
  // 支付提示
  isShowModel = false
  // 支持循环蓝贝支付
  isCheckBlue = 0

  ngOnInit(): void {
    let routprams = JSON.parse(decodeURI(this.route.snapshot.queryParams?.param))
    this.orderNo = routprams.order_no
    this.orderType = +routprams.order_type || 0
    this.blue_scallop_amount = +routprams.blue_scallop_amount
    this.moxibustion_wallet_amount = +routprams.moxibustion_wallet_amount
    this.travel_blue_scallop_amount = +routprams.travel_blue_scallop_amount
    this.isCheckBlue = +routprams.is_blue || 0
    this.resultPath = this.orderType?'/travelloop/sellingresult':'/travelloop/payresult'
    this.cancelPath = this.orderType?'/travelloop/order':routprams.cancel_path
    this.toPaidMoney = this.requiredMoney = Number(routprams?.amount_to_be_paid)
    this.nowEnvironment()
    this.getPlatformMoney()
    this.getPayEnv()
    // 支付成功和失败的回调
    const w = window as any;
    w.notifyWebPaySuccess = this.notifyWebPaySuccess.bind(this)
    w.notifyWebPayError = this.notifyWebPayError.bind(this)
    w.wechatSeverPaySuccess = this.wechatSeverPaySuccess.bind(this)// 走微信支付的回调
  }

  ngOnDestroy(): void {
    const w = window as any;
    delete w.notifyWebPaySuccess;
    delete w.notifyWebPayError;
    delete w.wechatSeverPaySuccess;
  }

  // app回调支付成功
  notifyWebPaySuccess(){}
  // app回调支付失败
  notifyWebPayError(){}
  // app走微信服务商失败和成功的回调
  wechatSeverPaySuccess(res){}

  // 获取平台可用余额
  getPlatformMoney(){
    this.http.post('/moxibustion/api/getuserplatformcurrency', {}).subscribe(
      (res: { data }) => {
        this.usableMoney = res.data.moxibustion_money
        this.usableBlue = res.data.blue_scallop
        this.usableTravelBlue = res.data.moxibustion_travel_blue_scallop // 旅游蓝贝
        if(this.blue_scallop_amount){
          this.buckleBlue = this.blue_scallop_amount
          this.selectBlue = true
        }
        if(this.moxibustion_wallet_amount){
          this.buckleMoney = this.moxibustion_wallet_amount
          this.selectMoney = true
        }
        if(this.travel_blue_scallop_amount){
          this.buckleTravelBlue = this.travel_blue_scallop_amount
          this.selectTravelBlue = true
        }
        this.deduction()
      }
    )
  }

  // 获取支付环境
  getPayEnv(){
    this.http.post('/moxibustion/api/getherbalpayenv', {  }).subscribe(
      (res: { data }) => {
        this.payWayArr = res.data
        this.payWayIndex = this.payWayArr.filter(item => item.env === this.envPlatform).map(item=>item.type)[0]
      }
    )
  }

  //当前环境判断
  nowEnvironment() {
    this.platform.check(
      null,
      () => this.appEnvironment(),
      () => this.appEnvironment(),
      () => this.h5Environment()
    );
  }
  // app环境
  appEnvironment() {
    this.envPlatform = 'app'
  }
  //h5环境
  h5Environment() {
    this.envPlatform = 'h5'
  }

  // 蓝贝选择
  changblueclick() {
    if(this.blue_scallop_amount)return
    this.selectBlue = !this.selectBlue;
    this.deduction()
  }
  // 余额选择
  moneyclick() {
    if(this.moxibustion_wallet_amount)return
    this.selectMoney = !this.selectMoney;
    this.deduction()
  }

  // 旅游蓝贝选择
  changTravelClick(){
    if(this.travel_blue_scallop_amount)return
    this.selectTravelBlue = !this.selectTravelBlue;
    this.deduction()
  }

  // 抵扣的公共方法
  deduction() {
    // 待支付数
    let needPayNum = Number(this.toPaidMoney);
    if (this.travel_blue_scallop_amount) {
      needPayNum = this.computed.subtract(needPayNum,this.travel_blue_scallop_amount,2)
    }
    if (this.blue_scallop_amount) {
      needPayNum = this.computed.subtract(needPayNum,this.blue_scallop_amount,2)
    }
    if (this.moxibustion_wallet_amount) {
      needPayNum = this.computed.subtract(needPayNum,this.moxibustion_wallet_amount,2)
    }
    if (this.selectTravelBlue) {
      if (this.travel_blue_scallop_amount) {
        this.buckleTravelBlue = this.travel_blue_scallop_amount
      } else {
        this.buckleTravelBlue = this.usableTravelBlue > needPayNum ? needPayNum : this.usableTravelBlue
        needPayNum = this.computed.subtract(needPayNum,this.buckleTravelBlue,2)
      }
    } else {
      this.buckleTravelBlue = 0
    }
    if (this.selectBlue) {
      if (this.blue_scallop_amount) {
        this.buckleBlue = this.blue_scallop_amount
      } else {
        this.buckleBlue = this.usableBlue > needPayNum ? needPayNum : this.usableBlue
        needPayNum = this.computed.subtract(needPayNum,this.buckleBlue,2)
      }
    } else {
      this.buckleBlue = 0
    }
    if (this.selectMoney) {
      if (this.moxibustion_wallet_amount) {
        this.buckleMoney = this.moxibustion_wallet_amount
      } else {
        this.buckleMoney = this.usableMoney > needPayNum ? needPayNum : this.usableMoney
        needPayNum = this.computed.subtract(needPayNum,this.buckleMoney,2)
      }
    } else {
      this.buckleMoney = 0
    }
    this.requiredMoney = needPayNum
  }

  // 选择支付方式
  paywayClick(index){
    this.payWayIndex = index
  }

  // 去支付
  payClick(){
    if(this.selectTravelBlue&&this.buckleTravelBlue){
      this.isShowModel = true
      return
    }
    this.payAckClick()
  }

  // 继续支付
  payAckClick(){
    this.isShowModel = false
    if(this.isSubmit){
      this.isSubmit = false
      setTimeout(() => {
        this.isSubmit = true
      }, 2000)
      this.platform.check(
        null,
        () => this.appPay(),
        () => this.appPay(),
        () => this.jsapiPay()
      )
    }
  }

  // app支付
  appPay(){
    if (this.orderNo) {
      let param = {}
      if(this.orderType){
        param = {
          order_no: this.orderNo,
          pay_type: this.payWayIndex,
          client_type: 3
        }
      }else{
        param = {
          order_no: this.orderNo,
          pay_type: this.payWayIndex,
          client_type: 3,
          blue_scallop_amount: this.selectBlue ? this.buckleBlue : 0,
          moxibustion_wallet_amount: this.selectMoney ? this.buckleMoney : 0,
          moxibustion_travel_blue_scallop_amount: this.selectTravelBlue ? this.buckleTravelBlue : 0
        }
      }
      const url = !this.orderType?'/moxibustion/api/travelseckillorderpaymentsignature':'/moxibustion/api/travelorderpaymentsignature'
      this.http.post(url, param).subscribe((res: { code: number, data: orderInfo, pay_type, msg: any }) => {
        if (res.code === 0) {// pay_type是支付为0，不跳支付
          if (res.data.pay_type === 0) {
            this.router.navigate([this.resultPath], {queryParams: {  },replaceUrl: true}).then()
          } else {
            this.showPopUp()
            if (res.data.pay_type === 2 || res.data.pay_type === 10) {//支付宝原生支付
              this.platform.check(
                null,
                () => window['android'].aliPayOther(res.data.order_no, res.data.body),
                () => window['webkit'].messageHandlers.aliPayOther.postMessage(JSON.stringify(res.data)),
                null
              );
            } else if(res.data.pay_type === 11 || res.data.pay_type === 12){
              this.platform.check(
                null,
                () => window['android'].openBrowser(res.data.paySignData),
                () => window['webkit'].messageHandlers.openBrowser.postMessage(res.data.paySignData),
                null
              );
            } else {
              this.platform.check(
                null,
                () => window['android'].aliPayFast(res.data.order_no, res.data.paySignData),
                () => window['webkit'].messageHandlers.aliPayFast.postMessage(JSON.stringify(res.data)),
                null
              );
            }
          }
        } else if (res.code === 4711) {
          setTimeout(() => {
            history.go(-1)
          }, 1000);
        }
      });
    }
  }

  // h5支付
  jsapiPay(){
    if (this.orderNo) {
      let param = {}
      if(this.orderType){
        param = {
          order_no: this.orderNo,
          pay_type: this.payWayIndex,
          client_type: 1,
          open_id: localStorage.getItem('openId')
        }
      }else{
        param = {
          order_no: this.orderNo,
          pay_type: this.payWayIndex,
          client_type: 1,
          blue_scallop_amount: this.selectBlue ? this.buckleBlue : 0,
          moxibustion_wallet_amount: this.selectMoney ? this.buckleMoney : 0,
          moxibustion_travel_blue_scallop_amount: this.selectTravelBlue ? this.buckleTravelBlue : 0,
          open_id: localStorage.getItem('openId')
        }
      }
      const url = !this.orderType?'/moxibustion/api/travelseckillorderpaymentsignature':'/moxibustion/api/travelorderpaymentsignature'
      this.http.post(url, param).subscribe((res: { code: number, data: orderInfo }) => {
        if (res.data.pay_type === 0) {//全额抵扣
          this.router.navigate([this.resultPath], {queryParams: {  },replaceUrl: true}).then()
        }else{// 支付
          this.showPopUp()
          if(res.data.pay_type===1){
            this.wechat.pay(res.data, res.data.order_no, () => {
              if (window.localStorage.getItem('paymentstatus')) {// 微信取消支付
                window.localStorage.removeItem('paymentstatus');
                this.ngZone.run(() => this.router.navigateByUrl(this.cancelPath, { replaceUrl: true })).then()
              } else {// 微信h5支付
                this.ngZone.run(() => this.router.navigateByUrl(this.resultPath, { queryParams: {  },replaceUrl: true })).then()
              }
            })
          } else if(res.data.pay_type === 11){
            window.location.href = res.data.paySignData
          } else if(res.data.pay_type === 12){
            this.router.navigate(['/simpleherbalzone/alipayh5'], { queryParams: { orderNo: encodeURIComponent(res.data.paySignData) } }).then()
          }
        }
      })
    }
  }

  // 显示支付弹窗公共方法
  showPopUp(){
    this.popData = {
      isShow: true,
      data: 1,//完成支付
      text: '请在微信或支付宝内完成支付，如您已支付成功，请点击“完成支付”按钮',
      okText: '完成支付'
    }
  }

  // 弹窗确认返回
  getReturnData(data) {
    this.popData.isShow = false
    if (data === 1) {
      this.queryOrderStatus()
    }else{
      this.router.navigate([this.cancelPath], {replaceUrl: true}).then()
    }
  }

  // 发起支付后查询订单支付状态
  queryOrderStatus(){
    const url = !this.orderType?'/moxibustion/api/gettravelseckillorderstatusafterinitiatepayment':'/moxibustion/api/gettravelorderstatusafterinitiatepayment'
    this.http.post(url, { order_no: this.orderNo }).subscribe(
      (res: { data }) => {
        if(res.data.pay_status){
          this._toast.show('支付成功')
          this.router.navigate([this.resultPath], {queryParams: {  },replaceUrl: true}).then()
        }else{
          this._toast.fail('支付失败')
          this.router.navigate([this.cancelPath], {replaceUrl: true}).then()
        }
      }
    );
  }

}
