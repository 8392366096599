<div class="group-compop">
    <div class="group-compop-all">
        <div class="head flex">
            <div class="title">组团请先选择一个服务社区</div>
            <div class="imgclose" (click)="close()"><img (click)="close()" src="https://base-assets.ddgeyou.com/gold/icon_close@2x.png" />
            </div>
        </div>
        <div class="pricker-all">
            <div class="region-name">
                <div class="default" *ngFor="let item of changearealist;let index = index"
                    (click)="changdatacolor(item)" [ngClass]="{default: !item.type,other: item.type}">
                    {{item.name}}</div>
            </div>
            <div class="region-list">
                <div class="item" *ngFor="let item of nowlist;let index = index" (click)="getArealist(item)">
                    {{item.name}}</div>
            </div>
        </div>
    </div>
</div>