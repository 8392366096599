import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router'
import { HttpClient } from "@angular/common/http";
import { ToastService } from 'ng-zorro-antd-mobile';
import { WechatService } from '../../../code/service/wechat/wechat.service';
import { Title } from '@angular/platform-browser';
import { AuthService } from "../../../code/service/auth/auth.service";
import { UserInfoService } from "../../../code/service/user/user.service";
import { PlatformService } from "../../../code/service/platform/platform.service";
@Component({
  selector: 'app-ydgivedetails',
  templateUrl: './newydgivedetails.component.html',
  styleUrls: ['./newydgivedetails.component.less']
})
export class NewYdGiveDetailsComponent implements OnInit {

  constructor(
    private title: Title,
    private router: Router,
    private _toast: ToastService,
    private http: HttpClient,
    public wechat: WechatService,
    private route: ActivatedRoute,
    private auth: AuthService,
    private platform: PlatformService,
    private user: UserInfoService) {
    title.setTitle('购物送易点');
  }
  // 活动线路详情
  routedetails;
  // 轮播图的下标
  dotsdata = []
  //商品详情需要参数
  goodsData = {}
  // 是否在微信环境
  iswechat = false

ngOnInit(): void {
    let routprams = this.route.snapshot.queryParams;
    if (routprams) {
      this.goodsData = routprams
      this.getrouteData(this.goodsData)
    }else{
      if(localStorage.getItem('goods_config_id')){
        this.goodsData = JSON.parse(localStorage.getItem('goods_config_id'))
        this.getrouteData(this.goodsData)
      }
    }
    // iswechat=yes判断是否在h5环境
    if (this.route.snapshot.queryParams.iswechat) {
      this.iswechat = true;
    }
    // 平台框架兼容,如果sell字段存在，则是uniapp框架进来,并保存在本地告诉平台页面
    if(this.route.snapshot.queryParams.sell){
      window.localStorage.setItem('sell','uniapp')
    }else{
      window.localStorage.setItem('sell','angular')
    }
    if (this.route.snapshot.queryParams.uniappdata) {
      window.localStorage.setItem('uniappdata', 'yes')
    }
    // 是否是新易点商城
    window.localStorage.setItem('newydmall', 'yes')
}

  // 获取详情
  getrouteData(e) {
    // 获取详情
    this.http.post('/seckill/api/geteasypointgoodsactivitygoodsinfo', e).subscribe(
      (res: { data }) => {
        this.routedetails = res.data
        if (this.routedetails.imgs) {
          this.routedetails.imgs.forEach(element => {
            this.dotsdata.push(element)
          });
          setTimeout(() => {
            if(this.auth.getToken()){
              localStorage.removeItem('goods_config_id')
            }
          }, 1500);
        }
      }
    );
  }
  // 点击去购买按钮
  gocomPurch(){
    this.platform.check(
      null,
      () => this.goPurchase(),
      () => this.goPurchase(),
      () => this.jsgoH5()
    );
  }
  // h5判断是否登陆
  jsgoH5(){
    if (!this.auth.getToken()) {
      this.auth.redirectUrl = this.router.url;
      this.router.navigateByUrl('/user/register/phone', { replaceUrl:true}).then()
    }else{
      this.goPurchase()
    }
  }
  // 去购买
  goPurchase() {
    this.routedetails.hometype = 'purchase'
    this.routedetails.comfirmType = '1'//新接口V2
    let param = encodeURI(JSON.stringify(this.routedetails))
    param = encodeURIComponent(param) // 第二次加密
    this.router.navigate(['/ydorder-confirm'], {
      queryParams: {
        param: param,
      },
    }).then()
  }
  // 去分享
  goDetailsshare() {
    // @ts-ignore
    window.uni.postMessage({
      data: {
        type: 'ydgivesharev2',
        data: JSON.stringify(this.goodsData)
      }
    });
  }
  //滑动锚点
  bannerIndex = 0
  onBannerIndexChange(index: number): void {
    this.bannerIndex = index;
  }
}

