import {Component, OnInit} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {InviteService} from "../../../code/service/invite/invite.service";
import {WechatService} from "../../../code/service/wechat/wechat.service";
import {ModalService} from "ng-zorro-antd-mobile";

interface Data {
    // 1 未支付 2 已支付
    is_pay_the_entry_fee: 1 | 2,
    // 0 未提交实名 1 审核中 2 审核通过 3 审核不通过
    is_real_name_auth: 0 | 1 | 2 | 3,
    // 0 未提交审核 1 审核中 2 审核通过 3 审核未通过 4 冻结
    is_guide_settle_in_auth: 0 | 1 | 2 | 3 | 4
}

@Component({
    selector: 'app-pay',
    templateUrl: './pay.component.html',
    styleUrls: ['./pay.component.less']
})
export class GuidePayComponent implements OnInit {

    step: number = 0

    constructor(
        private http: HttpClient,
        private invite: InviteService,
        private wechat: WechatService,
        private _modal: ModalService,
    ) {
    }

    ngOnInit(): void {
        // 微信支付前刷新
        if (window.location.href.split('#')[0] !== window['wechatConfigUrl']) {
            window.location.reload()
        } else {
            this.http.post('/travel/api/guideapplicationoutflow', {}).subscribe((res: { data: Data }) => {
                const {data} = res
                if (data.is_pay_the_entry_fee === 2) {
                    this.step = 1
                }
                if (data.is_real_name_auth === 2) {
                    this.step = 2
                }
                if (data.is_guide_settle_in_auth === 2) {
                    this.step = 3
                }
                if (this.step === 3){
                    this._modal.alert('提示', '已完成所有入驻步骤了，快打开APP开始接单吧', [
                        {text: '取消', onPress: () => console.log('cancel')},
                        {
                            text: '下载APP', onPress: () => {
                                window.location.href = 'https://assets.ddgeyou.com/index.html'
                            }
                        }
                    ]);
                }
            })
        }
    }

    pay(): void {
        this.http.post('/travel/api/paytheentryfee', {
            pay_way: 1,
            client_type: 1,
            invite_code: this.invite.getId(),
            open_id: localStorage.getItem('openId')
        }).subscribe((res: { data }) => {
            this.wechat.pay(res.data, res.data.order_no, () => {
                this.http.post('/travel/api/guideapplicationoutflow', {}).subscribe((res: { data: Data }) => {
                    const {data} = res
                    this.step = data.is_pay_the_entry_fee - 1
                })
            })
        })
    }

    showModal() {
        this._modal.alert('提示', '请下载滴咚个游APP完成导游入驻\n及时了解入驻审核进度吧', [
            {text: '取消', onPress: () => console.log('cancel')},
            {
                text: '下载APP', onPress: () => {
                    window.location.href = 'https://assets.ddgeyou.com/index.html'
                }
            }
        ]);
    }

}
