<div class="popmodel_box">
    <div class="mask" (click)="maskClick()"></div>
    <div class="popmodel_center">
        <div class="center_close" *ngIf="popdata.isClose" (click)="cancalClick()"><img src="https://base-assets.ddgeyou.com/cstore/icon_close.png"/></div>
        <div class="center_title" [class]="{'align_center':!popdata.isCenter}" *ngIf="popdata.title">{{popdata.title}}</div>
        <div class="center_text" [class]="{'align_center':!popdata.isCenter}" *ngIf="!popdata.isHtml">{{popdata.text}}</div>
        <div class="center_text" [class]="{'align_center':!popdata.isCenter}" *ngIf="popdata.isHtml" [innerHTML]="popdata.text"></div>
        <div class="center_bottom">
            <div class="center_btn_cancal" *ngIf="!popdata.isCancal" (click)="cancalClick()">{{popdata.cancelText||'取消'}}</div>
            <div class="center_btn_confirm" *ngIf="!popdata.isOk" (click)="okClick(popdata.data)">{{popdata.okText||'确认'}}</div>
        </div>
    </div>
</div>