<div class="groupbook-details" *ngIf="routedetails">
    <app-compop (outer)="getData($event)" *ngIf="showareapop"></app-compop>
    <app-compopmodel (outer)="getModeldata($event)" *ngIf="showmodelpop" [showmodelpopdata]="showmodelpopdata"
        [datatype]="datatype"></app-compopmodel>
    <div class="invite-all" *ngIf="routedetails.invite_name">
        <div class="invite-item">
            <img [src]="routedetails.invite_avatar"
                onerror="this.src='https://base-assets.ddgeyou.com/gold/defult.png'" />
            {{routedetails.invite_name}}邀你一起超值购
        </div>
        <div class="invite-my" *ngIf="routedetails.invite_name" (click)="goMyorder()">我的</div>
    </div>
    <div class="banner-view">
        <Carousel *ngIf="routedetails.imgs.length>0" [infinite]="true" [dots]="false"
            style='height: 100vw;object-fit: cover;' (afterChange)="onBannerIndexChange($event)">
            <CarouselSlide dotPosition='top' dots *ngIf="routedetails.video">
                <video controls [src]="routedetails.video" style='height: 100vw;width: 100vw;' #videoid></video>
            </CarouselSlide>
            <CarouselSlide dotPosition='top' dots *ngFor="let item of routedetails.imgs">
                <img [src]="item" alt="" style='height: 100vw;object-fit: cover;'>
            </CarouselSlide>
        </Carousel>
        <div class="dots-all" *ngIf="dotsdata.length>1">
            <div class="dots">
                <div *ngFor="let item of dotsdata;let index = index"
                    [ngClass]="{active:bannerIndex==index,noactive:bannerIndex!==index}"></div>
            </div>
        </div>
    </div>
    <div class="detailstitle">
        <div class="title">
            <span class="left" *ngIf="routedetails.identity_type===1">探客</span>
            <span class="left" *ngIf="routedetails.identity_type===2">旅游管家</span>
            {{routedetails.route_title}}
        </div>
        <div class="info flex" *ngIf="!goldinfo">
            <div class="price flex">
                <div class="current flex">
                    <div class="label1">￥</div>
                    <div class="label2">{{routedetails.selling_price}}</div>
                </div>
                <div class="original">
                    <label>市场价：</label><label>¥{{routedetails.scribing_price}}</label>
                </div>
            </div>
                <div class="residue">仅剩{{routedetails.stock}}份</div>
        </div>
        <div class="info flex" *ngIf="goldinfo">
            <div class="price flex">
                <div class="current flex">
                    <div class="label1">￥</div>
                    <div class="label2">{{goldinfo.resale_price}}</div>
                </div>
                <div class="original">
                    <label>市场价：</label><label>¥{{routedetails.scribing_price}}</label>
                </div>
            </div>
                <div class="residue" *ngIf="goldinfo.status===1">仅剩1份</div>
                <div class="residue" *ngIf="goldinfo.status===2">仅剩0份</div>
        </div>
    </div>
    <div *ngIf="routedetails.recommend_route_list">
        <div class="recommend" *ngIf="routedetails.recommend_route_list.length!==0">
            <div class="recommend-head flex" >
                <div class="title">超值推荐</div>
                <div class="more" *ngIf="routedetails.recommend_route_list.length>4" (click)="recommendmore()">更多</div>
            </div>
            <div class="recommend-center flex">
                <div class="center-item" *ngFor="let item of routedetails.recommend_route_list;let index = index"
                    (click)="getDetails(item)">
                    <img [src]="item.imgs[0]" />
                    <div class="text omit">{{item.route_title}}</div>
                    <div class="recommend-price">
                        <label>￥</label>{{item.selling_price}}
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- 商品详情 -->
    <div class="detail-view">
        <div class="title-view">
            <p>商品详情</p>
        </div>
        <div class="content">
            <ng-template ngFor let-item [ngForOf]="routedetails.details">
                <p *ngIf="item.type === 1">{{ item.content }}</p>
                <div *ngIf="item.type === 2">
                    <div *ngIf="item.url">
                        <a [href]="item.url">
                            <img  [src]="item.content" alt=""/>
                        </a>
                    </div>
                    <div *ngIf="!item.url">
                        <img  [src]="item.content" alt="" />
                    </div>
                </div>
                <video controls *ngIf="item.type === 3" [src]="item.content"></video>
            </ng-template>
        </div>
    </div>
    <div class="homepage-button" *ngIf="!exchangeid">
       <div style="width: 100%;height: 100%;" *ngIf="!routedetails.invite_name">
        <div class="tab flex" *ngIf="routedetails.is_can_group===1">
            <div class="tab-item" (click)="getClusterinfo()">
                <div class="tab-info1">
                    <div>
                        <div class="text1">组团</div>
                        <div class="text2">
                            <img *ngFor="let item of routedetails.group_people.slice(0,2);let index = index"
                                [src]="item" />
                            <img *ngIf="routedetails.group_people.length===0"
                                src="https://base-assets.ddgeyou.com/groupbook/ellipse.png" />
                            <img style="margin-left: -0.3rem;"
                                *ngIf="routedetails.group_people.length===1||routedetails.group_people.length===0"
                                src="https://base-assets.ddgeyou.com/groupbook/ellipse.png" />
                            邀请满{{routedetails.quantity}}人即获奖励
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-item">
                <div class="tab-info2" (click)="goSettlement()">
                    <div>
                        <div class="text1">活动价购买</div>
                        <div class="text2"><label>￥</label>{{routedetails.selling_price}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="tab flex single" (click)="goSettlement()" *ngIf="routedetails.is_can_group===0">
            <div class="tab-item">
                <div class="tab-info2">
                    <div>
                        <div class="text1">活动价购买</div>
                        <div class="text2"><label>￥</label>{{routedetails.selling_price}}</div>
                    </div>
                </div>
            </div>
            <img class="tip" src="https://base-assets.ddgeyou.com/groupbook/paytip.png" />
        </div>
       </div>
       <div style="width: 100%;height: 100%;" *ngIf="routedetails.invite_name">
        <div class="tab flex h5" (click)="goSettlement()">
            <div class="text1">活动价购买</div>
            <div class="text2"><label>￥</label>{{routedetails.selling_price}}</div>
        </div>
       </div>
    </div>
    <div class="homepage-button" *ngIf="exchangeid">
        <div class="tab flex single" (click)="gogoldSettlement()" *ngIf="pagetype==='conver'">
            <div class="buttontext">{{routedetails.need_gold}}金贝兑换</div>
        </div>
        <div class="tab flex single" (click)="gogoldh5Settlement()" *ngIf="pagetype==='gold'">
            <div class="buttontext">立即抢购</div>
        </div>
    </div>
    <div class="homepage-icon" *ngIf="!exchangeid" (click)="goPlaytext()">
        <img src="https://base-assets.ddgeyou.com/groupbook/clusterplay.png" />
    </div>
</div>