import { HttpClient } from '@angular/common/http';
import { Component, NgZone, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from 'ng-zorro-antd-mobile';
import { ComputedService } from 'src/app/code/service/computed/computed.service';
import { PlatformService } from 'src/app/code/service/platform/platform.service';
import { WechatService } from 'src/app/code/service/wechat/wechat.service';

type orderInfo = {
  order_no: string,
  body: string,
  pay_type: number,
  paySignData: string
};

@Component({
  selector: 'app-order-pay',
  templateUrl: './order-pay.component.html',
  styleUrls: ['./order-pay.component.less']
})
export class OrderPayComponent implements OnInit {

  constructor(
    private title: Title,
    private router: Router,
    private route: ActivatedRoute,
    private _toast: ToastService,
    private http: HttpClient,
    private platform: PlatformService,
    private computed: ComputedService,
    private wechat: WechatService,
    private ngZone: NgZone,
  ) {
    title.setTitle('支付');
  }
  // 平台环境
  envPlatform = 'app'
  // 待支付金额
  toPaidMoney = 0
  // 可用和合钱包余额
  usableMoney;
  // 可用蓝贝
  usableBlue;
  // 限制输入的蓝贝
  limitBlue
  // 用于抵扣的蓝贝数
  buckleBlue
  // 用于抵扣的钱包余额
  buckleMoney = 0
  // 还需支付金额
  requiredMoney
  // 是否选择蓝贝
  selectBlue = false
   // 是否选择余额
  selectMoney = false
  // 选择的支付方式--默认0
  payWayIndex = 0
  // 支付类型数组
  payWayArr = []
  // 弹窗参数
  popData: any = {}
  // 防抖
  isSubmit = true
  // 订单号
  orderNo = null

  ngOnInit(): void {
    let routprams = JSON.parse(decodeURI(this.route.snapshot.queryParams?.param))
    this.orderNo = routprams.order_no
    this.toPaidMoney = this.requiredMoney = +routprams?.amount_to_be_paid
    this.nowEnvironment()
    this.getPlatformMoney()
    this.getPayEnv()
    // 支付成功和失败的回调
    const w = window as any;
    w.notifyWebPaySuccess = this.notifyWebPaySuccess.bind(this)
    w.notifyWebPayError = this.notifyWebPayError.bind(this)
    w.wechatSeverPaySuccess = this.wechatSeverPaySuccess.bind(this)// 走微信支付的回调
  }

  ngOnDestroy(): void {
    const w = window as any;
    delete w.notifyWebPaySuccess;
    delete w.notifyWebPayError;
    delete w.wechatSeverPaySuccess;
  }

  // app回调支付成功
  notifyWebPaySuccess(){}
  // app回调支付失败
  notifyWebPayError(){
    this.isSubmit = true
  }
  // app走微信服务商失败和成功的回调
  wechatSeverPaySuccess(res){}

  // 获取平台可用余额
  getPlatformMoney(){
    this.http.post('/moxibustion/api/getuserplatformcurrency', {}).subscribe(
      (res: { data }) => {
        this.usableMoney = res.data.moxibustion_money;
        this.usableBlue = res.data.blue_scallop;
        this.limitBlue = this.usableBlue>this.toPaidMoney?Math.floor(this.toPaidMoney):this.usableBlue
      }
    )
  }

  // 获取支付环境
  getPayEnv(){
    this.http.post('/moxibustion/api/getherbalpayenv', {  }).subscribe(
      (res: { data }) => {
        this.payWayArr = res.data
        this.payWayIndex = this.payWayArr.filter(item => item.env === this.envPlatform).map(item=>item.type)[0]
      }
    )
  }

  //当前环境判断
  nowEnvironment() {
    this.platform.check(
      null,
      () => this.appEnvironment(),
      () => this.appEnvironment(),
      () => this.h5Environment()
    );
  }
  // app环境
  appEnvironment() {
    this.envPlatform = 'app'
  }
  //h5环境
  h5Environment() {
    this.envPlatform = 'h5'
  }

  modelChange(e) {
    if (Number(e) > Number(this.limitBlue)) {
      this.buckleBlue = this.limitBlue;
      (document.getElementById("imgtalk") as HTMLInputElement).value = this.limitBlue
    }
    this.deduction();
  }
  // 蓝贝选择
  changblueclick() {
    this.selectBlue = !this.selectBlue;
    this.deduction()
  }
  // 余额选择
  moneyclick() {
    this.selectMoney = !this.selectMoney;
    this.deduction()
  }

  // 抵扣的公共方法
  deduction() {
    // 待支付数
    const needPayNum = Number(this.toPaidMoney);
    // 抵扣蓝贝数
    const buckleBlue = this.selectBlue?Number(this.buckleBlue ? this.buckleBlue : 0):0;//蓝贝
    if(this.selectMoney){// 钱包余额
      const maxMoney = this.computed.subtract(needPayNum,buckleBlue,2)
      this.buckleMoney = this.usableMoney>maxMoney?maxMoney:this.usableMoney
    }else{
      this.buckleMoney = 0;
    }
    // 还需支付数
    this.requiredMoney = this.computed.subtract(this.computed.subtract(needPayNum,buckleBlue,2),this.buckleMoney,2)
  }

  // 选择支付方式
  paywayClick(index){
    this.payWayIndex = index
  }

  // 去支付
  payClick(){
    if(this.isSubmit){
      this.isSubmit = false
      // setTimeout(() => {
      //   this.isSubmit = true
      // }, 2000)
      this.platform.check(
        null,
        () => this.appPay(),
        () => this.appPay(),
        () => this.jsapiPay()
      )
    }
  }

  // app支付
  appPay(){
    if (this.orderNo) {
      const dataparam = {
        order_no: this.orderNo,
        pay_type: this.payWayIndex,
        client_type: 3,
        blue_scallop_amount: this.selectBlue ? this.buckleBlue : 0,
        moxibustion_wallet_amount: this.selectMoney ? this.buckleMoney : 0
      }
      this.http.post('/moxibustion/api/orderpaymentsignature', dataparam).subscribe((res: { code: number, data: orderInfo, pay_type, msg: any }) => {
        if (res.code === 0) {// pay_type是支付为0，不跳支付
          
          if (res.data.pay_type === 0) {
            this.router.navigate(['/simpleherbalzone/payresult'], {queryParams: { order_no: this.orderNo,type: 0 },replaceUrl: true}).then()
          } else {
            this.showPopUp()
            if (res.data.pay_type === 2 || res.data.pay_type === 10) {//支付宝原生支付
              this.platform.check(
                null,
                () => window['android'].aliPayOther(res.data.order_no, res.data.body),
                () => window['webkit'].messageHandlers.aliPayOther.postMessage(JSON.stringify(res.data)),
                null
              );
            } else if(res.data.pay_type === 11 || res.data.pay_type === 12){
              this.platform.check(
                null,
                () => window['android'].openBrowser(res.data.paySignData),
                () => window['webkit'].messageHandlers.openBrowser.postMessage(res.data.paySignData),
                null
              );
            } else {
              this.platform.check(
                null,
                () => window['android'].aliPayFast(res.data.order_no, res.data.paySignData),
                () => window['webkit'].messageHandlers.aliPayFast.postMessage(JSON.stringify(res.data)),
                null
              );
            }
          }
        } else if (res.code === 4711) {
          this._toast.show(res.msg)
          setTimeout(() => {
            history.go(-1)
          }, 1000);
        }
      });
    }
  }

  // h5支付
  jsapiPay(){
    if (this.orderNo) {
      const dataparam = {
        order_no: this.orderNo,
        pay_type: this.payWayIndex,
        client_type: 1,
        blue_scallop_amount: this.selectBlue ? this.buckleBlue : 0,
        moxibustion_wallet_amount: this.selectMoney ? this.buckleMoney : 0,
        open_id: localStorage.getItem('openId')
      }
      this.http.post('/moxibustion/api/orderpaymentsignature', dataparam).subscribe((res: { code: number, data: orderInfo }) => {
        if (res.data.pay_type === 0) {//全额抵扣
          this.router.navigate(['/simpleherbalzone/payresult'], {queryParams: { order_no: this.orderNo,type: 0 },replaceUrl: true}).then()
        }else{// 支付
          this.showPopUp()
          if(res.data.pay_type===1){
            this.wechat.pay(res.data, res.data.order_no, () => {
              if (window.localStorage.getItem('paymentstatus')) {// 微信取消支付
                this.isSubmit = true
                window.localStorage.removeItem('paymentstatus');
                this.ngZone.run(() => this.router.navigateByUrl('/simpleherbalzone/order', { replaceUrl: true })).then()
              } else {// 微信h5支付--->跳转成功无法带参数
                this.ngZone.run(() => this.router.navigateByUrl(`/simpleherbalzone/payresult?order_no=${this.orderNo}&type=0`, { replaceUrl: true })).then()
              }
            })
          } else if(res.data.pay_type === 11){
            window.location.href = res.data.paySignData;
          } else if(res.data.pay_type === 12){
            this.router.navigate(['/simpleherbalzone/alipayh5'], { queryParams: { orderNo: encodeURIComponent(res.data.paySignData) } }).then()
          }
        }
      })
    }
  }

  // 显示支付弹窗公共方法
  showPopUp(){
    this.popData = {
      isShow: true,
      data: 1,//完成支付
      text: '请在微信或支付宝内完成支付，如您已支付成功，请点击“完成支付”按钮',
      okText: '完成支付'
    }
  }

  // 弹窗确认返回
  getReturnData(data) {
    this.popData.isShow = false
    if (data === 1) {
      this.queryOrderStatus()
    }else{
      this.router.navigate(['/simpleherbalzone/order'], {queryParams: {  },replaceUrl: true}).then()
    }
  }

  // 发起支付后查询订单支付状态
  queryOrderStatus(){
    this.http.post('/moxibustion/api/getorderstatusafterinitiatepayment', { order_no: this.orderNo }).subscribe(
      (res: { data }) => {
        if(res.data.pay_status){
          this._toast.show('支付成功')
          this.router.navigate(['/simpleherbalzone/payresult'], {queryParams: { order_no: this.orderNo,type: 0 },replaceUrl: true}).then()
        }else{
          this._toast.fail('支付失败')
          this.router.navigate(['/simpleherbalzone/order'], {replaceUrl: true}).then()
        }
      }
    );
  }

}
