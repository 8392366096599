<div class="input-view flex align-center justify-between">
    <ng-template [ngIf]="status === 'phone'">
        <div class="select-view">
            <p>+{{ area }}</p>
            <label>
                <select [value]="area" (change)="onAreaChange($event.target['value'])">
                    <option [value]="item.areaCode"
                            *ngFor="let item of areaArray">
                        {{ item.area }} + {{ item.areaCode }}
                    </option>
                </select>
            </label>
        </div>
        <div class="border"></div>
    </ng-template>
    <label>
        <input [type]="inputStatus"
               [placeholder]="statusText"
               [value]="value | stringValue: disabled ? 16 : 100"
               [maxlength]="(status === 'code' || status === 'emailCode') ? 6 : 40"
               [ngClass]="{mini: status === 'code' || status === 'emailCode'}"
               [attr.disabled]="disabled ? true : null"
               [formControlName]="name"
               (input)="onValueChange($event.target['value'])">
    </label>
        <p (click)="getCode()"
       class="code-text"
       *ngIf="status === 'code'">{{ getCodeTime ? getCodeTime : '获取验证码' }}</p>
        <p (click)="getEmailCode()"
       class="code-text"
       *ngIf="status === 'emailCode'">{{ getCodeTime ? getCodeTime : '获取邮箱验证码' }}</p>
    <img
            [src]="inputStatus === 'text' ? 'https://promote-beta.ddgeyou.com/assets/images/register/eys.png' : 'https://promote-beta.ddgeyou.com/assets/images/register/eys-not.png'"
            *ngIf="status === 'password'"
            class="eys-image"
            (click)="changeInputStatus()"
            alt="">
</div>
