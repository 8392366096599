import { Component, OnInit, OnDestroy, NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router'
import { HttpClient } from "@angular/common/http";
import { WechatService } from '../../../code/service/wechat/wechat.service';
import { PlatformService } from "../../../code/service/platform/platform.service";
import { InviteService } from "../../../code/service/invite/invite.service";
import { Title } from '@angular/platform-browser';
import { ToastService } from 'ng-zorro-antd-mobile';
import { consoleSandbox } from '@sentry/utils';
import { AuthService } from "../../../code/service/auth/auth.service";
type orderInfo = {
  order_no: string,
  body: string,
  pay_type: number,
  paySignData: string
};
@Component({
  selector: 'app-order-pay',
  templateUrl: './order-pay.component.html',
  styleUrls: ['./order-pay.component.less']
})
export class OrderPayComponent implements OnInit, OnDestroy {

  constructor(
    private title: Title,
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    public wechat: WechatService,
    private platform: PlatformService,
    private invite: InviteService,
    private ngZone: NgZone,
    private _toast: ToastService,
    private auth: AuthService
  ) {
    title.setTitle('支付');
  }
  // 待支付金额
  unpaidinfo;
  // 剩余
  paidudata;
  //抵扣的蓝贝量
  bulunumber
  // 是否选择抵扣蓝贝
  changblue = false;
  // 是否选择抵扣余额
  changmoney = false
  // 是否选择抵扣商圈余额 ---高端区
  changNpMoney = false
  // 当前余额
  nowmoney;
  // 抵扣钱包余额
  deductionmoney = 0
  // 当前余额 ---高端区易场钱包余额
  npMoney
  // 抵扣钱包余额 ---高端区易场钱包抵扣余额
  buckleNpMoney = 0
  // 还需支付金额
  actuallymoney
  // 总的蓝贝值
  bluescallop;
  orderInfo: orderInfo;
  // 限制文本框的本来需要支付的值
  tobepaid
  concealpage = true;
  // 支付按钮防抖判断
  paybutton = true
  // 从上个页面进来的带有待支付字段的判断变量、
  showtobepaidordershow = 3
  // 是否展示加载
  showloading = false
  // 支付类型打钩
  changtype
  // 当前类型数组
  changtypedata
  // 当前环境字段
  environment
  // 是否出现询问支付成功的弹窗
  askedtopay = false
  // app支付类型数组
  payitemarr = []
  // h5app支付类型
  payitemarrh5 = []
  testuni
  testuni2
  ngOnInit(): void {
    let routprams = JSON.parse(decodeURI(this.route.snapshot.queryParams.param));
    this.unpaidinfo = routprams;
    this.actuallymoney = Number(this.unpaidinfo.amount_to_be_paid);
    this.getUserplatfor()
    // 支付成功和失败的回调
    const w = window as any;
    w.notifyWebPaySuccess = this.notifyWebPaySuccess.bind(this);
    w.notifyWebPayError = this.notifyWebPayError.bind(this);
    // 走微信支付的回调
    w.wechatSeverPaySuccess = this.wechatSeverPaySuccess.bind(this);
    if (this.unpaidinfo.showtobepaidorder) {
      // 从上个页面进来的带有待支付字段进行按钮置灰且判断
      this.timerinfo()
    }
    /* this.getPayarr(); */
    // 根据不同手机端去判断展示的数据
    if (this.CheckIsAndroid()) {
      // 根据不同页面判断获取不同的支付方式
      this.getPayarr('android');
    } else {
      this.getPayarr('ios');
    }
  }
  // 判断是安卓环境还是ios环境
  CheckIsAndroid() {
    var browser = {
      versions: function () {
        var u = navigator.userAgent//, app = navigator.appVersion;
        return { //移动终端浏览器版本信息
          ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
          android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1, //android终端或uc浏览器 //u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
          iPhone: u.indexOf('iPhone') > -1, //是否为iPhone或者QQHD浏览器
          iPad: u.indexOf('iPad') > -1, //是否iPad
        };
      }(),
    }
    if (browser.versions.android)
      return true;
    return false;
  }

  // 当前页面应显示应该为哪些支付类型判断   获取支付环境
  getPayarr(e) {
    let url
    if (this.unpaidinfo.newtype) {
      url = '/seckill/api/getcommunitypayenv';
    } else {
      if (this.unpaidinfo.type === 'compete'||this.unpaidinfo.type === 'ydpurch') {
        url = '/seckill/api/getsnapuppayenv'
      } else if (this.unpaidinfo.type === 'newgold') {
        url = '/seckill/api/getsnapupgoldgoodspayenv'
      } else if(this.unpaidinfo.type === 'cstore'){// c店
        url = '/cshop/api/getpayenv'
      } else {
        url = !!this.unpaidinfo.pay_url ? '/travel/api/getcommunitypayenv' : '/travel/api/getgrouppayenv';
      }
    }
    this.http.post(url, { platform: e }).subscribe(
      (res: { data }) => {
        this.payitemarr = res.data.filter(item => item.env === 'app');
        this.payitemarrh5 = res.data.filter(item => item.env === 'h5');
        this.payitemarr.forEach((element, index) => {
          if (index === 0) {
            this.changtype = Number(element.type)
            this.changtypedata = element
            element.pitch = true
          } else {
            element.pitch = false
          }
        });
        // 第一次进来判断当前环境
        this.nowEnvironment()
      }
    )
  }
  //当前环境判断
  nowEnvironment() {
    this.platform.check(
      null,
      () => this.appEnvironment(),
      () => this.appEnvironment(),
      () => this.h5Environment()
    );
  }
  // app环境
  appEnvironment() {
    this.environment = 'app'
  }
  //h5环境
  h5Environment() {
    this.environment = 'h5'
    this.payitemarr = this.payitemarrh5;
    this.payitemarr.forEach((element, index) => {
      if (index === 0) {
        this.changtype = Number(element.type)
        element.pitch = true
      } else {
        element.pitch = false
      }
    });
    console.log('当前是H5环境')
  }
  ngOnDestroy(): void {
    const w = window as any;
    delete w.notifyWebPaySuccess;
    delete w.notifyWebPayError;
    delete w.wechatSeverPaySuccess;
  }
  // 定时器按钮十秒钟后再支付
  timerinfo() {
    if (this.showtobepaidordershow !== 0) {
      setTimeout(() => {
        this.showtobepaidordershow = this.showtobepaidordershow - 1;
        this.timerinfo()
      }, 1000);
    }
  }
  // 支付unpaidinfo
  gosucceed() {
    this.showloading = true
    /* let param = encodeURI(JSON.stringify(1))
    this.router.navigate(['/groupbook/order-succeed' + '/' + param]).then() */
    if (this.paybutton === true) {
      this.platform.check(
        null,
        () => this.appPay(),
        () => this.appPay(),
        () => this.jsapiPay()
      );
    } else {
      this.showloading = false
      this._toast.show('支付中，请勿重复提交');
    }
    // 防抖操作
    this.paybutton = false;
  }
  // app支付方式
  appPay(): void {
    if (this.changtype === 3) {//微信服务商接口，不走拿支付签名的接口。
      this.appSeverpay()
    } else {
      // 除了微信服务商以外全部走这个支付方法
      this.appDefaultpay()
    }
  }
  // 常规支付方式
  appDefaultpay() {
    if (this.unpaidinfo.order_no) {
      let url;
      let dataparam;
      // 如果是新抢购页面过来的
      if (this.unpaidinfo.newtype) {
        url = '/seckill/api/communityorderpaymentsignature';
        dataparam = {
          order_no: this.unpaidinfo.order_no,
          pay_type: this.changtype,
          client_type: 3,
          np_wallet_amount: this.changmoney ? this.deductionmoney : 0
        }
      } else {
        if (this.unpaidinfo.type === "compete") {
          url = (this.unpaidinfo.competetype === 'compete') ? '/seckill/api/snapuporderpaymentsignature' : '/seckill/api/snapupdirectpurchaseorderpaymentsignature';
          dataparam = {
            order_no: this.unpaidinfo.order_no,
            pay_type: this.changtype,//1 微信 2 支付宝 3，微信服务商 4 广东快付微信 5 广东快付支付宝
            client_type: 3,
            su_wallet_amount: this.changmoney ? this.deductionmoney : 0
          }
          //this.router.navigate(['/multiactive/spellingcase'], { replaceUrl: true }).then()
        } else if (this.unpaidinfo.type === "newgold") {
          url = '/seckill/api/snapupgoldgoodsorderpaymentsignature';
          dataparam = {
            order_no: this.unpaidinfo.order_no,
            pay_type: this.changtype,//1 微信 2 支付宝 3，微信服务商 4 广东快付微信 5 广东快付支付宝
            client_type: 3,
            blue_scallop_amount: this.changblue ? this.bulunumber : 0,
            wallet_balance_amount: this.changmoney ? this.deductionmoney : 0
          }
        } else if(this.unpaidinfo.type === "ydpurch"){
          url = '/seckill/api/buygoodsgiveeasypointorderpaymentsignature';
          dataparam = {
            order_no: this.unpaidinfo.order_no,
            pay_type: this.changtype,
            client_type: 3,
            np_wallet_amount: this.changmoney ? this.deductionmoney : 0
          }
        } else if(this.unpaidinfo.type === "cstore"){// c 店
          url = '/cshop/api/orderpaymentsignature';
          dataparam = {
            order_no: this.unpaidinfo.order_no,
            pay_type: this.changtype,
            client_type: 3,
            blue_scallop_amount: this.changblue ? this.bulunumber : 0,
            wallet_balance_amount: this.changmoney ? this.deductionmoney : 0
          }
        }else {
          url = !!this.unpaidinfo.pay_url ? '/travel/api/communityorderpaymentsignature' : '/travel/api/grouprouteorderpaymentsignature';
          dataparam = {
            order_no: this.unpaidinfo.order_no,
            pay_type: this.changtype,//1 微信 2 支付宝 3，微信服务商 4 广东快付微信 5 广东快付支付宝
            client_type: 3,
            blue_scallop_amount: this.changblue ? this.bulunumber : 0,
            wallet_balance_amount: this.changmoney ? this.deductionmoney : 0,
            np_wallet_amount:this.buckleNpMoney
          }
        }
      }
      this.http.post(url, dataparam).subscribe((res: { code: number, data: orderInfo, pay_type, msg: any }) => {
        this.showloading = false
        // pay_type是支付为0，不跳支付,type为gold表示进去金贝转卖购买页面，否是正常组团页
        if (res.code === 0) {
          this.showloading = false
          if (res.data.pay_type === 0) {
            if (this.unpaidinfo.type !== 'gold' && this.unpaidinfo.type !== 'seckill'
              && this.unpaidinfo.type !== 'compete' && this.unpaidinfo.type !== 'newgold'
              && this.unpaidinfo.type !== 'ydpurch' && this.unpaidinfo.type !== 'cstore') {
              this.router.navigate(['/groupbook/order-succeed'], { replaceUrl: true }).then();
            } else if (this.unpaidinfo.type === 'gold') {
              /* this.router.navigate(['/groupbook/h5gold-pay']).then() */
              this.router.navigate(['/groupbook/gold-succeed'], { replaceUrl: true });
            } else if (this.unpaidinfo.type === 'seckill') {
              window.localStorage.setItem('seckill', 'yes')
              if (this.unpaidinfo.vip === 'yes') {
                window.localStorage.setItem('isvip', 'yes')
              }
              //newtype该字段判断是否是新抢购，如果是，就走新抢购的成功页面路由
              if (this.unpaidinfo.newtype) {
                // 如果是竞抢订单转区进来的
                if (this.unpaidinfo.iscompete) {
                  this.router.navigate(['/multiactive/tranarea-success'], { replaceUrl: true }).then()
                } else {
                  // 普通秒杀区进来
                  this.router.navigate(['/newuserseckill/h5seckill-pay'], { replaceUrl: true }).then()
                }
              } else {
                this.router.navigate(['/groupbook/h5seckill-pay'], { replaceUrl: true }).then()
              }
            } else if (this.unpaidinfo.type === 'compete') {
              if (this.unpaidinfo.competetype === 'compete') {
                // 如果是竞抢拼团去算还剩多少人成功
                this.goSucceedspell(res.data.order_no)
              } else {
                // 如果是竞抢立即购买的成功去详情
                this.router.navigate(['/multiactive/order-details'], {
                  queryParams: {
                    order_no: res.data.order_no
                  },
                  replaceUrl: true
                }).then()
              }
            } else if (this.unpaidinfo.type === 'ydpurch'){
              // 易点商城商品购买成功页面
              this.router.navigate(['/ydstoremall/ydgivepay-success'], {replaceUrl: true}).then()
            } else if (this.unpaidinfo.type === 'cstore'){// c店
              this.router.navigate(['/cstore/payresult'], {queryParams: { goods_type: this.unpaidinfo.goods_type },replaceUrl: true}).then()
            } else if (this.unpaidinfo.type === 'newgold') {
              // 新金贝返回页
              this.router.navigate(['/newgoldactive/paysucess'], { replaceUrl: true }).then()
            }
          } else {
            if (this.changtype === 2 || this.changtype === 10) {//支付宝/直付通支付宝原生支付
              // 首先获取是什么框架进入的app,如果是uniapp则去走 uniapp和h5通信的方法，否则走Android原生的方法
              if (window.localStorage.getItem('sell') === 'uniapp') {
                // this.uniapppayment(res.data)
                this.askedtopay = true;
                this.uniappAlipay(res.data)
              } else {
                this.platform.check(
                  null,
                  () => window['android'].aliPayOther(res.data.order_no, res.data.body),
                  () => window['webkit'].messageHandlers.aliPayOther.postMessage(JSON.stringify(res.data)),
                  null
                );
              }
            } else {
              this.askedtopay = true;
              if (window.localStorage.getItem('sell') === 'uniapp') {
                this.uniapppayment(res.data)
              } else {
                console.log('是新支付', res.data.order_no, res.data.paySignData)
                // 这里走新支付
                this.platform.check(
                  null,
                  () => window['android'].aliPayFast(res.data.order_no, res.data.paySignData),
                  () => window['webkit'].messageHandlers.aliPayFast.postMessage(JSON.stringify(res.data)),
                  null
                );
              }
            }
          }
        } else if (res.code === 4711) {
          setTimeout(() => {
            history.go(-1)
          }, 1000);
        }
      });
    }
  }
  // 竞抢订单付款后判断
  goSucceedspell(e) {
    if (this.unpaidinfo.snap_up_group_id !== 0) {
      this.http.post('/seckill/api/getsnapupgroupdetails', { snap_up_group_id: this.unpaidinfo.snap_up_group_id }).subscribe(
        (res: { data, code }) => {
          if (res.code === 0) {
            if (res.data.need_invite_amount === 0) {
              window.localStorage.setItem('competesucceed', JSON.stringify(this.unpaidinfo.snap_up_group_id))
              setTimeout(() => {
                history.go(-2)
              }, 100);
              setTimeout(() => {
                history.go(-3)
              }, 300);
            } else {
              window.localStorage.setItem('snapgroupid', this.unpaidinfo.snap_up_group_id)
              this.router.navigate(['/multiactive/spellingcase'], {
                queryParams: {
                  snap_up_group_id: this.unpaidinfo.snap_up_group_id,
                },
                replaceUrl: true
              }).then()
            }
          }
        }
      );
    } else {
      this.http.post('/seckill/api/getsnapupgroupidbyorderno', { order_no: e }).subscribe(
        (res: { data }) => {
          console.log('拿到了吗', res.data.snap_up_group_id)
          if (res.data.snap_up_group_id) {
            window.localStorage.setItem('snapgroupid', res.data.snap_up_group_id)
            this.router.navigate(['/multiactive/spellingcase'], {
              queryParams: {
                snap_up_group_id: res.data.snap_up_group_id,
              },
              replaceUrl: true
            }).then()
          } else {
            setTimeout(() => {
              this.goSucceedspell2(e)
            }, 2000);
          }
        }
      )
    }
  }
  goSucceedspell2(e) {
    this.http.post('/seckill/api/getsnapupgroupidbyorderno', { order_no: e }).subscribe(
      (res: { data }) => {
        console.log('拿到了吗', res.data.snap_up_group_id)
        if (res.data.snap_up_group_id) {
          window.localStorage.setItem('snapgroupid', res.data.snap_up_group_id)
          this.router.navigate(['/multiactive/spellingcase'], {
            queryParams: {
              snap_up_group_id: res.data.snap_up_group_id,
            },
            replaceUrl: true
          }).then()
        } else {
          setTimeout(() => {
            this.goSucceedspell3(e)
          }, 2000);
        }
      }
    )
  }
  goSucceedspell3(e) {
    this.http.post('/seckill/api/getsnapupgroupidbyorderno', { order_no: e }).subscribe(
      (res: { data }) => {
        if (res.data.snap_up_group_id) {
          window.localStorage.setItem('snapgroupid', res.data.snap_up_group_id)
          this.router.navigate(['/multiactive/spellingcase'], {
            queryParams: {
              snap_up_group_id: res.data.snap_up_group_id,
            },
            replaceUrl: true
          }).then()
        } else {
          this.router.navigate(['/multiactive/order-list'], { replaceUrl: true }).then()
        }
      }
    )
  }
  // uniapp的支付方式
  uniapppayment(e) {
    // @ts-ignore
    window.uni.postMessage({
      data: {
        type: 'quickpay',
        data: JSON.stringify(e)
      }
    });
  }

  // 支付宝直连方式支付
  uniappAlipay(e){
    // @ts-ignore
    window.uni.postMessage({
      data: {
        type: 'alipay',
        data: JSON.stringify(e)
      }
    });
  }

  // 微信服务商接口
  appSeverpay() {
    //余额为0的情况
    if (Number(this.actuallymoney) === 0) {
      this.appDefaultpay()
    } else {
      this.showloading = false;
      this.askedtopay = true;
      let signtype;//0组团1金贝2老抢购30新抢购4严选5新金贝。默认0
      let pathurl
      if (this.unpaidinfo.newtype) {
        signtype = '3'
        pathurl = `/pages/payOrder/payOrder?orderNo=${this.unpaidinfo.order_no}&orderPrice=${this.actuallymoney}&signtype=${signtype}&np_wallet_amount=${(this.changmoney ? this.deductionmoney : 0)}&token=${this.auth.getToken()}`;
      } else {
        if (this.unpaidinfo.type === 'newgold') {
          console.log('进入到新金贝跳转小程序')
          // 新金贝
          signtype = '5'
          pathurl = `/pages/payOrder/payOrder?orderNo=${this.unpaidinfo.order_no}&orderPrice=${this.actuallymoney}&signtype=${signtype}&blue_scallop_amount=${(this.changblue ? this.bulunumber : 0)}&wallet_balance_amount=${(this.changmoney ? this.deductionmoney : 0)}&token=${this.auth.getToken()}`;
        } else if (this.unpaidinfo.type === 'compete') {
          // 竞抢跳转小程序
          signtype = (this.unpaidinfo.competetype === 'compete') ? '6' : '7'
          pathurl = `/pages/payOrder/payOrder?orderNo=${this.unpaidinfo.order_no}&orderPrice=${this.actuallymoney}&signtype=${signtype}&su_wallet_amount=${(this.changmoney ? this.deductionmoney : 0)}&token=${this.auth.getToken()}`;
        } else if(this.unpaidinfo.type === 'ydpurch'){
            console.log('进入易点商城小程序')
            // 新金贝
            signtype = '8'
            pathurl = `/pages/payOrder/payOrder?orderNo=${this.unpaidinfo.order_no}&orderPrice=${this.actuallymoney}&signtype=${signtype}&np_wallet_amount=${(this.changmoney ? this.deductionmoney : 0)}&token=${this.auth.getToken()}`;
        }else {
          signtype = !!this.unpaidinfo.pay_url ? '2' : '1'
          pathurl = `/pages/payOrder/payOrder?orderNo=${this.unpaidinfo.order_no}&orderPrice=${this.actuallymoney}&signtype=${signtype}&blue_scallop_amount=${(this.changblue ? this.bulunumber : 0)}&wallet_balance_amount=${(this.changmoney ? this.deductionmoney : 0)}&token=${this.auth.getToken()}`;
        }
      }
      let nowinfo = {
        path: pathurl,
        appid: this.changtypedata.app_id,
        version_type: 2,//0正式版.1测试版.2体验版、(用不到这个参数，可以删除)
      }
      if (window.localStorage.getItem('sell') === 'uniapp') {
        // @ts-ignore
        window.uni.postMessage({
          data: {
            type: 'wechatpay',
            data: JSON.stringify(nowinfo)
          }
        });
      } else {
        this.platform.check(
          null,
          () => window['android'].wechatSeverPay(JSON.stringify(nowinfo)),
          () => window['webkit'].messageHandlers.wechatSeverPay.postMessage(JSON.stringify(nowinfo)),
          null
        );
      }
    }
  }
  // 微信服务商支付为0的情况
  // 新支付的支付询问弹窗内的成功按钮
  paySucceed() {
    let url;
    if (this.unpaidinfo.newtype) {
      url = '/seckill/api/getcommunityorderstatusafterinitiatepayment';
    } else {
      if (this.unpaidinfo.type === 'compete') {
        url = (this.unpaidinfo.competetype === 'compete') ? '/seckill/api/getsnapuporderstatusafterinitiatepayment' : '/seckill/api/getsnapupdirectpurchaseorderstatusafterinitiatepayment'
      } else if (this.unpaidinfo.type === 'newgold') {
        url = '/seckill/api/getsnapupgoldgoodsorderstatusafterinitiatepayment'
      } else if (this.unpaidinfo.type === 'ydpurch') {
        url = '/seckill/api/getbuygoodsgiveeasypointorderstatusafterinitiatepayment'
      } else if(this.unpaidinfo.type === 'cstore'){
        url = '/cshop/api/getorderstatusafterinitiatepayment'
      } else {
        url = !!this.unpaidinfo.pay_url ? '/travel/api/getcommunityorderstatusafterinitiatepayment' : '/travel/api/getgrouprouteorderstatusafterinitiatepayment';
      }
    }
    this.http.post(url, { order_no: this.unpaidinfo.order_no }).subscribe(
      (res: { data, code }) => {
        if (res.code === 0) {
          if (res.data.pay_status === 1) {
            // 已支付情况跳转
            if (this.unpaidinfo.type === 'seckill') {
              window.localStorage.setItem('seckill', 'yes')
              if (this.unpaidinfo.vip === 'yes') {
                window.localStorage.setItem('isvip', 'yes')
              }
              if (this.unpaidinfo.newtype) {
                // 如果是竞抢订单转区进来的
                if (this.unpaidinfo.iscompete) {
                  this.ngZone.run(() => {
                    // 跳转到成功页面
                    this.router.navigate(['/multiactive/tranarea-success'], { replaceUrl: true }).then()
                  });
                } else {
                  // 普通秒杀区进来
                  this.ngZone.run(() => {
                    // 跳转到成功页面
                    this.router.navigate(['/newuserseckill/h5seckill-pay'], { replaceUrl: true }).then()
                  });
                }

              } else {
                this.ngZone.run(() => {
                  // 跳转到成功页面
                  this.router.navigate(['/groupbook/h5seckill-pay'], { replaceUrl: true }).then()
                });
              }
            } else if (this.unpaidinfo.type === 'gold') {
              this.ngZone.run(() => {
                this.router.navigate(['/groupbook/gold-succeed'], { replaceUrl: true });
              });
            } else if (this.unpaidinfo.type === 'compete') {
              if (this.unpaidinfo.competetype === 'compete') {
                this.goSucceedspell(this.unpaidinfo.order_no)
              } else {
                this.router.navigate(['/multiactive/order-details'], {
                  queryParams: {
                    order_no: this.unpaidinfo.order_no
                  },
                  replaceUrl: true
                }).then()
              }
              // this.ngZone.run(() => {
              //   window.localStorage.setItem('snapgroupid',this.unpaidinfo.snap_up_group_id)
              //   this.router.navigate(['/multiactive/spellingcase'], { replaceUrl: true });
              // });
            } else if (this.unpaidinfo.type === 'newgold') {
              // 新金贝返回页
              this.router.navigate(['/newgoldactive/paysucess'], { replaceUrl: true }).then()
            } else if (this.unpaidinfo.type === 'ydpurch'){
              // 易点商城商品购买成功页面
              this.router.navigate(['/ydstoremall/ydgivepay-success'], {replaceUrl: true}).then()
            } else if(this.unpaidinfo.type === 'cstore'){// c店
              this.router.navigate(['/cstore/payresult'], {queryParams: { goods_type: this.unpaidinfo.goods_type },replaceUrl: true}).then()
            } else {
              this.ngZone.run(() => {
                // 跳转到成功页面
                this.router.navigate(['/groupbook/order-succeed'], { replaceUrl: true }).then()
              });
            }
          } else {
            // 未支付情况返回
            this._toast.show('支付失败!');
            setTimeout(() => {
              this.payCancel()
            }, 500);
          }
        }
      }
    )
  }
  // 新支付的支付询问弹窗内的未支付成功按钮
  payCancel() {
    const domain = window.location.origin;
    let fix = '';
    if (domain === 'https://beta-service.ddgeyou.com') {
      fix = '/h5';
    }
    // this.router.navigateByUrl('/preferential/equityorder/' + this.order_no).then();
    if (this.unpaidinfo.subpage) {
      history.go(-2);
    } else {
      if (this.unpaidinfo.type === 'seckill') {
        history.go(-1);
      } else {
        if (this.unpaidinfo.type === 'compete' || this.unpaidinfo.type === 'ydpurch') {
          // 如果是竞抢立即购买的失败的情况去商品订单列表，另外如果是易点商城的订单也是去商品订单列表
          if (this.unpaidinfo.competetype !== 'compete' || this.unpaidinfo.type === 'ydpurch') {
            window.localStorage.setItem('orderclaimgoods', 'yes')
          }
          if (history.replaceState) {
            history.replaceState(null, document.title, fix + '/multiactive/order-list');
            history.go(0);
          } else {
            location.replace(fix + '/multiactive/order-list');
          }
        } else if (this.unpaidinfo.type === 'newgold') {
          if (history.replaceState) {
            history.replaceState(null, document.title, fix + '/newgoldactive/order-list');
            history.go(0);
          } else {
            location.replace(fix + '/newgoldactive/order-list');
          }
        } else if(this.unpaidinfo.type === 'cstore'){// c店
          if (history.replaceState) {
            history.replaceState(null, document.title, fix + '/cstore/order');
            history.go(0);
          } else {
            location.replace(fix + '/cstore/order');
          }
        } else {
          if (history.replaceState) {
            history.replaceState(null, document.title, fix + '/order-list');
            history.go(0);
          } else {
            location.replace(fix + '/order-list');
          }
        }
      }
    }
  }
  // h5页面
  jsapiPay(): void {
    if (this.unpaidinfo.order_no) {
      let url;
      let dataparam;
      if (this.unpaidinfo.newtype) {
        url = '/seckill/api/communityorderpaymentsignature';
        dataparam = {
          order_no: this.unpaidinfo.order_no,
          pay_type: 1,
          client_type: 1,
          np_wallet_amount: this.changmoney ? this.deductionmoney : 0,
          open_id: localStorage.getItem('openId')
        }
      } else {
        if (this.unpaidinfo.type === "newgold") {
          url = '/seckill/api/snapupgoldgoodsorderpaymentsignature';
          dataparam = {
            order_no: this.unpaidinfo.order_no,
            pay_type: this.changtype,//1 微信 2 支付宝 3，微信服务商 4 广东快付微信 5 广东快付支付宝
            client_type: 1,
            blue_scallop_amount: this.changblue ? this.bulunumber : 0,
            wallet_balance_amount: this.changmoney ? this.deductionmoney : 0,
            open_id: localStorage.getItem('openId')
          }
        } else if(this.unpaidinfo.type === "cstore"){// c 店
          url = '/cshop/api/orderpaymentsignature'
          dataparam = {
            order_no: this.unpaidinfo.order_no,
            pay_type: this.changtype,
            client_type: 1,
            blue_scallop_amount: this.changblue ? this.bulunumber : 0,
            wallet_balance_amount: this.changmoney ? this.deductionmoney : 0,
            open_id: localStorage.getItem('openId')
          }
        } else {
          url = !!this.unpaidinfo.pay_url ? '/travel/api/communityorderpaymentsignature' : '/travel/api/grouprouteorderpaymentsignature';
          dataparam = {
            order_no: this.unpaidinfo.order_no,
            pay_type: 1,
            client_type: 1,
            blue_scallop_amount: this.changblue ? this.bulunumber : 0,
            wallet_balance_amount: this.changmoney ? this.deductionmoney : 0,
            np_wallet_amount:this.buckleNpMoney,
            open_id: localStorage.getItem('openId')
          }
        }
      }
      this.http.post(url, dataparam).subscribe((res: { code: number, data: orderInfo }) => {
        this.showloading = false
        if (res.data.pay_type === 0) {//全额抵扣
          if (this.unpaidinfo.type !== 'gold' && this.unpaidinfo.type !== 'seckill'
            && this.unpaidinfo.type !== 'newgold' && this.unpaidinfo.type !== 'cstore') {
            this.router.navigate(['/h5order-pay'], { replaceUrl: true }).then();
          } else if (this.unpaidinfo.type === 'gold') {
            this.router.navigate(['/groupbook/h5gold-pay'], { replaceUrl: true }).then()
          } else if (this.unpaidinfo.type === 'seckill') {
            window.localStorage.setItem('seckill', 'yes')
            if (this.unpaidinfo.vip === 'yes') {
              window.localStorage.setItem('isvip', 'yes')
            }
            //newtype该字段判断是否是新抢购，如果是，就走新抢购的成功页面路由
            if (this.unpaidinfo.newtype) {
              this.router.navigate(['/newuserseckill/h5seckill-pay'], { replaceUrl: true }).then()
            } else {
              this.router.navigate(['/groupbook/h5seckill-pay'], { replaceUrl: true }).then()
            }
          } else if (this.unpaidinfo.type === 'newgold') {// 新金贝返回页
            this.router.navigate(['/newgoldactive/h5paysucess'], { replaceUrl: true }).then()
          } else if(this.unpaidinfo.type === 'cstore'){// c店
            this.router.navigate(['/cstore/payresult'], {queryParams: { goods_type: this.unpaidinfo.goods_type },replaceUrl: true}).then()
          }
        } else {// 支付
          if (res.code === 0) {
            this.wechat.pay(res.data, res.data.order_no, (res) => {
              if (window.localStorage.getItem('paymentstatus')) {// 微信取消支付
                window.localStorage.removeItem('paymentstatus');
                if (this.unpaidinfo.subpage) {
                  console.log('进入这里了吗')
                  history.go(-2);
                } else {
                  console.log('或者进入这里了吗')
                  if (this.unpaidinfo.type === 'newgold') {// 新金贝返回页
                    this.ngZone.run(() => this.router.navigateByUrl('/newgoldactive/order-list', { replaceUrl: true })).then();
                  } else if(this.unpaidinfo.type === 'cstore'){// c店
                    this.ngZone.run(() => this.router.navigateByUrl('/cstore/order', { replaceUrl: true })).then();
                  } else {
                    this.ngZone.run(() => this.router.navigateByUrl('/order-list', { replaceUrl: true })).then();
                  }
                }
              } else {// 微信h5支付
                if (this.unpaidinfo.type !== 'gold' && this.unpaidinfo.type !== 'seckill' && this.unpaidinfo.type !== 'newgold' && this.unpaidinfo.type !== 'cstore') {
                  this.ngZone.run(() => this.router.navigateByUrl('/h5order-pay', { replaceUrl: true })).then();
                } else if (this.unpaidinfo.type === 'gold') {
                  this.ngZone.run(() => this.router.navigateByUrl('/groupbook/h5gold-pay', { replaceUrl: true })).then();
                } else if (this.unpaidinfo.type === 'seckill') {
                  window.localStorage.setItem('seckill', 'yes')
                  if (this.unpaidinfo.vip === 'yes') {
                    window.localStorage.setItem('isvip', 'yes')
                  }
                  if (this.unpaidinfo.newtype) {
                    this.router.navigate(['/newuserseckill/h5seckill-pay'], { replaceUrl: true }).then()
                  } else {
                    this.router.navigate(['/groupbook/h5seckill-pay'], { replaceUrl: true }).then()
                  }
                  /*  this.ngZone.run(() => this.router.navigateByUrl('/groupbook/h5seckill-pay', { replaceUrl: true })).then(); */
                } else if (this.unpaidinfo.type === 'newgold') {// 新金贝返回页
                  this.ngZone.run(() => this.router.navigateByUrl('/newgoldactive/h5paysucess', { replaceUrl: true })).then();
                  //this.router.navigate(['/newgoldactive/h5paysucess'], { replaceUrl: true }).then()
                } else if(this.unpaidinfo.type === 'cstore'){// c店
                  this.ngZone.run(() => this.router.navigateByUrl('/cstore/payresult', {queryParams: { goods_type: this.unpaidinfo.goods_type }, replaceUrl: true })).then();
                }
              }
            });
          }
        }
      });
    }
  }
  /**
     * app回调支付成功
     * @param orderNo
     */
  notifyWebPaySuccess(orderNo): void {
    if (this.unpaidinfo.type === 'seckill') {
      window.localStorage.setItem('seckill', 'yes')
      if (this.unpaidinfo.vip === 'yes') {
        window.localStorage.setItem('isvip', 'yes')
      }
      if (this.unpaidinfo.newtype) {
        // 如果是竞抢订单转区进来的
        if (this.unpaidinfo.iscompete) {
          this.router.navigate(['/multiactive/tranarea-success'], { replaceUrl: true }).then()
        } else {
          // 普通秒杀区进来
          this.ngZone.run(() => {
            // 跳转到成功页面
            this.router.navigate(['/newuserseckill/h5seckill-pay'], { replaceUrl: true }).then()
          });
        }
      } else {
        this.ngZone.run(() => {
          // 跳转到成功页面
          this.router.navigate(['/groupbook/h5seckill-pay'], { replaceUrl: true }).then()
        });
      }
    } else if (this.unpaidinfo.type === 'gold') {
      this.ngZone.run(() => {
        this.router.navigate(['/groupbook/gold-succeed'], { replaceUrl: true });
      });
    } else if (this.unpaidinfo.type === 'newgold') {
      // 新金贝返回页
      this.router.navigate(['/newgoldactive/paysucess'], { replaceUrl: true }).then()
    } else if (this.unpaidinfo.type === 'ydpurch'){
      // 易点商城商品购买成功页面
      this.ngZone.run(() => {
        this.router.navigate(['/ydstoremall/ydgivepay-success'], {replaceUrl: true}).then()
      });
    } else if(this.unpaidinfo.type === 'cstore'){ // c店
      this.ngZone.run(() => {
        this.router.navigate(['/cstore/payresult'], {queryParams: { goods_type: this.unpaidinfo.goods_type },replaceUrl: true}).then()
      });
    } else {
      this.ngZone.run(() => {
        // 跳转到成功页面
        this.router.navigate(['/groupbook/order-succeed'], { replaceUrl: true }).then()
      });
    }
  }

  /**
   * app回调支付失败
   * @param orderNo
   */
  notifyWebPayError(orderNo): void {
    console.log('s ', this.unpaidinfo.subpage)
    const domain = window.location.origin;
    let fix = '';
    if (domain === 'https://beta-service.ddgeyou.com') {
      fix = '/h5';
    }
    // this.router.navigateByUrl('/preferential/equityorder/' + this.order_no).then();
    if (this.unpaidinfo.subpage) {
      history.go(-2);
    } else {
      if (this.unpaidinfo.type === 'seckill') {
        history.go(-1);
      } else if (this.unpaidinfo.type === 'newgold') {
        if (history.replaceState) {
          history.replaceState(null, document.title, fix + '/newgoldactive/order-list');
          history.go(0);
        } else {
          location.replace(fix + '/newgoldactive/order-list');
        }
      } else if (this.unpaidinfo.type === 'ydpurch'){
        // 易点商城商品购买成功页面
        this.router.navigate(['/ydstoremall/ydgivepay-success'], {replaceUrl: true}).then()
      } else if(this.unpaidinfo.type === 'cstore'){ // c店
        if (history.replaceState) {
          history.replaceState(null, document.title, fix + '/cstore/order');
          history.go(0);
        } else {
          location.replace(fix + '/cstore/order');
        }
      } else {
        if (history.replaceState) {
          history.replaceState(null, document.title, fix + '/order-list');
          history.go(0);
        } else {
          location.replace(fix + '/order-list');
        }
      }
    }
  }

  // app走微信服务商失败和成功的回调
  wechatSeverPaySuccess(res): void {
    if (res) {
      if (JSON.parse(res).isPay) {
        this.askedtopay = false;
        if (this.unpaidinfo.type === 'seckill') {
          window.localStorage.setItem('seckill', 'yes')
          if (this.unpaidinfo.vip === 'yes') {
            window.localStorage.setItem('isvip', 'yes')
          }
          if (this.unpaidinfo.newtype) {
            // 如果是竞抢订单转区进来的
            if (this.unpaidinfo.iscompete) {
              this.ngZone.run(() => {
                // 跳转到成功页面
                this.router.navigate(['/multiactive/tranarea-success'], { replaceUrl: true }).then()
              });
            } else {
              // 普通秒杀区进来
              this.ngZone.run(() => {
                // 跳转到成功页面
                this.router.navigate(['/newuserseckill/h5seckill-pay'], { replaceUrl: true }).then()
              });
            }
          } else {
            this.ngZone.run(() => {
              // 跳转到成功页面
              this.router.navigate(['/groupbook/h5seckill-pay'], { replaceUrl: true }).then()
            });
          }
        } else if (this.unpaidinfo.type === 'gold') {
          this.ngZone.run(() => {
            this.router.navigate(['/groupbook/gold-succeed'], { replaceUrl: true });
          });
        } else if (this.unpaidinfo.type === 'newgold') {
          this.ngZone.run(() => {
            this.router.navigate(['/newgoldactive/paysucess'], { replaceUrl: true });
          });
        }  else if (this.unpaidinfo.type === 'ydpurch'){
          // 易点商城商品购买成功页面
          this.ngZone.run(() => {
            this.router.navigate(['/ydstoremall/ydgivepay-success'], {replaceUrl: true}).then()
          });
        } else if(this.unpaidinfo.type === 'cstore'){ // c店
          this.ngZone.run(() => {
            this.router.navigate(['/cstore/payresult'], {queryParams: { goods_type: this.unpaidinfo.goods_type },replaceUrl: true}).then()
          });
        } else {
          this.ngZone.run(() => {
            // 跳转到成功页面
            this.router.navigate(['/groupbook/order-succeed'], { replaceUrl: true }).then()
          });
        }
      }
    }
  }
  // 获取平台流通币
  getUserplatfor() {
    if (this.unpaidinfo.newtype||this.unpaidinfo.type === 'ydpurch') {//易点商城 -- 体验区秒杀
      this.http.post('/seckill/api/getnewpeoplecurrency', {}).subscribe(
        (res: { data }) => {
          this.paidudata = res.data;
          this.nowmoney = res.data.np_money;
          this.bluescallop = res.data.blue_scallop;
          if (this.bluescallop > this.unpaidinfo.amount_to_be_paid) {
            this.tobepaid = this.unpaidinfo.amount_to_be_paid + '';
            if (this.tobepaid.indexOf('.') === (-1)) {
              this.tobepaid = Number(this.tobepaid)
            } else {
              this.tobepaid = Number(parseInt(this.tobepaid.substring(0, this.tobepaid.indexOf('.'))))
            }
          } else {
            this.tobepaid = Number(this.bluescallop);
          }
        }
      )
    } else if(this.unpaidinfo.type === 'compete') {//竞抢
      this.http.post('/seckill/api/getactivityarewallet', {}).subscribe(
        (res: { data }) => {
          this.paidudata = res.data;
          this.nowmoney = res.data.su_money;
        }
      )
    } else if(this.unpaidinfo.oldtype) {//高端区秒杀
      this.http.post('/seckill/api/getuserplatformcurrency', {}).subscribe(
        (res: { data }) => {
          this.paidudata = res.data;
          this.nowmoney = res.data.money;
          this.npMoney = res.data.np_money;
          this.bluescallop = res.data.blue_scallop;
          if (this.bluescallop > this.unpaidinfo.amount_to_be_paid) {
            this.tobepaid = this.unpaidinfo.amount_to_be_paid + '';
            if (this.tobepaid.indexOf('.') === (-1)) {
              this.tobepaid = Number(this.tobepaid)
            } else {
              this.tobepaid = Number(parseInt(this.tobepaid.substring(0, this.tobepaid.indexOf('.'))))
            }
          } else {
            this.tobepaid = Number(this.bluescallop);
          }
        }
      )
    } else if(this.unpaidinfo.type === 'cstore'){// c店
      this.http.post('/cshop/api/getuserplatformcurrency', {}).subscribe(
        (res: { data }) => {
          this.paidudata = res.data;
          this.nowmoney = res.data.money;
          this.bluescallop = res.data.blue_scallop;
          if (this.bluescallop > this.unpaidinfo.amount_to_be_paid) {
            this.tobepaid = this.unpaidinfo.amount_to_be_paid + '';
            if (this.tobepaid.indexOf('.') === (-1)) {
              this.tobepaid = Number(this.tobepaid)
            } else {
              this.tobepaid = Number(parseInt(this.tobepaid.substring(0, this.tobepaid.indexOf('.'))))
            }
          } else {
            this.tobepaid = Number(this.bluescallop);
          }
        }
      )
    } else {//组团购 -- 金呗
      this.http.post('/travel/api/getuserplatformcurrency', {}).subscribe(
        (res: { data }) => {
          this.paidudata = res.data;
          this.nowmoney = res.data.money;
          this.bluescallop = res.data.blue_scallop;
          if (this.bluescallop > this.unpaidinfo.amount_to_be_paid) {
            this.tobepaid = this.unpaidinfo.amount_to_be_paid + '';
            if (this.tobepaid.indexOf('.') === (-1)) {
              this.tobepaid = Number(this.tobepaid)
            } else {
              this.tobepaid = Number(parseInt(this.tobepaid.substring(0, this.tobepaid.indexOf('.'))))
            }
          } else {
            this.tobepaid = Number(this.bluescallop);
          }
        }
      )
    }
  }
  modelChange(e) {
    if (Number(e) > Number(this.tobepaid)) {
      this.bulunumber = this.tobepaid;
      (document.getElementById("imgtalk") as HTMLInputElement).value = this.tobepaid
    }
    this.deduction();
  }
  // 蓝贝选择
  changblueclick() {
    this.changblue = !this.changblue;
    this.deduction()
  }
  // 余额选择
  moneyclick() {
    this.changmoney = !this.changmoney;
    this.deduction()
  }
  // 易场钱包余额 ----高端区
  npMoneyClick(){
    this.changNpMoney = !this.changNpMoney;
    this.deduction()
  }

  // 抵扣的公共方法
  deduction() {
    // 待支付数
    const needPayNum = Number(this.unpaidinfo.amount_to_be_paid);
    // 抵扣蓝贝数
    const bulunumber = this.changblue?Number(this.bulunumber ? this.bulunumber : 0):0;//蓝贝
    if(this.changNpMoney){//---高端区易场余额
      const maxNpMoney = this.subtract(needPayNum,bulunumber,2)
      this.buckleNpMoney = this.npMoney>=maxNpMoney?maxNpMoney:this.npMoney;
    }else{
      this.buckleNpMoney = 0;
    }
    if(this.changmoney){// 钱包余额
      const maxMoney = this.subtract(this.subtract(needPayNum,bulunumber,2),this.buckleNpMoney,2)
      this.deductionmoney = this.nowmoney>=maxMoney?maxMoney:this.nowmoney;
    }else{
      this.deductionmoney = 0;
    }
    // 还需支付数
    this.actuallymoney = this.subtract(this.subtract(this.subtract(needPayNum,bulunumber,2),this.buckleNpMoney,2),this.deductionmoney,2)
  }

  //支付类型选择
  choosePayclick(e) {
    this.changtype = Number(e.type);
    this.changtypedata = e
    this.payitemarr.forEach(element => {
      if (element.type === e.type) {
        element.pitch = true
      } else {
        element.pitch = false
      }
    });
  }

  // 判断obj是否为一个整数
  isInteger(obj: any): boolean {
    return Math.floor(obj) === obj
  }

  /*
   * 将一个浮点数转成整数，返回整数和倍数。如 3.14 >> 314，倍数是 100
   * @param floatNum {number} 小数
   * @return {object}
   *   {times:100, num: 314}
   */
  toInteger(floatNum: any) {
    let ret = { times: 1, num: 0 }
    if (this.isInteger(floatNum)) {
      ret.num = floatNum
      return ret
    }
    let strfi = floatNum + ''
    let dotPos = strfi.indexOf('.')
    let len = strfi.substring(dotPos + 1).length
    let times = Math.pow(10, len)
    let intNum = parseInt((floatNum * times + 0.5) + '', 10)
    ret.times = times
    ret.num = intNum
    return ret
  }

  /*
   * 核心方法，实现加减乘除运算，确保不丢失精度
   * 思路：把小数放大为整数（乘），进行算术运算，再缩小为小数（除）
   */
  subtract(a, b,op) {
    let o1 = this.toInteger(a)
    let o2 = this.toInteger(b)
    let n1 = o1.num  // 3.25+3.153
    let n2 = o2.num
    let t1 = o1.times
    let t2 = o2.times
    let max = t1 > t2 ? t1 : t2
    let result = null
    switch (op) {
      // 加减需要根据倍数关系来处理
      case 1://加
        if (t1 === t2) { // 两个小数倍数相同
            result = n1 + n2
        } else if (t1 > t2) {
            // o1 小数位 大于 o2
            result = n1 + n2 * (t1 / t2)
        } else {  // o1小数位小于 o2
            result = n1 * (t2 / t1) + n2
        }
        return result / max
      case 2://减
        if (t1 === t2) {
            result = n1 - n2
        } else if (t1 > t2) {
            result = n1 - n2 * (t1 / t2)
        } else {
            result = n1 * (t2 / t1) - n2
        }
        return result / max
      case 3://乘
        result = (n1 * n2) / (t1 * t2)
        return result
      case 4://除
        result = (n1 / n2) * (t2 / t1)
        return result
    }
  }
}
