import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class PlatformService {

    constructor() {
    }

    check(rn, android, ios, h5) {
        const isWechat = function () { //判断是否是微信
            const ua = navigator.userAgent.toLowerCase();
            // @ts-ignore
            return ua.match(/MicroMessenger/i) instanceof Array;
        };
        const u = navigator.userAgent;
        //android终端
        const isAndroid = u.indexOf('Android') > -1 && !isWechat() || u.indexOf('Adr') > -1 && !isWechat();
        // const isAndroid = !!window['android'] && !isWechat();
        //ios终端
        const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) && !isWechat();
        // const isiOS = !!window['webkit'] && !isWechat();
        //ReactNative
        let ReactNativeWebView = !!window['ReactNativeWebView'];
        if (ReactNativeWebView) {
            rn && rn()
        } else if (isAndroid) {
            android && android()
        } else if (isiOS) {
            ios && ios()
        } else {
            h5 && h5()
        }
    }

    getAppRes(rn, android, ios, h5) {
        const isWechat = function () { //判断是否是微信
            const ua = navigator.userAgent.toLowerCase();
            // @ts-ignore
            return ua.match(/MicroMessenger/i) instanceof Array;
        };
        const u = navigator.userAgent;
        //android终端
        const isAndroid = u.indexOf('Android') > -1 && !isWechat() || u.indexOf('Adr') > -1 && !isWechat();
        // const isAndroid = !!window['android'] && !isWechat();
        //ios终端
        const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) && !isWechat();
        // const isiOS = !!window['webkit'] && !isWechat();
        //ReactNative
        let ReactNativeWebView = !!window['ReactNativeWebView'];
        if (ReactNativeWebView) {
            return rn ? rn() : null;
        } else if (isAndroid) {
            return android ? android() : null;
        } else if (isiOS) {
            return ios ? ios() : null;
        } else {
            return h5 ? h5() : null;
        }
    }

    // 判断是不是iphone x 及以上
    CheckIsIphoneX() {
        var browser = {
        versions: function () {
            var u = navigator.userAgent
            return { //移动终端浏览器版本信息 
            ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端 
            android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1, //android终端或uc浏览器 //u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
            iPhone: u.indexOf('iPhone') > -1, //是否为iPhone或者QQHD浏览器 
            iPad: u.indexOf('iPad') > -1, //是否iPad 
            };
        }(),
        }
        if (!browser.versions.android && window.screen.height >= 812)// iphone x 及以上
            return true
        return false
    }

    // 判断是安卓环境还是ios环境
    CheckIsAndroid() {
        var browser = {
            versions: function () {
                var u = navigator.userAgent//, app = navigator.appVersion;
                return { //移动终端浏览器版本信息 
                ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端 
                android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1, //android终端或uc浏览器 //u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
                iPhone: u.indexOf('iPhone') > -1, //是否为iPhone或者QQHD浏览器 
                iPad: u.indexOf('iPad') > -1, //是否iPad 
                };
            }(),
        }
        if (browser.versions.android)
            return true;
        return false;
    }
}
