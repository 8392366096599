import { Component, OnInit, OnDestroy, NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router'
import { HttpClient } from "@angular/common/http";
import { WechatService } from '../../../code/service/wechat/wechat.service';
import { PlatformService } from "../../../code/service/platform/platform.service";
import { InviteService } from "../../../code/service/invite/invite.service";
import { Title } from '@angular/platform-browser';
import { ToastService } from 'ng-zorro-antd-mobile';
type orderInfo = {
  order_no: string,
  body: string,
  pay_type: number,
  paySignData: string
};
@Component({
  selector: 'app-seckorder-pay',
  templateUrl: './seckorder-pay.component.html',
  styleUrls: ['./seckorder-pay.component.less']
})
export class SeckorderPayComponent implements OnInit, OnDestroy {

  constructor(
    private title: Title,
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    public wechat: WechatService,
    private platform: PlatformService,
    private invite: InviteService,
    private ngZone: NgZone,
    private _toast: ToastService,
  ) {
    title.setTitle('支付');
  }
  // 待支付金额
  unpaidinfo;
  // 剩余
  paidudata;
  //抵扣的蓝贝量
  bulunumber
  // 是否选择抵扣蓝贝哦
  changblue = false;
  // 是否选择抵扣余额。
  changmoney = false
  // 当前余额
  nowmoney;
  // 抵扣余额
  deductionmoney = 0
  // 实际支付金额
  actuallymoney
  // 抵扣完的支付金额
  // 总的蓝贝值
  bluescallop;
  orderInfo: orderInfo;
  // 限制文本框的本来需要支付的值
  tobepaid
  concealpage = true;
  // 支付按钮防抖判断
  paybutton = true
  // 从上个页面进来的带有待支付字段的判断变量、
  showtobepaidordershow = 3
  // 是否展示加载
  showloading = false
  // 支付类型打钩
  changtype = 2
  // 当前环境字段
  environment
  // 是否出现询问支付成功的弹窗
  askedtopay = false
  ngOnInit(): void {
    /* let routprams = JSON.parse((decodeURI(this.route.snapshot.params.id))); */
    let routprams = JSON.parse(decodeURI(this.route.snapshot.queryParams.param));
    this.unpaidinfo = routprams;
    this.actuallymoney = Number(this.unpaidinfo.amount_to_be_paid);
    this.getUserplatfor()
    // 支付成功和失败的回调
    const w = window as any;
    w.notifyWebPaySuccess = this.notifyWebPaySuccess.bind(this);
    w.notifyWebPayError = this.notifyWebPayError.bind(this);
    console.log('routprams', routprams)
    if (this.unpaidinfo.showtobepaidorder) {
      // 从上个页面进来的带有待支付字段进行按钮置灰且判断
      this.timerinfo()
    }
    // 第一次进来判断当前环境
    this.nowEnvironment()
  }
  nowEnvironment() {
    this.platform.check(
      null,
      () => this.appEnvironment(),
      () => this.appEnvironment(),
      () => this.h5Environment()
    );
  }
  // app环境
  appEnvironment() {
    console.log('当前是app环境')
    this.environment = 'app'
  }
  //h5环境
  h5Environment() {
    this.environment = 'h5'
    console.log('当前是H5环境')
  }
  ngOnDestroy(): void {
    const w = window as any;

    delete w.notifyWebPaySuccess;
    delete w.notifyWebPayError;
  }
  // 定时器按钮十秒钟后再支付
  timerinfo() {
    if (this.showtobepaidordershow !== 0) {
      setTimeout(() => {
        this.showtobepaidordershow = this.showtobepaidordershow - 1;
        this.timerinfo()
      }, 1000);
    }
  }
  // 支付unpaidinfo
  gosucceed() {
    this.showloading = true
    /* let param = encodeURI(JSON.stringify(1))
    this.router.navigate(['/newuserseckill/order-succeed' + '/' + param]).then() */
    if (this.paybutton === true) {
      this.platform.check(
        null,
        () => this.appPay(),
        () => this.appPay(),
        () => this.jsapiPay()
      );
    } else {
      this.showloading = false
      this._toast.show('支付中，请勿重复提交');
    }
    // 防抖操作
    this.paybutton = false;
    /*  setTimeout(() => {
       this.paybutton=true;
     }, 2000); */
  }
  // 安卓
  appPay(): void {
    if (this.unpaidinfo.order_no) {
      let url = !!this.unpaidinfo.pay_url ? '/seckill/api/communityorderpaymentsignature' : '/travel/api/grouprouteorderpaymentsignature'
      this.http.post(url, {
        order_no: this.unpaidinfo.order_no,
        pay_type: this.changtype,
        client_type: 3,
        np_wallet_amount: this.changmoney ? this.deductionmoney : 0,
      }).subscribe((res: { code: number, data: orderInfo, pay_type }) => {
        // pay_type是支付为0，不跳支付,type为gold表示进去金贝转卖购买页面，否是正常组团页
        if (res.code === 0) {
          this.showloading = false
          if (res.data.pay_type === 0) {
            if (this.unpaidinfo.type !== 'gold' && this.unpaidinfo.type !== 'seckill') {
              this.router.navigate(['/newuserseckill/order-succeed'], { replaceUrl: true }).then();
            } else if (this.unpaidinfo.type === 'gold') {
              /* this.router.navigate(['/newuserseckill/h5gold-pay']).then() */
              this.router.navigate(['/newuserseckill/gold-succeed'], { replaceUrl: true });
            } else if (this.unpaidinfo.type === 'seckill') {
              window.localStorage.setItem('seckill', 'yes')
              if (this.unpaidinfo.vip === 'yes') {
                window.localStorage.setItem('isvip', 'yes')
              }
              this.router.navigate(['/newuserseckill/h5seckill-pay'], { replaceUrl: true }).then()
            }
          } else {
            if (this.changtype === 2) {
              // 如果this.changtype=2 是走正常支付宝，需要用到res.data.body。则走老方法。
              this.platform.check(
                null,
                () => window['android'].aliPayOther(res.data.order_no, res.data.body),
                () => window['webkit'].messageHandlers.aliPayOther.postMessage(JSON.stringify(res.data)),
                null
              );
            } else {
              console.log('是新支付', res.data.order_no, res.data.paySignData)
              this.askedtopay = true;
              // 这里走新支付
              this.platform.check(
                null,
                () => window['android'].aliPayFast(res.data.order_no, res.data.paySignData),
                () => window['webkit'].messageHandlers.aliPayOther.postMessage(JSON.stringify(res.data)),
                null
              );
            }
          }
        }
      });
    }
  }

  // 新支付的支付询问弹窗内的成功按钮
  paySucceed() {
    let url = !!this.unpaidinfo.pay_url ? '/seckill/api/getcommunityorderstatusafterinitiatepayment' : '/travel/api/getgrouprouteorderstatusafterinitiatepayment';
    this.http.post(url, { order_no: this.unpaidinfo.order_no }).subscribe(
      (res: { data, code }) => {
        if (res.code === 0) {
          if (res.data.pay_status === 1) {
            // 已支付情况跳转
            if (this.unpaidinfo.type === 'seckill') {
              window.localStorage.setItem('seckill', 'yes')
              if (this.unpaidinfo.vip === 'yes') {
                window.localStorage.setItem('isvip', 'yes')
              }
              this.ngZone.run(() => {
                // 跳转到成功页面
                this.router.navigate(['/newuserseckill/h5seckill-pay'], { replaceUrl: true }).then()
              });
            } else if (this.unpaidinfo.type === 'gold') {
              this.ngZone.run(() => {
                this.router.navigate(['/newuserseckill/gold-succeed'], { replaceUrl: true });
              });
            } else {
              this.ngZone.run(() => {
                // 跳转到成功页面
                this.router.navigate(['/newuserseckill/order-succeed'], { replaceUrl: true }).then()
              });
            }
          } else {
            // 未支付情况返回
            this._toast.show('支付失败!');
            setTimeout(() => {
              this.payCancel()
            }, 500);
          }
        }
      }
    )
  }
  // 新支付的支付询问弹窗内的未支付成功按钮
  payCancel() {
    /* history.go(-2); */
    const domain = window.location.origin;
    let fix = '';
    if (domain === 'https://beta-service.ddgeyou.com') {
      fix = '/h5';
    }
    // this.router.navigateByUrl('/preferential/equityorder/' + this.order_no).then();
    if (this.unpaidinfo.subpage) {
      history.go(-2);
    } else {
      if (this.unpaidinfo.type === 'seckill') {
        history.go(-1);
      } else {
        if (history.replaceState) {
          history.replaceState(null, document.title, fix + '/order-list');
          history.go(0);
        } else {
          location.replace(fix + '/order-list');
        }
      }
    }
  }
  // h5页面
  jsapiPay(): void {
    if (this.unpaidinfo.order_no) {
      let url = !!this.unpaidinfo.pay_url ? '/seckill/api/communityorderpaymentsignature' : '/travel/api/grouprouteorderpaymentsignature'
      this.http.post(url, {
        order_no: this.unpaidinfo.order_no,
        pay_type: 1,
        client_type: 1,
        blue_scallop_amount: this.changblue ? this.bulunumber : 0,
        wallet_balance_amount: this.changmoney ? this.deductionmoney : 0,
        open_id: localStorage.getItem('openId')
      }).subscribe((res: { code: number, data: orderInfo }) => {
        this.showloading = false
        if (res.data.pay_type === 0) {
          if (this.unpaidinfo.type !== 'gold' && this.unpaidinfo.type !== 'seckill') {
            this.router.navigate(['/h5order-pay'], { replaceUrl: true }).then();
          } else if (this.unpaidinfo.type === 'gold') {
            this.router.navigate(['/newuserseckill/h5gold-pay'], { replaceUrl: true }).then()
          } else if (this.unpaidinfo.type === 'seckill') {
            window.localStorage.setItem('seckill', 'yes')
            if (this.unpaidinfo.vip === 'yes') {
              window.localStorage.setItem('isvip', 'yes')
            }
            this.router.navigate(['/newuserseckill/h5seckill-pay'], { replaceUrl: true }).then()
          }
        } else {
          if (res.code === 0) {
            this.wechat.pay(res.data, res.data.order_no, (res) => {
              console.log('与偶吗，啊', window.localStorage.getItem('paymentstatus'))
              if (window.localStorage.getItem('paymentstatus')) {
                window.localStorage.removeItem('paymentstatus');
                if (this.unpaidinfo.subpage) {
                  console.log('进入这里了吗')
                  history.go(-2);
                } else {
                  console.log('或者进入这里了吗')
                  this.ngZone.run(() => this.router.navigateByUrl('/order-list', { replaceUrl: true })).then();
                }
              } else {
                if (this.unpaidinfo.type !== 'gold' && this.unpaidinfo.type !== 'seckill') {
                  this.ngZone.run(() => this.router.navigateByUrl('/h5order-pay', { replaceUrl: true })).then();

                } else if (this.unpaidinfo.type === 'gold') {
                  this.ngZone.run(() => this.router.navigateByUrl('/newuserseckill/h5gold-pay', { replaceUrl: true })).then();
                } else if (this.unpaidinfo.type === 'seckill') {
                  window.localStorage.setItem('seckill', 'yes')
                  if (this.unpaidinfo.vip === 'yes') {
                    window.localStorage.setItem('isvip', 'yes')
                  }
                  this.ngZone.run(() => this.router.navigateByUrl('/newuserseckill/h5seckill-pay', { replaceUrl: true })).then();
                }
              }
            });
          }
        }
      });
    }
  }
  /**
     * app回调支付成功
     * @param orderNo
     */
  notifyWebPaySuccess(orderNo): void {
    if (this.unpaidinfo.type === 'seckill') {
      window.localStorage.setItem('seckill', 'yes')
      if (this.unpaidinfo.vip === 'yes') {
        window.localStorage.setItem('isvip', 'yes')
      }
      this.ngZone.run(() => {
        // 跳转到成功页面
        this.router.navigate(['/newuserseckill/h5seckill-pay'], { replaceUrl: true }).then()
      });
    } else if (this.unpaidinfo.type === 'gold') {
      this.ngZone.run(() => {
        this.router.navigate(['/newuserseckill/gold-succeed'], { replaceUrl: true });
      });
    } else {
      this.ngZone.run(() => {
        // 跳转到成功页面
        this.router.navigate(['/newuserseckill/order-succeed'], { replaceUrl: true }).then()
      });
    }
  }

  /**
   * app回调支付失败
   * @param orderNo
   */
  notifyWebPayError(orderNo): void {
    console.log('s ', this.unpaidinfo.subpage)
    /* history.go(-2); */
    const domain = window.location.origin;
    let fix = '';
    if (domain === 'https://beta-service.ddgeyou.com') {
      fix = '/h5';
    }
    // this.router.navigateByUrl('/preferential/equityorder/' + this.order_no).then();
    if (this.unpaidinfo.subpage) {
      history.go(-2);
    } else {
      if (this.unpaidinfo.type === 'seckill') {
        history.go(-1);
      } else {
        if (history.replaceState) {
          history.replaceState(null, document.title, fix + '/order-list');
          history.go(0);
        } else {
          location.replace(fix + '/order-list');
        }
      }
    }
  }
  // 获取平台流通币
  getUserplatfor() {
    this.http.post('/seckill/api/getnewpeoplecurrency', {}).subscribe(
      (res: { data }) => {
        console.log('data', res.data)
        this.paidudata = res.data;
        this.nowmoney = res.data.np_money;
        this.bluescallop = res.data.blue_scallop;
        if (this.bluescallop > this.unpaidinfo.amount_to_be_paid) {
          this.tobepaid = this.unpaidinfo.amount_to_be_paid + '';
          if (this.tobepaid.indexOf('.') === (-1)) {
            this.tobepaid = Number(this.tobepaid)
          } else {
            this.tobepaid = Number(parseInt(this.tobepaid.substring(0, this.tobepaid.indexOf('.'))))
          }
        } else {
          this.tobepaid = Number(this.bluescallop);
        }
      }
    )
  }
  modelChange(e) {
    if (Number(e) > Number(this.tobepaid)) {
      this.bulunumber = this.tobepaid;
      (document.getElementById("imgtalk") as HTMLInputElement).value = this.tobepaid
    }
    this.deduction();
  }
  // 蓝贝选择
  changblueclick() {
    this.changblue = !this.changblue;
    this.deduction()
  }
  // 抵扣的公共方法
  deduction() {
    this.actuallymoney = Number(this.unpaidinfo.amount_to_be_paid);
    this.deductionmoney = 0;
    if (this.changblue === true) {
      if (Number(this.bulunumber ? this.bulunumber : 0) >= Number(this.actuallymoney)) {
        if (this.changmoney === true) {
          this.deductionmoney = this.bulunumber - this.actuallymoney;
          this.actuallymoney = 0;
        } else {
          this.actuallymoney = this.deductionmoney;
          this.deductionmoney = 0
        }
      } else {
        let temporary = Math.round(((Number(this.unpaidinfo.amount_to_be_paid) * 100)) - (Number(this.bulunumber ? this.bulunumber : 0) * 100)) / 100;
        if (this.changmoney === true) {
          if (Number(this.nowmoney) >= Number(temporary)) {
            this.deductionmoney = Number(temporary)
            this.actuallymoney = 0;
          } else {
            this.deductionmoney = Number(this.nowmoney);
            this.actuallymoney = Math.round(((Number(temporary) * 100)) - (Number(this.deductionmoney) * 100)) / 100;
          }
        } else {
          this.actuallymoney = temporary
        }
      }
    } else {
      if (this.changmoney === true) {
        if (Number(this.nowmoney) >= Number(this.unpaidinfo.amount_to_be_paid)) {
          this.deductionmoney = Number(this.unpaidinfo.amount_to_be_paid)
          this.actuallymoney = 0
        } else {
          console.log('是')
          this.deductionmoney = Number(this.nowmoney);
          this.actuallymoney = Math.round(((Number(this.unpaidinfo.amount_to_be_paid) * 100)) - (Number(this.deductionmoney) * 100)) / 100;
        }
      } else {
        this.actuallymoney = this.unpaidinfo.amount_to_be_paid
      }
    }
  }
  // 余额选择
  moneyclick() {
    this.changmoney = !this.changmoney;
    this.deduction()
  }
  //支付类型选择
  choosePayclick(e) {
    this.changtype = e;
  }
}
