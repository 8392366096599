import { Component, OnInit } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';
import { Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-popmodel',
  templateUrl: './popmodel.component.html',
  styleUrls: ['./popmodel.component.less']
})
export class PopModelComponent implements OnInit {
  @Output() private outer = new EventEmitter()
  @Input() popdata

  constructor(
    private sanitizer: DomSanitizer
  ) { }

  // popdata-----参数说明
  // text 显示的文字
  // isCenter 文字是否居中显示 默认居中
  // isHtml 文字是否加载html
  // isOk 是否显示确认按钮 默认显示
  // isCancal 是否显示取消按钮 默认隐藏
  // cancalText 取消按钮文本
  // okText 确认按钮文本
  // isOkWide 确认按钮是否加宽
  // isCancalWide 取消按钮是否加宽
  // data 要返回的参数
  // isMaskClose 点击遮罩层是否关闭弹窗 默认否
  // isClose 是否显示圆形关闭图标
  // isTwo 是否是两个提交事件按钮 默认否 显示两个按钮时另一个用取消的文本字段 -------特定弹窗
  // dataTwo 第二个要返回的参数
  // isTwoTheme 第二种主题---橘黄色 默认第一种
  // isCancalWhite 取消按钮白色

  ngOnInit(): void {
    if (this.popdata.isHtml) {
      this.popdata.text = this.sanitizer.bypassSecurityTrustHtml(this.popdata.text);
    }
  }

  // 遮罩层点击关闭
  maskClick() {
    if (this.popdata.isMaskClose)
      this.outer.emit(-1)// 关闭弹窗
  }

  // 取消点击
  cancalClick() {
    this.outer.emit(-1)// 关闭弹窗
  }

  // 确认点击
  okClick(data) {
    this.outer.emit(data)
  }

}
