<div class="container_box" *ngIf="goodsData">
  <div class="container_center">
    <div class="detail_gohome" *ngIf="false" (click)="homeClick()"><img src="https://base-assets.ddgeyou.com/cstore/icon_gohome.png"/></div>
    <div class="detail_title">{{goodsData.goods_name}}</div>
    <div class="detail_tip">多店通用</div>
    <div class="detail_banner">
      <Carousel *ngIf="goodsData.imgs" [infinite]="true" [dots]="true" style="height: 68.54vw;">
        <CarouselSlide *ngFor="let item of goodsData.imgs; let i = index" style="border-radius: 5px;">
          <div class="detail_banner_item">
            <img [src]="item+'?x-oss-process=image/resize,w_800,h_800,m_lfit'">
          </div>
        </CarouselSlide>
      </Carousel>
    </div>
    <div class="detail_num" *ngIf="goodsData.goods_type==1">
      <span>包含服务次数</span>
      <span>{{goodsData.service_number}}次</span>
    </div>
    <div class="detail_subtitle">详细介绍</div>
    <div class="detail_content">
      <ng-template ngFor let-item [ngForOf]="goodsData.details">
          <p *ngIf="item.type === 1">{{ item.content }}</p>
          <div *ngIf="item.type === 2">
              <div *ngIf="item.url">
                  <a [href]="item.url">
                      <img [src]="item.content" alt=""/>
                  </a>
              </div>
              <div *ngIf="!item.url">
                  <img [src]="item.content" alt="" />
              </div>
          </div>
          <video controls *ngIf="item.type === 3" [src]="item.content"></video>
      </ng-template>
    </div>
  </div>
  <div class="container_tabbar" [class]="{'tabbar_bottom':isIphoneX}">
    <div class="tabbar_left">
      <div class="tabbar_price"><span>￥</span>{{goodsData.price}}</div>
      <div class="tabbar_tip" *ngIf="goodsData.is_booking && !goodsData.check_stock_rule">支持定金 ￥{{goodsData.booking_price}}</div>
    </div>
    <div class="tabbar_right">
      <div class="tabbar_deposit_btn" *ngIf="goodsData.is_booking && !goodsData.check_stock_rule" (click)="buyClick(2)">定金购买</div>
      <div class="tabbar_buy_btn" (click)="buyClick(1)">立即抢购</div>
    </div>
  </div>
</div>