<app-popmodel (outer)="getReturnData($event)" *ngIf="popData.isShow" [popdata]="popData"></app-popmodel>
<div class="order-pay">
    <div class="order-box">
        <div class="lable2">￥<label>{{toPaidMoney}}</label></div>
        <div class="lable">待支付金额</div>
        <div class="order-info" *ngIf="!orderType">
            <div class="order-item" *ngIf="isCheckBlue">
                <div class="choiceblue">
                    <div class="left">
                        <div class="text">循环蓝贝抵扣</div>
                        <div class="text2">(剩余可用：{{usableTravelBlue}}<label *ngIf="buckleTravelBlue">，已抵扣：{{buckleTravelBlue}}</label>)</div>
                    </div>
                </div>
                <div class="changeicon" [ngClass]="{changeicon:selectTravelBlue,changeiconno:!selectTravelBlue,active:travel_blue_scallop_amount}" (click)="changTravelClick()"></div>
            </div>
            <div class="order-item">
                <div class="choiceblue">
                    <div class="left">
                        <div class="text">蓝贝抵扣</div>
                        <div class="text2">(剩余可用：{{usableBlue}}<label *ngIf="buckleBlue">，已抵扣：{{buckleBlue}}</label>)</div>
                    </div>
                </div>
                <div class="changeicon" [ngClass]="{changeicon:selectBlue,changeiconno:!selectBlue,active:blue_scallop_amount}" (click)="changblueclick()"></div>
            </div>
            <div class="order-item">
                <div class="choiceblue">
                    <div class="left">
                        <div class="text">钱包余额抵扣</div>
                        <div class="text2">(剩余可用：{{usableMoney}}元<label *ngIf="buckleMoney">，已抵扣：￥{{buckleMoney}}</label>)</div>
                    </div>
                </div>
                <div class="changeicon" [ngClass]="{changeicon:selectMoney,changeiconno:!selectMoney,active:moxibustion_wallet_amount}" (click)="moneyclick()"></div>
            </div>
            <div class="allprice">还需现金支付：<label>¥{{requiredMoney}}</label></div>
        </div>
        <div *ngIf="orderType" class="pay_text">请选择支付方式</div>
        <!-- 支付环境显示 -->
        <div class="pay-type">
            <div *ngFor="let item of payWayArr;let index = index">
                <div class="pay-type-item" *ngIf="envPlatform==item.env">
                    <div class="flex_item">
                        <div class="pay-item">
                            <img [src]="item.imgurl" />
                            <div class="text">{{item.name}}</div>
                        </div>
                        <div class="item_icon" [ngClass]="{icon_active:payWayIndex==item.type}" (click)="paywayClick(item.type)"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="homepage-button">
        <div class="pay-btn" (click)="payClick()">支付<span>¥{{requiredMoney}}</span></div>
    </div>
</div>
<!-- 旅游券兑换确认 -->
<div class="model">
    <div [class]="{'mask':isShowModel}" (click)="isShowModel=false"></div>
    <div class="model_center" [class]="{'on':isShowModel}">
        <div class="model_title">温馨提示</div>
        <div class="model_text">选择使用循环蓝贝抵扣后，本单礼包不享受全额返名额、对接商家名额等附加权益，确定继续支付？</div>
        <div class="model_footer">
          <div class="model_cancel" (click)="isShowModel=false">重新选择</div>
          <div class="model_ack" (click)="payAckClick()">继续支付</div>
        </div>
    </div>
</div>