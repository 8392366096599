<div class="group-compop">
    <div class="group-comitem">
        <div class="comitem-all" *ngIf="datatype==='hint'">
            <div class="head">
                <div class="imgclose"><img (click)="close()"
                        src="https://base-assets.ddgeyou.com/groupbook/close.png" /></div>
            </div>
            <div class="center">
                <div class="label">你当前所属社区</div>
                <div class="label-info flex">
                    <div class="label1">{{areatext}}</div>
                    <div class="label2" (click)="operation(1)">变更<img  src="https://base-assets.ddgeyou.com/groupbook/more.png" />
                    </div>
                </div>
                <div class="checkbox">
                    <input type="checkbox" [checked]="inputchecked" [(ngModel)]="inputchecked" (click)="getinputchecked()"  />不再提示<br>
                </div>
            </div>
            <div class="button" (click)="operation(2)">发起组团</div>
        </div>
        <div class="comitem-all commodel" *ngIf="datatype==='order'||datatype==='defaulttip'||datatype==='resell'" style="margin-top: -6rem;">
            <!-- <div class="head">
                <div class="imgclose"><img (click)="close()"
                        src="https://base-assets.ddgeyou.com/groupbook/close.png" /></div>
            </div> -->
            <div class="center">
                <div class="label-info flex">
                    <div class="label1" style="font-size: 1rem;">{{showmodelpopdata.head}}</div>
                </div>
            </div>
            <div class="input" *ngIf="datatype==='resell'">
                <div class="priceunit">￥</div>
                <input [placeholder]="defaulttext" [(ngModel)]="fillinnumber" value=""
                  type="number" id="inputtalk" oninput="if(value<0)value='';" 
                  (ngModelChange)="fillinChange($event)">
                  <div class="inputimg" *ngIf="fillinnumber" (click)="deleteinput()"><img src="https://base-assets.ddgeyou.com/groupbook/inputclose.png"/></div>
                  <div class="inputtip" *ngIf="showerr">{{fillinnumbertip}}</div>
            </div>
            <div class="center2">
                <div class="text">{{showmodelpopdata.title}}</div>
             </div>
            <div class="button1 flex" *ngIf="datatype==='order'">
                <div class="left" (click)="close()">{{showmodelpopdata.text}}</div>
                <div class="right" (click)="operation(showmodelpopdata.incident)">{{showmodelpopdata.text2}}</div>
            </div>
            <div class="button1 flex" *ngIf="datatype==='resell'">
                <div class="left" (click)="close()">{{showmodelpopdata.text}}</div>
                <div class="right" (click)="operation('input')">{{showmodelpopdata.text2}}</div>
            </div>
            <div class="button1 flex defaulttip" *ngIf="datatype==='defaulttip'">
                <div class="left" (click)="close()">{{showmodelpopdata.text}}</div>
                <div class="right" (click)="operation(showmodelpopdata.incident)">{{showmodelpopdata.text2}}</div>
            </div>
        </div>
        <div class="comitem-all commodel" *ngIf="datatype==='jurisdiction'" style="margin-top: -6rem;">
            <div class="center">
                <div class="label-info flex">
                    <div class="center3" style="font-size: 0.94rem;">
                    <label class="label1">{{showmodelpopdata.head}}</label>
                    <label class="label2">{{showmodelpopdata.title2}}</label>
                    <label class="label1">{{showmodelpopdata.title3}}</label></div>
                </div>
            </div>
            <div class="button1 flex defaulttip" >
                <div class="left" (click)="close()">{{showmodelpopdata.text}}</div>
                <div class="right" (click)="operation(showmodelpopdata.incident)">{{showmodelpopdata.text2}}</div>
            </div>
        </div>
        <div class="comitem-all seckill" *ngIf="datatype==='seckillpagetip'" style="margin-top: -6rem;">
            <div class="head-title">
                {{showmodelpopdata.head}}
            </div>
            <div class="center">
                <div class="label-info flex">
                    <div class="center3" style="font-size: 0.94rem;">
                    <label class="label1">{{showmodelpopdata.title}}</label>
                    <label class="label2"  (click)="operation('seckillagreement')">{{showmodelpopdata.title2}}</label>
                </div>
                </div>
            </div>
            <div class="button1 flex defaulttip" >
                <div class="left" (click)="operation('seckillclose')">{{showmodelpopdata.text}}</div>
                <div class="right" (click)="operation(showmodelpopdata.incident)">{{showmodelpopdata.text2}}</div>
            </div>
        </div>
        <div class="comitem-all resell" *ngIf="datatype==='allresell'||datatype==='itemresell'" style="margin-top: -6rem;">
            <div class="head-title" [innerHTML]="showmodelpopdata.head">
                <!-- {{showmodelpopdata.head}} -->
            </div>
            <div class="center" *ngIf="datatype==='allresell'">
                <div class="changresell flex">
                    <!-- <div class="changeicon"></div> -->
                    <div class="changeicon "[ngClass]="{changeicon:changresell,changeiconno:!changresell}" (click)="changResellclick()"></div>
                    <div class="text">仅转售购入价低于指导价的商品</div>
                </div>
               <!--  <input type="checkbox" [checked]="inputchecked" [(ngModel)]="inputchecked" (click)="getinputchecked()"  />不再提示<br> -->
            </div>
            <div class="button1 flex defaulttip" >
                <div class="left"  (click)="close()">{{showmodelpopdata.text}}</div>
                <div class="right" *ngIf="datatype==='allresell'" (click)="operation('allresell')">{{showmodelpopdata.text2}}</div>
                <div class="right" *ngIf="datatype==='itemresell'" (click)="operation(showmodelpopdata.incident)">{{showmodelpopdata.text2}}</div>
            </div>
        </div>
        <div class="comitem-all newitemresell" *ngIf="datatype==='newitemresell'" style="margin-top: -6rem;">
            <div class="head-left">
                {{showmodelpopdata.title}}
            </div>
            <div class="head-center">
                <div class="left">{{showmodelpopdata.title2}}</div>
                <div class="right" [innerHTML]="showmodelpopdata.head"></div>
            </div>
            <div class="head-tip">{{showmodelpopdata.tip}}</div>
            <div class="button1 flex defaulttip" >
                <div class="left"  (click)="close()">{{showmodelpopdata.text}}</div>
                <div class="right" (click)="operation(showmodelpopdata.incident)">{{showmodelpopdata.text2}}</div>
            </div>
        </div>
        <div class="comitem-all resell" *ngIf="datatype==='sellItemtip'" style="margin-top: -6rem;">
            <div class="head-title">
                {{showmodelpopdata.head}}
            </div>
            <div class="button2 flex justify-center" >
                <div class="right" (click)="close()">{{showmodelpopdata.text2}}</div>
            </div>
        </div>
        <!-- 该字段针对限时补领 -->
        <div class="comitem-all earningsreplace" *ngIf="datatype==='earningsreplace'">
            <div class="imgclose"><img (click)="close()"
                src="https://base-assets.ddgeyou.com/groupbook/close.png" /></div>
            <div class="bg">
                <img src="https://base-assets.ddgeyou.com/groupbook/remedybg.png"/>
            </div>
            <div class="center">
                <div class="up-one">
                    <div class="text">方法一</div>
                    <div class="text2">邀请<label>2名</label>新用户成功抢购</div>
                    <div class="up-one-button" (click)="operation('replaceinvite')">{{showmodelpopdata.text}}</div>
                </div>
                <div class="up-one">
                    <div class="text">方法二</div>
                    <div class="text2">购买<label>1份</label>指定3199元商品</div>
                    <div class="up-one-button" (click)="operation('replacepurchase')">{{showmodelpopdata.text2}}</div>
                </div>
            </div>
        </div>
        <!-- 如何送好友蓝滴咚贝 -->
        <div class="comitem-all givebule" *ngIf="datatype==='givebule'" style="margin-top: -6rem;">
            <div class="givebule">
                <div class="imgclose"><img (click)="close()"
                    src="https://base-assets.ddgeyou.com/groupbook/close.png" /></div>
                <div class="head-title">
                    <img src="https://base-assets.ddgeyou.com/groupbook/givebulemodel.png"/>{{showmodelpopdata.head}}
                </div>
                <div class="center">{{showmodelpopdata.title}}</div>
                <div class="buttoninvite" (click)="operation('givebule')">{{showmodelpopdata.text}}</div>
                <div class="checkbox">
                    <input type="checkbox" [checked]="givebulechecked" [(ngModel)]="givebulechecked" (click)="getgivebulechecked()"  />不再提醒<br>
                </div>
                <div class="invitetip">{{showmodelpopdata.texttip}}</div>
            </div>
        </div>
        <div class="comitem-all spilltash" *ngIf="datatype==='spilltash'" style="margin-top: -6rem;">
            <div class="spilltash-all">
                <div class="imgclose"><img (click)="close()"
                        src="https://base-assets.ddgeyou.com/multiactive/closemodel.png" /></div>
                <div class="spilltash-head">{{showmodelpopdata.text}}</div>
                <div class="spilltash-title" [innerHTML]="showmodelpopdata.head"></div>
                <div class="spilltash-item" *ngFor="let item of showmodelpopdata.textdata;let index=index" (click)="operation(item.incident)">
                    <div class="spillitem-left">
                        <div class="text">{{item.tash}}</div>
                        <div class="text2">{{item.text}}</div>
                    </div>
                    <div class="spillitem-right">
                        <img src="https://base-assets.ddgeyou.com/multiactive/blueretrurn.png" />
                    </div>
                </div>
                <div *ngIf="showmodelpopdata.endData" (click)="jumpClick(showmodelpopdata.endData.url)" class="spilltash-end flex justify-end align-center">
                    {{showmodelpopdata.endData.text}}
                    <img src="https://base-assets.ddgeyou.com/multiactive/question_mark.png" />
                </div>
            </div>
        </div>
        <!-- 提交订单不同状态 -->
        <div class="orderlist-box" *ngIf="datatype==='orderfeedback'">
            <div class="comitem-all orderlist">
                <div class="head">{{showmodelpopdata.head}}</div>
                <div class="button-all one">
                    <div class="button-item" (click)="operation(showmodelpopdata.incident)">{{showmodelpopdata.text}}</div>
                </div>
            </div>
            <div class="close-box" (click)="close()" ><img src="https://base-assets.ddgeyou.com/groupbook/itemclose.png" /></div>
        </div>
        <!-- 提示弹窗 -->
        <div class="comitem-all commodel" *ngIf="datatype==='showTips'">
            <div class="center-tip">
                <div class="text">{{showmodelpopdata.title}}</div>
            </div>
            <div class="center-model">
                <div class="model-left">可取货额度：<span>{{showmodelpopdata.price}}元</span></div>
                <div class="model-right" (click)="pickClick(showmodelpopdata.incident1)">合并取货<img src="https://base-assets.ddgeyou.com/groupbook/icon_left_bule.png" /></div>
            </div>
            <div class="button1 flex">
                <div class="left" (click)="close()">{{showmodelpopdata.text}}</div>
                <div class="right" (click)="operation(showmodelpopdata.incident)">{{showmodelpopdata.text2}}</div>
            </div>
        </div>

        <!-- 订单确认取货弹窗 -->
        <div class="comitem-all orderlist" style="margin-top: -6rem;" *ngIf="datatype==='orderlist'">
            <div class="head">{{showmodelpopdata.head}}</div>
            <div class="button-all">
                <div class="button-item" (click)="operation(showmodelpopdata.incident2)">{{showmodelpopdata.text}}</div>
                <div class="button-item confirm" (click)="operation(showmodelpopdata.incident)">{{showmodelpopdata.text2}}</div>
            </div>
        </div>
        <!-- 提交订单不同状态 -->
        <div class="comitem-all orderlist" style="margin-top: -6rem;" *ngIf="datatype==='orderback'">
            <div class="head">{{showmodelpopdata.head}}</div>
            <div class="button-all one">
                <div class="button-item" (click)="operation(showmodelpopdata.incident)">{{showmodelpopdata.text}}</div>
            </div>
        </div>
        <!-- (新)订单确认取货弹窗 -->
        <div class="comitem-all neworderlist" *ngIf="datatype==='neworderlist'">
            <div class="title">已选择：<span>{{showmodelpopdata.allnum}}</span>&nbsp;件商品</div>
            <div class="content">
                <div class="text1">已选商品总值：<span>{{showmodelpopdata.allprice}}元</span></div>
                <div class="text2">可取货额度：<span>{{showmodelpopdata.pickup_worth}}元</span></div>
            </div>
            <div class="tips-box" *ngIf="showmodelpopdata.isTips">
                <div class="tips"><img src="https://base-assets.ddgeyou.com/groupbook/icon_frames.png" />已选商品价格过低，请谨慎确认</div>
            </div>
            <div class="button-all">
                <div class="button-item" (click)="operation(showmodelpopdata.incident2)">{{showmodelpopdata.text}}</div>
                <div class="button-item confirm" (click)="operation(showmodelpopdata.incident)">{{showmodelpopdata.text2}}</div>
            </div>
        </div>
    </div>
</div>
<app-compop (outer)="getareaData($event)" *ngIf="showareapop" ></app-compop>
