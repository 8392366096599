import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {RoutesRoutingModule} from './routes-routing.module';
import {SharedModule} from "../shared/shared.module";
import {ExceptionComponent} from "./exception/exception.component";


@NgModule({
    declarations: [ExceptionComponent],
    imports: [
        CommonModule,
        RoutesRoutingModule,
        SharedModule
    ],
    exports: [
        RoutesRoutingModule
    ]
})
export class RoutesModule {
}
