import {Component, Input, OnInit} from '@angular/core';
type DButtonType = 'shop' | null | 'disabled';
type DButtonSize = 'default' | 'l' | 'xl' | 's';
type DButtonShape = 'circle' | null
type DButtonLine = 'line' | null

@Component({
    selector: 'button[d-button], a[d-button]',
    template: `
        <ng-content></ng-content>
    `,
    host: {
        '[class.d-btn]': `true`,
        '[class.d-btn-shop]': `dType === 'shop'`,
        '[class.d-btn-disabled]': `dType === 'disabled'`,
        '[class.d-btn-block]': `dBlock`,
        '[class.d-btn-size-l]': `dSize === 'l'`,
        '[class.d-btn-size-xl]': `dSize === 'xl'`,
        '[class.d-btn-size-s]': `dSize === 's'`,
        '[class.d-btn-circle]': `dShape === 'circle'`,
        '[class.d-btn-line]': `dLine === 'line'`,
    }
})
export class ButtonComponent implements OnInit {

    @Input() dType: DButtonType = null;
    @Input() dSize: DButtonSize = null;
    @Input() dBlock: boolean = false;
    @Input() dShape: DButtonShape = null;
    @Input() dLine: DButtonLine = null;

    constructor() {
    }

    ngOnInit(): void {
    }

}
