import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from 'ng-zorro-antd-mobile';
import city from 'src/app/code/data/city';
import { PlatformService } from 'src/app/code/service/platform/platform.service';
import { ScrollService } from 'src/app/code/service/scroll/scroll.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { InviteService } from 'src/app/code/service/invite/invite.service';
import { ComputedService } from 'src/app/code/service/computed/computed.service';
import { WechatService } from 'src/app/code/service/wechat/wechat.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-store-edit',
  templateUrl: './store-edit.component.html',
  styleUrls: ['./store-edit.component.less']
})
export class StoreEditComponent implements OnInit {

  constructor(
    private title: Title,
    private router: Router,
    private route: ActivatedRoute,
    private _toast: ToastService,
    private http: HttpClient,
    private platform: PlatformService,
    private scroll: ScrollService,
    private invite: InviteService,
    private computed: ComputedService,
    private wechat: WechatService,
    private clipboard: Clipboard
  ) {
    title.setTitle('商家入驻');
  }

  isChecked = false

  isShowPop = false
  // 选择的支付方式--默认0
  payWayIndex = 0
  // 支付类型数组
  payWayArr = []
  // 平台环境
  envPlatform = 'app'
  // 步骤
  stepIndex = 0
  // 店铺名
  store_name = null
  // 详细地址
  address = null
  // 所在地(选择的值)
  cityText = null
  //所在地
  domicilecity
  // 城市选择
  options = city
  province = null
  province_code = null
  city = null
  city_code = null
  area = null
  area_code = null
  // 弹窗参数
  popData: any = {}
  // 提示信息
  popupTip = null
  // 联系方式
  popupPhone = null
  // 入驻信息
  settle_info: any = {}
  // 订单支付状态
  order_status = 0
  // 支付信息
  settle_order_info: any = {}
  // 禁止信息输入
  disableName = false
  disableCity = false
  disableAdds = false
  // 禁止支付信息选择
  disablePay = false
  // 是否显示红色
  isShowError = false

  ngOnInit(): void {
    this.getData()
    // 支付成功和失败的回调
    const w = window as any;
    w.notifyWebPaySuccess = this.notifyWebPaySuccess.bind(this)
    w.notifyWebPayError = this.notifyWebPayError.bind(this)
    w.wechatSeverPaySuccess = this.wechatSeverPaySuccess.bind(this)// 走微信支付的回调
  }

  ngOnDestroy(): void {
    const w = window as any;
    delete w.notifyWebPaySuccess;
    delete w.notifyWebPayError;
    delete w.wechatSeverPaySuccess;
  }

  // app回调支付成功
  notifyWebPaySuccess(){}
  // app回调支付失败
  notifyWebPayError(){}
  // app走微信服务商失败和成功的回调
  wechatSeverPaySuccess(){}

  // 获取商家入驻页面信息
  getData(){
    this.http.post('/moxibustion/api/getmerchantsettleindexinfo', {invite_code:this.invite.getId()}).subscribe(
      (res: any) => {
        if(res.code==0){
          const settle_info = res.data.settle_info // 入驻信息
          settle_info.time_text = this.computed.parseTime(settle_info.create_time,'{y}-{m}-{d} {h}:{i}:{s}')
          const settle_order_info = res.data.settle_order_info // 入驻订单信息
          const store_info = res.data.store_info // 店铺信息
          if(settle_info.examine_status==0||settle_info.examine_status==3){ // 未提交 || 拒绝
            this.disableName = !!store_info.store_name // 是否可以输入
            this.disableCity = !!store_info.province_code||!!settle_order_info.order_no // 是否可以选择地区
            this.disableAdds = !!store_info.address // 是否可以输入详情地址
            this.disablePay = !!settle_order_info.order_no // 是否可以支付选择
            this.store_name = this.disableName?store_info.store_name:settle_info.store_name
            this.province_code = !!store_info.province_code?store_info.province_code:settle_info.province_code
            this.city_code = !!store_info.province_code?store_info.city_code:settle_info.city_code
            this.area_code = !!store_info.province_code?store_info.area_code:settle_info.area_code
            this.province = !!store_info.province_code?store_info.province:settle_info.province
            this.city = !!store_info.province_code?store_info.city:settle_info.city
            this.area = !!store_info.province_code?store_info.area:settle_info.area
            this.address = this.disableAdds?store_info.address:settle_info.address
            this.cityText = !!store_info.province_code?(store_info.province+store_info.city+store_info.area):(settle_info.province+settle_info.city+settle_info.area)
            if(settle_order_info.order_status==0){ // 未支付订单
              this.nowEnvironment()
              this.getPayEnv()
            }
            if(this.disablePay&&settle_info.examine_status==0){ // 有未支付订单
              this.stepIndex = 1
              this.settle_order_info = {
                order_no: settle_order_info.order_no,
                price: settle_order_info.payable_price,
                payable_price: settle_order_info.payable_price,
                give_yi_ticket: settle_order_info.give_yi_ticket,
                bundling_extra_service_list: settle_order_info.extra_service_list,
                can_select_extra_service_list: []
              }
            }
            if(settle_info.examine_status==3){
              this.stepIndex = 2
            }
          }else if(settle_info.examine_status==1||settle_info.examine_status==2){
            this.stepIndex = 2
          }
          this.settle_info = settle_info
          this.order_status = settle_order_info.order_status
        }
        if(res.code===42401){ // 对接人没名额
          this.stepIndex = 4
          this.popupTip = `${res.data.name} (${res.data.phone})`
          this.popupPhone = res.data.phone || ''
        }
        if(res.code===42402){ // 已开通实体店
          this.stepIndex = 0
          this.popData = {
            isShow: true,
            data: 1,
            okText: '进入管理',
            isCancel: true,
            text: `您已开通实体店，可直接进入管理页面进行易券赠送及购买`
          }
        }
        if(res.code===42403){ // 已存在对接人
          this.stepIndex = 3
          this.popupTip = `${res.data.name} (${res.data.phone})`
          this.popupPhone = res.data.phone || ''
        }
      }
    )
  }

  // 获取支付信息
  getPayInfo(){
    this.http.post('/moxibustion/api/getmerchantssettlecost', {}).subscribe(
      (res: { data }) => {
        if(res.data.can_select_extra_service_list&&res.data.can_select_extra_service_list.length){
          res.data.bundling_extra_service_list.map(item => {
            item.isChecked = false
          })
        }
        res.data.payable_price = res.data.price
        this.settle_order_info = res.data
      }
    )
  }

  // 获取支付环境
  getPayEnv(){
    this.http.post('/moxibustion/api/getherbalpayenv', {}).subscribe(
      (res: { data }) => {
        this.payWayArr = res.data
        this.payWayIndex = this.payWayArr.filter(item => item.env === this.envPlatform).map(item=>item.type)[0]
      }
    )
  }

  //当前环境判断
  nowEnvironment() {
    this.platform.check(
      null,
      () => this.envPlatform = 'app',
      () => this.envPlatform = 'app',
      () => this.envPlatform = 'h5'
    );
  }

  // 选择支付方式
  paywayClick(index){
    this.payWayIndex = index
  }

  // 下一步
  nextClick(){
    if(!this.store_name){
      return this._toast.show('请填写店铺名称')
    }
    if(!this.cityText){
      return this._toast.show('请选择所在地区')
    }
    if(!this.address){
      return this._toast.show('请填写详细地址')
    }
    const data = {
      invite_code: this.invite.getId(),
      store_name: this.store_name,
      province_code: this.province_code,
      city_code: this.city_code,
      area_code: this.area_code,
      province: this.province,
      city: this.city,
      area: this.area,
      address: this.address
    }
    this.http.post('/moxibustion/api/merchantsettlesubmitbasicinfo', data).subscribe(
      (res: { data,code }) => {
        if(res.code==0){
          this.stepIndex = !this.order_status?1:2
          this.settle_info.examine_status=1
          this.settle_info.store_name = this.store_name
          if(!this.order_status){
            this.getPayInfo()
          }
        }
        if(res.code===42405){ // 店铺名称检测有问题
          this.isShowError = true
          this.popData = {
            isShow: true,
            data: 3,
            cancelText: '重新编辑',
            isOk: true,
            text: `店铺名重复或不符合政策规范，请重新编辑后提交`,
          }
        }
      }
    )
  }

  // 选择可选择服务
  selectService(item){
    if(this.disablePay)return
    item.isChecked = !item.isChecked
    const pricePrice = this.settle_order_info.can_select_extra_service_list.filter(item=>item.isChecked).reduce((pre: number, counts: any) => pre + counts.price,0) || 0
    this.settle_order_info.payable_price = this.computed.subtract(this.settle_order_info.price,pricePrice,1)
  }

  // 协议点击
  agreementClick(){
    window.location.href = `${environment.h5Url}travel_enter.html`
  }
  
  // 购买
  buyClick(){
    if(!this.isChecked){
      this._toast.show('请先同意商城入驻协议')
      return
    }
    if(!this.disablePay){
      const extra_service_ids = this.settle_order_info.can_select_extra_service_list.filter(item=>item.isChecked).map(item=>item.id)
      this.http.post('/moxibustion/api/merchantssettlesubmitorder', {extra_service_ids}).subscribe(
        (res: { data }) => {
          this.isShowPop = true
          this.settle_order_info.order_no = res.data.order_no
        }
      )
    }else{
      this.isShowPop = true
    }
  }

  // 户籍地选择
  onPickerChange(e: { label: string,value: string }[]): void {
    if (e) {
      const code = e.map(item=>item.value)
      const text = e.map(item=>item.label)
      if(code?.length){
        this.province_code= code[0]
        this.city_code= code[1]
        this.area_code = code.length==3? code[2] : 0
        this.province = text[0]
        this.city = text[1]
        this.area = text.length==3? text[2] : ''
      }
      this.cityText = this.province+this.city+this.area
    }
  }

  // 重新编辑
  editClick(){
    this.stepIndex = 0
    this.disableName = false
    this.disableCity = true
    this.disableAdds = false
  }

  // 复制
  copyClick(){
    this.clipboard.copy(this.popupPhone);
    this._toast.success('复制成功');
  }

  // 弹窗确认返回
  getReturnData(data) {
    this.popData.isShow = false
    if (data === 1) { // 进入管理
      this.router.navigate(['/simpleherbalzone/entitystore'], { })
    }else if (data === 2) {
      this.queryOrderStatus()
    }else if(data === 3){ // 重新编辑
      
    }
  }

  // 确认支付
  payClick(){
    this.platform.check(
      null,
      () => this.appPay(),
      () => this.appPay(),
      () => this.jsapiPay()
    )
  }

  // app支付
  appPay(){
    if(this.settle_order_info.order_no){
      const dataparam = {
        order_no: this.settle_order_info.order_no,
        pay_type: this.payWayIndex,
        client_type: 3
      }
      this.http.post('/moxibustion/api/merchantssettleorderpaymentsignature', dataparam).subscribe((res: any) => {
        if (res.code === 0) {// pay_type是支付为0，不跳支付
          this.isShowPop = false
          if (res.data.pay_type === 0) {
            this.paySuccess()
          } else {
            this.showPopUp()
            if (res.data.pay_type === 2 || res.data.pay_type === 10) {//支付宝原生支付
              this.platform.check(
                null,
                () => window['android'].aliPayOther(res.data.order_no, res.data.body),
                () => window['webkit'].messageHandlers.aliPayOther.postMessage(JSON.stringify(res.data)),
                null
              );
            } else if(res.data.pay_type === 11 || res.data.pay_type === 12){
              this.platform.check(
                null,
                () => window['android'].openBrowser(res.data.paySignData),
                () => window['webkit'].messageHandlers.openBrowser.postMessage(res.data.paySignData),
                null
              );
            } else {
              this.platform.check(
                null,
                () => window['android'].aliPayFast(res.data.order_no, res.data.paySignData),
                () => window['webkit'].messageHandlers.aliPayFast.postMessage(JSON.stringify(res.data)),
                null
              );
            }
          }
        } else if (res.code === 4711) {
          this._toast.show(res.msg)
          setTimeout(() => {
            history.go(-1)
          }, 1000);
        }
      });
    }
  }

  // h5支付
  jsapiPay(){
    if (this.settle_order_info.order_no) {
      const dataparam = {
        order_no: this.settle_order_info.order_no,
        pay_type: this.payWayIndex,
        client_type: 1,
        open_id: localStorage.getItem('openId')
      }
      this.http.post('/moxibustion/api/merchantssettleorderpaymentsignature', dataparam).subscribe((res: any) => {
        this.isShowPop = false
        if (res.data.pay_type === 0) {//全额抵扣
          this.paySuccess()
        }else{// 支付
          this.showPopUp()
          if(res.data.pay_type===1){
            this.wechat.pay(res.data, res.data.order_no, () => {
              if (window.localStorage.getItem('paymentstatus')) {// 微信取消支付
                window.localStorage.removeItem('paymentstatus');
                this.disablePay = true
              } else {// 微信h5支付--->跳转成功无法带参数
                this.paySuccess()
              }
            })
          } else if(res.data.pay_type === 11){
            window.location.href = res.data.paySignData;
          } else if(res.data.pay_type === 12){
            this.router.navigate(['/simpleherbalzone/alipayh5'], { queryParams: { orderNo: encodeURIComponent(res.data.paySignData) } }).then()
          }
        }
      })
    }
  }

  // 显示支付弹窗公共方法
  showPopUp(){
    this.popData = {
      isShow: true,
      data: 2,//完成支付
      text: '请在微信或支付宝内完成支付，如您已支付成功，请点击“完成支付”按钮',
      okText: '完成支付'
    }
  }

  // 发起支付后查询订单支付状态
  queryOrderStatus(){
    this.http.post('/moxibustion/api/getmerchantssettleorderstatusafterinitiatepayment', { order_no: this.settle_order_info.order_no }).subscribe(
      (res: { data }) => {
        if(res.data.pay_status){
          this._toast.show('支付成功')
          this.paySuccess()
        }else{
          this._toast.fail('支付失败')
          this.disablePay = true
        }
      }
    );
  }

  // 支付成功
  paySuccess(){
    this.stepIndex = 2
    this.settle_info = {
      store_name: this.store_name,
      examine_status: 1,
      time_text: this.computed.parseTime(new Date().getTime(),'{y}-{m}-{d} {h}:{i}:{s}')
    }
  }

  // 输入框获取焦
  modelFocus(){
    this.isShowError = false
  }

}
