<div class="newuserintroduce-all">
    <!-- <div class="limit-invite-img">
            <div class="detail-view">
                <div class="content" *ngIf="groupshowinfo">
                    <ng-template ngFor let-item [ngForOf]="groupshowinfo.content">
                        <div class="text">
                            <p *ngIf="item.type === 1">{{ item.content }}</p>
                        </div>
                        <img *ngIf="item.type === 2" [src]="item.content" alt="" />
                        <video controls *ngIf="item.type === 3" [src]="item.content"></video>
                    </ng-template>
                </div>
            </div>
    </div> -->
    <div style="position: relative;">
        <img src="https://base-assets.ddgeyou.com/groupbook/maskbg3.png" />
        <div class="button-click" (click)="goNewuser()">
            <img src="https://base-assets.ddgeyou.com/groupbook/button-item.png" />
        </div>
    </div>
   
    <div class="button">
        <!-- <div class="button-item" (click)="goNewuser()">进入社区抢购专区</div></div> -->
    </div>