<div class="container flex column align-center justify-center">
    <div class="avatar-image">
        <img [src]="avatar"
             alt="">
        <img src="https://promote-beta.ddgeyou.com/assets/images/register/right.png"
             alt=""
             class="status-icon"
             *ngIf="status === 1 || status === 2">
        <img src="https://promote-beta.ddgeyou.com/assets/images/register/wrong.png"
             alt=""
             class="status-icon"
             *ngIf="status === 3">
    </div>
    <p class="name">{{ name | stringValue:6 }}</p>
    <p class="tag" *ngIf="status < 2">邀请人</p>
    <p class="tag right" *ngIf="status === 2">已有邀请人</p>
    <p class="tag wrong" *ngIf="status === 3">无法邀请</p>
</div>
