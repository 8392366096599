<div class="competehome" *ngIf="spellinfo">
    <!-- -->
    <div class="head-info" *ngIf="spellinfo.status!==3">
        <div class="shopdetails">
            <div class="left">
                <img [src]="spellinfo.img" />
            </div>
            <div class="right">
                <div class="title omits">{{spellinfo.goods_name}}</div>
                <div class="label">{{spellinfo.quantity}}人竞抢</div>
                <div class="price-all">
                    <div class="compete-price">限时竞抢价：<label>{{spellinfo.price}}元</label></div>
                    <div class="original-price">原价买：￥{{spellinfo.selling_price}}</div>
                </div>
            </div>
            <div class="rightlabel">
                <div class="rightlabelitem">
                    <div class="text">立省</div>
                    <div class="text2">￥{{spellinfo.diff_price}}</div>
                </div>
            </div>
        </div>
    </div>
    <div class="succeed-info" *ngIf="spellinfo.status===3">
        <div class="spellicon">
            <div><img src="https://base-assets.ddgeyou.com/multiactive/strength.png" /></div>
            <div>竞抢已满员</div>
        </div>
        <div class="button-all">
            <div class="button" (click)="godounApp()">
                <div class="text1">下载易场APP</div>
                <div class="text2">查看竞抢结果</div>
            </div>
        </div>
    </div>
    <div class="spellingcase-all">
        <div class="spell-center">
            <div class="title">满<label>5</label>人即可竞抢结果，赶紧邀请好友一起抢吧~</div>
            <div class="userinfo">
                <div class="img" *ngFor="let it of spellinfo.snap_up_user_list;let index = inde">
                    <img [src]="it.avatar" />
                    <div class="tag" *ngIf="it.is_start_user==1">发起</div>
                </div>
                <span *ngIf="spellinfo.snap_up_user_list.length<spellinfo.quantity" (click)="goShowmodel()"><img
                        src="https://base-assets.ddgeyou.com/multiactive/queryicon.png" /></span>
            </div>
            <div class="compete-button" *ngIf="!bepayinfo&&spellinfo.status!==3" (click)="goParticipate()">立即参与竞抢</div>
            <div class="compete-button" *ngIf="bepayinfo&&spellinfo.status!==3"  (click)="goagainParticipate()">请在 {{counttime}} 内支付</div>
        </div>
        <div class="orderdetails">
            <div class="details-list-all" (click)="gocompeteRule()">
                <div class="details-list" >
                    <div class="left">竞抢玩法规则</div>
                    <div class="right">
                        <div class="number">
                            <div class="statustext"></div>
                        </div>
                        <div class="rightarrows"><img
                                src="https://base-assets.ddgeyou.com/multiactive/rightarrows.png" />
                        </div>
                    </div>
                </div>
                <div class="details-item">
                    <div class="text">竞抢团满员后即出结果，成功竞抢的用户可获得商品；</div>
                    <div class="text">没有竞抢成功，订金将退回钱包，并奖励1次抽奖机会；</div>
                </div>
            </div>
        </div>
    </div>
</div>