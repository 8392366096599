import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: 'app-exception',
  templateUrl: './exception.component.html',
  styleUrls: ['./exception.component.less']
})
export class ExceptionComponent implements OnInit {

  title = {
    403: '对不起，你没有查看该页面的权限。',
    404: '对不起，没有找到该页面。',
    500: '服务器发生错误了！',
  }

  code: string = '404';

  constructor(private route: ActivatedRoute, private router: Router) {
  }

  ngOnInit(): void {
    this.code = this.route.snapshot.paramMap.get('code') || '404'
  }

  toIndex() {
    this.router.navigateByUrl('/merchant/register/4GQ94N').then()
  }

}
