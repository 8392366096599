import { Component, OnInit, NgZone } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { ActivatedRoute, Router } from '@angular/router';
import { WechatService } from '../../../code/service/wechat/wechat.service';
import { PlatformService } from "../../../code/service/platform/platform.service";
import { UserInfoService } from "../../../code/service/user/user.service";
import { AuthService } from "../../../code/service/auth/auth.service";
import { InviteService } from "../../../code/service/invite/invite.service";
export type detail = {
  id?: number,
  type: number,// 类型 1 视频
  video: string,// 购买后会返回视频地址
  video_img: string,
  pic: string[],
  title: string,
  price: number,
  origin_price: number,
  content: detailInfo[],
  is_free: number, // 是否免费 1 是 2 否
  is_buy: number, // 是否购买 1 是 2 否
  is_gs_pay: 0 | 1,
  quantity: number
} | null;

type detailInfo = {
  // 1文字, 2图片, 3视频
  type: 1 | 2 | 3,
  content: string
};
type orderInfo = {
  order_no: string,
  body: string
};
@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.less']
})
export class DetailComponent implements OnInit {
  orderInfo: orderInfo;
  topcoupon = 0;//点击的点券是多少
  // 商品ID
  id: number = null;
  // 商品详情
  detail: detail = null;
  // 当前banner的Index
  bannerIndex = 0;
  hasvideo = false;
  showplay = true; // 播放  showpause = false; // 暂停
  isdone = false;
  constructor(
    private router: Router,
    private http: HttpClient,
    public wechat: WechatService,
    private platform: PlatformService,
    private ngZone: NgZone,
    private route: ActivatedRoute,
    private invite: InviteService,
    private auth: AuthService) {
  }

  ngOnInit(): void {
    let routprams = (decodeURI(this.route.snapshot.queryParams.id));
    this.id = Number(routprams)
    this.getdetail()
    // 支付成功和失败的回调
    const w = window as any;
    w.notifyWebPaySuccess = this.notifyWebPaySuccess.bind(this);
    w.notifyWebPayError = this.notifyWebPayError.bind(this);

    // 微信支付前刷新
    if (window.location.href.split('#')[0] !== window['wechatConfigUrl']) {
      window.location.reload()
    }
  }
  ngOnDestroy(): void {
    const w = window as any;
    delete w.notifyWebPaySuccess;
    delete w.notifyWebPayError;
  }
  onBannerIndexChange(index: number): void {
    this.bannerIndex = index;
  }
  getdetail() {
    // 获取详情
    this.http.post('/advert/api/geyou/nonphysicalgoodsplaceinfo', { goods_id: this.id }).subscribe(
      (res: { data: detail }) => {
        this.detail = res.data;
        if (this.detail.is_buy === 1) {
          this.isdone = true;
        }
      }
    );
  }

  //购买
  gopay(item) {
    this.platform.check(
      null,
      () => this.appPay2(),
      () => this.appPay2(),
      () => this.gologin()
    );
  }
  gologin() {
    if (!this.auth.getToken()) {
      this.auth.redirectUrl = this.router.url;
      this.router.navigateByUrl('/user/register/phone').then();
    } else {
      //视频商品购买
      this.appPay2();
    }
  }
  appPay2() {
    //视频商品购买
    /* this.http.post('/advert/api/geyou/nonphysicalgoodsplaceorder', { goods_id: this.id, nums: 1 ,share_invite_code:this.invite.getId()}).subscribe(
      (res: { data }) => {
        if (res.data.order_no) {
          // this.goPay2(res.data.order_no)
          this.platform.check(
            null,
            () => this.appPay(res.data.order_no),
            () => this.appPay(res.data.order_no),
            () => this.jsapiPay(res.data.order_no)
          );
        }
      }
    ); */
    this.platform.check(
      null,
      () => this.appPay(),
      () => this.appPay(),
      () => this.jsapiPay()
    );
  }
  appPay(): void {
    this.http.post('/advert/api/geyou/nonphysicalgoodsplaceorder', { goods_id: this.id, nums: 1 }).subscribe(
      (res: { data }) => {
        if (res.data.order_no) {
          this.http.post('/advert/api/geyou/nonphysicalgoodsgopay', { order_no: res.data.order_no, pay_way: 2, client_type: 3 }).subscribe((res: { code: number, data: orderInfo }) => {
            if (res.code === 0) {
              this.orderInfo = res.data;
              this.platform.check(
                null,
                () => window['android'].aliPayOther(this.orderInfo.order_no, this.orderInfo.body),
                () => window['webkit'].messageHandlers.aliPayOther.postMessage(JSON.stringify(this.orderInfo)),
                null
              );
            }
          });
        }
      }
    );
  }
  // h5页面
  jsapiPay(): void {
    this.http.post('/advert/api/geyou/nonphysicalgoodsplaceorder', { goods_id: this.id, nums: 1, share_invite_code: this.invite.getId() }).subscribe(
      (res: { data }) => {
        if (res.data.order_no) {
          this.http.post('/advert/api/geyou/nonphysicalgoodsgopay', { order_no: res.data.order_no, pay_way: 1, client_type: 1, open_id: localStorage.getItem('openId') }).subscribe((res: { code: number, data: orderInfo }) => {
            if (res.code === 0) {
              this.wechat.pay(res.data, res.data.order_no, () => {
                this.router.navigate(['/videogoods/payresult' + '/' + this.id]).then();
              });
            }
          });

        }
      }
    );
  }
  /**
     * app回调支付成功
     * @param orderNo
     */
  notifyWebPaySuccess(orderNo): void {
    this.ngZone.run(() => {
      // 跳转到支付
      this.router.navigate(['/videogoods/payresult' + '/' + this.id]).then();
    });
  }

  /**
   * app回调支付失败
   * @param orderNo
   */
  notifyWebPayError(orderNo): void {
    console.log('error');
  }
}