<div class="order-list">
    <app-compopmodel (outer)="getModeldata($event)" *ngIf="showmodelpop" [datatype]="datatype" [showmodelpopdata]="showmodelpopdata"></app-compopmodel>
    <app-grouppopmodel #grouppopmodel></app-grouppopmodel>
    <div class="child-item child">
        <div class="flex_between">
            <div class="nav_title" *ngFor="let item of childdata;let index = index">
                <div [ngClass]="{'active':item.active,'noactive':!item.active}" (click)="childchenked(item)">
                    {{item.text}}
                </div>
            </div>
        </div>
    </div>
    <div class="order-list-item">
        <div class="list-item" (click)="goorderdetails(item)" *ngFor="let item of orderlistdata;let index = index">
            <div class="item-info flex">
                <div class="info-left flex">
                    <div class="img"><img [src]="item.img"/></div>
                    <div class="text-info">
                        <div class="text1 omits">{{item.route_title}}</div>
                        <div class="text2">数量：1</div>
                    </div>
                </div>
                <div class="info-right">
                    <div class="status" *ngIf="item.order_status===0">待付款</div>
                    <div class="status green" *ngIf="item.order_status===1">待使用</div>
                    <div class="status defult" *ngIf="item.order_status===2">已完成</div>
                    <div class="status defult" *ngIf="item.order_status===3">退款成功</div>
                    <div class="status defult" *ngIf="item.order_status===4">退款中</div>
                    <div class="status defult" *ngIf="item.order_status===5">已取消</div>
                    <div class="price">¥ {{item.selling_price}}</div>
                </div>
            </div>
            <div class="item-operation flex">
                <div class="allprce">应付：<label>¥ {{item.payable_price}}</label></div>
                <div class="button1" *ngIf="item.order_status===0" (click)="cancelorder(item,$event)">取消订单</div>
                <div class="button2" *ngIf="item.order_status===0">去支付</div>
                <div class="button3" *ngIf="item.order_status===1&&item.pickup_type===1" (click)="pickupOrder(item,$event)">自由取货</div>
                <div class="button2" *ngIf="item.order_status===1">去使用</div>
                <div class="button1" *ngIf="(item.order_status===2||item.order_status===5)&&item.is_voucher==0" (click)="deleteorder(item,$event)">删除订单</div>
                <div class="button2" *ngIf="item.order_status===2&&item.is_voucher==1">展示凭证</div>
            </div>
        </div>
    </div>
    <div class="datahint" *ngIf='datahint'>到底啦~</div>
    <div class="nodata" *ngIf="orderlistdata.length===0">
        <img src="https://base-assets.ddgeyou.com/groupbook/nodata.png" />
        <div>暂无数据</div>
    </div>
</div>
