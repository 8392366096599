import { HttpClient } from '@angular/common/http';
import { Component, NgZone, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ToastService } from 'ng-zorro-antd-mobile';
import { AuthService } from 'src/app/code/service/auth/auth.service';
import { WechatService } from 'src/app/code/service/wechat/wechat.service';
import { InviteService } from 'src/app/code/service/invite/invite.service';

@Component({
  selector: 'app-share',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.less']
})
export class ShareComponent implements OnInit {

  constructor(
    private title: Title,
    private router: Router,
    private _toast: ToastService,
    private http: HttpClient,
    private auth: AuthService,
    private invite: InviteService,
    private wechat: WechatService,
    private ngZone: NgZone,
  ) {
    title.setTitle('易场');
  }
  // 显示下载弹窗
  showDown = false
  // 显示下单弹窗
  showPopup = false
  isLogin = false

  jsonData: any = {}

  ngOnInit(): void {
    this.isLogin = this.auth.isLoggedIn()
    this.getData()
  }

  // 获取秒杀邀请活动详情
  getData() {
    this.http.post('/seckill/api/ycseckillinviteactivitydetails', {}).subscribe(
      (res: any) => {
        this.jsonData = res.data
      }
    )
  }

  // 免抢购下单
  orderClick(){
    if(this.isLogin){
      if(this.jsonData.is_can_buy){ // 判断是否有权限
        this.showPopup = true
      }else{
        this.showDown = true
      }
    }else{
      this.jumpLogin()
    }
  }

  // 抢单
  buyClick(item){
    this.http.post('/seckill/api/ycseckillinviteactivitybuysubmitorder', {
      price: item,
      open_id: localStorage.getItem('openId'),
      invite_code: this.invite.getId()
    }).subscribe((res: any) => {
        if(res.code==0){
          this.wechat.pay(res.data, res.data.order_no, () => {
            if (window.localStorage.getItem('paymentstatus')) {// 微信取消支付
              window.localStorage.removeItem('paymentstatus');
              this._toast.fail('已取消支付')
            } else {// 微信h5支付
              this.ngZone.run(() => this.router.navigateByUrl('/activity/result', { replaceUrl: true })).then()
            }
          })
        }else{
          this._toast.show(res.msg)
        }
    })
  }

  // 跳转登陆
  jumpLogin(){
    this.auth.redirectUrl = this.router.url
    this.router.navigateByUrl('/user/register/phone').then()
  }

  downApp(){
    window.location.href = 'http://assets.ddgeyou.com/ycindex.html'
  }

}
