import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router'
import { Title } from '@angular/platform-browser';
import ClipboardJS from "clipboard";
import { ToastService } from "ng-zorro-antd-mobile";
@Component({
  selector: 'app-h5order-pay',
  templateUrl: './h5order-pay.component.html',
  styleUrls: ['./h5order-pay.component.less']
})
export class H5orderPayComponent implements OnInit {
// 当前页面类型，是从组团购进来，还是金贝兑换进来
typepage
  constructor(
    private toast: ToastService,
    private title: Title,
    private router: Router,
    private route: ActivatedRoute,) {
    title.setTitle('购买成功');
  }
  // 复制
  clipboard = new ClipboardJS('.button');
  ngOnInit(): void {
    this.clipboard.on('success', () => {
      this.toast.success('复制成功');
    });
    this.typepage = this.route.snapshot.queryParams.type
    console.log('this.typepage',this.typepage)
  }
  
  // 查看我的订单
  goOrder(){
    this.router.navigate(['/order-list']).then()
  }
}
