import {Injectable, NgModule} from '@angular/core';
import {CommonModule} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {AvatarComponent} from "../components/avatar/avatar.component";
import {StringValuePipe} from "../code/pipe/string.pipe";
import {InputComponent} from "../components/input/input.component";
import {CarouselModule, DrawerModule, IconModule, PickerModule,InputItemModule,DatePickerModule,ListModule,SwitchModule} from "ng-zorro-antd-mobile";
import {ButtonComponent} from "../components/button/button.component";

const AntModule = [
    CarouselModule,
    DrawerModule,
    IconModule,
    ListModule,
    PickerModule,
    InputItemModule,
    DatePickerModule,
    SwitchModule
]

@Injectable({
    providedIn: 'root'
})
@NgModule({
    declarations: [
        AvatarComponent,
        ButtonComponent,
        InputComponent,
        StringValuePipe
    ],
    providers: [],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ...AntModule
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        StringValuePipe,
        AvatarComponent,
        ButtonComponent,
        InputComponent,
        ...AntModule
    ]
})
export class SharedModule {
}
