import { Injectable } from '@angular/core';
import { fromEvent, Subscription } from "rxjs";
import { debounceTime } from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class ScrollService {

    private subscribeScroll: Subscription;
    private callback: any = null;

    constructor() {
    }

    public listen(callback, scrollEvent?) {
        if (callback) this.callback = callback;
        this.subscribeScroll = fromEvent(window, 'scroll').pipe(debounceTime(500)).subscribe(() => {
                this.onWindowScroll();
                scrollEvent && scrollEvent()
            });
    }

    private onWindowScroll(): void {
        let scrollTop = document.documentElement.scrollTop || window.pageYOffset,
            clientHeight = document.documentElement.clientHeight,
            scrollHeight = document.documentElement.scrollHeight
        if (scrollTop + clientHeight + 40 > scrollHeight && !!this.callback) this.callback()
    }

    public unListen(): void {
        this.subscribeScroll.unsubscribe();
    }
}
