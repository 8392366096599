<app-commodel *ngIf="showmodel" (outer)="getModeldata($event)" [modeldata]="modeldata"></app-commodel>
<div class="order-confirm">
    <div *ngIf="detailsinfo.use_type===1">
        <div class="changaddress" *ngIf="addressinfo" (click)="changeAddress()">
            <div class="left">
                <div *ngIf="addressinfo.name;else addrinfo">
                    <div class="text">{{addressinfo.name}} {{addressinfo.phone}}</div>
                    <div class="addressinfo omit">{{addressinfo.area}}{{addressinfo.address}}</div>
                </div>
                <ng-template #addrinfo>
                    <div >
                        <div class="text2">请选择收货地址</div>
                        <div class="addressinfo">(必填)</div>
                    </div>
                </ng-template>
            </div>
            <div class="right">
                <img src="https://base-assets.ddgeyou.com/newgold/blackmore.png" />
            </div>
        </div>
    </div>
    <div class="shop-info">
        <div class="left">
            <img [src]="detailsinfo.imgs[0]" />
        </div>
        <div class="right">
            <div class="price-all">
                <div class="shoptitle omits">{{detailsinfo.goods_name}}</div>
                <div class="price" *ngIf="pagetype==='purchase'">{{detailsinfo.selling_price}}元
                    <!-- <label class="label" *ngIf="detailsinfo.buy_give_point>0">送{{detailsinfo.buy_give_point}}易点</label> -->
                    <div class="label" *ngIf="detailsinfo.buy_give_point>0||detailsinfo.tickets>0">送
                        <div *ngIf="detailsinfo.buy_give_point>0">{{detailsinfo.buy_give_point}}易点</div>
                        <div class="plus" *ngIf="detailsinfo.buy_give_point>0&&detailsinfo.tickets>0"> + </div>
                        <div *ngIf="detailsinfo.tickets>0">{{detailsinfo.tickets}}
                            <span *ngIf="detailsinfo.tickets_type===1">消费点券</span>
                            <span *ngIf="detailsinfo.tickets_type===2">旅游点券</span>
                        </div>
                    </div>
                </div>
                <div class="price" *ngIf="pagetype==='newconpon'">{{detailsinfo.need_point}}
                   <span>易点</span>
                </div>
                <div class="marketprice" *ngIf="pagetype==='purchase'">市场价：{{detailsinfo.scribing_price}}元</div>
            </div>
            <div class="number">x1</div>
        </div>
    </div>
    <div class="show-remark">
        <div class="input-item">
            <div class="label" style="align-items:normal;">备注</div>
            <div class="input">
                <textarea maxlength="50" id="story" name="story" [(ngModel)]="claimremark" rows="5" cols="33" placeholder="请填写备注信息"></textarea>
            </div>
        </div>
    </div>
    <div class="orderinfo-footer">
        <div class="footer-operation purchase" *ngIf="pagetype==='purchase'" (click)="goCompay()">
            <div class="purchase-button">支付 ￥{{detailsinfo.selling_price}}</div>
        </div>
        <div class="footer-operation" *ngIf="pagetype==='newconpon'">
            <div class="left">
                合计：<label>{{detailsinfo.need_point}}<label style="font-size: 0.75rem;">
             <span>易点</span>
                </label></label>
            </div>
            <div class="right">
                <div class="button" (click)="goPay()">确认兑换</div>
            </div>
        </div>
    </div>
</div>