import {Component, Input, OnInit, Output, EventEmitter, OnDestroy} from '@angular/core';
import {
    FormControl,
    ControlContainer,
    FormGroupDirective,
    ValidatorFn,
} from '@angular/forms';
import {RegisterService} from "../../code/service/register/register.service";

@Component({
    selector: 'app-input',
    templateUrl: './input.component.html',
    styleUrls: ['./input.component.less'],
    viewProviders: [{provide: ControlContainer, useExisting: FormGroupDirective}]
})
export class InputComponent implements OnInit, OnDestroy {
    // 表单name
    @Input() name: string;
    @Input() validators: ValidatorFn | ValidatorFn[]
    // 类型
    @Input() status: ('phone' | 'password' | 'text' | 'code' | 'emailCode') = 'text';
    // 是否禁用
    @Input() disabled: boolean;
    // 值
    @Input() value: string | number = '';
    // 输入框placeholder
    @Input() placeholder: string | number = '文本';
    // 区号
    @Input() area: string;

    // 发送验证码的手机
    @Input() beGetCodeValue: number | string;
    // 双向绑定
    @Output() valueChange = new EventEmitter();
    @Output() areaChange = new EventEmitter();

    areaArray: any = [];
    getCodeTime: number = 0;
    getCodeTimeInterval: any = null;
    inputStatus: string = 'text';

    inputForm = null;

    constructor(
        private register: RegisterService,
        private fd: FormGroupDirective
    ) {
    }

    ngOnInit(): void {
        this.inputForm = this.fd.form.addControl(this.name, new FormControl(this.value, this.validators))

        if (this.status === 'phone') {
            this.areaArray = this.register.getAreaData()
            this.inputStatus = 'number'
        } else if (this.status === 'code' || this.status === 'emailCode') {
            this.inputStatus = 'number';
        } else if (this.status === 'password') {
            this.inputStatus = 'password'
        }
    }

    ngOnDestroy(): void {
        this.fd.form.controls[this.name].clearValidators();
        this.fd.form.updateValueAndValidity();
        this.getCodeTimeInterval = null;
    }

    get statusText(): string {
        if (this.status === 'phone') {
            return '手机号';
        } else if (this.status === 'password') {
            return '密码';
        } else if (this.status === 'text') {
            return this.placeholder.toString();
        } else if (this.status === 'code' || this.status === 'emailCode') {
            return '验证码';
        }
    }

    /**
     * 输入值改变
     * @param value
     */
    onValueChange(value): void {
        this.valueChange.emit(value);
    }

    /**
     * 更改区号
     * @param value
     */
    onAreaChange(value): void {
        this.areaChange.emit(value)
    }

    /**
     * 获取手机验证码
     */
    getCode(): void {
        this.getCodeTime === 0 && this.register.getCode['phone'](this.beGetCodeValue, this.area)
            .subscribe(() => this.onGetCodeStartTimeout())
    }

    getEmailCode(): void {
        this.getCodeTime === 0 && this.register.getCode['email'](this.beGetCodeValue.toString())
            .subscribe(() => this.onGetCodeStartTimeout())
    }

    onGetCodeStartTimeout(): void {
        if(!this.getCodeTimeInterval){
            this.getCodeTime = 60;
            this.getCodeTimeInterval = setInterval(() => {
                this.getCodeTime--;
                if(this.getCodeTime <= 0){
                    clearInterval(this.getCodeTimeInterval);
                    this.getCodeTimeInterval = null;
                }
            }, 1000)
        }
    }

    /**
     * 密码框切换状态
     */
    changeInputStatus(): void {
        if (this.inputStatus === 'text') {
            this.inputStatus = 'password'
        } else {
            this.inputStatus = 'text'
        }
    }
}
