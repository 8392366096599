<app-compopmodel (outer)="getModeldata($event)" *ngIf="showmodelpop" [datatype]="datatype"
    [showmodelpopdata]="showmodelpopdata"></app-compopmodel>
<div class="newuserspecial" *ngIf="seckillinfo">
    <div class="seckilltip" *ngIf="formintroduce">
        <div class="misstip" *ngIf="seckillinfo.is_buy_invite_community===0">
            <div class="tipleft">
                <div class="text">好友送你1次免抢购抢购机会</div>
                <div class="text2 omit">(仅限使用1次)</div>
            </div>
            <div class="tipright">
                <div class="button" (click)="getshowSeckill()">免抢购抢单</div>
            </div>
        </div>
    </div>
    <div class="head">
        <div class="shareicon" *ngIf="!formintroduce">
            <div>
                <div><img src="https://base-assets.ddgeyou.com/groupbook/shareicon1.png" (click)="gouserShare()" />
                </div>
                <div style="margin-top: 0.85rem;"><img src="https://base-assets.ddgeyou.com/groupbook/ruleicon.png"
                        (click)="goRule()" /></div>
            </div>
        </div>
        <img src="https://base-assets.ddgeyou.com/groupbook/experience2.png" (click)="goShopintroduce()" />
    </div>
    <div class="newtip"
        *ngIf="seckillinfo.is_first_order_in_seven_days_condition&&seckillinfo.miss_community_income===0"
        (click)="goRule()">
        <div class="text">【新手福利】你已获得<label class="label1">免条件</label>收益权 <label
                class="label2">(剩余{{seckillinfo.surplus_days}}天)</label></div>
        <img class="newusergo" src="https://base-assets.ddgeyou.com/groupbook/newusergo.png" />
    </div>
    <div class="misstip" *ngIf="seckillinfo.miss_community_income>0">
        <div class="tipleft">
            <div class="text">累计已错过奖金：<label>{{seckillinfo.miss_community_income}}元</label></div>
            <div class="text2 omit">限时内邀请2位好友参与抢购即可补领</div>
        </div>
        <div class="tipright">
            <div class="button" (click)="rightReplace()">马上补领</div>
        </div>
    </div>
    <div class="spill-tip" (click)="goTask()"
        *ngIf="!seckillinfo.calculation_of_reward_conditions&&seckillinfo.pending_profit_price===0">
        <div class="spill-left">
            <div class="img"><img src="https://base-assets.ddgeyou.com/multiactive/errrule.png" /></div>
            <div class="text">
                <div>未完成收益增值任务</div>
                <div>下一场转售溢价将少得<label>{{seckillinfo.less_profit_percentage}}%</label>溢价奖励哦~</div>
            </div>
        </div>
        <div class="spill-right">
            <div class="text">去完成</div>
            <div class="img"><img src="https://base-assets.ddgeyou.com/multiactive/returnrule.png" /></div>
        </div>
    </div>
    <div class="spill-tip" (click)="goTask()" *ngIf="seckillinfo.calculation_of_reward_conditions">
        <div class="spill-left">
            <div class="img"><img src="https://base-assets.ddgeyou.com/multiactive/rightrule.png" /></div>
            <div class="text">
                <div>已完成收益增值任务</div>
                <div>下一场转售溢价将得全额溢价奖励</div>
            </div>
        </div>
        <div class="spill-right">
            <div class="text">查看</div>
            <div class="img"><img src="https://base-assets.ddgeyou.com/multiactive/returnrule.png" /></div>
        </div>
    </div>
    <div class="spill-tip" (click)="goTask()"
        *ngIf="!seckillinfo.calculation_of_reward_conditions&&seckillinfo.pending_profit_price>0">
        <div class="spill-left">
            <div class="img"><img src="https://base-assets.ddgeyou.com/multiactive/moneyrule.png" /></div>
            <div class="text">
                <div>你已错过<label>￥{{seckillinfo.pending_profit_price}}</label>溢价收益</div>
                <div>下一场开场前补完成任务可补领</div>
            </div>
        </div>
        <div class="spill-right">
            <div class="text">补任务</div>
            <div class="img"><img src="https://base-assets.ddgeyou.com/multiactive/returnrule.png" /></div>
        </div>
    </div>
    <!--  <div class="newuser-center">
        <div class="newuser-entrance">
            <div class="entrance-item" (click)="goNewseckill()">
                <div class="textinfo">
                    <div class="text1">
                        <div class="tip" *ngIf="seckillinfo.in_seckill_time===1">
                            <div class="misstip">抢购中…</div>
                        </div>
                        开场时间
                    </div>
                    <div class="text2" #text3><img
                            src="https://base-assets.ddgeyou.com/groupbook/newuserclock.png" />{{seckillinfo.is_has_numbers_session?seckillinfo.seckill_start_time:'准备中'}}
                    </div>
                </div>
                <div class="operation">
                    <div class="operation-button">
                        <img src="https://base-assets.ddgeyou.com/groupbook/operationbutton.png" />
                        <div class="operation-text">进入专场</div>
                    </div>
                    <div class="newseckillinfo">已抢购：<label class="blue">{{seckillinfo.settled_amount}}</label>件<label
                            class="grey">({{seckillinfo.settled_amount_cost}}元)</label>
                    </div>
                </div>
            </div>
            <div class="entrance-item" (click)="goWithdrawpage()">
                <div class="textinfo">
                    <div class="text1" #text1>新手钱包</div>
                    <div class="text2" #text2>{{seckillinfo.money}}<label>元</label></div>
                    <div class="button">提现</div>
                </div>
                <div class="operation">
                    <div class="operation-label">
                        <div class="label1">累计抢购收益</div>
                        <div class="label2">明细</div>
                    </div>
                    <div class="operation-earnings">
                        <div class="label1">{{seckillinfo.community_income}}<label>元</label></div>
                        <div class="label2" *ngIf="seckillinfo.today_community_income!==0">
                            <div class="status-tip">
                                <div class="tip-one">
                                    <label>今日</label>
                                    <label class="newly">+{{seckillinfo.today_community_income}}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    <div class="newuser-center">
        <div class="newuser-seckill-entrance">
            <div class="entrance-title">
                <div class="title">抢购专场<img src="https://base-assets.ddgeyou.com/groupbook/viplabel.png"
                        *ngIf='seckillinfo.is_member===1' /></div>
                <div class="status" *ngIf="seckillinfo.in_seckill_time!==1">未开始</div>
                <div class="status" *ngIf="seckillinfo.in_seckill_time===1">抢购中…</div>
            </div>
            <div class="entrance-info">
                <div class="time-info">
                    <div class="time">
                        <div class="date" *ngIf="seckillinfo.seckill_start_date"><img
                                src="https://base-assets.ddgeyou.com/groupbook/hintclock.png" />{{seckillinfo.seckill_start_date}}
                        </div>
                        {{seckillinfo.is_has_numbers_session?seckillinfo.seckill_start_time:'准备中'}}
                    </div>
                    <div class="newseckillinfo" *ngIf="seckillinfo.settled_amount">已抢购：<label
                            class="blue">{{seckillinfo.settled_amount}}</label>件<label class="grey">(价值
                            {{seckillinfo.settled_amount_cost}}元)</label>
                    </div>
                    <div class="img" (click)="goNewseckill()">
                        <img src="https://base-assets.ddgeyou.com/groupbook/gonewsecill5.png" />
                    </div>
                </div>
            </div>
        </div>
        <div class="newuser-earnings-entrance" *ngIf="!formintroduce">
            <div class="earnings-item">
                <div class="title">累计抢购收益</div>
                <div class="info">
                    <div class="status-tip" *ngIf="seckillinfo.today_community_income!==0">
                        <div class="tip-one">
                            <label>今日</label>
                            <label class="newly">+{{seckillinfo.today_community_income}}</label>
                        </div>
                    </div>
                    <div class="price">{{seckillinfo.community_income}}<label>元</label></div>
                </div>
                <div class="text" (click)="goReturnsdetail()">收益明细</div>
            </div>
            <div class="earnings-item" (click)="goWithdrawpage($event)">
                <div class="title">体验区钱包</div>
                <div class="info" #text1>
                    <div class="price" #text2>{{seckillinfo.money}}<label>元</label></div>
                </div>
                <div class="text" (click)="goWalletdetails($event)">钱包明细</div>
                <div class="button">提现</div>
            </div>
        </div>
    </div>
    <!-- <div class="newuser-center">
        <div class="newuser-entrance">
            <div class="entrance-item" (click)="goNewseckill()">
                <div class="text1">
                    <div class="tip" *ngIf="seckillinfo.in_seckill_time===1">
                        <div class="misstip">抢购中…</div>
                    </div>
                    <img src="https://base-assets.ddgeyou.com/groupbook/clock.png" />开场时间
                </div>
                <div class="text2" #text3>{{seckillinfo.is_has_numbers_session?seckillinfo.seckill_start_time:'准备中'}}</div>
                <div class="button">去抢购</div>
            </div>
            <div class="entrance-item"  (click)="goWithdrawpage()">
                <div class="text1" #text1>体验区钱包</div>
                <div class="text2" #text2 >{{seckillinfo.money}}<label>元</label></div>
                <div class="button">提现</div>
            </div>
        </div>
    </div> -->
    <div class="newuser-banner" *ngIf="!formintroduce">
        <img src="https://base-assets.ddgeyou.com/groupbook/gainbuletitle.png" />
    </div>
    <div class="newuser-gainblue" *ngIf="!formintroduce">
        <div class="gainblue-conten">
            <div class="gainconten-head">
                <div class="left">
                    <div class="text">累计待领取蓝贝奖励</div>
                </div>
                <div class="right" (click)="goawardRule()">规则</div>
            </div>
            <div class="gainconten-center">
                <div class="center-item">
                    <div class="left">
                        <div class="text">
                            <span>待领个人奖励</span>
                            <img src="https://base-assets.ddgeyou.com/groupbook/alreadyicon.png" *ngIf="seckillinfo.this_cycle_seckill_amount>=seckillinfo.cycle_need_seckill_amount;else right" />
                            <ng-template #right>
                                <img src="https://base-assets.ddgeyou.com/groupbook/unclaimedicon.png"/>
                            </ng-template>
                        </div>
                        <div class="number"><label>{{seckillinfo.personal_reward}}</label>枚蓝贝</div>
                    </div>
                    <div class="right">
                        <div class="right-all">
                            <div class="button" (click)="goRush()">去抢购</div>
                            <div class="text">完成({{seckillinfo.this_cycle_seckill_amount>seckillinfo.cycle_need_seckill_amount?seckillinfo.cycle_need_seckill_amount:seckillinfo.this_cycle_seckill_amount}}/{{seckillinfo.cycle_need_seckill_amount}})次抢购</div>
                        </div>
                    </div>
                </div>
                <div class="center-item">
                    <div class="left">
                        <div class="text">
                            <span>待下发好友奖励</span>
                            <img src="https://base-assets.ddgeyou.com/groupbook/alreadyicon.png" *ngIf="seckillinfo.this_cycle_invite_seckill_amount>0;else right" />
                            <ng-template #right>
                                <img src="https://base-assets.ddgeyou.com/groupbook/unclaimedicon.png"/>
                            </ng-template>
                        </div>
                        <div class="number"><label>{{seckillinfo.gift_to_friends}}</label>枚蓝贝</div>
                    </div>
                    <div class="right">
                        <div class="right-all">
                            <div class="button" (click)="goGive()">邀请好友</div>
                            <div class="text">(共{{seckillinfo.this_cycle_invite_seckill_amount}}人瓜分)</div>
                            <!-- <div class="text">首次参与抢购的好友瓜分</div> -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="gainconten-footer" (click)="gainBlueexamine()">查看奖励记录</div>
        </div>
        <div class="notice">
            注：蓝贝奖励将在每场结束后重新计算，赶紧在下一场结束前及时邀请好友参与抢购瓜分蓝贝吧~
        </div>
    </div>
    <!-- <div class="newuser-banner" *ngIf="!formintroduce">
        <img src="https://base-assets.ddgeyou.com/groupbook/newseckillbanner2.png" />
    </div> -->
    <!-- <div class="newuser-award" *ngIf="!formintroduce">
        <div class="award-title">
            <div class="title">好友转售成功，邀请人获赠溢价奖励{{seckillinfo.invite_premium}}%蓝贝</div>
            <div class="rule" (click)="goawardRule()">奖励规则</div>
        </div>
        <div class="award-center">
            <div class="award-item">
                <div class="award-item-info">
                    <div class="info-item" (click)="goBluedetails()">
                        <div class="text1">本月已发奖励</div>
                        <div class="text2">等量蓝贝可赠好友</div>
                        <div class="text3">{{seckillinfo.seckill_this_month_profit}}<label>蓝滴咚贝</label></div>
                        <div class="label">明细</div>
                    </div>
                    <div class="info-item">
                        <div class="text1">可赠好友</div>
                        <div class="text2">{{seckillinfo.gift_to_friends_expired}}清零</div>
                        <div class="text3">{{seckillinfo.gift_to_friends}}<label>蓝滴咚贝</label></div>
                        <div class="button" (click)="goGive()">送出</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="award-task">
            <div class="task-info">
                <div class="task-info-left">
                    <div class="title">累计中蓝贝奖励<label>奖励于下月1日结算发放</label></div>
                    <div class="number">{{seckillinfo.progressive_rewards}}<label>蓝滴咚贝</label></div>
                </div>
                <div class="task-info-right">
                    <div class="info-right">
                        <div class="button-item">
                            <div class="button" *ngIf="seckillinfo.this_month_seckill_amount>0">已完成</div>
                            <div class="button list" *ngIf="seckillinfo.this_month_seckill_amount===0"
                                (click)="goNewseckill()">去抢购</div>
                        </div>
                        <div class="tip">
                            月底前完成({{(seckillinfo.this_month_seckill_amount>0)?'1':'0'}}/{{seckillinfo.need_invite_amount}})次抢购</div>
                    </div>
                </div>
            </div>
            <div class="task-info">
                <div class="task-info-left">
                    <div class="title">本月参与抢购好友</div>
                </div>
                <div class="task-info-right">
                    <div class="info-right">
                        <div class="button-item">
                            <div class="button list" (click)="goFriendlist()">好友列表</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    <!-- <div class="newuser-share" (click)="gouserShare()" *ngIf="!formintroduce">
        <img src="https://base-assets.ddgeyou.com/groupbook/newusershare2.png" />
    </div> -->
    <div class="explain" *ngIf="formintroduce">
        <div class="title">
            <div class="img">
                <img src="https://base-assets.ddgeyou.com/groupbook/explaintext.png" />
            </div>
            <div class="text" (click)="goRule()">详细规则说明</div>
            <img class="newusergo" src="https://base-assets.ddgeyou.com/groupbook/newusergo.png" />
        </div>
        <div class="content">
            <img src="https://base-assets.ddgeyou.com/groupbook/newuserexplain3.png" />
        </div>
    </div>
    <div class="button-download" *ngIf="formintroduce">
        <div class="button-item" (click)="goDownload()">下载APP参与抢购</div>
    </div>
</div>
<div *ngIf="seckillinfo">
    <div class="introduceshade" *ngIf="formintroduce&&showcontseckill&&seckillinfo.is_buy_invite_community===0">
        <div class="introduceshade-item">
            <div class="shadeall">
                <div class="shadeall-item">
                    <div class="shadeall-title">好友送你1次免抢购抢购机会<label>（仅限使用1次）</label></div>
                    <img src="https://base-assets.ddgeyou.com/groupbook/seckillinva2.png" />
                    <div class="footer">
                        <div class="footer-don" (click)="giveUp()">放弃机会</div>
                        <div class="footer-button" (click)="getPurchase($event)"><label>￥200</label>免抢购抢单</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="introduceshade" *ngIf="showdownapp">
        <div class="introduceshade-item">
            <div class="shadeall2">
                <div class="shadeall2-item">
                    <img class="close-img" (click)="closePop()"
                        src="https://base-assets.ddgeyou.com/groupbook/icon_close.png" />
                    <div class="img" *ngIf="!ifyichang">
                        <img src="https://base-assets.ddgeyou.com/groupbook/seckillinva.png" />
                    </div>
                    <div class="img" *ngIf="ifyichang">
                        <img src="https://base-assets.ddgeyou.com/easygame/yc_seckillinva.png" />
                    </div>
                    <div class="button">
                        <div class="button-item" (click)="goDownload()"><img
                                src="https://base-assets.ddgeyou.com/groupbook/seckilinvaicon.png" />前往下载APP</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>