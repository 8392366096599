<app-popmodel (outer)="getReturnData($event)" *ngIf="popdata.isShow" [popdata]="popdata"></app-popmodel>
<div class="container_box">
  <div class="container_center">
    <div class="pay_top">
      <div class="top_sum">￥{{totalPriceText}}</div>
      <div class="top_tip">待支付金额 (支持分笔)</div>
    </div>
    <div class="pay_title">1. 选择本笔支付金额：</div>
    <div class="pay_select_box">
      <div class="select_item" 
        [class]="{'disable':item>totalPrice,'active':selectIndex==index}" 
        (click)="moneyClick(index,item)" 
        *ngFor="let item of moneyList;let index = index"
      >
        {{item}}元
      </div>
    </div>
    <div class="pay_input" [class]="{'active':selectIndex==3}" (click)="inputClick()">
      <span>手动输入 (元)</span>
      <input id="payInput" *ngIf="isAndroid" type="tel" placeholder="最高支持5万元每笔" [(ngModel)]="inputMoney" autocomplete="off" (ngModelChange)="modelMoneyChange($event)"
      oninput="value=value.replace(/[^\d.]/g, '').replace(/^(-)*(\d+)\.(\d\d).*$/,'$1$2.$3');if(value<0)value='';if(value>50000)value=50000"/>
      <input id="payInput" *ngIf="!isAndroid" type="number" placeholder="最高支持5万元每笔" [(ngModel)]="inputMoney" autocomplete="off" (ngModelChange)="modelMoneyChange($event)"
      oninput="value=value.replace(/[^\d.]/g, '').replace(/^(-)*(\d+)\.(\d\d).*$/,'$1$2.$3');if(value<0)value='';if(value>50000)value=50000"/>
    </div>
    <div class="pay_title">2. 选择支付方式：</div>
    <div class="pay_type">
      <div class="type_item">
        <div class="type_left">
          <img src="https://base-assets.ddgeyou.com/cstore/icon_hehe.png"/>
          <div>和合商圈资产
            <span *ngIf="!selectBlue&&!selectMoney"> (余额￥{{computed.subtract(usableBlue,usableMoney,1)}})</span>
            <span *ngIf="selectBlue||selectMoney" class="type_red"> (已选￥{{computed.subtract(buckleBlue,buckleMoney,1)}})</span>
          </div>
        </div>
        <div class="item_icon" [class]="{'icon_active':payWayIndex==0}" (click)="paywayClick(0)"></div>
      </div>
      <div class="type_hh" *ngIf="payWayIndex==0">
        <div class="hh_item">
          <div class="item_left">
            <div class="hh_icon" [class]="{'icon_active':selectBlue}" (click)="blueClick()"></div>
            <div class="item_center">
              <div class="item_text">使用蓝滴咚贝抵扣</div>
              <div class="item_tip">(蓝贝余额：{{usableBlue}}枚)</div>
            </div>
          </div>
          <input placeholder="0" type="number" [(ngModel)]="inputBlue" id="blueInput" autocomplete="off" (ngModelChange)="modelBlueChange($event)"
          oninput="value=value.replace(/[^0-9-]+/,'');if(value<=0)value='';if(value>this.usableBlue)value=this.usableBlue;"/>
        </div>
        <div class="hh_item">
          <div class="item_left">
            <div class="hh_icon" [class]="{'icon_active':selectMoney}" (click)="surplusClick()"></div>
            <div class="item_center">
              <div class="item_text">使用商圈钱包余额支付</div>
              <div class="item_tip">(可用余额：¥{{usableMoney}}，已抵扣：¥{{buckleMoney}} )</div>
            </div>
          </div>
        </div>
      </div>
      <div class="type_item_box" *ngFor="let item of payWayArr;let index = index">
        <div class="type_item" *ngIf="envPlatform==item.env">
          <div class="type_left">
            <img [src]="item.imgurl"/>
            <div>{{item.name}}支付</div>
          </div>
          <div class="item_icon" [class]="{'icon_active':payWayIndex==item.type}" (click)="paywayClick(item.type)"></div>
        </div>
      </div>
    </div>
    <div class="pay_tip">温馨提示：为支持各支付渠道单次支付限额，本笔订单支持分多笔进行支付，请按实际情况选择单次支付金额。</div>
  </div>
  <div class="container_tabbar" [class]="{'tabbar_bottom':isIphoneX}">
    <div class="tabbar_buy_btn" [class]="{'disable':selectIndex==-1||payWayIndex==-1||!payTotalMoney}" (click)="payClick()">立即支付
      <span *ngIf="payTotalMoney">￥{{payTotalMoney}}</span>
    </div>
  </div>
</div>