<div class="shop-detail" *ngIf="detail">
    <div class="shop-container">
        <div class="banner-view">
            <img *ngIf="detail.is_buy===2" [src]="detail.video_img" object-fit='cover' alt="" style='height: 100vw;'>
            <video controls *ngIf="detail.is_buy===1" [src]="detail.video"
              poster="{{ detail.video_img}}"></video>
              <div class="videopalyicon" *ngIf="detail.is_buy===2" ><img class="icon" src="https://base-assets.ddgeyou.com/gold/playicon.png"/></div>
        </div>        
        <div class="shop-title">
            <div class="name omit3">{{detail.title}}</div>
            <div class="left">
                <div class="shop-unit">￥</div>
                <div class="price">{{(detail.price).toFixed(2)}}</div>
                <div class="old-price">¥{{(detail.origin_price).toFixed(2)}}</div>
            </div>
        </div>
    </div>
    <!-- 商品详情 -->
    <div class="detail-view">
        <div class="title-view">
            <p>课程详情</p>
        </div>
        <div class="content">
            <ng-template ngFor let-item [ngForOf]="detail.content">
                <p *ngIf="item.type === 1">{{ item.content }}</p>
                <img *ngIf="item.type === 2" [src]="item.content" alt=""/>
                <video controls *ngIf="item.type === 3" [src]="item.content"></video>
            </ng-template>
        </div>
    </div>
    <div class="button-all">
        <div class="operation">
            <div class="buttongopay" *ngIf="detail.is_buy===2" (click)="gopay(taskdetails)"><label>购买</label></div>  
            <div class="button" *ngIf="detail.is_buy===1" (click)="gopay(taskdetails)"><label>已购买</label></div>          
        </div>
    </div>
</div>