import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {UserInfoService} from '../../../code/service/user/user.service';
import {WechatService} from '../../../code/service/wechat/wechat.service';
import {ToastService} from 'ng-zorro-antd-mobile';
import {InviteService} from '../../../code/service/invite/invite.service';
import {mergeMap} from "rxjs/operators";

export type detail = {
    route_service_id?: number,
    service_cover: detailInfo[],
    route_title: string,
    days: string,
    can_be_purchased: string,
    destination: string,
    service_details: detailInfo[],
    default_guide_selling_price: number,
    default_route_service_agent_id: number,
    selling_price: number,
    owner_type: number,
    travel_agency_name: string,
    travel_agency_pic: string,
    route_amount: number,
    service_people_amount: number,
    is_has_default_guide: number,
    guide_info: guideInfo,
    specification_list: specification[],
    agent_specification_list: specification[],
    other_description: otherDescription,
    travel_agency_user_id: number,
    img: detailInfo
} | null;

type detailInfo = {
    // 1文字, 2图片, 3视频
    type: 1 | 2 | 3,
    file_url: string | null,
    content: string | null,
};

type guideInfo = {
    name: string,
    avatar: string,
    sex: number,
    star: number,
    profile: string,
    guide_id: number
} | null;

type specification = {
    package_type: string,
    specification_list: specificationList[]
};

type specificationList = {
    specification_name: string,
    specification_price: number,
    specification_num: number | 0,
};

type otherDescription = {
    one_refund_scale: number,
    second_refund_scale: number,
    three_refund_scale: number,
    fourth_refund_scale: number,
    expense_description: string,
    precautions: string
};

type guideList = {
    guide_id: number,
    user_id: number,
    route_service_agent_id: number,
    selling_price: number,
    need_to_add_price: number,
    star: number,
    agent_specification_list: specification[],
    other_service_description: string,
    avatar: string,
    name: string,
    sex: number,
    profile: string,
    is_me_self: number
};

type travelerInfo = {
    traveler_id: number,
    name: string,
    certificate_type: number,
    certificate_name: string,
    id_number: string,
    phone: string,
    isSelected: boolean
};

@Component({
    selector: 'app-order',
    templateUrl: './confirm.component.html',
    styleUrls: ['./confirm.component.less']
})
export class ConfirmComponent implements OnInit {

    type: number = null;
    // 详情
    detail: detail = null;
    // 导游信息
    guideInfo: guideList = null;
    // 出行信息
    travelerInfo: {
        traveler_people_amount: number,
        contact_name: string,
        contact_phone: string,
        traveler_list: travelerInfo[],
        travel_date: string
    };
    // 选购
    specificationList: specification[] = [];
    agentSpecificationList: specification[] = [];
    // 总计
    totalPrice: 0;
    // 留言内容
    message = '';
    messageMinLength = 0;
    messageMaxLength = 50;
    // 点击协议
    agreementStatus = false;

    constructor(
        private router: Router,
        private http: HttpClient,
        private user: UserInfoService,
        private wechat: WechatService,
        private toast: ToastService,
        private invite: InviteService,
    ) {
    }

    ngOnInit() {
        // 微信支付前刷新
        if (window.location.href.split('#')[0] !== window['wechatConfigUrl']) {
            window.location.reload()
        }

        const data = JSON.parse(sessionStorage.getItem('confirmRouteOrderInfo'));

        this.detail = data.detail;
        this.guideInfo = data.guideInfo;
        this.travelerInfo = data.travelerInfo;
        this.specificationList = data.specification_list;
        this.agentSpecificationList = data.agent_specification_list;
        this.totalPrice = data.total_price;
        this.type = data.type;

    }

    getMessage(e): void {
        this.messageMinLength = e.length;
    }

    clickAgreementStatus(): void {
        this.agreementStatus = !this.agreementStatus;
    }

    goToPay(): void {

        if (!this.agreementStatus) {
            this.toast.info('请勾选协议');
            return;
        }

        const data = {
            route_service_agent_id: this.guideInfo.route_service_agent_id,
            travel_date: this.travelerInfo.travel_date,
            people_of_number: this.travelerInfo.traveler_people_amount,
            contact_number: this.travelerInfo.contact_phone,
            contact_name: this.travelerInfo.contact_name,
            service_specification: this.specificationList,
            agent_service_specification: this.agentSpecificationList,
            leave_comments: this.message,
            traveler_list: this.travelerInfo.traveler_list,
            yellow_scallop_amount: 0,
            live_id: 0,
        };

        this.http.post('/travel/api/submittravelorder', data).pipe(
            mergeMap((res: { data: { order_no: string} }) => this.http.post('/travel/api/travelorderpaymentsignature', {
                order_no: res.data.order_no,
                blue_scallop_amount: 0,
                wallet_balance_amount: 0,
                // 支付方式，1微信
                pay_type: 1,
                // client_type: this.wechat.isWechatUa ? 1 : 2,
                client_type: 1,
                open_id: localStorage.getItem('openId'),
            }))
        ).subscribe((res: { data }) => {
            if (JSON.stringify(res.data) === '{}' || JSON.stringify(res.data) === '[]') {
                this.toast.show('购买成功，正在为您跳转到下载页..')
                setTimeout(() => {
                    window.location.href = 'https://assets.ddgeyou.com/index.html';
                }, 2000)
            } else {
                this.wechat.pay(res.data, res.data.order_no, () => {
                    window.location.href = 'https://assets.ddgeyou.com/index.html';
                });
            }
        });


    }

}
