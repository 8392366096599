<div class="productetails-all" *ngIf="routedetails">
    <div class="head">
        <div class="banner-view">
            <Carousel *ngIf="routedetails.imgs.length>0" [infinite]="true" [dots]="false"
                style='height: 100vw;object-fit: cover;' (afterChange)="onBannerIndexChange($event)">
                <CarouselSlide dotPosition='top' dots *ngIf="routedetails.video">
                    <video controls [src]="routedetails.video" style='height: 100vw;width: 100vw;' #videoid></video>
                </CarouselSlide>
                <CarouselSlide dotPosition='top' dots *ngFor="let item of routedetails.imgs">
                    <img [src]="item" alt="" style='height: 100vw;object-fit: cover;'>
                </CarouselSlide>
            </Carousel>
            <div class="dots-all" *ngIf="dotsdata.length>1">
                <div class="dots">
                    <div *ngFor="let item of dotsdata;let index = index"
                        [ngClass]="{active:bannerIndex==index,noactive:bannerIndex!==index}"></div>
                </div>
            </div>
        </div>
        <div class="share" (click)="goDetailsshare()" *ngIf="!iswechat">
            <img src="https://base-assets.ddgeyou.com/multiactive/homeshare.png" />
        </div>
    </div>
    <div class="center">
        <div class="title">
            <img src="https://base-assets.ddgeyou.com/multiactive/titleicon2.png"
                onerror="this.src='https://base-assets.ddgeyou.com/gold/defult.png'" />
            <div class="rule">
                <div (click)="gocompeteRule()">规则</div>
            </div>
        </div>
        <div class="title-info">
            <div class="info-up">
                <div class="blindi-all">
                    <div class="label">{{routedetails.quantity}}人竞抢
                    </div>
                    <div class="icon">
                        <img src="https://base-assets.ddgeyou.com/multiactive/blindicon.png"
                            *ngFor="let item of [].constructor(routedetails.fail_give_blind_box_amount);let index = index" />
                    </div>
                </div>
                <div class="probability">
                    <div class="text">竞抢成功率</div>
                    <div class="icon">
                        <img src="https://base-assets.ddgeyou.com/multiactive/winicon.png"
                            *ngFor="let item of [].constructor(routedetails.successful_quantity);let index = index" />
                        <img src="https://base-assets.ddgeyou.com/multiactive/nowin.png"
                            *ngFor="let item of [].constructor(routedetails.noclustering);let index = index" />

                    </div>
                </div>
            </div>
            <div class="info-title">{{routedetails.goods_name}}</div>
            <div class="price">市场价：{{routedetails.selling_price}}元/件</div>
            <div class="compete-price">限时竞抢价：<label>{{routedetails.price}}元</label></div>
        </div>
    </div>
    <div class="groupbooklist" *ngIf="activeotherteamlist.length>0">
        <div class="list-head">
            <div class="text">
                满<label>{{routedetails.quantity}}</label>人公布结果
            </div>
            <div class="refresh" (click)="refreshOther()" [class]="{'refresh':isRefresh,'refreshing':!isRefresh}">
                <img src="https://base-assets.ddgeyou.com/multiactive/refresh.png" />刷新
            </div>
        </div>
        <div class="list-all">
            <div class="list-item" *ngFor="let item of activeotherteamlist;let index = index">
                <div class="people-img">
                    <div class="imgall" *ngFor="let it of item.snap_up_user_list;let index = inde">
                        <div class="tag">
                            <div class="tag-item" *ngIf="it.is_start_user===1">发起</div>
                        </div>
                        <div class="img"><img [src]="it.avatar" /></div>
                    </div>
                    <div class="text">{{item.quantity}}人已加入竞抢</div>
                </div>
                <div class="compete-button" (click)="goJoin(item)">加入竞抢</div>
            </div>
        </div>
    </div>
    <!-- 商品详情 -->
    <div class="detail-view">
        <div class="title-view">
            <p>商品详情</p>
        </div>
        <div class="content">
            <ng-template ngFor let-item [ngForOf]="routedetails.details">
                <p *ngIf="item.type === 1">{{ item.content }}</p>
                <div *ngIf="item.type === 2">
                    <div *ngIf="item.url">
                        <a [href]="item.url">
                            <img [src]="item.content" alt="" />
                        </a>
                    </div>
                    <div *ngIf="!item.url">
                        <img [src]="item.content" alt="" />
                    </div>
                </div>
                <video controls *ngIf="item.type === 3" [src]="item.content"></video>
            </ng-template>
        </div>
    </div>
    <div class="bottom" >
        <div class="button-item black" *ngIf="!iswechat" (click)="goPurchase()">
            <div class="item">
                <div>￥{{routedetails.direct_buy_price}}</div>直接购买
            </div>
            <div class="pointlabel"><label>送{{routedetails.buy_give_point}}易点</label></div>
        </div>
        <div class="button-item" (click)="goSponsor()">
            <div class="item">
                <div>￥{{routedetails.price}}</div>{{iswechat?'立即竞抢':'发起竞抢'}}
            </div>
        </div>
    </div>
</div>
<div class="affirm-model" *ngIf="showaffirmmodel&&routedetails">
    <div class="affirm-centen">
        <div class="centen-item">
            <div class="head">
                <div class="title">确认订单</div>
                <div class="head-close" (click)="close()"><img
                        src="https://base-assets.ddgeyou.com/multiactive/close.png" /></div>
            </div>
            <div *ngIf="routedetails.use_type===1">
                <div class="addressinfo" (click)="chooseAddress()" *ngIf="addressinfo.name;else addrinfo">
                    <div class="left">
                        <div class="nameinfo">{{addressinfo.name}}<label>{{addressinfo.phone}}</label></div>
                        <div class="addressdetails">{{addressinfo.address}}</div>
                    </div>
                    <div class="right">
                        <img src="https://base-assets.ddgeyou.com/multiactive/barckrightarrows.png" />
                    </div>
                </div>
                <ng-template #addrinfo>
                    <div class="addressinfo" (click)="chooseAddress()">
                        <div class="left">
                            <div class="text1">请选择收货地址</div>
                            <div class="text2" *ngIf="!purchbuy">(可先跳过不填写)</div>
                            <div class="text2" *ngIf="purchbuy">(必填)</div>
                        </div>
                        <div class="right">
                            <img src="https://base-assets.ddgeyou.com/multiactive/barckrightarrows.png" />
                        </div>
                    </div>
                </ng-template>
            </div>
            <div class="shopdetails">
                <div class="left">
                    <img [src]="routedetails.imgs[0]"
                        onerror="this.src='https://base-assets.ddgeyou.com/gold/defult.png'" />
                </div>
                <div class="right">
                    <div class="info">
                        <div class="title omits">{{routedetails.goods_name}}</div>
                        <div class="price-all">
                            <div class="compete-price" *ngIf="!purchbuy">价格：<label>{{routedetails.price}}元</label></div>
                            <div class="compete-price" *ngIf="purchbuy">价格：<label>{{routedetails.direct_buy_price}}元</label></div>
                            <div class="label" *ngIf="!purchbuy">{{routedetails.quantity}}人竞抢</div>
                        </div>
                        <div class="price">市场价：{{routedetails.selling_price}}元</div>
                    </div>
                    <div class="number">x1</div>
                </div>
            </div>
            <div class="footer">
                <div class="agreement" (click)="goCompeteagreement()">提交订单即同意《易场竞抢协议》</div>
                <div class="button-item" (click)="goPayorder()" *ngIf="!purchbuy">
                    <div class="pay">支付订金 ￥{{routedetails.price}}</div>
                    <div class="tip">未抢到原额退回</div>
                </div>
                <div class="button-item purchbuy" (click)="gorightPayorder()" *ngIf="purchbuy">
                    <div class="pay">直接购买￥{{routedetails.direct_buy_price}}</div>
                </div>
            </div>
        </div>
    </div>
</div>