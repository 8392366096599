import { Component, OnInit, ElementRef, ViewChild,NgZone  } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router'
import { HttpClient } from "@angular/common/http";
import { ToastService } from 'ng-zorro-antd-mobile';
import { WechatService } from '../../../code/service/wechat/wechat.service';
import { Title } from '@angular/platform-browser';
import { AuthService } from "../../../code/service/auth/auth.service";
import { UserInfoService } from "../../../code/service/user/user.service";
import { InviteService } from "../../../code/service/invite/invite.service";
import { PlatformService } from "../../../code/service/platform/platform.service";
type orderInfo = {
  order_no: string,
  body: string,
  pay_type: number
};
@Component({
  selector: 'app-order-confirm',
  templateUrl: './order-confirm.component.html',
  styleUrls: ['./order-confirm.component.less']
})
export class OrderConfirmComponent implements OnInit {

  constructor(
    private title: Title,
    private router: Router,
    private _toast: ToastService,
    private http: HttpClient,
    public wechat: WechatService,
    private route: ActivatedRoute,
    private auth: AuthService,
    private invite: InviteService,
    private platform: PlatformService,
    private ngZone: NgZone,
    private user: UserInfoService) {
    title.setTitle('订单确认');
  }
  // 默认地址
  addressdata
  // 可选择的地址信息
  addressinfo = {
    name: '',
    phone: '',
    address: '',
    area: ''
  }
  //判断当前是从金贝优选进入还是点券商城进入
  pagetype = 'newconpon'
  // 打开弹窗
  showmodel = false
  // 自子组件的数据
  modeldata = {}
  // 上个页面的信息
  detailsinfo
  // 是否在微信环境
  iswechat=false
  // 取货备注
  claimremark=''
  // 是否从最新的页面进入
  comfirmType=false

  ngOnInit(): void {
    let routprams = JSON.parse(decodeURIComponent(decodeURI(this.route.snapshot.queryParams.param)))
    this.detailsinfo = routprams
    if (routprams.hometype === 'purchase') {
      this.pagetype = 'purchase'
    }
    this.comfirmType = routprams?.comfirmType == 1
    // 检查是不是从上个页面过来
    this.changAddress()
  }
  // 如果是选择地址薄回来的场景
   changAddress() {
    if (window.localStorage.getItem('addressentrance') === 'affirmoder' && window.localStorage.getItem('addressdata')) {
      window.localStorage.removeItem('addressentrance')
      if (window.localStorage.getItem('addressdata')) {
        this.addressdata = JSON.parse(window.localStorage.getItem('addressdata'));
        this.addressinfo = {
          name: this.addressdata.name,
          phone: this.addressdata.phone,
          address: `${this.addressdata.address}`,
          area: this.addressdata.area
        }
        window.localStorage.removeItem('addressdata')
      }
    } else {
      this.getDefaultAddress()
    }
  }
  /**
  * 从服务器拿默认地址
  */
  getDefaultAddress(): void {
    this.http.post('/shop/api/addressbydefault', { user_id: this.user.id })
      .subscribe((res: { data }) => {
        if (JSON.stringify(res.data) !== "{}") {
          this.addressdata = res.data;
          this.addressinfo = {
            name: this.addressdata.name,
            phone: this.addressdata.phone,
            address: `${this.addressdata.address}`,
            area: this.addressdata.area
          }
        }
      })
  }
 // 选择收货地址
 changeAddress() {
    window.localStorage.setItem('addressentrance', 'affirmoder')
    this.router.navigate(['/multiactive/myaddress'], {}).then()
  }
  // 去支付
  goPay() {
    if(this.detailsinfo.use_type===1){
      if (!this.addressinfo.name) {
        this._toast.show('请先选择收货地址!')
        return
      }
    }
    if(this.pagetype==='purchase'){
      this.goydPurchase()
    }else{
      this.goCoupon();
    }
  }
  // 消费点券提交
  goCoupon() {
    // 消费点券商品进来不用去收银台
    if (this.detailsinfo.need_tickets_type === 2) {
      if (Number(this.detailsinfo.need_tickets) > Number(this.detailsinfo.travel_tickets)) {
        this.modeldata = {
          head: '提示',
          title: `易点不足,不能下单`,
          buttonone: '知道了',
          type: 'inform'
        }
        this.showmodel = true;
        return
      }
    }
      this.modeldata = {
        head: '提示',
        title: `确认使用<span style="color:rgba(255, 76, 76, 1);font-family: WeChatSansStd-Regular, WeChatSansStd-Bold;">${this.detailsinfo.need_point}易点</span>兑换？`,
        buttonone: '取消',
        buttontwo: '兑换',
        type: 'optimization'
      }
      this.showmodel = true;
  }
  // 自组建传来值方法
  getModeldata(e) {
    this.showmodel = false
    if (e === 'confirm') {
      console.log('继续')
    }else if(e==='optimization'){
      this.confirmConpon();
    }
  }
  // 兑换 
  confirmConpon(){
    if(this.comfirmType){// 最新接口
      this.http.post('/seckill/api/easypointexchangegoodsv2', {
        activity_id: this.detailsinfo.activity_id,
        time_id: this.detailsinfo.time_id,
        goods_config_id: this.detailsinfo.goods_config_id,
        receiver_name: this.addressinfo.name,
        receiver_phone: this.addressinfo.phone,
        receiver_area: this.addressinfo.area,
        receiver_address: this.addressinfo.address,
        remark:this.claimremark.trim()
      }).subscribe(
        (res: { data, code }) => {
          if (res.code === 0) {
            this.router.navigate(['/ydstoremall/ydexchang-success'], { replaceUrl: true }).then()
          }
        }
      )
    }else{
      // 点券直接兑换
      this.http.post('/seckill/api/easypointexchangegoods', {
        easy_point_exchange_config_id: this.detailsinfo.easy_point_exchange_config_id,
        receiver_name: this.addressinfo.name,
        receiver_phone: this.addressinfo.phone,
        receiver_area: this.addressinfo.area,
        receiver_address: this.addressinfo.address,
        remark:this.claimremark.trim()
      }).subscribe(
        (res: { data, code }) => {
          if (res.code === 0) {
            this.router.navigate(['/ydstoremall/ydexchang-success'], { replaceUrl: true }).then()
          }
        }
      )
    }
  }
  // 购物送易点过来的，立即购买
  goydPurchase() {
    if(this.comfirmType){// 最新接口
      this.http.post('/seckill/api/buygoodsgiveeasypointsubmitorderv2', {
        activity_id: this.detailsinfo.activity_id,
        time_id: this.detailsinfo.time_id,
        goods_config_id: this.detailsinfo.goods_config_id,
        receiver_name: this.addressinfo.name,
        receiver_phone: this.addressinfo.phone,
        receiver_area: this.addressinfo.area,
        receiver_address: this.addressinfo.address,
        remark:this.claimremark.trim(),
        invite_code: this.invite.getId()
      }).subscribe(
        (res: { data, code }) => {
          if (res.code === 0) {
            if(!this.iswechat){
              res.data.type = 'ydpurch';
              let param = encodeURI(JSON.stringify(res.data))
              this.router.navigate(['/order-pay'], {
                queryParams: {
                  param: param
                },
              }).then()
            }else{
              this.jsgoPurchase(res.data)
            }
          }
        }
      )
    }else{
      this.http.post('/seckill/api/buygoodsgiveeasypointsubmitorder', {
        easy_point_goods_config_id: this.detailsinfo.easy_point_goods_config_id,
        receiver_name: this.addressinfo.name,
        receiver_phone: this.addressinfo.phone,
        receiver_area: this.addressinfo.area,
        receiver_address: this.addressinfo.address,
        remark:this.claimremark.trim(),
        invite_code: this.invite.getId()
      }).subscribe(
        (res: { data, code }) => {
          if (res.code === 0) {
            if(!this.iswechat){
              res.data.type = 'ydpurch';
              let param = encodeURI(JSON.stringify(res.data))
              this.router.navigate(['/order-pay'], {
                queryParams: {
                  param: param
                },
              }).then()
            }else{
              this.jsgoPurchase(res.data)
            }
          }
        }
      )
    }
  }
  // 购买公共方法
  goCompay(){
    this.platform.check(
      null,
      () => this.goydPurchase(),
      () => this.goydPurchase(),
      () => this.jsgoH5()
    );
  }
  // h5环境下
  jsgoH5(){
    // 进入微信环境
    this.iswechat=true
    if (!this.auth.getToken()) {
      this.auth.redirectUrl = this.router.url;
      this.router.navigateByUrl('/user/register/phone').then();
    } else{
      console.log('在h5的操作')
      this.goydPurchase()
    }
  }
  // h5的去购买
  jsgoPurchase(item){
    // h5支付页面
      if (item.order_no) {
        let url = '/seckill/api/buygoodsgiveeasypointorderpaymentsignature'
        this.http.post(url, {
          order_no: item.order_no,
          pay_type: 1,
          client_type: 1,
          np_wallet_amount: 0,
          open_id: localStorage.getItem('openId')
        }).subscribe((res: { code: number, data: orderInfo }) => {
          console.log('拿到什么了啊', res.data)
          if (res.data.pay_type === 0) {
            window.location.reload()
          } else {
            if (res.code === 0) {
              this.wechat.pay(res.data, res.data.order_no, (res) => {
                console.log('拿到什么了啊2', res)
                if (window.localStorage.getItem('paymentstatus')) {
                  window.localStorage.removeItem('paymentstatus');
                  console.log('进入哪里1', window.localStorage.getItem('paymentstatus'))
                  window.location.reload()
                } else {
                  console.log('进入哪里2')
                  // this.ngZone.run(() => {
                  //   // 跳转到成功页面
                  //   this.router.navigate(['/newuserseckill/h5seckill-pay'], { replaceUrl: true }).then()
                  // });
                  this.ngZone.run(() => this.router.navigateByUrl('/ydstoremall/h5pay-success')).then();
                }
              });
            }
          }
        });
      }
    }
  //查看协议
  goAgreement() {
    window.location.href = 'https://assets.ddgeyou.com/web/newgoldagreement.html'
  }
}
