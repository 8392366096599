import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { AuthService } from "../auth/auth.service";
import { ActivatedRoute } from "@angular/router";
import { UserInfoService } from "../user/user.service";
import { ModalService, ToastService } from "ng-zorro-antd-mobile";
import {PlatformService} from "../../../code/service/platform/platform.service";

@Injectable({
  providedIn: 'root'
})
export class WechatService {

  constructor(
    private http: HttpClient,
    private auth: AuthService,
    private user: UserInfoService,
    private _modal: ModalService,
    private _toast: ToastService,
    private platform: PlatformService
  ) {

  }

  get isIos(): boolean {
    return navigator.userAgent.indexOf('iPhone') !== -1
  }

  get wechat(): any {
    return window['wx'] || null
  }

  get isWechatUa(): boolean {
    const ua = navigator.userAgent.toLowerCase();
    return ua.indexOf('micromessenger') > 0;
  }

  wechatShareItem = [
    'menuItem:share:appMessage',
    'menuItem:share:timeline'
  ]

  wechatProtectionItem = [
    'menuItem:openWithSafari',
    'menuItem:copyUrl',
    'menuItem:originPage',
    'menuItem:openWithQQBrowser',
    'menuItem:share:email'
  ]

  init(route: ActivatedRoute): void {
    this.http.post('/shop/api/identitypayconfig', { url: window['wechatConfigUrl'] })
      .subscribe((res: { data }) => {
        sessionStorage.setItem('weChatConfig', JSON.stringify(res.data));
        this.config(res.data, route)
      });
  }

  private config(data: any, route: ActivatedRoute): void {
    if (this.isIos && this.isWechatUa) {
      if (localStorage.getItem('openId') || localStorage.getItem('bargainingOpenId')) {
        console.log(data, window.location.href)
        this.wechat.config({
          debug: false,
          appId: data.appid,
          timestamp: data.timestamp,
          nonceStr: data.noncestr,
          signature: data.sign,
          jsApiList: ['chooseWXPay', 'updateAppMessageShareData', 'updateTimelineShareData', 'hideMenuItems', 'showMenuItems']
        });
        this.wechat.ready(() => {
          // 修改分享内容
          this.setIosShareData(route);

          // 隐藏或者显示按钮
          this.wechat.hideMenuItems({
            menuList: [
              ...this.wechatProtectionItem,
              'menuItem:share:qq',
              'menuItem:share:weiboApp',
              'menuItem:favorite',
              'menuItem:share:facebook',
              'menuItem:share:QZone',
            ]
          });
          if (this.auth.isLoggedIn()) {
            this.wechat.showMenuItems({
              menuList: [
                ...this.wechatShareItem,
              ]
            });
          } else if (localStorage.getItem('bargainingOpenId')) {
            this.wechat.showMenuItems({
              menuList: [
                ...this.wechatShareItem,
              ]
            });
          } else {
            this.wechat.hideMenuItems({
              menuList: [
                ...this.wechatShareItem,
              ]
            });
          }
        });
      }
      else {
        if (window.location.pathname.search('/activity/talk') === -1) {
          window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${data.appid}&redirect_uri=${encodeURIComponent(window.location.origin + window.location.pathname)}&response_type=code&scope=snsapi_base&state=a#wechat_redirect`
        }
      }
    } else
      if (this.wechat && this.isWechatUa) {
        if (localStorage.getItem('openId') || localStorage.getItem('bargainingOpenId')) {
          console.log(data, window.location.href)
          this.wechat.config({
            debug: false,
            appId: data.appid,
            timestamp: data.timestamp,
            nonceStr: data.noncestr,
            signature: data.sign,
            jsApiList: ['chooseWXPay', 'updateAppMessageShareData', 'updateTimelineShareData', 'hideMenuItems', 'showMenuItems']
          });
          this.wechat.ready(() => {
            // 修改分享内容
            this.setShareData(route);

            // 隐藏或者显示按钮
            this.wechat.hideMenuItems({
              menuList: [
                ...this.wechatProtectionItem,
                'menuItem:share:qq',
                'menuItem:share:weiboApp',
                'menuItem:favorite',
                'menuItem:share:facebook',
                'menuItem:share:QZone',
              ]
            });
            if (this.auth.isLoggedIn()) {
              this.wechat.showMenuItems({
                menuList: [
                  ...this.wechatShareItem,
                ]
              });
            } else if (localStorage.getItem('bargainingOpenId')) {
              this.wechat.showMenuItems({
                menuList: [
                  ...this.wechatShareItem,
                ]
              });
            } else {
              this.wechat.hideMenuItems({
                menuList: [
                  ...this.wechatShareItem,
                ]
              });
            }
          });
        }else {
          if (window.location.pathname.search('/activity/talk') === -1) {
            window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${data.appid}&redirect_uri=${encodeURIComponent(window.location.origin + window.location.pathname)}&response_type=code&scope=snsapi_base&state=a#wechat_redirect`
          }
        }
      }
  }

  // ios功能，目前快速分享导致没有图片依旧存在,测试一下内容

  private setIosShareData(route: ActivatedRoute): void {
    if (sessionStorage.getItem('bargainingInfotalk') || localStorage.getItem('bargainingInfotalk')) {
      const data = JSON.parse(sessionStorage.getItem('bargainingInfotalk'));
      this.wechat.updateAppMessageShareData({
        title: '就差你啦帮忙讲讲价吧',
        desc: '我在购买' + data.sku_name.substring(0, 10) + '...,' + '来帮忙讲个价吧~',
        link: window.location.origin + window.location.pathname + '?bargaining_id=' + data.bargaining_id,
        imgUrl: data.img + '?x-oss-process=image/resize,w_100,h_100,m_lfit',
      });
      // 朋友圈配置
      this.wechat.updateTimelineShareData({
        title: '就差你啦帮忙讲讲价吧',
        link: window.location.origin + window.location.pathname + '?bargaining_id=' + data.bargaining_id,
        imgUrl: data.img + '?x-oss-process=image/resize,w_100,h_100,m_lfit',
      });
    }
  }

  private setShareData(route: ActivatedRoute): void {
    if (sessionStorage.getItem('bargainingInfotalk') || localStorage.getItem('bargainingInfotalk')) {
      // let url = '';
      // if (window.location.origin === 'https://h5.ddgeyou.com') {
      //   url = '/activity/talk';
      // } else {
      //   url = '/h5/activity/talk';
      // }
      const data = JSON.parse(sessionStorage.getItem('bargainingInfotalk'));
      this.wechat.updateAppMessageShareData({
        title: '就差你啦帮忙讲讲价吧',
        desc: '我在购买' + data.sku_name.substring(0, 10) + '...,' + '来帮忙讲个价吧~',
        link: window.location.origin + window.location.pathname + '?bargaining_id=' + data.bargaining_id,
        imgUrl: data.img,
      });
      // 朋友圈配置
      this.wechat.updateTimelineShareData({
        title: '就差你啦帮忙讲讲价吧',
        link: window.location.origin + window.location.pathname + '?bargaining_id=' + data.bargaining_id,
        imgUrl: data.img,
      });
    } else
      if (route.snapshot.data['shareUrl']) {
        this.wechat.updateAppMessageShareData({
          title: route.snapshot.data['shareFriend']['title'],
          desc: route.snapshot.data['shareFriend']['shareContent'],
          link: window.location.origin + window.location.pathname + '?invite=' + this.user.userInfo['invite_code'],
          imgUrl: 'https://promote-beta.ddgeyou.com/assets/images/logo.png',
        });
        this.wechat.updateTimelineShareData({
          title: route.snapshot.data['shareTimeLine']['title'],
          link: window.location.origin + window.location.pathname + '?invite=' + this.user.userInfo['invite_code'],
          imgUrl: 'https://promote-beta.ddgeyou.com/assets/images/logo.png',
        })
      }
      else {
        this.wechat.updateAppMessageShareData({
          title: '欢迎注册滴咚个游，帮您实现边玩边赚梦想',
          desc: '轻松边玩边赚，越花越有',
          link: window.location.origin + '/h5/user/register/phone' + '?invite=' + this.user.userInfo['invite_code'],
          imgUrl: 'https://promote-beta.ddgeyou.com/assets/images/logo.png',
        })
        this.wechat.updateTimelineShareData({
          title: '欢迎注册滴咚个游，帮您实现边玩边赚梦想',
          link: window.location.origin + '/h5/user/register/phone' + '?invite=' + this.user.userInfo['invite_code'],
          imgUrl: 'https://promote-beta.ddgeyou.com/assets/images/logo.png',
        })
      }
  }

  pay(data: any, order_no: string, callback: Function): void {
    if (this.isWechatUa) {
      this.wechat.chooseWXPay({
        timestamp: data.timestamp,
        nonceStr: data.noncestr,
        package: data.package,
        signType: data.signType,
        paySign: data.sign,
        success: () => {
          callback && callback()
        },
        cancel:() =>{
          window.localStorage.setItem('paymentstatus','no')
          console.log('与偶吗，啊222',window.localStorage.getItem('paymentstatus'))
          callback && callback()
        },
        fail: (fail) => {
          console.log(fail)
        }
      });
    } else {
      this._modal.alert('支付完成前请勿关闭此窗口', '请确认是否完成支付', [
        { text: '未完成', onPress: () => console.log('cancel') },
        {
          text: '已完成', onPress: () => {
            callback && callback()
          }
        }
      ]);
      window.location.href = data.mweb_url;
    }
  }

    shareFriend(sendType: number, data: any): void {
        const sendData = {
          ...data, // title 标题，subtitle 副标题，image_url 图片地址，jump_url 地址/路径，app_id 小程序appId
          type: sendType // 1 链接 2 图片 3 小程序卡片
        };
        this.platform.check(
            null,
            () => window['android'].shareWechatFriend(JSON.stringify(sendData)),
            () => window['webkit'].messageHandlers.shareWechatFriend.postMessage(JSON.stringify(sendData)),
            null
        );
    }

    shareCircle(sendType: number, data: any): void {
        const sendData = {
            ...data, // title 标题，image_url 图片地址，jump_url 地址
            type: sendType // 1 链接 2 图片
        };
        this.platform.check(
            null,
            () => window['android'].shareWechatCircle(JSON.stringify(sendData)),
            () => window['webkit'].messageHandlers.shareWechatCircle.postMessage(JSON.stringify(sendData)),
            null
        );
    }

    saveImageToLocal(dataURL: string): void {
        const imgUrl = dataURL.split('data:image/png;base64,')[1];
        this.platform.check(
            null,
            () => window['android'].savePoster(imgUrl),
            () => window['webkit'].messageHandlers.savePoster.postMessage(imgUrl),
            () => this._toast.show('请长按海报保存')
        );
    }
}
