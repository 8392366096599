<div class="mcommodel">
    <div class="commodel-all">
        <!-- 订单确认取货弹窗 -->
        <div class="model-item" *ngIf="modeldata.type==='orderlist'">
            <div class="title">{{modeldata.title}}</div>
            <div class="button">
                <div class="button-item" (click)="operation('close')">{{modeldata.buttonone}}</div>
                <div class="button-item confirm" (click)="operation('confirm')">{{modeldata.buttontwo}}</div>
            </div>
        </div>
        <!-- 订单确认取消弹窗 -->
        <div class="model-item" *ngIf="modeldata.type==='cancelorder'">
            <div class="title">{{modeldata.title}}</div>
            <div class="button">
                <div class="button-item" (click)="operation('close')">{{modeldata.buttonone}}</div>
                <div class="button-item confirm" (click)="operation(modeldata.method)">{{modeldata.buttontwo}}</div>
            </div>
        </div>
        <!-- 拼单邀请弹窗 -->
        <div class="model-item spell" *ngIf="modeldata.type==='spelling'">
            <div>
                <div class="head">还差<label>{{modeldata.number}}</label>人，赶紧邀请好友一起竞抢吧</div>
                <div class="text">竞抢成功即获超值折扣好货</div>
                <div class="img" (click)="operation('spell')"><img src="https://base-assets.ddgeyou.com/multiactive/weixinicon.png" /></div>
                <div class="text2"  (click)="operation('spell')">立即邀请</div>
            </div>
            <div class="spclose" (click)="operation('close')"><img src="https://base-assets.ddgeyou.com/multiactive/spillclosemodel.png" /></div>
        </div>
        <!-- 拼单成功弹窗 -->
        <div class="model-item spell" *ngIf="modeldata.type==='activehome'">
            <div>
                <div class="head"><label>{{modeldata.number}}</label>位竞友已凑齐，赶紧去看结果吧~</div>
                <div class="img-list">
                    <div class="imgall" *ngFor="let item of modeldata.imglist;;let index = index">
                        <!-- <img [src]="item.avatar" /> -->
                        <!-- <div class="imgall" *ngFor="let it of item.snap_up_user_list;let index = inde"> -->
                            <div class="tag"><div class="tag-item" *ngIf="item.is_start_user===1">发起</div></div>
                            <div class="img"><img [src]="item.avatar"/></div>
                        <!-- </div> -->
                    </div>
                </div>
                <div class="button-examine" (click)="operation('examine')">查看竞抢结果</div>
            </div>
            <div class="spclose" (click)="operation('close')"><img src="https://base-assets.ddgeyou.com/multiactive/spillclosemodel.png" /></div>
        </div>

        <!-- 订单详情取货未填写地址弹窗 -->
        <div class="model-item claimgoods" *ngIf="modeldata.type==='claimgoods'">
            <div class="title left">{{modeldata.title}}</div>
            <div class="centen" (click)="operation('claimgoodsno')">
                <div class="left" *ngIf="modeldata.centen.name;else centen">
                    <div class="text omit">{{modeldata.centen.name}} <label>{{modeldata.centen.phone}}</label></div>
                    <div class="text omit" *ngIf="!modeldata.icon">{{modeldata.centen.address}}</div>
                    <div class="text" style="padding-right: 1rem;" *ngIf="modeldata.icon">{{modeldata.centen.address}}</div>
                </div>
                <ng-template #centen>
                    <div class="text omit">请选择收货地址</div>
                </ng-template>
                <div class="rightarrows" *ngIf="!modeldata.icon"><img src="https://base-assets.ddgeyou.com/multiactive/rightarrows.png" />
                </div>
            </div>
            <div class="button">
                <div class="button-item" (click)="operation('close')">{{modeldata.buttonone}}</div>
                <div class="button-item confirm" (click)="operation('claimgoods')">{{modeldata.buttontwo}}</div>
            </div>
        </div>
        <!--人工确认取货 -->
        <div class="model-item claimgoods" *ngIf="modeldata.type==='artclaimgoods'">
            <div class="title left">{{modeldata.title}}</div>
            <div class="centen art" (click)="operation('claimgoodsno')">
                    <div class="text">{{modeldata.centen.centen}}</div>
            </div>
            <div class="button">
                <div class="button-item" (click)="operation('close')">{{modeldata.buttonone}}</div>
                <div class="button-item confirm" (click)="operation('artclaimgoods')">{{modeldata.buttontwo}}</div>
            </div>
        </div>
        <!-- 订单详情取货已填写地址弹窗 -->
        <div class="model-item claimgoods" *ngIf="modeldata.type==='claimgoodsaffirm'">
            <div class="title left">{{modeldata.title}}</div>
            <div class="center">{{modeldata.center}}</div>
            <div class="button">
                <div class="button-item" (click)="operation('close')">{{modeldata.buttonone}}</div>
                <div class="button-item confirm" (click)="operation('confirm')">{{modeldata.buttontwo}}</div>
            </div>
        </div>
        <!--推广补领 -->
        <div class="model-item transferarea" *ngIf="modeldata.type==='transferarea'">
            <div class="tranhead">{{modeldata.head}}</div>
            <div class="title left" [innerHTML]="modeldata.title">
            </div>
            <div class="button">
                <div class="button-item" (click)="operation('close')">{{modeldata.buttonone}}</div>
                <div class="button-item confirm" (click)="operation('transferarea')">{{modeldata.buttontwo}}</div>
            </div>
        </div>
    </div>
</div>