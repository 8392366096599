import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router'
import { HttpClient } from "@angular/common/http";
import { ToastService } from 'ng-zorro-antd-mobile';
import { WechatService } from '../../../code/service/wechat/wechat.service';
import { Title } from '@angular/platform-browser';
import { AuthService } from "../../../code/service/auth/auth.service";
import { UserInfoService } from "../../../code/service/user/user.service";
@Component({
  selector: 'app-golddetails',
  templateUrl: './golddetails.component.html',
  styleUrls: ['./golddetails.component.less']
})
export class GolddetailsComponent implements OnInit {

  constructor(
    private title: Title,
    private router: Router,
    private _toast: ToastService,
    private http: HttpClient,
    public wechat: WechatService,
    private route: ActivatedRoute,
    private auth: AuthService,
    private user: UserInfoService) {
    title.setTitle('金贝换购商城');
  }
  // 活动线路详情
  routedetails;
  // 轮播图的下标
  dotsdata = []
  // 打开弹窗
  showmodel = false
  // 自子组件的数据
  modeldata = {}
  // 当前是否是推广页面
  isPromotion=false
  // 金贝id
  gold_config_id
  //商品id
  obj_id
  listoptimidata = [{ 'name': '1' }, { 'name': '1' }, { 'name': '1' }]
  ngOnInit(): void {
    let routprams = this.route.snapshot.queryParams;
    console.log('参数',routprams)
    if(routprams.pagetype){
      this.isPromotion=true
      this.gold_config_id=routprams.gold_config_id;
      this.obj_id=routprams.obj_id
      this.getrouteData(this.gold_config_id,this.obj_id)
    }else{
      if(routprams.data){
        this.gold_config_id=JSON.parse(routprams.data).gold_config_id
        this.obj_id=JSON.parse(routprams.data).obj_id
        this.getrouteData(this.gold_config_id,this.obj_id)
      }
    }
    
  }
  // 获取详情
  getrouteData(e,e2) {
    // 获取详情
    this.http.post('/advert/api/geyou/getgoldpreferredgoodsdetails', { gold_config_id: e, obj_id: e2 }).subscribe(
      (res: { data }) => {
        this.routedetails = res.data
        //dotsdata为轮播图下标循环的数组
        if (this.routedetails.imgs) {
          this.routedetails.imgs.forEach(element => {
            this.dotsdata.push(element)
          });
        }
        // this.routedetails.buy_give_gold=0
        // this.routedetails.buy_reback_tickets=0
        // 赠送消费点券和金贝都有时才会出现弹窗
        if(this.routedetails.buy_give_gold>0||this.routedetails.buy_reback_tickets>0){
          this.showdetailsmodel=true
        }
      }
    );
  }
  // 购买
  goPurchase() {
    if (!this.auth.getToken()) {
      this.auth.redirectUrl = this.router.url;
      this.router.navigateByUrl('/user/register/phone').then();
    } else {
      this.routedetails.hometype='newgold'
      let param = encodeURI(JSON.stringify(this.routedetails))
      param = encodeURIComponent(param) // 第二次加密
      // this.router.navigate(['/newgoldactive/order-confirm' + '/' + param]).then()
      this.router.navigate(['/newgoldactive/order-confirm'], {
        queryParams: {
          param:param,
        },
      }).then()
    }
  }
  // 去推广
  goPromotion() {
    if(Number(this.routedetails.buy_give_gold)>Number(this.routedetails.gold)){
      this.modeldata = {
        head: '提示',
        title: `你当前金贝不足，推广成功奖励暂不 发放，可于金贝足够后补领。`,
        buttonone: '取消',
        buttontwo: '继续推广',
        type: 'replace'
      }
      this.showmodel = true
    }else{
      // 去分享
      this.goPostpromotion()
    }
  }
    // 去分享
    goPostpromotion(){
      // 获取详情
      this.http.post('/advert/api/geyou/getgoldpreferredgoodsshareinfo', { gold_config_id: this.gold_config_id, obj_id:this.obj_id}).subscribe(
        (res: { data,code}) => {
          if(res.code===4701){
          }else if(res.code===0){
              this.wechat.shareFriend(1, {
                title: res.data.title,
                subtitle: res.data.desc,
                icon_url: res.data.img,
                jump_url: res.data.url,
                app_id: '',
              });
          }
        }
      );
    }
    // 自组建传来值方法
    getModeldata(e) {
      this.showmodel = false
      console.log('什么',e)
      if (e === 'optimization') {
        console.log('进入优选')
      } else if (e === 'selection') {
        console.log('进入优选')
      }else if(e==='confirm'){
        // 去分享
        this.goPostpromotion()
      }else if(e==='coupon'){
        // 去下载
        window.location.href = `https://assets.ddgeyou.com/index.html`
      }
    }
    //查看规则
    goRule(){
      window.location.href = 'https://assets.ddgeyou.com/web/newgoldrule.html'
    }
    showdetailsmodel=false
    // 关闭弹窗
    closemodel(){
      this.showdetailsmodel=false
    }
    //滑动锚点
    bannerIndex=0
    onBannerIndexChange(index: number): void {
      this.bannerIndex = index;
    }
    // 消费点券详情
    goshopDetails(item){
      if(this.isPromotion){
        this.modeldata = {
          title: `请下载和合商圈APP免费兑换吧！`,
          buttonone: '取消',
          buttontwo: '立即下载',
          type: 'coupon'
        }
        this.showmodel = true
        return
      }
      let itemdata={
        tickets_config_id:item.tickets_config_id,
        obj_id:item.obj_id
      }
      this.router.navigate(['/newgoldactive/coupondetails'], {
        queryParams: {
          data: JSON.stringify(itemdata)
        },
      });
    }
}
