import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {HttpClient,HttpHeaders} from "@angular/common/http";
import {AuthService} from "../auth/auth.service";
import {UserInfoService} from "../user/user.service";
import {Router} from "@angular/router";

interface RegisterCallback {
    data: {
        token: string,
        id: number
    }
}

@Injectable({
    providedIn: 'root'
})
export class RegisterService {

    area = [
        {"area": "安哥拉", "areaCode": "0244", "areaEnglish": "Angola"}, {
            "area": "阿富汗",
            "areaCode": "93",
            "areaEnglish": "Afghanistan"
        }, {"area": "阿尔巴尼亚", "areaCode": "335", "areaEnglish": "Albania"}, {
            "area": "阿尔及利亚",
            "areaCode": "213",
            "areaEnglish": "Algeria"
        }, {"area": "安道尔共和国", "areaCode": "376", "areaEnglish": "Andorra"}, {
            "area": "安圭拉岛",
            "areaCode": "1254",
            "areaEnglish": "Anguilla"
        }, {"area": "安提瓜和巴布达", "areaCode": "1268", "areaEnglish": "Antigua and Barbuda"}, {
            "area": "阿根廷",
            "areaCode": "54",
            "areaEnglish": "Argentina"
        }, {"area": "亚美尼亚", "areaCode": "374", "areaEnglish": "Armenia"}, {
            "area": "阿森松",
            "areaCode": "247",
            "areaEnglish": "Ascension"
        }, {"area": "澳大利亚", "areaCode": "61", "areaEnglish": "Australia"}, {
            "area": "奥地利",
            "areaCode": "43",
            "areaEnglish": "Austria"
        }, {"area": "阿塞拜疆", "areaCode": "994", "areaEnglish": "Azerbaijan"}, {
            "area": "巴哈马",
            "areaCode": "1242",
            "areaEnglish": "Bahamas"
        }, {"area": "巴林", "areaCode": "973", "areaEnglish": "Bahrain"}, {
            "area": "孟加拉国",
            "areaCode": "880",
            "areaEnglish": "Bangladesh"
        }, {"area": "巴巴多斯", "areaCode": "1246", "areaEnglish": "Barbados"}, {
            "area": "白俄罗斯",
            "areaCode": "375",
            "areaEnglish": "Belarus"
        }, {"area": "比利时", "areaCode": "32", "areaEnglish": "Belgium"}, {
            "area": "伯利兹",
            "areaCode": "501",
            "areaEnglish": "Belize"
        }, {"area": "贝宁", "areaCode": "229", "areaEnglish": "Benin"}, {
            "area": "百慕大群岛",
            "areaCode": "1441",
            "areaEnglish": "Bermuda Is"
        }, {"area": "玻利维亚", "areaCode": "591", "areaEnglish": "Bolivia"}, {
            "area": "博茨瓦纳",
            "areaCode": "267",
            "areaEnglish": "Botswana"
        }, {"area": "巴西", "areaCode": "55", "areaEnglish": "Brazil"}, {
            "area": "文莱",
            "areaCode": "673",
            "areaEnglish": "Brunei"
        }, {"area": "保加利亚", "areaCode": "359", "areaEnglish": "Bulgaria"}, {
            "area": "布基纳法索",
            "areaCode": "226",
            "areaEnglish": "Burkina Faso"
        }, {"area": "缅甸", "areaCode": "95", "areaEnglish": "Burma"}, {
            "area": "布隆迪",
            "areaCode": "257",
            "areaEnglish": "Burundi"
        }, {"area": "喀麦隆", "areaCode": "237", "areaEnglish": "Cameroon"}, {
            "area": "加拿大",
            "areaCode": "1",
            "areaEnglish": "Canada"
        }, {"area": "开曼群岛", "areaCode": "1345", "areaEnglish": "Cayman Is"}, {
            "area": "中非共和国",
            "areaCode": "236",
            "areaEnglish": "Central African Republic"
        }, {"area": "乍得", "areaCode": "235", "areaEnglish": "Chad"}, {
            "area": "智利",
            "areaCode": "56",
            "areaEnglish": "Chile"
        }, {"area": "中国", "areaCode": "86", "areaEnglish": "China"}, {
            "area": "哥伦比亚",
            "areaCode": "57",
            "areaEnglish": "Colombia"
        }, {"area": "刚果", "areaCode": "242", "areaEnglish": "Congo"}, {
            "area": "库克群岛",
            "areaCode": "682",
            "areaEnglish": "Cook Is"
        }, {"area": "哥斯达黎加", "areaCode": "506", "areaEnglish": "Costa Rica"}, {
            "area": "古巴",
            "areaCode": "53",
            "areaEnglish": "Cuba"
        }, {"area": "塞浦路斯", "areaCode": "357", "areaEnglish": "Cyprus"}, {
            "area": "捷克",
            "areaCode": "420",
            "areaEnglish": "Czech Republic"
        }, {"area": "丹麦", "areaCode": "45", "areaEnglish": "Denmark"}, {
            "area": "吉布提",
            "areaCode": "253",
            "areaEnglish": "Djibouti"
        }, {"area": "多米尼加共和国", "areaCode": "1890", "areaEnglish": "Dominica Rep"}, {
            "area": "厄瓜多尔",
            "areaCode": "593",
            "areaEnglish": "Ecuador"
        }, {"area": "埃及", "areaCode": "20", "areaEnglish": "Egypt"}, {
            "area": "萨尔瓦多",
            "areaCode": "503",
            "areaEnglish": "EI Salvador"
        }, {"area": "爱沙尼亚", "areaCode": "372", "areaEnglish": "Estonia"}, {
            "area": "埃塞俄比亚",
            "areaCode": "251",
            "areaEnglish": "Ethiopia"
        }, {"area": "斐济", "areaCode": "679", "areaEnglish": "Fiji"}, {
            "area": "芬兰",
            "areaCode": "358",
            "areaEnglish": "Finland"
        }, {"area": "法国", "areaCode": "33", "areaEnglish": "France"}, {
            "area": "法属圭亚那",
            "areaCode": "594",
            "areaEnglish": "French Guiana"
        }, {"area": "法属玻利尼西亚", "areaCode": "689", "areaEnglish": "French Polynesia"}, {
            "area": "加蓬",
            "areaCode": "241",
            "areaEnglish": "Gabon"
        }, {"area": "冈比亚", "areaCode": "220", "areaEnglish": "Gambia"}, {
            "area": "格鲁吉亚",
            "areaCode": "995",
            "areaEnglish": "Georgia"
        }, {"area": "德国", "areaCode": "49", "areaEnglish": "Germany"}, {
            "area": "加纳",
            "areaCode": "233",
            "areaEnglish": "Ghana"
        }, {"area": "直布罗陀", "areaCode": "350", "areaEnglish": "Gibraltar"}, {
            "area": "希腊",
            "areaCode": "30",
            "areaEnglish": "Greece"
        }, {"area": "格林纳达", "areaCode": "1809", "areaEnglish": "Grenada"}, {
            "area": "关岛",
            "areaCode": "1671",
            "areaEnglish": "Guam"
        }, {"area": "危地马拉", "areaCode": "502", "areaEnglish": "Guatemala"}, {
            "area": "几内亚",
            "areaCode": "224",
            "areaEnglish": "Guinea"
        }, {"area": "圭亚那", "areaCode": "592", "areaEnglish": "Guyana"}, {
            "area": "海地",
            "areaCode": "509",
            "areaEnglish": "Haiti"
        }, {"area": "洪都拉斯", "areaCode": "504", "areaEnglish": "Honduras"}, {
            "area": "中国香港",
            "areaCode": "852",
            "areaEnglish": "Hongkong"
        }, {"area": "匈牙利", "areaCode": "36", "areaEnglish": "Hungary"}, {
            "area": "冰岛",
            "areaCode": "354",
            "areaEnglish": "Iceland"
        }, {"area": "印度", "areaCode": "91", "areaEnglish": "India"}, {
            "area": "印度尼西亚",
            "areaCode": "62",
            "areaEnglish": "Indonesia"
        }, {"area": "伊朗", "areaCode": "98", "areaEnglish": "Iran"}, {
            "area": "伊拉克",
            "areaCode": "964",
            "areaEnglish": "Iraq"
        }, {"area": "爱尔兰", "areaCode": "353", "areaEnglish": "Ireland"}, {
            "area": "以色列",
            "areaCode": "972",
            "areaEnglish": "Israel"
        }, {"area": "意大利", "areaCode": "39", "areaEnglish": "Italy"}, {
            "area": "科特迪瓦",
            "areaCode": "225",
            "areaEnglish": "Ivory Coast"
        }, {"area": "牙买加", "areaCode": "1876", "areaEnglish": "Jamaica"}, {
            "area": "日本",
            "areaCode": "81",
            "areaEnglish": "Japan"
        }, {"area": "约旦", "areaCode": "962", "areaEnglish": "Jordan"}, {
            "area": "柬埔寨",
            "areaCode": "855",
            "areaEnglish": "Kampuchea (Cambodia )"
        }, {"area": "哈萨克斯坦", "areaCode": "327", "areaEnglish": "Kazakstan"}, {
            "area": "肯尼亚",
            "areaCode": "254",
            "areaEnglish": "Kenya"
        }, {"area": "韩国", "areaCode": "82", "areaEnglish": "Korea"}, {
            "area": "科威特",
            "areaCode": "965",
            "areaEnglish": "Kuwait"
        }, {"area": "吉尔吉斯坦", "areaCode": "331", "areaEnglish": "Kyrgyzstan"}, {
            "area": "老挝",
            "areaCode": "856",
            "areaEnglish": "Laos"
        }, {"area": "拉脱维亚", "areaCode": "371", "areaEnglish": "Latvia"}, {
            "area": "黎巴嫩",
            "areaCode": "961",
            "areaEnglish": "Lebanon"
        }, {"area": "莱索托", "areaCode": "266", "areaEnglish": "Lesotho"}, {
            "area": "利比里亚",
            "areaCode": "231",
            "areaEnglish": "Liberia"
        }, {"area": "利比亚", "areaCode": "218", "areaEnglish": "Libya"}, {
            "area": "列支敦士登",
            "areaCode": "423",
            "areaEnglish": "Liechtenstein"
        }, {"area": "立陶宛", "areaCode": "370", "areaEnglish": "Lithuania"}, {
            "area": "卢森堡",
            "areaCode": "352",
            "areaEnglish": "Luxembourg"
        }, {"area": "中国澳门", "areaCode": "853", "areaEnglish": "Macao"}, {
            "area": "马达加斯加",
            "areaCode": "261",
            "areaEnglish": "Madagascar"
        }, {"area": "马拉维", "areaCode": "265", "areaEnglish": "Malawi"}, {
            "area": "马来西亚",
            "areaCode": "60",
            "areaEnglish": "Malaysia"
        }, {"area": "马尔代夫", "areaCode": "960", "areaEnglish": "Maldives"}, {
            "area": "马里",
            "areaCode": "223",
            "areaEnglish": "Mali"
        }, {"area": "马耳他", "areaCode": "356", "areaEnglish": "Malta"}, {
            "area": "马里亚那群岛",
            "areaCode": "1670",
            "areaEnglish": "Mariana Is"
        }, {"area": "马提尼克", "areaCode": "596", "areaEnglish": "Martinique"}, {
            "area": "毛里求斯",
            "areaCode": "230",
            "areaEnglish": "Mauritius"
        }, {"area": "墨西哥", "areaCode": "52", "areaEnglish": "Mexico"}, {
            "area": "摩尔多瓦",
            "areaCode": "373",
            "areaEnglish": "Moldova"
        }, {"area": "摩纳哥", "areaCode": "377", "areaEnglish": "Monaco"}, {
            "area": "蒙古",
            "areaCode": "976",
            "areaEnglish": "Mongolia"
        }, {"area": "蒙特塞拉特岛", "areaCode": "1664", "areaEnglish": "Montserrat Is"}, {
            "area": "摩洛哥",
            "areaCode": "212",
            "areaEnglish": "Morocco"
        }, {"area": "莫桑比克", "areaCode": "258", "areaEnglish": "Mozambique"}, {
            "area": "纳米比亚",
            "areaCode": "264",
            "areaEnglish": "Namibia"
        }, {"area": "瑙鲁", "areaCode": "674", "areaEnglish": "Nauru"}, {
            "area": "尼泊尔",
            "areaCode": "977",
            "areaEnglish": "Nepal"
        }, {"area": "荷属安的列斯", "areaCode": "599", "areaEnglish": "Netheriands Antilles"}, {
            "area": "荷兰",
            "areaCode": "31",
            "areaEnglish": "Netherlands"
        }, {"area": "新西兰", "areaCode": "64", "areaEnglish": "New Zealand"}, {
            "area": "尼加拉瓜",
            "areaCode": "505",
            "areaEnglish": "Nicaragua"
        }, {"area": "尼日尔", "areaCode": "227", "areaEnglish": "Niger"}, {
            "area": "尼日利亚",
            "areaCode": "234",
            "areaEnglish": "Nigeria"
        }, {"area": "朝鲜", "areaCode": "850", "areaEnglish": "North Korea"}, {
            "area": "挪威",
            "areaCode": "47",
            "areaEnglish": "Norway"
        }, {"area": "阿曼", "areaCode": "968", "areaEnglish": "Oman"}, {
            "area": "巴基斯坦",
            "areaCode": "92",
            "areaEnglish": "Pakistan"
        }, {"area": "巴拿马", "areaCode": "507", "areaEnglish": "Panama"}, {
            "area": "巴布亚新几内亚",
            "areaCode": "675",
            "areaEnglish": "Papua New Cuinea"
        }, {"area": "巴拉圭", "areaCode": "595", "areaEnglish": "Paraguay"}, {
            "area": "秘鲁",
            "areaCode": "51",
            "areaEnglish": "Peru"
        }, {"area": "菲律宾", "areaCode": "63", "areaEnglish": "Philippines"}, {
            "area": "波兰",
            "areaCode": "48",
            "areaEnglish": "Poland"
        }, {"area": "葡萄牙", "areaCode": "351", "areaEnglish": "Portugal"}, {
            "area": "波多黎各",
            "areaCode": "1787",
            "areaEnglish": "Puerto Rico"
        }, {"area": "卡塔尔", "areaCode": "974", "areaEnglish": "Qatar"}, {
            "area": "留尼旺",
            "areaCode": "262",
            "areaEnglish": "Reunion"
        }, {"area": "罗马尼亚", "areaCode": "40", "areaEnglish": "Romania"}, {
            "area": "俄罗斯",
            "areaCode": "7",
            "areaEnglish": "Russia"
        }, {"area": "圣卢西亚", "areaCode": "1758", "areaEnglish": "Saint Lueia"}, {
            "area": "圣文森特岛",
            "areaCode": "1784",
            "areaEnglish": "Saint Vincent"
        }, {"area": "东萨摩亚(美)", "areaCode": "684", "areaEnglish": "Samoa Eastern"}, {
            "area": "西萨摩亚",
            "areaCode": "685",
            "areaEnglish": "Samoa Western"
        }, {"area": "圣马力诺", "areaCode": "378", "areaEnglish": "San Marino"}, {
            "area": "圣多美和普林西比",
            "areaCode": "239",
            "areaEnglish": "Sao Tome and Principe"
        }, {"area": "沙特阿拉伯", "areaCode": "966", "areaEnglish": "Saudi Arabia"}, {
            "area": "塞内加尔",
            "areaCode": "221",
            "areaEnglish": "Senegal"
        }, {"area": "塞舌尔", "areaCode": "248", "areaEnglish": "Seychelles"}, {
            "area": "塞拉利昂",
            "areaCode": "232",
            "areaEnglish": "Sierra Leone"
        }, {"area": "新加坡", "areaCode": "65", "areaEnglish": "Singapore"}, {
            "area": "斯洛伐克",
            "areaCode": "421",
            "areaEnglish": "Slovakia"
        }, {"area": "斯洛文尼亚", "areaCode": "386", "areaEnglish": "Slovenia"}, {
            "area": "所罗门群岛",
            "areaCode": "677",
            "areaEnglish": "Solomon Is"
        }, {"area": "索马里", "areaCode": "252", "areaEnglish": "Somali"}, {
            "area": "南非",
            "areaCode": "27",
            "areaEnglish": "South Africa"
        }, {"area": "西班牙", "areaCode": "34", "areaEnglish": "Spain"}, {
            "area": "斯里兰卡",
            "areaCode": "94",
            "areaEnglish": "SriLanka"
        }, {"area": "圣卢西亚", "areaCode": "1758", "areaEnglish": "St.Lucia"}, {
            "area": "圣文森特",
            "areaCode": "1784",
            "areaEnglish": "St.Vincent"
        }, {"area": "苏丹", "areaCode": "249", "areaEnglish": "Sudan"}, {
            "area": "苏里南",
            "areaCode": "597",
            "areaEnglish": "Suriname"
        }, {"area": "斯威士兰", "areaCode": "268", "areaEnglish": "Swaziland"}, {
            "area": "瑞典",
            "areaCode": "46",
            "areaEnglish": "Sweden"
        }, {"area": "瑞士", "areaCode": "41", "areaEnglish": "Switzerland"}, {
            "area": "叙利亚",
            "areaCode": "963",
            "areaEnglish": "Syria"
        }, {"area": "中国台湾", "areaCode": "886", "areaEnglish": "Taiwan"}, {
            "area": "塔吉克斯坦",
            "areaCode": "992",
            "areaEnglish": "Tajikstan"
        }, {"area": "坦桑尼亚", "areaCode": "255", "areaEnglish": "Tanzania"}, {
            "area": "泰国",
            "areaCode": "66",
            "areaEnglish": "Thailand"
        }, {"area": "多哥", "areaCode": "228", "areaEnglish": "Togo"}, {
            "area": "汤加",
            "areaCode": "676",
            "areaEnglish": "Tonga"
        }, {"area": "特立尼达和多巴哥", "areaCode": "1809", "areaEnglish": "Trinidad and Tobago"}, {
            "area": "突尼斯",
            "areaCode": "216",
            "areaEnglish": "Tunisia"
        }, {"area": "土耳其", "areaCode": "90", "areaEnglish": "Turkey"}, {
            "area": "土库曼斯坦",
            "areaCode": "993",
            "areaEnglish": "Turkmenistan"
        }, {"area": "乌干达", "areaCode": "256", "areaEnglish": "Uganda"}, {
            "area": "乌克兰",
            "areaCode": "380",
            "areaEnglish": "Ukraine"
        }, {"area": "阿拉伯联合酋长国", "areaCode": "971", "areaEnglish": "United Arab Emirates"}, {
            "area": "英国",
            "areaCode": "44",
            "areaEnglish": "United Kiongdom"
        }, {"area": "美国", "areaCode": "1", "areaEnglish": "United States of America"}, {
            "area": "乌拉圭",
            "areaCode": "598",
            "areaEnglish": "Uruguay"
        }, {"area": "乌兹别克斯坦", "areaCode": "233", "areaEnglish": "Uzbekistan"}, {
            "area": "委内瑞拉",
            "areaCode": "58",
            "areaEnglish": "Venezuela"
        }, {"area": "越南", "areaCode": "84", "areaEnglish": "Vietnam"}, {
            "area": "也门",
            "areaCode": "967",
            "areaEnglish": "Yemen"
        }, {"area": "南斯拉夫", "areaCode": "381", "areaEnglish": "Yugoslavia"}, {
            "area": "津巴布韦",
            "areaCode": "263",
            "areaEnglish": "Zimbabwe"
        }, {"area": "扎伊尔", "areaCode": "243", "areaEnglish": "Zaire"}, {
            "area": "赞比亚",
            "areaCode": "260",
            "areaEnglish": "Zambia"
        }];
    showCode= false;

    constructor(
        private http: HttpClient,
        private auth: AuthService,
        private userService: UserInfoService,
        private router: Router
    ) {
    }
    

    /**
     * 邀请码获取用户信息
     * @param code
     */
    getUserInfoByCode(code: string): Observable<any> {
        return this.http.post('/auth/api/geyou/getuserinfobyInvitecode', {inviteCode: code})
    }

    /**
     * 获取区号
     */
    getAreaData() {
        return this.area
    }

    getShowCode() {
        return this.showCode;
    }

    /**
     * 获取验证码
     */
    getCode = {
        phone: (phone: any, area: string): Observable<any> => {
            return this.http.post('/auth/api/geyou/sendcode', {area, phone})
        },
        email: (email: string): Observable<any> => {
            return this.http.post('/auth/api/geyou/sendemailcode', {email})
        }
    }

    /**
     * 检查邀请状态
     */
    checkInviteStatus = {
        shop: {
            phone: (phone: number, area: string): Observable<any> => {
                return this.http.post('/auth/api/geyou/h5/isparentmerchantbyphone', {area, phone});
            },
        },
        user: {
            phone: (phone: number, area: string): Observable<any> => {
                return this.http.post('/auth/api/geyou/h5/isparentbyphone', {area, phone});
            },
            email: (email: string): Observable<any> => {
                return this.http.post('/auth/api/geyou/h5/isparentbyemail', {email});
            },
        },
        guide: {
            phone: (phone: number, area: string): Observable<any> => {
                return this.http.post('/auth/api/geyou/h5/isparentguidebyphone', {area, phone});
            },
            email: (email: string): Observable<any> => {
                return this.http.post('/auth/api/geyou/h5/isparentguidebyemail', {email});
            },
        }
    }

    /**
     * 注册
     */
    register = {
        shop: { // 商品购物
            phone: (value: { phone: number, code: string, area: string, inviteCode: string, nickname?: string }): Promise<any> => {
                const openid = new HttpHeaders({
                    'device-code': window.localStorage.getItem('openId'),
                  });
                if(!this.showCode){
                    return new Promise( async (resolve,reject)=>{
                        await this.http.post('/auth/api/geyou/h5/directlylogin',{
                            ...value,
                            'nickname':'',
                            'invite_type':'merchant',
                            'code':'2',
                        },{
                            headers : openid
                        })
                        .subscribe((res: RegisterCallback) => {
                            this.auth.setToken(res.data.token);
                            this.userService.setId(res.data.id, () => {
                                if (this.auth.redirectUrl) {
                                    this.router.navigateByUrl(this.auth.redirectUrl, {replaceUrl: true}).then()
                                } else {
                                    this.router.navigateByUrl('/merchant-pay', {replaceUrl: true}).then()
                                }
                            });
                            resolve(false)
                        },() => {
                            this.showCode = true;
                            resolve(true)
                        });
                    })
                }else{
                    this.http.post('/auth/api/geyou/h5/messregistermerchant', {
                        ...value
                    },{
                        headers : openid
                    }).subscribe((res: RegisterCallback) => {
                        this.auth.setToken(res.data.token);
                        this.userService.setId(res.data.id, () => {
                            if (this.auth.redirectUrl) {
                                this.router.navigateByUrl(this.auth.redirectUrl, {replaceUrl: true}).then()
                            } else {
                                this.router.navigateByUrl('/merchant-pay', {replaceUrl: true}).then()
                            }
                        });
                    })
                }
                
            },
        },
        user: { // 用户登录
            phone: (value: { phone: number, code: string, area: string, inviteCode: string, nickname?: string }): Promise<any> => {
                const openid = new HttpHeaders({
                    'device-code': window.localStorage.getItem('openId'),
                });
                if(!this.showCode){
                    return new Promise( async (resolve,reject)=>{
                        await this.http.post('/auth/api/geyou/h5/directlylogin',{
                            ...value,
                            'nickname':'',
                            'invite_type':'user',
                            'code':'2',
                        },{
                            headers : openid
                        })
                        .subscribe((res: RegisterCallback) => {
                            this.auth.setToken(res.data.token);
                            this.userService.setId(res.data.id, () => {
                                if (this.auth?.redirectUrl) {
                                    this.router.navigateByUrl(this.auth.redirectUrl, {replaceUrl: true}).then()
                                } else {
                                    this.router.navigateByUrl('/simpleherbalzone/home', {replaceUrl: true}).then()
                                }
                            });
                            resolve(false)
                        }, () => {
                            this.showCode = true;
                            resolve(true)
                        });
                    })
                }else{
                    return new Promise( async (resolve,reject)=>{
                        await this.http.post('/auth/api/geyou/h5/messlogin', {
                            ...value
                        },{
                            headers : openid
                        }).subscribe((res: RegisterCallback) => {
                            this.auth.setToken(res.data.token);
                            this.userService.setId(res.data.id, () => {
                                if (this.auth?.redirectUrl) {
                                    this.router.navigateByUrl(this.auth.redirectUrl, {replaceUrl: true}).then()
                                } else {
                                    this.router.navigateByUrl('/simpleherbalzone/home', {replaceUrl: true}).then()
                                }
                            });
                            resolve(false)
                        }, () => {
                            resolve(true)
                        });
                    })
                }
            },
            email: (value: { email: string, code: string, password: string, inviteCode: string, nickname?: string }): void => {
                this.http.post('/auth/api/geyou/h5/emailregister', {
                    ...value
                }).subscribe((res: RegisterCallback) => {
                    this.auth.setToken(res.data.token);
                    this.userService.setId(res.data.id, () => {
                        if (this.auth.redirectUrl) {
                            this.router.navigateByUrl(this.auth.redirectUrl, {replaceUrl: true}).then()
                        } else {
                            this.router.navigateByUrl('/simpleherbalzone/home', {replaceUrl: true}).then()
                        }
                    });
                })
            }
        },
        guide: { // 导游
            phone: (value: { phone: number, code: string, area: string, inviteCode: string, nickname?: string }): Promise<any> => {
                const openid = new HttpHeaders({
                    'device-code': window.localStorage.getItem('openId'),
                  });
                if(!this.showCode){
                    return new Promise( async (resolve,reject)=>{
                        await  this.http.post('/auth/api/geyou/h5/directlylogin',{
                            ...value,
                            'nickname':'',
                            'invite_type':'user',
                            'code':'2',
                        },{
                            headers : openid
                        })
                        .subscribe((res: RegisterCallback) => {
                            this.auth.setToken(res.data.token);
                            this.userService.setId(res.data.id, () => {
                                if (this.auth.redirectUrl) {
                                    this.router.navigateByUrl(this.auth.redirectUrl, {replaceUrl: true}).then()
                                } else {
                                    this.router.navigateByUrl('/guide-pay', {replaceUrl: true}).then()
                                }
                            });
                            resolve(false)
                        }, () => {
                            resolve(true)
                        });
                    })
                }else{
                    return new Promise( async (resolve,reject)=>{
                        await this.http.post('/auth/api/geyou/h5/messlogin', {
                            ...value
                        }).subscribe((res: RegisterCallback) => {
                            this.auth.setToken(res.data.token);
                            this.userService.setId(res.data.id, () => {
                                if (this.auth.redirectUrl) {
                                    this.router.navigateByUrl(this.auth.redirectUrl, {replaceUrl: true}).then()
                                } else {
                                    this.router.navigateByUrl('/guide-pay', {replaceUrl: true}).then()
                                }
                            });
                            resolve(false)
                        }, () => {
                            resolve(true)
                        });
                    })
                }
            },
            email: (value: { email: string, code: string, password: string, inviteCode: string, nickname?: string }): void => {
                this.http.post('/auth/api/geyou/h5/emailregister', {
                    ...value
                }).subscribe((res: RegisterCallback) => {
                    this.auth.setToken(res.data.token);
                    this.userService.setId(res.data.id, () => {
                        if (this.auth.redirectUrl) {
                            this.router.navigateByUrl(this.auth.redirectUrl, {replaceUrl: true}).then()
                        } else {
                            this.router.navigateByUrl('/guide-pay', {replaceUrl: true}).then()
                        }
                    });
                })
            }
        }
    }
}
