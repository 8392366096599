import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler, APP_INITIALIZER } from '@angular/core';

import { RoutesModule } from "./routes/routes.module";
import { AppComponent } from './app.component';
import { FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { registerLocaleData } from '@angular/common';
import zh from '@angular/common/locales/zh';
import { AuthInterceptor } from "./code/net/default.injectable";
import { LOCAL_PROVIDER_TOKEN, NgZorroAntdMobileModule, zh_CN } from 'ng-zorro-antd-mobile';
import * as Sentry from "@sentry/angular";
import { Router } from '@angular/router';

registerLocaleData(zh);

const HttpInterceptors = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
]

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    RoutesModule,
    NgZorroAntdMobileModule
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
      },
      deps: [Sentry.TraceService],
      multi: true,
    },
    { provide: LOCAL_PROVIDER_TOKEN, useValue: zh_CN },
    ...HttpInterceptors
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
