<div class="form-container flex column justify-between">
    <div class="switch-view">
        <p class="title">蓝贝网购</p>
        <div class="switch">
            <label class="switch-item flex align-center justify-between"
                   *ngFor="let item of shopType;let i = index">
                <p [attr.data-content]="item.content">{{ item.title }}</p>
                <input type="radio" name="switch" [value]="i" [(ngModel)]="value">
            </label>
        </div>
    </div>
    <div class="button-view">
        <div class="flex align-center justify-center protocol-view">
            <input type="checkbox"
                   name="checkbox"
                   [(ngModel)]="checkboxStatus">
            <span>已同意滴咚个游 </span>
            <a href="http://assets.ddgeyou.com/web/business-agreement.html">{{ shopType[value].protocolName }}</a>
        </div>
        <button class="form-button block"
                (click)="submit()">{{ shopType[value].buttonText }}</button>
    </div>
</div>
