import { Component, Inject } from '@angular/core';
import { WechatService } from "./code/service/wechat/wechat.service";
import { filter } from "rxjs/operators";
import { NavigationEnd, Router, RouterOutlet,ActivatedRoute } from "@angular/router";
import { InviteService } from "./code/service/invite/invite.service";
import { HttpClient } from "@angular/common/http";
import { UserInfoService } from "./code/service/user/user.service";
import { AuthService } from "./code/service/auth/auth.service";
import { PlatformService } from "./code/service/platform/platform.service";
import { TravelAgencyService } from "./code/service/travelAgency/travelAgency.service";
import {ModalService, ToastService} from "ng-zorro-antd-mobile";
import { DOCUMENT } from "@angular/common";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent {
  title = 'front-web-promote';
  showDownload: boolean = false;

  constructor(
    private wechat: WechatService,
    private router: Router,
    private invite: InviteService,
    private http: HttpClient,
    private user: UserInfoService,
    public auth: AuthService,
    private platform: PlatformService,
    private travelAgency: TravelAgencyService,
    private modal: ModalService,
    private route: ActivatedRoute,
    private _toast: ToastService,
    @Inject(DOCUMENT) private document: Document
  ) {
  }
  // 判断哪个平台进去
  nowsell='angular'

  isLogin: boolean = false

  ngOnInit(): void {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        // 获取用户信息
        if (event.url === '/shop/index' && this.user.id &&  this.auth.isLoggedIn()) this.user.getUserInfo()
        // 判断支付页面不出现下载按钮
        if (event.url === '/guide-pay' || event.url === '/merchant-pay' || event.url === '/order'
          || event.url === '/travel/order'
          || event.url === '/travel/traveler'
          || event.url === '/travel-order-pay'
          || event.url.includes('/yichangshare')
          || event.url.includes('/activity/result')
          || event.url.includes('/newgoldactive/order-details')) {
          this.platform.check(
            null,
            null,
            null,
            () => this.showDownload = false
          )
        } else {
          this.platform.check(
            null,
            null,
            null,
            () => this.showDownload = true
          )
        }
        this.modal.close();
    });
    this.platform.check(
      null,
      null,
      null,
      () => this.showDownload = true
    )
    setTimeout(() => {
      if(this.route.snapshot.queryParams.sell){
        window.localStorage.setItem('sell','uniapp')
      }else{
        window.localStorage.setItem('sell','angular')
      }
      if(window.localStorage.getItem('sell')==='uniapp'){
        this.nowsell='uniapp'
      }else{
        this.nowsell='angular'
      }
    }, 100);
    setTimeout(() => {
      if(this.route.snapshot.queryParams.sell){
        window.localStorage.setItem('sell','uniapp')
      }else{
        window.localStorage.setItem('sell','angular')
      }
      if(window.localStorage.getItem('sell')==='uniapp'){
        this.nowsell='uniapp'
      }else{
        this.nowsell='angular'
      }
    }, 1000);
    this.isLogin = this.auth.isLoggedIn()
    const w = window as any;
    w.initApp = this.initApp.bind(this)
  }

  //web初始化调用
  initApp(token): void {
    if (!this.isLogin && token !== '') {
      this._toast.show('请在页面刷新后操作...')
      this.auth.setToken(token);
      this.user.getUserInfo(()=>{
        this._toast.hide()
        this.document.location.reload()
      })
    }
  }

  prepareRoute(outlet: RouterOutlet) {
    // 判断不同设备生成url
    if (!this.wechat.isIos || !window['wechatConfigUrl']) {
      window['wechatConfigUrl'] = window.location.href.split('#')[0]
    }
    const data = outlet.activatedRoute.snapshot.queryParams;
    if (data['invite'] && !this.invite.getId()) {
      this.invite.setId(data['invite'])
    } else if (!data['invite'] && !this.invite.getId()) {
      // this.invite.setId('2AMG6D')
      this.invite.setId('BDDLP1')
    }
    if (data['token']) {
      this.auth.setToken(data['token']);
      this.user.getUserInfo()
    }
    if (data['order_no']) {
      this.travelAgency.setOrderNo(data['order_no'])
    }
    if (data['payment_serial_number']) {
      this.travelAgency.setPaymentSerialNumber(data['payment_serial_number'])
    }
    if (data['pay_price']) {
      this.travelAgency.setPayPrice(data['pay_price'])
    }
    if (data['bargaining_id']) {
      localStorage.setItem('bargainingId', data['bargaining_id']);
    }
    if (data['platform']) {
      localStorage.setItem('platformdata', JSON.stringify(data));
    }
    if (data['goods_config_id']) {// 易点商城详情分享
      localStorage.setItem('goods_config_id', JSON.stringify(data));
    }
    if (data['code'] && !localStorage.getItem('openId') && window.location.pathname.search('/activity/talk') === -1) {
      this.http.post('/shop/api/identitywepayopenid', { code: data['code'] }).subscribe((res: { data }) => {
        localStorage.setItem('openId', res.data.open_id);
        this.wechat.init(outlet.activatedRoute);
      })
    } else {
      this.wechat.init(outlet.activatedRoute);
    }
  }
}
