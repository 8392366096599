import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class TravelAgencyService {

    orderNo: string = '';

    paymentSerialNumber: string = '';

    payPrice: number = 0;

    constructor() {
    }

    setOrderNo(orderNo: string): void {
        this.orderNo = orderNo;
    }

    getOrderNo(): string {
        return this.orderNo;
    }

    setPaymentSerialNumber(paymentSerialNumber: string): void {
        this.paymentSerialNumber = paymentSerialNumber;
    }

    getPaymentSerialNumber(): string {
        return this.paymentSerialNumber;
    }

    setPayPrice(payPrice: number): void {
        this.payPrice = payPrice;
    }

    getPayPrice(): number {
        return this.payPrice;
    }

}
