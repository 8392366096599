import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ComputedService {

  constructor() {}

  // 判断obj是否为一个整数
  private isInteger(obj: any): boolean {
    return Math.floor(obj) === obj
  }

  /*
  * 将一个浮点数转成整数，返回整数和倍数。如 3.14 >> 314，倍数是 100
  * @param floatNum {number} 小数
  * @return {object}
  *   {times:100, num: 314}
  */
  private toInteger(floatNum: any) {
    let ret = { times: 1, num: 0 }
    if (this.isInteger(floatNum)) {
      ret.num = floatNum
      return ret
    }
    let strfi = floatNum + ''
    let dotPos = strfi.indexOf('.')
    let len = strfi.substring(dotPos + 1).length
    let times = Math.pow(10, len)
    let intNum = parseInt((floatNum * times + 0.5) + '', 10)
    ret.times = times
    ret.num = intNum
    return ret
  }

  /*
  * 核心方法，实现加减乘除运算，确保不丢失精度
  * 思路：把小数放大为整数（乘），进行算术运算，再缩小为小数（除）
  */
  subtract(a, b,op) {
    let o1 = this.toInteger(a)
    let o2 = this.toInteger(b)
    let n1 = o1.num  // 3.25+3.153
    let n2 = o2.num
    let t1 = o1.times
    let t2 = o2.times
    let max = t1 > t2 ? t1 : t2
    let result
    switch (op) {
      // 加减需要根据倍数关系来处理
      case 1://加
        if (t1 === t2) { // 两个小数倍数相同
          result = n1 + n2
        } else if (t1 > t2) {
          // o1 小数位 大于 o2
          result = n1 + n2 * (t1 / t2)
        } else {  // o1小数位小于 o2
          result = n1 * (t2 / t1) + n2
        }
        return result / max
      case 2://减
        if (t1 === t2) {
          result = n1 - n2
        } else if (t1 > t2) {
          result = n1 - n2 * (t1 / t2)
        } else {
          result = n1 * (t2 / t1) - n2
        }
        return result / max
      case 3://乘
        result = (n1 * n2) / (t1 * t2)
        return result
      case 4://除
        result = (n1 / n2) * (t2 / t1)
        return result
    }
  }

  // 日期格式转换
  getYMDHMS(timestamp) {
    let time = new Date(timestamp * 1000)
    let year = time.getFullYear()
    let month = time.getMonth() + 1
    let date = time.getDate()
    let hours = time.getHours()
    let minute = time.getMinutes()
    let second = time.getSeconds()
    let monthtext
    let datetext
    let hourstext
    let minutetext
    let secondtext
    if (month < 10) { monthtext = '0' + month }
    if (date < 10) { datetext = '0' + date }
    if (hours < 10) { hourstext = '0' + hours }
    if (minute < 10) { minutetext = '0' + minute }
    if (second < 10) { secondtext = '0' + second }
    return year + '-' + (monthtext ? monthtext : month) + '-' + (datetext ? datetext : date) + ' '
    + (hourstext ? hourstext : hours) + ':' + (minutetext ? minutetext : minute) + ':' + (secondtext ? secondtext : second)
  }

  /**
   * 日期格式转换
   * @param time 时间字符串
   * @param cFormat 返回格式
   * @param isMend 是否补全
   * @returns 
   */
  parseTime(time, cFormat=null,isMend=true) {
    if (arguments.length === 0 || !time) {
      return null
    }
    const format = cFormat || '{y}-{m}-{d}'
    let date
    if (typeof time === 'object') {
      date = time
    } else {
      if ((typeof time === 'string')) {
        if ((/^[0-9]+$/.test(time))) {
          time = parseInt(time)
        } else {
          time = time.replace(new RegExp(/-/gm), '/')
        }
      }
      if ((typeof time === 'number') && (time.toString().length === 10)) {
        time = time * 1000
      }
      date = new Date(time)
    }
    const formatObj = {
      y: date.getFullYear(),
      m: date.getMonth() + 1,
      d: date.getDate(),
      h: date.getHours(),
      i: date.getMinutes(),
      s: date.getSeconds(),
      a: date.getDay()
    }
    const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
      const value = formatObj[key]
      if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value] }
      return isMend?value.toString().padStart(2, '0'):value.toString()
    })
    return time_str
  }

  /**
   * 计算与当前时间相差天数
   * @param endTime 结束时间
   */
  getDiffDay(endTime){
    let days = 0
    let date = new Date();//获取当前时间
    let NowTime = Math.round((date.getTime() / 1000));
    let t = (endTime - NowTime) * 1000;
    if (t >= 0) {
      //计算出相差天数
      days = Math.floor(t / (24 * 3600 * 1000));
    } else {
      days = 0
    }
    return days
  }
    
}
