import { Component, OnInit , NgZone} from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router'
import { HttpClient } from "@angular/common/http";
import { ToastService } from 'ng-zorro-antd-mobile';
import { WechatService } from '../../../../code/service/wechat/wechat.service';
import { Title } from '@angular/platform-browser';
import { AuthService } from "../../../../code/service/auth/auth.service";
@Component({
  selector: 'app-newuserintroduce',
  templateUrl: './newuserintroduce.component.html',
  styleUrls: ['./newuserintroduce.component.less']
})
export class NewuserintroduceComponent implements OnInit {

  constructor(
    private title: Title,
    private router: Router,
    private _toast: ToastService,
    private http: HttpClient,
    public wechat: WechatService,
    private route: ActivatedRoute,
    private ngZone: NgZone,
    private auth: AuthService
  ) { 
    title.setTitle('新手福利专场');
  }
  ngOnInit(): void {
    // 平台框架兼容,如果sell字段存在，则是uniapp框架进来,并保存在本地告诉平台页面
    if(this.route.snapshot.queryParams.sell){
      window.localStorage.setItem('sell','uniapp')
    }else{
      window.localStorage.setItem('sell','angular')
    }
  }
  // 进入新人抢购专区
  goNewuser(){
    this.gologin()
  }
  gologin() {
    if (!this.auth.getToken()) {
      this.auth.redirectUrl = this.router.url;
      this.router.navigateByUrl('/user/register/phone').then();
    } else {
      //视频商品购买
      this.router.navigate(['/newuser-special'], {
        queryParams: {
          introduce:'introduce',
          sell:'uniapp'
        },
      });
    }
  }
}
