<app-commodel *ngIf="showmodel" (outer)="getModeldata($event)" [modeldata]="modeldata"></app-commodel>
<app-joinmodel *ngIf="showjoinmodel" (joinouter)="getjoinModel($event)" [joindata]="hotsdata"></app-joinmodel>
<div class="multiaactivehome" *ngIf='multiainfo'>
    <div [ngClass]="{whitebg:multiainfo,whitebg2:!multiainfo}"></div>
    <div class="head-all">
        <img class="homebg" src="https://base-assets.ddgeyou.com/multiactive/homebg4.png" />
        <div class="icon">
            <!-- <div (click)="goMyprize()"><img src="https://base-assets.ddgeyou.com/multiactive/prize.png" /></div> -->
            <div (click)="goHomeshare()" *ngIf="!iswechat"><img
                    src="https://base-assets.ddgeyou.com/multiactive/homeshare.png" /></div>
            <div (click)="goOrderlist()" class="myorder">
                <div class="tip" *ngIf="multiainfo.snap_up_processing_amount>0">{{multiainfo.snap_up_processing_amount}}
                </div>
                <img src="https://base-assets.ddgeyou.com/multiactive/ordericon2.png" />
            </div>
        </div>
    </div>
    <div class="conten">
        <div class="conten-all">
            <div class="entrance-all" *ngIf="!isIosShow">
                <div class="entrance-item">
                    <div class="tip" *ngIf="multiainfo.blind_box_amount>0">{{multiainfo.blind_box_amount}}</div>
                    <img src="https://base-assets.ddgeyou.com/multiactive/blindboxe.png" (click)="goBlindbox()" />
                </div>
                <div class="entrance-item"><img src="https://base-assets.ddgeyou.com/multiactive/playingmethod.png"
                        (click)="gocompeteRule()" />
                </div>
            </div>
            <div class="shop-info yellow" *ngIf="hotsdata.length>0&&!iswechat" >
                <div class="title">
                    <div class="titleimg">
                        <img src="https://base-assets.ddgeyou.com/multiactive/competeingtitle.png" />
                        <img class="catalog" (click)="goJoninmode()" src="https://base-assets.ddgeyou.com/multiactive/catalog.png" />
                    </div>
                    <span (click)="refreshOther()" [class]="{'refresh':isRefresh,'refreshing':!isRefresh}">
                        <img src="https://base-assets.ddgeyou.com/multiactive/comingrefresh.png" />刷新
                    </span>
                </div>
                <div class="shop-details">
                    <div class="details-item">
                        <div class="list-joining">
                            <div class="joining-item" *ngFor="let item of hotsdata.slice(0, 2);let index = index" (click)="goOrderconfirm(item)">
                                <div class="join-info">
                                    <div class="join-left">
                                        <div class="josnimg">
                                            <img [src]="item.img" />
                                        </div>
                                        <div class="titleinfo">
                                            <div class="titles omit">{{item.goods_name}}</div>
                                            <div class="price-all">
                                                <div class="price">{{item.price}}元</div>
                                                <div class="label">{{item.quantity}}人竞抢</div>
                                            </div>
                                            <div class="imginfo">
                                                <div class="imgall" *ngFor="let it of item.snap_up_user_list;let index = index">
                                                    <div class="img"><img [src]="it.avatar" /></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="joining">
                                        <div class="button">加入竞抢</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="shop-info" *ngIf="multiainfo" (click)="goDetails(multiainfo.snap_up_activity_id)">
                <div class="title"><img src="https://base-assets.ddgeyou.com/multiactive/titlelabel.png" />
                    <span (click)="goMoreproduct()">
                        更多<img src="https://base-assets.ddgeyou.com/multiactive/morelist.png" />
                    </span>
                </div>
                <div class="shop-details" *ngIf="multiainfo">
                    <div class="details-item">
                        <div class="details-title omits">{{multiainfo.goods_name}}</div>
                        <div class="details-info">
                            <div class="left"><img [src]="multiainfo.img" /></div>
                            <div class="right">
                                <div class="right-all">
                                    <div class="details-probability">
                                        <div class="probability">
                                            <div class="text">竞抢成功率</div>
                                            <div class="icon">
                                                <img src="https://base-assets.ddgeyou.com/multiactive/winicon.png"
                                                    *ngFor="let item of [].constructor(multiainfo.successful_quantity);let index = index" />
                                                <img src="https://base-assets.ddgeyou.com/multiactive/nowin.png"
                                                    *ngFor="let item of [].constructor(multiainfo.noclustering);let index = index" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="blindi-all">
                                        <div class="label">{{multiainfo.quantity}}人竞抢
                                        </div>
                                        <div class="ydlabel">送易点</div>
                                        <div class="icon">
                                            <img src="https://base-assets.ddgeyou.com/multiactive/blindicon.png"
                                                *ngFor="let item of [].constructor(multiainfo.fail_give_blind_box_amount);let index = index" />
                                        </div>
                                    </div>
                                    <div class="price">￥{{multiainfo.price}}</div>
                                    <div class="original-price">原价买: {{multiainfo.selling_price}}元</div>
                                </div>
                            </div>
                        </div>
                        <div class="rightinfo">
                            <div class="text1">立省</div>
                            <div class="text2">￥{{multiainfo.diff_price}}</div>
                        </div>
                        <div class="botteminfo">
                            <img src="https://base-assets.ddgeyou.com/multiactive/competebutton.png" />
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
    <div class="broadcast-info" *ngIf='multiainfo.snap_up_scroll_list.length>0'>
        <div class="img"><img src="https://base-assets.ddgeyou.com/multiactive/broadcaseticon.png" /></div>
        <div class="carousel-all">
            <Carousel class="my-carousel" [autoplay]="true" [infinite]="true" [vertical]="true" [dots]="false"
                [dragging]="false" [autoplayInterval]='2000'>
                <CarouselSlide *ngFor="let item of multiainfo.snap_up_scroll_list">
                    <div class="item-all" *ngIf="item.status===3">
                        {{item.name}} &nbsp;竞抢成功<label>{{item.content}}</label>
                    </div>
                    <div class="item-all" *ngIf="item.status===4">
                        {{item.name}} &nbsp;竞抢未成功<label>{{item.content}}</label>
                    </div>
                </CarouselSlide>
            </Carousel>
        </div>
    </div>
    <div class="shop-list">
        <div class="list-head">
            <!-- <div class="list-head-title">好货低至2折竞抢</div>
            <div class="list-head-text">未成功还送盲盒</div> -->
            <div><img src="https://base-assets.ddgeyou.com/multiactive/comlisttitle.png" /></div>
        </div>
        <div class="list-conten">
            <div class="list-item" *ngFor="let item of multialist;let index = index"
                (click)="goDetails(item.snap_up_activity_id)">
                <div class="shopdetails">
                    <div class="left">
                        <img [src]="item.img" />
                        <div class="savelabel">
                            <span>省</span><span class="">￥{{item.diff_price}}</span>
                        </div>
                    </div>
                    <div class="right">
                        <div class="title omits">{{item.goods_name}}</div>
                        <div class="blindi-all">
                            <div class="label">{{item.quantity}}人竞抢
                            </div>
                            <div class="ydlabel">送易点</div>
                            <div class="icon">
                                <img src="https://base-assets.ddgeyou.com/multiactive/blindicon.png"
                                    *ngFor="let item of [].constructor(item.fail_give_blind_box_amount);let index = index" />
                            </div>
                        </div>
                        <div class="price-all">
                            <div class="compete-price">限时竞抢价：<label>{{item.price}}元</label></div>
                            <div class="original-price">原价买：￥{{item.selling_price}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="datahint" *ngIf='datahint'>到底啦~</div>
    </div>
</div>
<div class="public-model" *ngIf="showdowun">
    <div class="footer-item">
        <div class="item-center">
            <div class="centen">
                <div class="yc-close" (click)="ycclose()">
                    <img src="https://base-assets.ddgeyou.com/multiactive/ycclose.png" />
                </div>
                <div class="centen-item">
                    <div><img src="https://base-assets.ddgeyou.com/multiactive/ycapp.png" /></div>
                    <div class="button-down" (click)="godounApp()">
                        下载易场APP
                        <div class="text">体验完整功能吧~</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>