import { Component, OnInit,ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router'
import { HttpClient } from "@angular/common/http";
import { ToastService } from 'ng-zorro-antd-mobile';
import { WechatService } from '../../../code/service/wechat/wechat.service';
import { Title } from '@angular/platform-browser';
import { AuthService } from "../../../code/service/auth/auth.service";
import { PlatformService } from 'src/app/code/service/platform/platform.service';
@Component({
  selector: 'app-groupbook-details',
  templateUrl: './groupbook-details.component.html',
  styleUrls: ['./groupbook-details.component.less']
})
export class GroupbookDetailsComponent implements OnInit {
  @ViewChild('videoid') audioView: ElementRef;
  //轮播图
  carouseldata:any;
  // 当前选中的轮播图
  bannerIndex=0
  // 推荐列表
  recommendlist=[]
  constructor(
    private title: Title,
    private router: Router,
    private _toast: ToastService,
    private http: HttpClient,
    public wechat: WechatService,
    private route: ActivatedRoute,
    private platform: PlatformService,
    private auth: AuthService){
      title.setTitle('详情');
    }
  activity_id;
  route_id;
  // 活动线路详情
  routedetails;
  // 是否显示设置区域弹窗
  showareapop=false
  //得到的区域组件的值
  communitydata
  // 弹窗值展示
  showmodelpop=false
  //弹窗类型
  datatype='hint'
  //弹窗要传的值
  showmodelpopdata={}
  // 分享的信息
  sharedata
  // 可能有的邀请码
  invitecode
  // 兑换线路的线路配置idid
  exchangeid 
  //兑换线路id
  exchangerouteid
  // 当前页面类型
  pagetype
  // 当前是什么状态
  // 当前如果是金贝h5打开页面的话获取个人信息
  goldinfo
  // 轮播图的下标
  dotsdata=[]

  ngOnInit(): void {
    let routprams;
    if(window.localStorage.getItem('platformdata')){
      console.log('进来这里',window.localStorage.getItem('platformdata'))
      routprams = JSON.parse(window.localStorage.getItem('platformdata'))
    }else{
      console.log('进来这里？')
      routprams = this.route.snapshot.queryParams;
    }
    //routprams.type!=='conver'表示是正常进入详情页面，为conver表示从金贝那进来
    if(routprams.type==='conver'){
      this.exchangeid=routprams.exchange_route_config_id
      this.pagetype='conver'
      this.getgoldData()
    }else if(routprams.type==='gold'){
      this.exchangeid=routprams.exchange_route_config_id
      this.pagetype='gold'
      this.exchangerouteid=routprams.exchange_route_id
      this.getgoldData()
      this.getgoldResellinfo()
    } else{
      this.activity_id=routprams.group_activity_id
      this.route_id=routprams.group_route_id
      if(routprams.invite){
        this.invitecode=routprams.invite
      }
      this.getrouteData();
    }
  }
  getVideopause(){
    //视频暂停
    const audio = this.audioView.nativeElement;
    audio.pause()
   /*  audio.play(); */
  }
  onBannerIndexChange(index: number): void {
    if(index===1&&this.routedetails.video){
      this.getVideopause()
    }
    this.bannerIndex = index;
  }
  // 获取详情
  getrouteData() { 
    // 获取详情
    this.http.post('/travel/api/getgroupactivityroutedetails', { group_activity_id: this.activity_id, group_route_id:this.route_id,invite_code:this.invitecode}).subscribe(
      (res: { data}) => {
        this.routedetails=res.data
        if(this.invitecode){
          this.routedetails.invitecode=this.invitecode
        }
        //dotsdata为轮播图下标循环的数组
        if(this.routedetails.video){
          this.dotsdata.push(this.routedetails.video)
        }
        if(this.routedetails.imgs){
          this.routedetails.imgs.forEach(element => {
            this.dotsdata.push(element)
          });
        }
      }
    );
  }
  // 获取转卖的详情价格
  getgoldResellinfo() {
    // 获取详情
    this.http.post('/travel/api/getexchangerouteinfo', { exchange_route_id: this.exchangerouteid}).subscribe(
      (res: { data}) => {
        this.goldinfo=res.data;
        console.log('总共是多少1',this.goldinfo)
      }
    );
  }
  // 获取兑换金贝详情
  getgoldData() {
    // 获取详情
    this.http.post('/travel/api/getexchangerouteconfigdetails', { exchange_route_config_id: this.exchangeid}).subscribe(
      (res: { data}) => {
        this.routedetails=res.data
        console.log('总共是多少',this.routedetails)
        //dotsdata为轮播图下标循环的数组
        if(this.routedetails.video){
          this.dotsdata.push(this.routedetails.video)
        }
        if(this.routedetails.imgs){
          this.routedetails.imgs.forEach(element => {
            this.dotsdata.push(element)
          });
        }
      }
    );
  }
  // 更多推荐
  recommendmore(){
    let data={ 
      group_activity_id:this.activity_id
    }
    let param = encodeURI(JSON.stringify(data))
    this.router.navigate(['/groupbook/recommend' + '/' + param]).then()
  }
  // 去结算
  goSettlement(){
    if (!this.auth.getToken()) {
      this.platform.check(
        null,
        () => window['android'].login(),
        () => window['webkit'].messageHandlers.login.postMessage(1),
        () => {
          this.auth.redirectUrl = this.router.url;
          this.router.navigateByUrl('/user/register/phone').then();
        }
      );
    }else{
      let param = encodeURI(JSON.stringify(this.routedetails))
      param = encodeURIComponent(param) // 第二次加密
      this.router.navigate(['/groupbook/settlement' + '/' + param]).then() 
    }
  }

  // 金贝购买h5页面打开
  gogoldh5Settlement(){
    this.routedetails.type='gold'
    if(this.goldinfo){
      //当前是转卖详情情况下
      this.routedetails.resale_price=this.goldinfo.resale_price
    }
    this.routedetails.exchange_route_id=this.exchangerouteid
    let param = encodeURI(JSON.stringify(this.routedetails))
    param = encodeURIComponent(param) // 第二次加密
    this.router.navigate(['/groupbook/settlement' + '/' + param]).then() 
  }
  // 金贝购买
  gogoldSettlement(){
    if(this.routedetails.gold_exchange_route_jurisdiction===1){
        this.routedetails.type='conver'
        let param = encodeURI(JSON.stringify(this.routedetails))
        param = encodeURIComponent(param) // 第二次加密
        this.router.navigate(['/groupbook/settlement' + '/' + param]).then()
    }else{
      this.showmodelpop=true;
      this.datatype='defaulttip'
      this.showmodelpopdata={
        head:'你暂未获得兑换权限',
        title:'完成探客权益商品组团或购买过旅游管家权益商品，即可兑换',
        text:'我先看看',
        text2:'去获取',
        incident:'goGain'
      }
    }
  }
  // 获取组团线路分享信息
  getClusterinfo(){
    // 获取详情
    this.http.post('/travel/api/getgrouprouteshareinfo', { group_activity_id: this.activity_id, group_route_id:this.route_id}).subscribe(
      (res: { data,code}) => {
        if(res.code===4701){
          this.showareapop=true;
        }else if(res.code===0){
          this.sharedata=res.data;
          if(res.data.is_show_select_community===1){
            console.log('有吗',res.data.desc)
            this.wechat.shareFriend(1, {
              title: res.data.title,
              subtitle: res.data.desc,
              icon_url: res.data.img,
              jump_url: res.data.url,
              app_id: '',
            });
          }
          else{
            localStorage.setItem('inputchecked', 'no');
            this.datatype='hint'
            this.showmodelpop=true
            this.showmodelpopdata={
              text:res.data.community_name
            }
          }
        }
      }
    );
  }
  // 地区组件的值
  getData(e){
    this.showareapop=false;
    if(e.type==="1"){
      this.showareapop=false;
      // 去掉默认值
      let communitydata=''
      let community_id;
      e.data.forEach(element => {
        communitydata=element.name
        community_id=element.id
      });
      this.setCommunity(community_id,communitydata)
    }
  }
  // 设置社区
  setCommunity(e,e2) {
    this.http.post('/travel/api/setusercommunity', {community_id:e}).subscribe(
      (res: { data ,code}) => {
        if(res.code===0){
          setTimeout(() => {
            this._toast.success('设置成功', 1000, () => {
            });
            this.communitydata=e2;
          }, 500);
        }
      }
    )
  }
  // 弹窗回来的值
  getModeldata(e){
    console.log('是111',e)
    if(e===1){
      this.showareapop=true;
    }if(e===2){
      this.sharecom()
    }else if(e==='close'){
      this.showareapop=false;
      this.showmodelpop=false;
    }else if(e==='goGain'){
      console.log('去获取')
      this.goGain()
    }
  }
  // 分享的公共方法
  sharecom(){
    if(localStorage.getItem('inputchecked')==='yes'){
      this.http.post('/travel/api/setnotshowcommunitywindows', {}).subscribe(
        (res: { data ,code}) => {
          if(res.code===0){
            console.log('是',res)
          }
        }
      )
    }
    this.showareapop=false;
    this.showmodelpop=false;
    this.wechat.shareFriend(1, {
      title: this.sharedata.title,
      subtitle:this.sharedata.desc,
      icon_url: this.sharedata.img,
      jump_url: this.sharedata.url,
      app_id: '',
    });
  }
  //前往我的订单
  goMyorder(){
    this.gologin()
  }
  // 判断登陆
  gologin() {
    if (!this.auth.getToken()) {
      this.auth.redirectUrl = this.router.url;
      this.router.navigateByUrl('/user/register/phone').then();
    } else {
      //订单商品购买
      this.router.navigate(['/order-list']).then()
    }
  }
  // 跳转至详情
  getDetails(item){
      /* this.router.navigate(['/groupbook/groupbookdetails' + '/' + param]).then() */
      this.router.navigate(['/groupbookdetails'], {
        queryParams: {
          group_activity_id:item.group_activity_id,
          group_route_id:item.group_route_id,
          platform:'wechat'
        },
      });
      setTimeout(() => {
        window.location.reload()
      }, 200);
  }
  //去获取权益goGain
  goGain(){
    /* console.log(this.router) */
    history.go(-2);
    /* this.router.navigate(['/groupbook/homepage'], {relativeTo: this.route}); */
  }
  // 组团购说明
  goPlaytext(){
    this.router.navigateByUrl('/groupbook/groupshow')
  }
}
