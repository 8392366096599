import { AuthService } from '../service/auth/auth.service';
import { Injectable, Injector } from "@angular/core";
import {
    HttpErrorResponse,
    HttpHandler,
    HttpInterceptor,
    HttpRequest, HttpResponse,
    HttpResponseBase
} from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { Observable, of, throwError } from "rxjs";
import { catchError, mergeMap } from "rxjs/operators";
import { Router } from "@angular/router";
import { Md5 } from "ts-md5";
import { ToastService } from "ng-zorro-antd-mobile";

const CodeMessage = {
    200: '服务器成功返回请求的数据。',
    201: '新建或修改数据成功。',
    202: '一个请求已经进入后台排队（异步任务）。',
    204: '删除数据成功。',
    400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
    401: '用户没有权限（令牌、用户名、密码错误）。',
    403: '用户得到授权，但是访问是被禁止的。',
    404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
    406: '请求的格式不可得。',
    410: '请求的资源被永久删除，且不会再得到的。',
    422: '当创建一个对象时，发生一个验证错误。',
    500: '服务器发生错误，请检查服务器。',
    502: '网关错误。',
    503: '服务不可用，服务器暂时过载或维护。',
    504: '网关超时。',
};

@Injectable({
    providedIn: "root"
})
export class AuthInterceptor implements HttpInterceptor {

    constructor(
        private auth: AuthService,
        private injector: Injector,
        private message: ToastService) {
    }

    private goTo(url: string) {
        setTimeout(() => this.injector.get(Router).navigateByUrl(url, { replaceUrl: true }));
    }

    private checkStatus(ev: HttpResponseBase) {
        if ((ev.status >= 200 && ev.status < 300) || ev.status === 401) {
            return;
        }

        const errorText = CodeMessage[ev.status] || ev.statusText;
        if (ev.status) {
            this.message.offline(`请求错误 ${errorText}: ${ev.url}`);
        } else {
            this.message.offline('无法连接网络')
        }
    }

    checkError(code, ev) {
        switch (code) {
            case 401:
                this.message.offline(`未登录或登录已过期，请重新登录。`);
                (this.injector.get(AuthService)).logout();
                break;
            case 403:
            case 404:
            case 500:
                this.goTo(`/exception/${ev.status}`);
                break;
            default:
                if (ev instanceof HttpErrorResponse) {
                    console.warn('未可知错误，大部分是由于后端不支持CORS或无效配置引起', ev);
                }
                break;
        }
    }

    /**
     * 生成token用于验证
     * @param body
     * @private
     */
    private static createToken(body: object): string {
        const time = new Date().getTime().toString().slice(0, 10);
        const bodyText = JSON.stringify(Object.keys(body).length ? body : []);
        const key = environment.key;
        return <string>Md5.hashStr(time + bodyText + key)
    }

    /**
     * 生成sign用于验证
     * @param body
     * @private
     */
    private createSign(body: object): string {
        const time = new Date().getTime().toString().slice(0, 10);
        const bodyText = JSON.stringify(Object.keys(body).length ? body : []);
        const token = this.auth.getToken();
        return <string>Md5.hashStr(time + bodyText + token)
    }

    private handleData(ev: HttpResponseBase): Observable<any> {
        this.checkStatus(ev);
        switch (ev.status) {
            case 0:
                if (ev instanceof HttpResponse) {
                    const body: any = ev.body;
                    this.checkError(body.code, ev)
                }
                break;
            case 200:
                if (ev instanceof HttpResponse) {
                    const body: any = ev.body;
                    if (body && body.code !== 0) {
                        if (body.code === 4507) {
                            this.message.fail(body.msg);
                        }else if (body.code === 4002) {
                            if(window.localStorage.getItem('sell')==='uniapp'){
                                if(ev.url.indexOf("/shop/api/addressbydefault")!==(-1)){
                                    console.log('竞抢未登录')
                                    // 竞抢活动里面的跳转登陆
                                        // @ts-ignore
                                        window.uni.postMessage({
                                            data: {
                                            type: 'notlogincom',
                                            data: ''
                                            }
                                        });
                                }else if(ev.url.indexOf("/advert/api/geyou/useridentity")!==(-1)){
                                    console.log('不处理')
                                }else{  
                                    // 其他页面的跳转登陆
                                    console.log('其他未登录')
                                    // @ts-ignore
                                    window.uni.postMessage({
                                        data: {
                                        type: 'notlogin',
                                        data: ''
                                        }
                                    });
                                }
                            }
                        } else if (body.code === 4219 
                            || body.code === 4220 
                            || body.code === 4250 
                            || body.code === 4251 
                            || body.code === 4252
                            || body.code === 4701 
                            || body.code === 4702
                            || body.code === 4703
                            || body.code === 4704
                            || body.code === 4705
                            || body.code === 4706
                            || body.code === 4707
                            || body.code === 4708
                            || body.code === 4709
                            || body.code === 4710
                            || body.code === 4711
                            || body.code === 4712
                            || body.code === 4801
                            || body.code === 4901
                            || body.code === 4902
                            || body.code === 4903
                            || body.code === 4904
                            || body.code === 4905
                            || body.code === 4906
                            || body.code === 4907
                            || body.code === 41101
                            || body.code === 41102
                            || body.code === 41103
                            || body.code === 41104
                            || body.code === 4201
                            || body.code === 41201
                            || body.code === 41202
                            || body.code === 42201
                            || body.code === 42205
                            || body.code === 42206
                            || body.code === 42301
                            || body.code === 42401
                            || body.code === 42402
                            || body.code === 42403
                            || body.code === 42405) {
                            return of(ev);
                        } else {
                            this.message.offline(body.msg);
                            // 单独针对该接口需要有所返回
                            if(ev.url.indexOf("travel/api/seckillcommunityaddcartv2")!==(-1)
                            ||ev.url.indexOf("travel/api/seckillsubmitcommunityorderv2")!==(-1)
                            ||ev.url.indexOf("seckill/api/seckillcommunityaddcartv2")!==(-1)
                            ||ev.url.indexOf("seckill/api/seckillsubmitcommunityorderv2")!==(-1)
                            ||ev.url.indexOf("travel/api/communityorderpaymentsignature")!==(-1)
                            ||ev.url.indexOf("travel/api/grouprouteorderpaymentsignature")!==(-1)
                            ||ev.url.indexOf("seckill/api/communityorderpaymentsignature")!==(-1)
                            ||ev.url.indexOf("seckill/api/communitytransferpricegift")!==(-1)){
                                return of(ev);
                            }
                        }
                        return throwError({});
                    } else {
                        return of(ev);
                    }
                }
                break;
            case 401:
                this.message.offline(`未登录或登录已过期，请重新登录。`);
                (this.injector.get(AuthService)).logout();
                break;
            case 403:
            case 404:
            case 500:
                this.goTo(`/exception/${ev.status}`);
                break;
            default:
                if (ev instanceof HttpErrorResponse) {
                    console.warn('未可知错误，大部分是由于后端不支持CORS或无效配置引起', ev);
                }
                break;
        }
        if (ev instanceof HttpErrorResponse) {
            return throwError(ev);
        } else {
            return of(ev);
        }
    }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        // 判断连接是否需要拼接
        let url = req.url;
        if (!url.startsWith('https://') && !url.startsWith('http://')) {
            url = environment.serviceUrl + url;
        }

        // 添加时间戳
        let body = req.body;

        // 排序
        let bodyKey = [...Object.keys(body)];
        bodyKey.sort();
        let newBody = {};
        bodyKey.forEach(key => newBody[key] = body[key]);

        // 请求头
        const authToken = this.auth.getToken();
        const header = {
            token: authToken ? authToken : AuthInterceptor.createToken(newBody),
            language: 'zh-cn',
            'System-code': 'web',
        };
        if (authToken) {
            Object.assign(newBody, { sign: this.createSign(newBody) });
        }

        Object.assign(newBody, { timestamp: new Date().getTime().toString().slice(0, 10) })

        const authReq = req.clone({
            url,
            setHeaders: header,
            body: newBody,
        });

        return next.handle(authReq).pipe(
            catchError((err: HttpErrorResponse) => this.handleData(err)),
            mergeMap((event: any) => {
                if (event instanceof HttpResponse) {
                    return this.handleData(event);
                }
                return of(event);
            }),
        );
    }
}
