import { Component, OnInit, NgZone } from '@angular/core';
import { Router } from "@angular/router";
import { detail } from "../detail/detail.component";
import { HttpClient } from "@angular/common/http";
import { UserInfoService } from "../../../code/service/user/user.service";
import { address } from "../address/list/list.component";
import { WechatService } from "../../../code/service/wechat/wechat.service";
import { ToastService } from "ng-zorro-antd-mobile";
import { InviteService } from "../../../code/service/invite/invite.service";
import { PlatformService } from 'src/app/code/service/platform/platform.service';

@Component({
    selector: 'app-order',
    templateUrl: './order.component.html',
    styleUrls: ['./order.component.less']
})
export class OrderComponent implements OnInit {

    // 商品信息
    detail: any = null;
    sku: { key: string, value: string }[] = [];
    skuId: number = 0;
    skuDetail: { stock: number, img: string, price: number } | null = null;
    expressType: number = 0;
    quantity: number = null;

    // 地址
    addressData: address = null;

    // 服务中心
    serviceCenterData: { label: string, value: string } | null = null;

    identity_type: number = 0;

    // 支付方式弹窗
    showPop = false

    isIphoneX = false

    constructor(
        private router: Router,
        private http: HttpClient,
        private user: UserInfoService,
        private wechat: WechatService,
        private toast: ToastService,
        private invite: InviteService,
        private ngZone: NgZone,
        private platform: PlatformService,
    ) {
    }

    /**
     * 总价格
     */
    get allPress(): number {
        let press = this.skuDetail.price * Number(this.quantity);
        if (this.expressType === 1) {
            press = Number(this.detail.express_fee) + Number(press)
        }
        return press
    }

    /**
     * 是否可选地址
     */
    get addressStatus(): boolean {
        return this.expressType === 1
    }

    ngOnInit(): void {
        const html = document.documentElement;
        html.setAttribute('style','')
        this.isIphoneX = this.platform.CheckIsIphoneX()
        // 微信支付前刷新
        if (window.location.href.split('#')[0] !== window['wechatConfigUrl']) {
            window.location.reload()
        }
        const data = JSON.parse(sessionStorage.getItem('goodsItem'));
        this.detail = data.detail;
        this.sku = data.sku;
        this.skuId = data.skuId;
        this.skuDetail = data.skuDetail;
        this.quantity = data.quantity
        this.detail.express_ways = this.detail.express_ways.map(item => (Number(item)))
        // 取默认配送方式
        this.expressType = Number(this.detail.express_ways[0])
        this.identity_type = data.identity_type
        this.getAddressBySessionStorage();
        this.getServiceCenterBySessionStorage();
    }

    /**
     * 从本地缓存拿地址
     */
    getAddressBySessionStorage(): void {
        let data = sessionStorage.getItem('orderAddressItem')
        data = JSON.parse(data)
        if (data && typeof data === 'object') {
            this.addressData = data;
        } else {
            this.getDefaultAddress();
        }
    }

    /**
     * 从本地缓存拿服务中心
     */
    getServiceCenterBySessionStorage(): void {
        const data = sessionStorage.getItem('serviceCenterItem')
        if (data) {
            this.serviceCenterData = JSON.parse(data);
            sessionStorage.removeItem('serviceCenterItem')
        }
    }

    /**
     * 从服务器拿默认地址
     */
    getDefaultAddress(): void {
        this.http.post('/shop/api/addressbydefault', { user_id: this.user.id })
            .subscribe((res: { data: address }) => {
                if (res.data.hasOwnProperty('address') && res.data.address) {
                    this.addressData = res.data
                } else {
                    this.addressData = null;
                }
            })
    }

    /**
     * 切换配送方式
     */
    onExpressTypeChange(e): void {
        this.expressType = e;
    }

    /**
     * 提交订单
     */
    submit(): void | boolean {
        if (this.addressStatus && !this.addressData) {
            this.toast.info('请选择地址')
            return
        }
        this.http.post('/shop/api/ordershopbuy', {
            // 用户id
            user_id: this.user.id,
            // 地址id
            address_id: this.addressData?.address_id,
            orders: [{
                store_id: this.detail.store_id,
                skus: [{
                    good_id: this.detail.id,
                    sku_id: this.skuId,
                    express_way: this.expressType,
                    quantity: this.quantity,
                    yellow_scallop_discount: 0,
                }],
                remark: '',
                sum: this.allPress
            }],
            // 总价
            total: this.allPress,
            // 立即购买
            buy_now: 1,
            // 订单类型
            order_type: this.detail.type === 6 ? 1 : 2,
            // 运营中心
            // center_id: this.serviceCenterData?.value || this.detail.specify_center_id,
            // 分享码
            invite_code: this.invite.getId(),
            // 是否购买默认运营中心
            is_default_center: 0,
            is_gs_pay: this.detail.is_gs_pay
        }).subscribe((res: { data }) => {
            if (JSON.stringify(res.data) === '{}' || JSON.stringify(res.data) === '[]') {
                this.toast.show('购买成功，正在为您跳转到下载页..')
                this.router.navigate(['/shop/order-succeed'], { queryParams: { orderno: res.data.order_no }, replaceUrl: true }).then()
            } else {
                this.orderInfoData = res.data;
                this.getPayEnv(res.data.order_no);
            }
        });
    }

    // 提交订单返回参数
    orderInfoData: any = {}

    // 支付环境
    payEnvData: any = {}

    // 获取支付环境
    getPayEnv(order_no){
        this.http.post('/shop/api/getshoppayenv', { platform: 'wechat',order_no: order_no }).subscribe((res: { data }) => {
            this.payEnvData = res.data;
            this.showPop = !this.showPop;
        })
    }

    // 支付方式点击
    payClick(pay_way) {
        if(!localStorage.getItem('openId') && pay_way===1){
            this.toast.show('请在微信环境中打开链接')
            return
        }
        this.showPop = !this.showPop;
        this.http.post('/shop/api/ordershoppay', { 
            ...this.orderInfoData,
            actual_total: this.orderInfoData.total,
            pay_way: pay_way,// 支付方式，1微信 11支付宝
            order_type: this.detail.type === 6 ? 1 : 2,// 订单类型
            open_id: localStorage.getItem('openId'),
            client_type: this.wechat.isWechatUa ? 1 : 2
         }).subscribe((res: { data }) => {
            if(pay_way!=1){
                this.router.navigate(['/shop/alipay'], { queryParams: { orderNo: this.orderInfoData.order_no } }).then()
            }else{
                this.wechat.pay(res.data, this.orderInfoData.order_no, () => {
                    if (window.localStorage.getItem('paymentstatus')) {
                        window.localStorage.removeItem('paymentstatus');
                        window.location.href = 'https://assets.ddgeyou.com/index.html'
                    } else {
                        this.ngZone.run(() => {
                            // 跳转到成功页面
                            this.router.navigate(['/shop/order-succeed'], { queryParams: { orderno: res.data.order_no }, replaceUrl: true }).then()
                        });
                        // 刷新试试
                        setTimeout(() => {
                            window.location.reload()
                        }, 200);
                    }
                });
            }
        })
    }

    // 查看自提点
    lookAddress(){
        this.router.navigate(['/shop/self-pickup'], { queryParams: { goods_id: this.detail.id, type: 0 } }).then()
    }
}
