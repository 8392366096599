<!--<nz-result [nzStatus]="code"-->
<!--           [nzTitle]="code"-->
<!--           [nzSubTitle]="title[code]">-->
<!--    <div nz-result-extra>-->
<!--        <button nz-button-->
<!--                (click)="toIndex()"-->
<!--                nzType="primary">返回到首页-->
<!--        </button>-->
<!--    </div>-->
<!--</nz-result>-->

<div class="container flex column align-center">
  <img [src]="'https://promote-beta.ddgeyou.com/assets/images/' + code + '.png'" alt="">
  <p>{{ title[code] }}</p>
  <button d-button routerLink="/simpleherbalzone/home">回到首页</button>

</div>