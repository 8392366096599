import { Component, OnInit } from '@angular/core';
import { Output,EventEmitter} from '@angular/core';
import {Input} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'app-commodel',
  templateUrl: './commodel.component.html',
  styleUrls: ['./commodel.component.less']
})
export class CommodelComponent implements OnInit {
  @Output() private outer = new EventEmitter()
  @Input() modeldata
  constructor(private  sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    if(this.modeldata.type==='replace'||this.modeldata.type==='optimization'||this.modeldata.type==='selection'){
      this.modeldata.title= this.sanitizer.bypassSecurityTrustHtml(this.modeldata.title);
    }
  }
  operation(e){
   
    if(e==='claimgoods'){
      if(this.modeldata.centen.name){
        this.outer.emit('claimgoodsok')
      }else{
        this.outer.emit('claimgoodsno')
      }
    }else{
      this.outer.emit(e)
    }
  }
}
