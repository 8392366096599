import { ThrowStmt } from '@angular/compiler';
import { Component, OnInit, NgZone } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { HttpClient } from "@angular/common/http";
import { ToastService } from 'ng-zorro-antd-mobile';
import { ScrollService } from '../../../code/service/scroll/scroll.service';
import { PlatformService } from "../../../code/service/platform/platform.service";
import { AuthService } from "../../../code/service/auth/auth.service";
import { ActivatedRoute, Router } from '@angular/router'
import { WechatService } from '../../../code/service/wechat/wechat.service';
type orderInfo = {
  order_no: string,
  body: string,
  pay_type: number
};
@Component({
  selector: 'app-competehome',
  templateUrl: './competehome.component.html',
  styleUrls: ['./competehome.component.less']
})
export class CompetehomeComponent implements OnInit {

  constructor(
    private title: Title,
    private router: Router,
    private http: HttpClient,
    private _toast: ToastService,
    private platform: PlatformService,
    private scroll: ScrollService,
    private auth: AuthService,
    private route: ActivatedRoute,
    public wechat: WechatService,
    private ngZone: NgZone,
  ) {
    title.setTitle('易场');
  }
  //倒计时
  counttime = ''
  // 组团id
  snapgroupid
  // 活动id
  snapinviteinfo
  ngOnInit(): void {
    this.snapgroupid = this.route.snapshot.queryParams.snap_up_group_id;
    this.snapinviteinfo = this.route.snapshot.queryParams;
    console.log(this.route.snapshot.queryParams)
    this.getGroupdetails(this.snapgroupid);
  }
  // 抢购信息
  spellinfo
  // 支付信息
  bepayinfo
  // 获取抢购组详情
  getGroupdetails(e) {
    this.http.post('/seckill/api/getsnapupgroupdetailsbyshareversion', { snap_up_group_id: e }).subscribe(
      (res: { data, code }) => {
        if (res.code === 0) {
          console.log(res.data, '什么呢')
          this.spellinfo = res.data;
          if (JSON.stringify(this.spellinfo.to_be_paid_order) !== '[]') {
            this.bepayinfo = this.spellinfo.to_be_paid_order
            this.countTime()
            console.log('不为空数组', this.bepayinfo)
          }
        }
      }
    );

  }
  // 下载app
  godounApp() {
    window.location.href = 'https://assets.ddgeyou.com/ycindex.html'
  }
  // 有倒计时的点击
  goagainParticipate() {
    this.http.post('/seckill/api/snapuporderlistclicktopaytogetinformation', { order_no: this.bepayinfo.order_no }).subscribe(
      (res: { data, code }) => {
        if (res.code === 0) {
          this.jsapiPay(res.data)
        }
      }
    );
  }

  // 防抖字段
  isantishake = true
  //免抢购抢单
  goParticipate() {
    // this.gologin()
    if (!this.auth.getToken()) {
      this.auth.redirectUrl = this.router.url;
      this.router.navigateByUrl('/user/register/phone').then();
    } else {
      // 防抖
      if (this.isantishake === true) {
        this.isantishake = false
        setTimeout(() => {
          this.isantishake = true
        }, 3000);
      } else {
        return
      }
      //提交订单
      this.http.post('/seckill/api/snapupsubmitorder', {
        snap_up_activity_id: this.snapinviteinfo.snap_up_activity_id,
        snap_up_group_id: this.snapgroupid,
        invite_code: this.snapinviteinfo.invite
      }).subscribe(
        (res: { data, code }) => {
          if (res.code === 0) {
            this.jsapiPay(res.data)
          }
        }
      )
    }
  }
  // h5页面
  jsapiPay(item): void {
    if (item.order_no) {
      let url = '/seckill/api/snapuporderpaymentsignature'
      this.http.post(url, {
        order_no: item.order_no,
        pay_type: 1,
        client_type: 1,
        su_wallet_amount: 0,
        open_id: localStorage.getItem('openId')
      }).subscribe((res: { code: number, data: orderInfo }) => {
        if (res.data.pay_type === 0) {
          window.location.reload()
        } else {
          if (res.code === 0) {
            this.wechat.pay(res.data, res.data.order_no, (res) => {
              if (window.localStorage.getItem('paymentstatus')) {
                window.localStorage.removeItem('paymentstatus');
                window.location.reload()
              } else {
                // this.ngZone.run(() => {
                //   // 跳转到成功页面
                //   this.router.navigate(['/newuserseckill/h5seckill-pay'], { replaceUrl: true }).then()
                // });
                this.ngZone.run(() => this.router.navigateByUrl('/multiactive/h5pay-success')).then();
              }
            });
          }
        }
      });
    }
  }
  // 看规则
  gocompeteRule() {
    window.location.href = 'https://assets.ddgeyou.com/web/competeactiverule.html'
  }
  // // 判断登陆
  // gologin() {
  //   if (!this.auth.getToken()) {
  //     this.auth.redirectUrl = this.router.url;
  //     this.router.navigateByUrl('/user/register/phone').then();
  //   }
  // }
  //倒计时封装接口倒计时
  countTime() {
    //获取当前时间
    var date = new Date();
    let EndTime = this.bepayinfo.latest_pay_time;
    var NowTime = Math.round((date.getTime() / 1000));
    var t = EndTime - NowTime;
    if (t >= 0) {

      var d = Math.floor(t / 60 / 60 / 24);
      // var h = Math.floor(t / 60 / 60 % 24) + (d * 24);
      var h = Math.floor((t % (60 * 60 * 24)) / (60 * 60));
      var m = Math.floor(t / 60 % 60);
      var s = Math.floor(t % 60);
      h = this.checkTime(h);
      m = this.checkTime(m);
      s = this.checkTime(s);
      this.counttime = `${d ? d + '天 ' : ''}` + h + ':' + m + ':' + s + '';
      setTimeout(() => {
        this.countdown();
        // 倒计时到0时页面刷新关闭
        if (t === 0) {
          window.location.reload()
        }
      }, 1000)
    } else {
      this.counttime = "00时00分00秒"
      this.bepayinfo = null
    }
  }
  countdown() {
    this.countTime()
  }
  checkTime(i) {
    if (i < 10) {
      i = "0" + i;
    }
    return i;
  }
}
