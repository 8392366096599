<app-popmodel (outer)="getReturnData($event)" *ngIf="popData.isShow" [popdata]="popData"></app-popmodel>
<div class="container_box">
  <div class="store_box">
    <ng-template [ngIf]="3>stepIndex">
      <div class="store_top">
        <img src="https://base-assets.ddgeyou.com/simpleherbalzone/icon_logo.png" />
      </div>
      <div class="store_step">
        <div class="step_item" [class]="{'active': stepIndex==0}">
          <div class="step_text">1</div>
          <div class="step_txt">填写信息</div>
        </div>
        <div class="step_line"></div>
        <div class="step_item" [class]="{'active': stepIndex==1}">
          <div class="step_text">2</div>
          <div class="step_txt">选择版本</div>
        </div>
        <div class="step_line"></div>
        <div class="step_item" [class]="{'active': stepIndex==2}">
          <div class="step_text">3</div>
          <div class="step_txt">等待开通</div>
        </div>
      </div>
    </ng-template>
    <ng-template [ngIf]="stepIndex==0">
      <div class="store_list" [class]="{'store_disabled': disableName&&disableCity&&disableAdds}">
        <div class="store_item" [class]="{'item_disabled': disableName}">
          <div class="item_label">店铺名</div>
          <input type="text" class="item_input" [class]="{'item_red':isShowError}" [disabled]="disableName" placeholder="请填写" (focus)="modelFocus()" [(ngModel)]="store_name" maxlength="32">
          <img class="item_img item_hide" [class]="{'item_show':store_name&&store_name.trim()}" (click)="store_name=null" src="https://base-assets.ddgeyou.com/travelloop/icon_close_4.png" />
        </div>
        <div class="store_item" [class]="{'item_disabled': disableCity}">
          <div class="item_label">所在地区</div>
          <p
            Picker
            class="item_val"
            [data]="options"
            [mask]="true"
            [(ngModel)]="domicilecity"
            (ngModelChange)="onPickerChange($event)"
            [disabled]="disableCity"
            [title]="'选择地区'"
          >
            <label class="item_txt item_grey" *ngIf="!cityText">请选择</label>
            <label class="item_txt omit" style="width: 100%;" *ngIf="cityText">{{cityText}}</label>
          </p>
          <img class="item_img" src="https://base-assets.ddgeyou.com/simpleherbalzone/icon_next_1.png" />
        </div>
        <div class="store_item" [class]="{'item_disabled': disableAdds}">
          <div class="item_label">详细地址</div>
          <input type="text" class="item_input" [disabled]="disableAdds" placeholder="请填写详细地址" [(ngModel)]="address" maxlength="128">
        </div>
      </div>
      <div class="store_btn" (click)="nextClick()">下一步</div>
    </ng-template>
    <ng-template [ngIf]="stepIndex==1">
      <div class="store_center" [class]="{'center_disabled': disablePay}">
        <div class="center_tag"><img src="https://base-assets.ddgeyou.com/simpleherbalzone/icon_true.png" /></div>
        <div class="center_title"><span>基础版</span><span>{{ settle_order_info.payable_price }}元</span></div>
        <div class="center_tip"><span>限免年费</span><span>赠送{{ settle_order_info.give_yi_ticket }}和合易券</span></div>
        <div class="center_list">
          <div class="center_item" *ngFor="let item of settle_order_info.bundling_extra_service_list">
            <img [src]="disablePay?'https://base-assets.ddgeyou.com/travelloop/icon_checkbox_2.png':'https://base-assets.ddgeyou.com/travelloop/icon_checkbox.png'" />
            <div class="center_right">
              <div class="center_text">{{ item.name }}</div>
              <div class="center_txt">{{ item.describe }}</div>
            </div>
          </div>
          <div class="center_item" *ngFor="let item of settle_order_info.can_select_extra_service_list" (click)="selectService(item)">
            <img [src]="item.isChecked?'https://base-assets.ddgeyou.com/travelloop/icon_checkbox_1.png':'https://base-assets.ddgeyou.com/travelloop/icon_uncheckbox.png'" />
            <div class="center_right">
              <div class="center_text">{{ item.name }}</div>
              <div class="center_txt">{{ item.describe }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="store_check">
        <img [src]="isChecked?'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADgAAAA4CAMAAACfWMssAAAAWlBMVEUAAAD/YRr/YBz/YRv/YBv/YBX/Xxv/XBj/Xxr/Xxv/YBz/YBv/YBr/Xxv/Xxv/Xxv/Xxz/YBv/Xhn/YBz/YBz/Xxv/WRr/YBD/Xxv/Yhv/VRX/YBr/YBv/YBz13I30AAAAHXRSTlMAR39P1xjzGzus93Vo47/t288z57ezFBBLLwxQnxa9WWsAAAFESURBVEjH5dbbbsMgEARQCG0pie/XuO38/2+2UiqvAe+CLeUp82jpaC2zYqxeOXOnjdHLdAjZwpX4T+kKm8lMhSCDyWD3BjtpU9SOYOKs5HQNNr3m3Q1ibpy7IJGLMO/ETI2M6NjZPgfWNoIuQd4eazGGzqTc+1e1+7Jtyin1kA0zkHd/sgEA48Eh5eicq62z+Q6wG1gccCjYs5Ad3AbWBxxKctP68OPKOsq8wm51n/qadOhov1enFMnIxZv+TY4k62B2IUlyLLwjkKKDjj4OSclhWeGMSAoOm1ooI9nyrgxXjqR877lgyUmyLl5yC0+KDt59VflSckNwdfiSdwh6qwkk69qwFuHLH8gDKSOy4uIKqHNcb8+WztmaO1usp6ucje55VmslxDrOjVbJMe0ea2icQIeQVeZ5P4GUadHG6G5WL5xfoOSmz1TSOcYAAAAASUVORK5CYII=':'https://base-assets.ddgeyou.com/travelloop/icon_uncheck.png'" (click)="isChecked=!isChecked" />
        <div>我已同意<span (click)="agreementClick()">《供需循环商城入驻协议》</span></div>
      </div>
      <div class="store_btn" (click)="buyClick()">去支付</div>
    </ng-template>
    <ng-template [ngIf]="stepIndex==2">
      <div class="store_info">
        <div class="info_top">
          <div class="info_left">{{ settle_info.store_name }}</div>
          <div class="info_right">状态:
            <span *ngIf="settle_info.examine_status==1">审核中</span>
            <span *ngIf="settle_info.examine_status==2">已通过</span>
            <span *ngIf="settle_info.examine_status==3">已拒绝</span>
          </div>
        </div>
        <ng-template [ngIf]="settle_info.examine_status==3">
          <div class="info_text">拒绝原因：{{ settle_info.reject_reason }}</div>
          <div class="info_btn" (click)="editClick()">重新编辑</div>
        </ng-template>
        <div class="info_txt">提交时间: {{ settle_info.time_text }}</div>
      </div>
    </ng-template>
    <ng-template [ngIf]="stepIndex==3">
      <!-- 已有对接人 -->
       <div class="store_tip">
        <img src="https://base-assets.ddgeyou.com/travelloop/icon_store_1.png" />
        <div class="tip_txt">很抱歉，您已存在店铺入驻顾问<span>{{ popupTip }}</span>请联系原入驻顾问完成入驻</div>
        <div class="tip_btn" (click)="copyClick()">复制号码</div>
       </div>
    </ng-template>
    <ng-template [ngIf]="stepIndex==4">
      <!-- 名额已用完 -->
      <div class="store_tip">
        <img src="https://base-assets.ddgeyou.com/travelloop/icon_store_1.png" />
        <div class="tip_txt">很抱歉，当前入驻顾问服务额度已满，暂未支持入驻，详情请咨询您的入驻顾问<span>{{ popupTip }}</span></div>
        <div class="tip_btn" (click)="copyClick()">复制号码</div>
       </div>
    </ng-template>
  </div>
</div>
<div class="popup">
  <div [class]="{'mask':isShowPop}" (click)="isShowPop=false"></div>
  <div class="popup_center" [class]="{'on':isShowPop}">
    <div class="popup_title">
      <div class="title_text">选择支付方式</div>
      <img src="https://base-assets.ddgeyou.com/simpleherbalzone/icon_colse.png" (click)="isShowPop=false" />
    </div>
    <div class="popup_list">
      <div *ngFor="let item of payWayArr;let index = index">
        <div class="popup_item" *ngIf="envPlatform==item.env">
          <img class="popup_img" [src]="item.imgurl" />
          <div class="popup_right">
            <div class="popup_txt">{{item.name}}</div>
            <div class="popup_icon" [ngClass]="{icon_active:payWayIndex==item.type}" (click)="paywayClick(item.type)"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="popup_footer">
      <div class="popup_btn" (click)="payClick()">立即支付</div>
    </div>
  </div>
</div>