import {Injectable} from '@angular/core';
import {UserInfo} from "../../interface/user";
import {HttpClient} from "@angular/common/http";

@Injectable({
    providedIn: 'root'
})
export class UserInfoService {

    constructor(
        private http: HttpClient,
    ) {
    }

    public userInfo: UserInfo = JSON.parse(sessionStorage.getItem('userInfo')) || {};

    private getUserData = (): UserInfo => {
        return this.userInfo || JSON.parse(sessionStorage.getItem('userInfo')) || {};
    }

    get id(): number {
        return this.getUserData()?.id || null
    }

    get name(): string {
        return this.getUserData()?.name || null
    }

    get nickname(): string {
        return this.getUserData()?.nickname || null
    }

    get avatar(): string {
        return this.getUserData()?.avatar || null
    }

    setName(name: string): void {
        this.userInfo.name = name;
        this.setSessionStorage()
    }

    setId(id: number, callback?: Function): void {
        this.userInfo.id = id;
        this.getUserInfo(callback);
    }

    getUserInfo(callback?: Function): void {
        this.http.post('/advert/api/geyou/useridentity', {})
            .subscribe((res: { data }) => {
                Object.assign(this.userInfo, res.data)
                this.setSessionStorage()
                callback && callback();
            })
    }

    logout(): void {
        this.userInfo = {};
        sessionStorage.removeItem('userInfo')
    }

    private setSessionStorage(): void {
        sessionStorage.setItem('userInfo', JSON.stringify(this.userInfo))
    }
}
