<div class="group-pop">
    <div [class]="isShow?'mask':''" (click)="popClose()"></div>
    <div class="group-content-box" [class]="isShow?'on':''">
        <div class="group-content-block">
            <div class="content-top flex justify-center align-center">
                <div class="content-title">合并取货</div>
                <div class="content-close" (click)="popClose()"><img src="https://base-assets.ddgeyou.com/groupbook/itemclose.png" /></div>
            </div>
            <div class="content-list-title">已选择：<span>{{groupCheckNum}}</span>个</div>
            <div class="content-list-box" (scroll)="onWindowScroll($event)" *ngIf="groupList.length>0">
                <div class="content-list flex justify-center align-center" *ngFor="let item of groupList;let index = index">
                    <div class="item-left flex justify-center align-center">
                        <div class="item-img flex justify-center align-center"><img [src]="item.img" /></div>
                        <div class="item-center">
                            <div class="item-title">{{item.route_title}}</div>
                            <div class="item-price">订单金额：￥{{item.selling_price}}</div>
                            <div class="item-price1">取货额度：<span>￥{{item.pickup_worth}}</span></div>
                        </div>
                    </div>
                    <div class="item-right flex justify-center align-center" (click)="itemCheck(item)">
                        <img *ngIf="item.isCheck" src="https://base-assets.ddgeyou.com/claimgood/check_checked.png" />
                        <img *ngIf="!item.isCheck" src="https://base-assets.ddgeyou.com/claimgood/check_unchecked.png" />
                    </div>
                </div>
            </div>
            <div class="content-empty flex justify-center align-center" *ngIf="groupList.length==0">
                <div class="empty-img"><img src="https://base-assets.ddgeyou.com/claimgood/empty_suit.png" /></div>
                <div class="empty-text">暂无数据</div>
            </div>
        </div>
        <div class="group-bottom">
            <div *ngIf="groupList.length>0" class="group-btn flex justify-center align-center" (click)="subClick()">合并取货
                <span *ngIf="groupPrice!=0">（总额度：<b>{{groupPrice}}元</b>）</span>
            </div>
            <div *ngIf="groupList.length==0" class="group-btn-empty flex justify-center align-center">合并取货</div>
        </div>
    </div>
</div>
