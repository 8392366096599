import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';

import * as Sentry from "@sentry/angular";
import {Integrations} from "@sentry/tracing";
import * as vconsole from "vconsole";

Sentry.init({
    dsn: "https://9591fb7c318048d0bb281e5d9d8becfe@o497016.ingest.sentry.io/5572563",
    autoSessionTracking: true,
    integrations: [
        new Integrations.BrowserTracing({
            tracingOrigins: ["localhost", "https://service.ddgeyou.com", "https://beta-service.ddgeyou.com"],
            routingInstrumentation: Sentry.routingInstrumentation,
        }),
    ],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
});

if (environment.production) {
    enableProdMode();
} else {
    // @ts-ignore
    new vconsole()
}

platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));
