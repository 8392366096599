import {Component, NgZone, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import { Router} from '@angular/router';
import {HttpClient} from "@angular/common/http";
import {TravelAgencyService} from "../../../code/service/travelAgency/travelAgency.service";
import {WechatService} from "../../../code/service/wechat/wechat.service";
import {ModalService} from "ng-zorro-antd-mobile";

@Component({
    selector: 'app-pay',
    templateUrl: './pay.component.html',
    styleUrls: ['./pay.component.less']
})
export class TravelAgencyPayComponent {

    constructor(
        private http: HttpClient,
        private travelAgency: TravelAgencyService,
        private wechat: WechatService,
        private router: Router,
        private _modal: ModalService,
        private title: Title,
        private ngZone: NgZone
    ) {
        title.setTitle('支付');
    }

    pay(): void {
        this.http.post('/travel/api/travel_agency/paytheentryfee', {
            order_no: this.travelAgency.getOrderNo(),
            payment_serial_number: this.travelAgency.getPaymentSerialNumber(),
            pay_way: 1,
            client_type: 1,
            open_id: localStorage.getItem('openId')
        }).subscribe((res: { data }) => {
            this.wechat.pay(res.data, res.data.order_no, () => {
                // this.http.post('/travel/api/guideapplicationoutflow', {}).subscribe((res: { data: Data }) => {
                //     const {data} = res
                // })
                // setTimeout(() => {
                this.ngZone.run(() => this.router.navigateByUrl('/travel-agency/success', {replaceUrl: true})).then();
                // }, 10);
                // this.router.navigateByUrl('/travel-agency/success', {replaceUrl: true}).then()
            })
        })
    }

    showModal() {
        this._modal.alert('提示', '请下载滴咚个游APP完成导游入驻\n及时了解入驻审核进度吧', [
            {text: '取消', onPress: () => console.log('cancel')},
            {
                text: '下载APP', onPress: () => {
                    window.location.href = 'https://assets.ddgeyou.com/index.html'
                }
            }
        ]);
    }

}
