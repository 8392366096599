import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'stringValue'
})
export class StringValuePipe implements PipeTransform {
    transform(value: string | number, length: number): string {
        let text = value.toString()
        if (text.length <= length) {
            return text.slice(0, length)
        } else {
            return text.slice(0, length) + '…'
        }
    }
}
