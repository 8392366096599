<div class="container">
    <!-- 地址 -->
    <div class="address-view flex align-center justify-between" routerLink="/shop/address/list" *ngIf="addressStatus">
        <img src="https://promote-beta.ddgeyou.com/assets/images/shop/address.png" class="address-image" alt="">
        <div class="flex-sub text-view">
            <ng-template [ngIf]="addressData" [ngIfElse]="elseBlock">
                <div class="flex align-center">
                    <p>{{ addressData.name }}</p>
                    <p>{{ addressData.phone }}</p>
                </div>
                <p class="address-text omits">{{ addressData.area }}{{ addressData.address }}</p>
            </ng-template>
            <ng-template #elseBlock>
                <p>请填写收货人地址</p>
            </ng-template>
        </div>
        <Icon type="right" color="#ffffff"></Icon>
    </div>
    <!-- 商品详情 -->
    <div class="detail-view"
        [style]="{marginTop: (detail.express_ways.length === 1 && detail.express_ways[0] === 2) || !addressStatus ? '0' : '-16px'}">
        <div class="commodity-view flex align-center justify-between">
            <img [src]="detail.pic[0]" alt="">
            <div class="flex-sub">
                <p class="title">{{ detail.name }}</p>
                <!-- 规格 -->
                <div class="guide-view flex align-center justify-between">
                    <div class="flex flex-wrap align-center flex-sub">
                        <p *ngFor="let item of sku">{{ item.key }}: {{ item.value }}</p>
                    </div>
                    <p>x{{ quantity }}</p>
                </div>

                <!-- 规格对应价格 -->
                <div class="flex align-center justify-start price-view">
                    <p class="price-text">{{ skuDetail.price }}</p>
                    <del class="price-text">{{ detail.origin_price }}</del>
                </div>
            </div>
        </div>
        <!-- 价格 -->
        <div class="all-price-view flex align-baseline justify-end">
            <p class="text">小计：</p>
            <p class="price-text">{{ allPress }}</p>
        </div>
    </div>
    <!-- 配送方式 -->
    <div class="order_way">
        <div class="order_way_index" *ngFor="let item of detail.express_ways;let index = index">
            <div class="way_item" *ngIf="item==1">
                <div class="way_text">快递：<span>¥{{ detail.express_fee }}</span></div>
                <input type="radio" name="express" [checked]="expressType === 1" (change)="onExpressTypeChange(1)">
            </div>
            <div class="way_item" *ngIf="item==2">
                <div class="way_text">
                    <div>到店自提</div>
                    <div class="way_img" *ngIf="detail.type==6" (click)="lookAddress()"><span>|</span>查看自提点<img src="https://base-assets.ddgeyou.com/center/icon_address.png" alt=""></div>
                </div>
                <input type="radio" name="express" [checked]="expressType === 2" (change)="onExpressTypeChange(2)">
            </div>
        </div>
        <div class="way_self_box" *ngIf="detail.type!=6 && detail.self_pick_address"><div class="way_self">{{detail.self_pick_address}}</div></div>
    </div>
</div>

<div class="footer-view flex align-center justify-between">
    <div class="flex align-baseline">
        <p class="text">小计：</p>
        <p class="price-text">{{ allPress }}</p>
    </div>
    <button d-button dType="shop" dShape="circle" (click)="submit()">去付款</button>
</div>

<div class="order_pop_box">
    <div [class]="{'order_mask':showPop}" (click)="showPop=false"></div>
    <div class="order_pop" [class]="{'on':showPop,'pop_bottom':isIphoneX}">
        <div class="order_pop_title">请选择支付方式</div>
        <div class="order_item" (click)="payClick(payEnvData.wechatpay.pay_way)" *ngIf="payEnvData.wechatpay"><img
                src="https://base-assets.ddgeyou.com/groupbook/weixin.png" />微信支付</div>
        <div class="order_item" (click)="payClick(payEnvData.alipay.pay_way)" *ngIf="payEnvData.alipay"><img
                src="https://base-assets.ddgeyou.com/groupbook/zhifubao.png" />支付宝支付</div>
    </div>
</div>