import { Component, OnInit, ElementRef, ViewChild, NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router'
import { HttpClient } from "@angular/common/http";
import { ToastService } from 'ng-zorro-antd-mobile';
import { WechatService } from '../../../code/service/wechat/wechat.service';
import { Title } from '@angular/platform-browser';
import { AuthService } from "../../../code/service/auth/auth.service";
import { UserInfoService } from "../../../code/service/user/user.service";
import { PlatformService } from "../../../code/service/platform/platform.service";
import { InviteService } from "../../../code/service/invite/invite.service";

type orderInfo = {
  order_no: string,
  body: string,
  pay_type: number
};
@Component({
  selector: 'app-productdetails',
  templateUrl: './productdetails.component.html',
  styleUrls: ['./productdetails.component.less']
})
export class ProductdetailsComponent implements OnInit {

  //轮播图
  carouseldata: any;
  // 当前选中的轮播图
  bannerIndex = 0
  // 推荐列表
  recommendlist = []
  constructor(
    private title: Title,
    private router: Router,
    private _toast: ToastService,
    private http: HttpClient,
    public wechat: WechatService,
    private route: ActivatedRoute,
    private auth: AuthService,
    private platform: PlatformService,
    private ngZone: NgZone,
    private user: UserInfoService,
    private invite: InviteService) {
    title.setTitle('商品详情');
  }
  // 活动线路详情
  routedetails;
  // 轮播图的下标
  dotsdata = []
  // 展开订单弹窗
  showaffirmmodel = false
  // 地址信息
  addressinfo = {}
  // 活动id
  activityid
  // 活动详情
  multiadetails
  //其他团队数据
  activeotherteamlist = []
  // 刷新
  isRefresh = true// 是否登陆
  // 是否在微信环境
  iswechat=false
  // 已经调用了收货地址的字段
  isgetaddress=false
  // 防抖字段
  isantishake=true
  ngOnInit(): void {
    this.addressinfo = {
      name: '',
      phone: '',
      address: ''
    }

    let routprams = this.route.snapshot.queryParams;
    this.activityid = routprams.snap_up_activity_id;
    console.log('都有什么字段',routprams)
    if(this.activityid){
      // 获取抢购活动详情
      this.getActivedetails()
      // 抢购活动进行中团队
      this.getActiveotherteam()
    }
    // 平台框架兼容,如果sell字段存在，则是uniapp框架进来,并保存在本地告诉平台页面
    if (this.route.snapshot.queryParams.sell) {
      window.localStorage.setItem('sell', 'uniapp')
    } else {
      window.localStorage.setItem('sell', 'angular')
    }
    // iswechat=yes判断是否在h5环境
    if (this.route.snapshot.queryParams.iswechat) {
      this.iswechat=true
    }
    if (window.localStorage.getItem('addressentrance') === 'affirmoder' && window.localStorage.getItem('addressdata')) {
      this.changAddress()
    }
  }
  // 获取抢购活动详情
  getActivedetails() {
    this.http.post('/seckill/api/getsnapupactivitydetails', { snap_up_activity_id: this.activityid }).subscribe(
      (res: { data, code }) => {
        if (res.code === 0) {
          // this.multiadetails=res.data.data
          this.routedetails = res.data
          this.routedetails.noclustering = Number(this.routedetails.quantity) - Number(this.routedetails.successful_quantity)

          if (this.routedetails.imgs) {
            this.routedetails.imgs.forEach(element => {
              this.dotsdata.push(element)
            });
          }
        }
      }
    );
  }
  // 抢购活动进行中团队
  getActiveotherteam() {
    this.http.post('/seckill/api/snapupeventinprogressteam', { snap_up_activity_id: this.activityid }).subscribe(
      (res: { data, code }) => {
        if (res.code === 0) {
          if (JSON.stringify(res.data) !== '{}') {
            this.activeotherteamlist = res.data
          } else {
            this.activeotherteamlist = []
          }
        }
      }
    );
  }
  // 刷新抢购进行中团队
  refreshOther() {
    if (this.isRefresh) {
      this.isRefresh = false
      setTimeout(() => {
        this.isRefresh = true
      }, 2000);
      this.getActiveotherteam()
    }
  }
  // 关闭订单弹窗
  close() {
    this.showaffirmmodel = false;
    this.purchbuy=false
  }
  // 地址信息
  addressdata
  // 如果是选择地址薄回来的场景
  changAddress() {
    this.isgetaddress=true
    if (window.localStorage.getItem('addressentrance') === 'affirmoder' && window.localStorage.getItem('addressdata')) {
      this.showaffirmmodel = true;
      window.localStorage.removeItem('addressentrance')
      if (window.localStorage.getItem('addressdata')) {
        this.addressdata = JSON.parse(window.localStorage.getItem('addressdata'));
        this.addressinfo = {
          name: this.addressdata.name,
          phone: this.addressdata.phone,
          address: `${this.addressdata.area}${this.addressdata.address}`
        }
        window.localStorage.removeItem('addressdata')
      }
      // 选择地址竞抢状态回来
      if (window.localStorage.getItem('addressjoindata')){
          this.jonindata=JSON.parse(window.localStorage.getItem('addressjoindata'))
          window.localStorage.removeItem('addressjoindata')
      }
      // 立即购买状态回来
      if (window.localStorage.getItem('addressPurchbuy')){
          this.purchbuy=true
          window.localStorage.removeItem('addressPurchbuy')
      }
      
    } else {
      setTimeout(() => {
        this.getDefaultAddress()
      }, 1000);
    }
  }
  // 分享
  goDetailsshare(){
    let activityid = {
      snapactiveid: this.activityid
    }
    // @ts-ignore
    window.uni.postMessage({
      data: {
        type: 'comdetailsshare',
        data: JSON.stringify(activityid)
      }
    });
  }
  // 判断是否是点击立即购买字段
  purchbuy=false
  // 直接购买状态
  goPurchase(){
    this.purchbuy=true;
    this.appOperation()
  }
  //发起竞抢
  goSponsor() {
    // 竞抢状态
    this.purchbuy=false
    this.platform.check(
      null,
      () => this.appOperation(),
      () => this.appOperation(),
      () => this.jsh5Operation()
    );
  }
  // 在app的操作
  appOperation(){
      // 判断是否拿取过地址，没有则就再拿取一次
      if(this.isgetaddress===false){
        this.changAddress()
      }
      this.jonindata = null
      this.showaffirmmodel = true
  }
  // 在h5的操作
  jsh5Operation(){
      if (!this.auth.getToken()) {
        this.auth.redirectUrl = this.router.url;
        this.router.navigateByUrl('/user/register/phone').then();
      } else{
        console.log('在h5的操作')
        this.goPayorder()
      }
  }
    // h5支付页面
    jsapiPay(item): void {
      if (item.order_no) {
        let url = '/seckill/api/snapuporderpaymentsignature'
        this.http.post(url, {
          order_no: item.order_no,
          pay_type: 1,
          client_type: 1,
          su_wallet_amount: 0,
          open_id: localStorage.getItem('openId')
        }).subscribe((res: { code: number, data: orderInfo }) => {
          console.log('拿到什么了啊', res.data)
          if (res.data.pay_type === 0) {
            window.location.reload()
          } else {
            if (res.code === 0) {
              this.wechat.pay(res.data, res.data.order_no, (res) => {
                console.log('拿到什么了啊2', res)
                if (window.localStorage.getItem('paymentstatus')) {
                  window.localStorage.removeItem('paymentstatus');
                  console.log('进入哪里1', window.localStorage.getItem('paymentstatus'))
                  window.location.reload()
                } else {
                  console.log('进入哪里2')
                  // this.ngZone.run(() => {
                  //   // 跳转到成功页面
                  //   this.router.navigate(['/newuserseckill/h5seckill-pay'], { replaceUrl: true }).then()
                  // });
                  this.ngZone.run(() => this.router.navigateByUrl('/multiactive/h5pay-success')).then();
                }
              });
            }
          }
        });
      }
    }
  // 加入竞抢赋值当前要加入的item值
  jonindata
  // 点击加入竞抢
  goJoin(e) {
    this.jonindata = e
    this.platform.check(
      null,
      () => this.postappJoin(),
      () => this.postappJoin(),
      () => this.jsh5Join()
    );
  }
  // app加入竞抢
  postappJoin(){
    this.showaffirmmodel = true
  }
  // h5加入竞抢
  jsh5Join(){
    if (!this.auth.getToken()) {
      this.auth.redirectUrl = this.router.url;
      this.router.navigateByUrl('/user/register/phone').then();
    } else{
      this.showaffirmmodel = true
    }
  }
  // 看规则
  gocompeteRule() {
    window.location.href = 'https://assets.ddgeyou.com/web/competeactiverule.html'
  }
  // 立即购买支付
  gorightPayorder(){
    if(this.routedetails.use_type===1){
      if(!this.addressdata){
        this._toast.show('请填写收货地址！')
        return
      }
    }
    this.goPayorder()
  }
  // 支付定金
  goPayorder() {
    let parm
    // 地址信息封装
    let addressparm = {
      receiver_name: this.addressdata ? this.addressdata.name : '',
      receiver_phone: this.addressdata ? this.addressdata.phone : '',
      receiver_area: this.addressdata ? this.addressdata.area : '',
      receiver_address: this.addressdata ? this.addressdata.address : '',
    }
    let invitedata
    if(this.iswechat===true){
      invitedata= {
        invite_code: this.invite.getId()
      }
    }
    console.log('什么值0',this.jonindata)
    // 加入场景
    if (this.jonindata) {
      parm = {
        snap_up_activity_id: this.activityid,
        snap_up_group_id: this.jonindata.snap_up_group_id,
        ...addressparm,
        ...invitedata
      }
    } else {
      // 发起场景
      parm = {
        snap_up_activity_id: this.activityid,
        snap_up_group_id: 0,
        ...addressparm,
        ...invitedata
      }
    }
    // 防抖
    if(this.isantishake===true){
    this.isantishake=false
    setTimeout(() => {
          this.isantishake=true
        }, 3000);
    }else{
        return
    }
    let url ,typedata
    // 如果是购买下单
    if(this.purchbuy===true){
      url='/seckill/api/snapupdirectpurchasesubmitorder'
      typedata={
        type:'compete',
        competetype:'purchbuy'
      }
    }else{
      // 如果是竞抢下单
      url='/seckill/api/snapupsubmitorder'
      typedata={
        type:'compete',
        competetype:'compete'
      }
    }
    this.http.post(url, parm).subscribe(
      (res: { data, code }) => {
        if (res.code === 0) {
         if(!this.iswechat){
            // 要带下个页面的参数
            let data = {
              ...typedata,
              amount_to_be_paid: res.data.amount_to_be_paid,
              order_no: res.data.order_no,
              snap_up_group_id: this.jonindata ? this.jonindata.snap_up_group_id : 0
            }
            let param = encodeURI(JSON.stringify(data))
            this.router.navigate(['/order-pay'], {
              queryParams: {
                param: param
              },
            }).then()
         }else{
          this.jsapiPay(res.data)
         }
        }
      }
    );
  }
  addressData
  /**
  * 从服务器拿默认地址
  */
  getDefaultAddress(): void {
    this.http.post('/shop/api/addressbydefault', { user_id: this.user.id })
      .subscribe((res: { data }) => {
        if (JSON.stringify(res.data) !== "{}") {
          this.addressdata = res.data;
          this.addressinfo = {
            name: this.addressdata.name,
            phone: this.addressdata.phone,
            address: `${this.addressdata.area}${this.addressdata.address}`
          }
        }
      })
  }
  // 竞抢协议
  goCompeteagreement() {
    window.location.href = 'https://assets.ddgeyou.com/web/competeagreement.html'
  }
  // 获取详情
  getrouteData() {
    // 获取详情
    this.http.post('/travel/api/getgroupactivityroutedetails', { group_activity_id: 30, group_route_id: 20 }).subscribe(
      (res: { data }) => {
        this.routedetails = res.data
        //dotsdata为轮播图下标循环的数组
        if (this.routedetails.video) {
          this.dotsdata.push(this.routedetails.video)
        }
        if (this.routedetails.imgs) {
          this.routedetails.imgs.forEach(element => {
            this.dotsdata.push(element)
          });
        }
      }
    );
  }
  // 选择地址
  chooseAddress() {
    window.localStorage.setItem('addressentrance', 'affirmoder')
    //this.router.navigateByUrl('/multiactive/myaddress')
    let parm
    if(this.purchbuy){
      // 立即购买
      parm={
        status:'purchbuy',
       }
    }else{
    if(this.jonindata){
      // 加入
      parm={
       status:'join',
       jonindata:this.jonindata
      }
    }else{
      // 发起
      parm={
        status:'spon',
       }
    }
    }
    this.router.navigate(['/multiactive/myaddress'], {
      queryParams: {
        param: JSON.stringify(parm)
      },
    }).then()
  }
  onBannerIndexChange(index: number): void {
    if (index === 1 && this.routedetails.video) {
      this.getVideopause()
    }
    this.bannerIndex = index;
  }
  @ViewChild('videoid') audioView: ElementRef;
  getVideopause() {
    //视频暂停
    const audio = this.audioView.nativeElement;
    audio.pause()
  }
}
