import { Component, OnInit } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';
import { Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-popmodel',
  templateUrl: './popmodel.component.html',
  styleUrls: ['./popmodel.component.less']
})
export class PopModelComponent implements OnInit {
  @Output() private outer = new EventEmitter()
  @Input() popdata

  constructor(
    private sanitizer: DomSanitizer
  ) { }

  // popdata-----参数说明
  // text 显示的文字
  // isCenter 文字是否居中显示 默认居中
  // isHtml 文字是否加载html
  // isOk 是否显示确认按钮 默认显示
  // isCancal 是否显示取消按钮 默认显示
  // cancelText 取消按钮文本
  // okText 确认按钮文本
  // data 要返回的参数
  // isMaskClose 点击遮罩层是否关闭弹窗 默认否
  // isClose 是否显示圆形关闭图标

  ngOnInit(): void {
    if (this.popdata.isHtml) {
      this.popdata.text = this.sanitizer.bypassSecurityTrustHtml(this.popdata.text);
    }
  }

  // 遮罩层点击关闭
  maskClick() {
    if (this.popdata.isMaskClose)
      this.outer.emit(-1)// 关闭弹窗
  }

  // 取消点击
  cancalClick() {
    this.outer.emit(this.popdata.isOk?this.popdata.data:-1)// 关闭弹窗
  }

  // 确认点击
  okClick(data) {
    this.outer.emit(data)
  }

}
