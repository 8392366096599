import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class InviteService {

    inviteId: string | null = sessionStorage.getItem('inviteId') || null;

    constructor() {
    }

    setId(id: string): void {
        this.inviteId = id;
        sessionStorage.setItem('inviteId', id)
    }

    getId(): string | null {
        return this.inviteId;
    }

}
