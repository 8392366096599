import { Component, OnInit } from '@angular/core';
import { Output,EventEmitter} from '@angular/core';
import {Input} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-compopmodel',
  templateUrl: './compopmodel.component.html',
  styleUrls: ['./compopmodel.component.less']
})
export class CompopmodelComponent implements OnInit {
  @Output() private outer = new EventEmitter()
  @Input() datatype;
  @Input() showmodelpopdata
  constructor(
    private  sanitizer: DomSanitizer
  ) { }
  // 设置文本框绑定的值
  fillinnumber
  // 文本框提示语
  fillinnumbertip='格式不正确'
  // 是否展示提示语
  showerr=false
  //专卖价格文本框默认值
  defaulttext
  ngOnInit(): void {
    if(this.showmodelpopdata){
      if(this.showmodelpopdata.text){
       this.areatext=this.showmodelpopdata.text;
      }
      if(this.showmodelpopdata.data){
        this.defaulttext='转卖价不低于'+this.showmodelpopdata.data.minimum_resale_price+'元'
      }
    }
    if(this.datatype==='itemresell'){
      this.showmodelpopdata.head= this.sanitizer.bypassSecurityTrustHtml(this.showmodelpopdata.head);
    }
    if(this.datatype==='spilltash'){
      this.showmodelpopdata.head= this.sanitizer.bypassSecurityTrustHtml(this.showmodelpopdata.head);
    }
    if(this.datatype==='newitemresell'){
      this.showmodelpopdata.head= this.sanitizer.bypassSecurityTrustHtml(this.showmodelpopdata.head);
    }
  }
  // 是否选择
  inputchecked=false;
  //蓝滴咚贝邀请弹窗内的勾选givebulechecked
  givebulechecked=false;
  // 打开区域选择器showareapop
  showareapop=false
  // 社区变更的值
  areatext
  // 转售弹窗的勾选
  changresell=true
  // 点击关闭
  close(){
    this.outer.emit('close')
  }
  //input框选择
  getinputchecked(){
    console.log('选择',this.inputchecked)
    if(this.inputchecked===false){
      localStorage.setItem('inputchecked', 'yes');
    }else{
      localStorage.setItem('inputchecked', 'no');
    }
  }
  //赠送好友蓝滴咚贝提示弹窗
  getgivebulechecked(){
    if(this.givebulechecked===false){
      localStorage.setItem('givebulechecked', 'yes');
    }else{
      localStorage.setItem('givebulechecked', 'no');
    }
  }
  // 弹窗操作
  operation(e){
    if(e===1){
      this.showareapop=true
    }else if(e===2){
      this.outer.emit(e)
    }else if(e===this.showmodelpopdata.incident){
      this.outer.emit(e)
    }else if(e==="seckillclose"
          ||e==="seckillagreement"
          ||e==="replaceinvite"
          ||e==="replacepurchase"){
      this.outer.emit(e)
    } else if(e==="allresell"){
      let data={
        type:'postResell',
        ifallchangresell:this.changresell
      }
      this.outer.emit(data)
    }else if(e==='input'){
      this.changeinput()
    }else if(e==='givebule'){
      console.log('赠送的',this.givebulechecked)
      this.outer.emit(e)
    }else {
      this.outer.emit(e)
    }
  }
  // 专卖价格文本框判断
  changeinput(){
    console.log('什么',this.fillinnumber,this.showmodelpopdata.data.minimum_resale_price)
    console.log(this.isSixdecimals(this.fillinnumber),'是啥')
    if(!this.fillinnumber){
      this.showerr=true;
      this.fillinnumbertip='转卖价不能为空!'
      return
    }else{
      this.showerr=false;
    }
    if(this.isSixdecimals(this.fillinnumber)===false){
      this.showerr=true;
      this.fillinnumbertip='格式不正确!'
      return
    }else{
      this.showerr=false;
    }
    if(Number(this.fillinnumber)>100000){
      this.showerr=true;
      this.fillinnumbertip='转卖价不高于10万元!'
      return
    }else{
      this.showerr=false;
    }
    if(Number(this.fillinnumber)<Number(this.showmodelpopdata.data.minimum_resale_price)){
      this.showerr=true;
      this.fillinnumbertip='转售价设置过低!'
      return
    }else{
      this.showerr=false;
    }
    if(this.showerr===false){
      let data={
        status:'input',
        number:this.fillinnumber
      }
      this.outer.emit(data)
    }
  }
    // 验证五个正整数带两个小数
  isSixdecimals (val) {
    const reg = /(?:^[-]?[1-9]\d{0,5}(?:$|(?:,\d{3})*(?:$|(\.\d{1,2}$))))|(?:(?:^[0](\.\d{1,2})?)|(?:^[-][0]\.\d{1,2}))$/;
    return reg.test(val);
  };
  getareaData(e){
    console.log('是',e)
    if(e.type==="1"){
      this.showareapop=false;
      this.areatext=e.data[e.data.length-1].name+' '+e.last.name;
      console.log('是多少',this.areatext)
    }else if(e==="close"){
      this.showareapop=false;
    }
  }
  // 文本框输入的值
  fillinChange(e){
    console.log('是啥',e,(document.getElementById("inputtalk") as HTMLInputElement).value)
    if((document.getElementById("inputtalk") as HTMLInputElement).value=='00'){
      (document.getElementById("inputtalk") as HTMLInputElement).value="0"
    }
  }
  //文本框内删除的值
  deleteinput(){
    this.fillinnumber = "";
    (document.getElementById("inputtalk") as HTMLInputElement).value = ""
  }
  // 转售弹窗的勾选
  changResellclick() {
    this.changresell = !this.changresell;
  }
}
