import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { UserInfoService } from "../user/user.service";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  token: string = sessionStorage.getItem('token') || '';
  redirectUrl: string;

  constructor(
    private router: Router,
    private user: UserInfoService
  ) {
  }

  public getToken = (): string => {
    return this.token || sessionStorage.getItem('token') || '';
  }

  get isLogin(): Boolean {
    return !!this.token
  }

  public setToken = (token: string) => {
    this.token = token;
    sessionStorage.setItem('token', token)
  }

  public isLoggedIn = (): boolean => {
    return !!this.getToken()
  }

  public logout = () => {
    this.token = '';
    sessionStorage.removeItem('token');
    this.user.logout();
    this.router.navigateByUrl('/user/register/phone').then()
  }
}
