<div class="popmodel_box">
    <div class="mask" (click)="maskClick()"></div>
    <div class="popmodel_center">
        <div class="center_close" *ngIf="popdata.isClose" (click)="cancalClick()"><img src="https://base-assets.ddgeyou.com/cstore/icon_close.png"/></div>
        <div class="center_text" [class]="{'align_center':!popdata.isCenter}" *ngIf="!popdata.isHtml">{{popdata.text}}</div>
        <div class="center_text" [class]="{'align_center':!popdata.isCenter}" *ngIf="popdata.isHtml" [innerHTML]="popdata.text"></div>
        <div class="center_bottom" *ngIf="!popdata.isTwo; else isTwoBtn" [class]="{'center_theme':popdata.isTwoTheme}">
            <div class="center_btn_cancal" [class]="{'wide_btn':popdata.isCancalWide,'cancel_white': popdata.isCancalWhite}" *ngIf="popdata.isCancal" (click)="cancalClick()">{{popdata.cancalText||'取消'}}</div>
            <div class="center_btn_confirm" [class]="{'wide_btn':popdata.isOkWide}" *ngIf="!popdata.isOk" (click)="okClick(popdata.data)">{{popdata.okText||'确认'}}</div>
        </div>
        <!-- 两个返回按钮上下并排样式 -->
        <ng-template #isTwoBtn>
            <div class="center_bottom center_bottom_two">
                <div class="center_btn_confirm" [class]="{'wide_btn':popdata.isOkWide}" (click)="okClick(popdata.data)">{{popdata.okText}}</div>
                <div class="center_btn_cancal" [class]="{'wide_btn':popdata.isCancalWide}" (click)="okClick(popdata.dataTwo)">{{popdata.cancalText}}</div>
            </div>
        </ng-template>
    </div>
</div>