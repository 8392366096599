import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router'
import { HttpClient } from "@angular/common/http";
import { ToastService } from 'ng-zorro-antd-mobile';
import { Title } from '@angular/platform-browser';
import { PlatformService } from 'src/app/code/service/platform/platform.service';
import { AuthService } from 'src/app/code/service/auth/auth.service';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.less']
})
export class DetailsComponent implements OnInit {

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    private _toast: ToastService,
    private title: Title,
    private platform: PlatformService,
    private auth: AuthService
  ) {
    title.setTitle('详情');
  }
  isIphoneX = false
  // 是否在微信环境(分享打开的)
  iswechat = false
  // 商品信息
  goodsData: any = {}
  isLogin: boolean
  // 批发配置id
  wholesale_config_id = null
  // 订单类型 1 自购 2 转卖
  order_type = 1

  ngOnInit(): void {
    this.isIphoneX = this.platform.CheckIsIphoneX()
    //版本兼容--防止微信打开id为空
    if (this.route.snapshot.params.goodsId) {//新版本
      const id = this.route.snapshot.params.goodsId
      if (this.route.snapshot.queryParams.iswechat || window.localStorage.getItem('iswechat')) {
        localStorage.setItem('iswechat','1')
        this.iswechat = true
        this.wholesale_config_id = this.route.snapshot.params.configId
        this.order_type = !this.wholesale_config_id ? 1 : 2
      }
      this.getrouteData(id)
    }
    this.isLogin = this.auth.isLoggedIn()
  }

  // 获取详情
  getrouteData(goods_id) {
    this.http.post('/cshop/api/getgoodsdetails', { goods_id: goods_id }).subscribe(
      (res: { data }) => {
        this.goodsData = res.data
      }
    );
  }

  homeClick() {
    this.platform.check(
      null,
      () => this.toHome(),
      () => this.toHome(),
      () => this.jsgoH5Home()
    );
  }

  // 返回首页
  toHome() {
    this.router.navigate(['/cstore/home'], { replaceUrl: true });
  }

  // h5返回首页
  jsgoH5Home() {
    if (!this.auth.getToken()) {
      this.auth.redirectUrl = this.router.url;
      this.router.navigateByUrl('/user/register/phone', { replaceUrl: true }).then();
    } else {
      this.toHome()
    }
  }

  // 点击提交 1正价 2定金
  buyClick(type) {
    this.goodsData.buy_type = type
    this.platform.check(
      null,
      () => this.toBuy(),
      () => this.toBuy(),
      () => this.jsgoH5()
    )
  }

  // h5判断是否登陆
  jsgoH5() {
    if (!this.auth.getToken()) {
      this.auth.redirectUrl = this.router.url;
      this.router.navigateByUrl('/user/register/phone', { replaceUrl: true }).then();
    } else {
      this.toBuy()
    }
  }

  // 去购买
  toBuy() {
    if (this.isLogin) {
      // 判断订金按钮是否开启存量统计
      // if(this.goodsData.buy_type==2 && this.goodsData.check_stock_rule){
      //   return this._toast.show('暂不支持定金购买')
      // }
      let data = {
        goods_id: this.goodsData.goods_id,
        buy_type: this.goodsData.buy_type,
        goods_name: this.goodsData.goods_name,
        price: this.goodsData.buy_type == 1 ? this.goodsData.price : this.goodsData.booking_price,
        service_number: this.goodsData.service_number,
        promotion_plan_id: this.goodsData.promotion_plan_id,
        wholesale_config_id: this.wholesale_config_id,
        order_type: this.order_type,
        goods_type: this.goodsData.goods_type,
        imgs:this.goodsData.imgs[0],
        express_ways: this.goodsData.express_ways.map(item => (Number(item)))
      }
      let param = encodeURI(JSON.stringify(data))
      param = encodeURIComponent(param) // 第二次加密
      this.router.navigate(['/cstore/ordersubmit'], {
        queryParams: {
          param: param,
        },
      }).then()
    } else {
      this.platform.check(
        null,
        () => window['android'].login(),
        () => window['webkit'].messageHandlers.login.postMessage(1),
        null
      )
    }
  }

}
