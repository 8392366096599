import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { HttpClient } from "@angular/common/http";
import { ToastService } from 'ng-zorro-antd-mobile';
import { ScrollService } from '../../../code/service/scroll/scroll.service';
import { PlatformService } from "../../../code/service/platform/platform.service";
import { AuthService } from "../../../code/service/auth/auth.service";
import { ActivatedRoute, Router } from '@angular/router'
@Component({
  selector: 'app-multiaactivehome',
  templateUrl: './multiaactivehome.component.html',
  styleUrls: ['./multiaactivehome.component.less']
})
export class MultiaactivehomeComponent implements OnInit {

  constructor(
    private title: Title,
    private router: Router,
    private http: HttpClient,
    private _toast: ToastService,
    private platform: PlatformService,
    private scroll: ScrollService,
    private auth: AuthService,
    private route: ActivatedRoute,
  ) {
    title.setTitle('竞抢区');
  }
  listdata = [
    { status: '1' },
    { status: '1' },
    { status: '1' }
  ]
  // 打开弹窗
  showmodel = false
  // 自子组件的数据
  modeldata = {
    type: 'activehome',
    number: 0,
    imglist: [],
    order_no: '',
    snap_up_group_id: ''
  }
  // 推荐商品数据
  multiainfo
  // 更多推荐活动列表
  multialist = []
  testlist = [{ type: '1' }, { type: '2' }]
  //是否显示到底了字段
  datahint = false
  page = 0
  page_size = 30
  // 是否在微信环境
  iswechat=false
  // 展示下载弹窗
  showdowun=false;
  // 竞抢中弹窗
  showjoinmodel=false
  isRefresh=true

  isIosShow = false

  ngOnInit(): void {
    // 清除一下数据
    window.localStorage.removeItem('addressentrance')
    this.getmultiainfo()
    this.getActivelist()
    // 平台框架兼容,如果sell字段存在，则是uniapp框架进来,并保存在本地告诉平台页面
    if (this.route.snapshot.queryParams.sell) {
      window.localStorage.setItem('sell', 'uniapp')
    } else {
      window.localStorage.setItem('sell', 'angular')
    }
    // iswechat=yes判断是否在h5环境
    if (this.route.snapshot.queryParams.iswechat) {
      this.iswechat=true
    }
    // 如果是冲支付成功满人情况进来的
    if (window.localStorage.getItem('competesucceed')) {
      let groupid = window.localStorage.getItem('competesucceed');
      this.getGroupdetails(JSON.parse(groupid))
      setTimeout(() => {
        window.localStorage.removeItem('competesucceed')
      }, 1000);
    }
    this.scroll.listen(() => {
      this.getActivelist()
    });
    // 以防万一，进入这个页面删除一下地址的相关缓存
    window.localStorage.removeItem('addressentrance')
    // 获取竞抢中列表
    if(!this.iswechat){
      this.getHotsnapuplist('')
    }
    // ios环境隐藏--审核用
    this.isIosShow = this.judgeIos()
  }

  // 判断是否是安卓环境
  judgeIos(){
    const ua = navigator.userAgent;
    return !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
  }

  // 如果是冲支付成功满人情况进来的
  getGroupdetails(e) {
    this.http.post('/seckill/api/getsnapupgroupdetails', { snap_up_group_id: e }).subscribe(
      (res: { data, code }) => {
        if (res.code === 0) {
          this.showmodel = true;
          this.modeldata.number = res.data.quantity
          this.modeldata.imglist = res.data.snap_up_user_list
          this.modeldata.order_no = res.data.order_no
          this.modeldata.snap_up_group_id = res.data.snap_up_group_id
        }
      }
    );
  }
  // 去开盲盒
  goBlindbox() {
    // 如果是h5环境则不可以点击
    if(this.iswechat===true){
      this.showdowun=true
      return
    }
    this.router.navigate(['/multiactive/blindbox'], {});
  }
  //去订单列表
  goOrderlist() {
     // 如果是h5环境则不可以点击
     if(this.iswechat===true){
      this.showdowun=true
      return
    }
    this.router.navigate(['/multiactive/order-list'], {});
  }
  // 去更多
  goMoreproduct() {
    let wechatdata
    if(this.iswechat){
      wechatdata={
        iswechat:'yes'
      }
    }
    this.router.navigate(['/multiactive/productlist'], {
      queryParams: {
        ...wechatdata
      },
    });
  }
  //奖品
  goMyprize() {
    // 如果是h5环境则不可以点击
    if(this.iswechat===true){
      this.showdowun=true
      return
    }
    this.router.navigate(['/multiactive/lotterydetils'], {});
  }
  // 获取推荐商品
  getmultiainfo() {
    this.http.post('/seckill/api/getsnapuprecommendgoods', {}).subscribe(
      (res: { data, code }) => {
        if (res.code === 0) {
          this.multiainfo = res.data
          this.multiainfo.noclustering = Number(this.multiainfo.quantity) - Number(this.multiainfo.successful_quantity)
          console.log('拿到了吗', this.multiainfo)
        } else if (res.code === 4801) {
          console.log('暂无推荐')
        }
      }
    );
  }
  // 获取抢购活动列表
  getActivelist() {
    this.page += 1
    this.http.post('/seckill/api/getsnapupactivitylist', { page: this.page, page_size: this.page_size }).subscribe(
      (res: { data, code }) => {
        if (res.code === 0) {
          // this.multialist=res.data.list
          if (res.data.list.length !== 0) {
            res.data.list.forEach(element => {
              this.multialist.push(element)
            });
          } else {
            if (this.page > 1 && this.multialist.length >= 4) {
              this.datahint = true
            }
          }
        }
      }
    );
  }
  // 去详情
  goDetails(e) {
    let wechatdata
    if(this.iswechat){
      wechatdata={
        iswechat:'yes'
      }
    }
    this.router.navigate(['/productdetails'], {
      queryParams: {
        snap_up_activity_id: e,
        sell:'uniapp',
        //platform: 'wechat',
        ...wechatdata
      },
    });
  }
  // 自组建传来值方法
  getModeldata(e) {
    this.showmodel = false
    console.log('打印出', e)
    if (e === 'examine') {
      console.log('查看竞抢结果')
      this.gocomDetails()
    }
  }
  // 看规则
  gocompeteRule() {
    window.location.href = 'https://assets.ddgeyou.com/web/competeactiverule.html'
  }
  // 查看竞抢结果
  gocomDetails() {
    this.router.navigate(['/multiactive/activeorder-details'], {
      replaceUrl: true,
      queryParams: {
        snap_up_group_id: this.modeldata.snap_up_group_id,
        order_no: this.modeldata.order_no
      },
    });
  }
  // 主页去分享
  goHomeshare(){
    // @ts-ignore
    window.uni.postMessage({
      data: {
        type: 'comhomeshare',
      }
    });
  }
  godounApp(){
    window.location.href = 'https://assets.ddgeyou.com/ycindex.html'
  }
  ycclose(){
    this.showdowun=false
  }
  // 点击展开弹窗
  goJoninmode(){
    this.showjoinmodel=true;
  }
  // 竞抢列表弹窗返回值
  getjoinModel(e){
    if(e.type==='close'){
      this.showjoinmodel=false
    }else if(e.type==='join'){
      this.goOrderconfirm(e.info)
    }
  }
  // 去订单页面
  goOrderconfirm(e){
    let param = encodeURI(JSON.stringify(e))
    param = encodeURIComponent(param) // 第二次加密
    this.router.navigate(['/multiactive/order-confirm'], {
      queryParams: {
        param:param,
      },
    }).then()
  }
  hotsdata=[]
  // 火热竞抢中列表
  getHotsnapuplist(e) {
    this.http.post('/seckill/api/hotsnapuplist', {}).subscribe(
      (res: { data, code }) => {
        if (res.code === 0) {
          if(JSON.stringify(res.data)!=="{}"){
            this.hotsdata = res.data
          }
          if(e==='refresh'){
            if (this.isRefresh) {
              this.isRefresh = false
              setTimeout(() => {
                this.isRefresh = true
              }, 2000);
            }
          }
          console.log('加入中的值', this.hotsdata)
        } 
      }
    );
  }
  // 刷新抢购进行中团队
  refreshOther() {
    this.getHotsnapuplist('refresh')
  }
}
