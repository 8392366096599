<div class="mcommodel">
    <div class="commodel-all">
        <!-- 订单确认取货弹窗 -->
        <div class="model-item" *ngIf="modeldata.type==='orderlist'">
            <div class="title">{{modeldata.title}}</div>
            <div class="button">
                <div class="button-item" (click)="operation('close')">{{modeldata.buttonone}}</div>
                <div class="button-item confirm" (click)="operation('confirm')">{{modeldata.buttontwo}}</div>
            </div>
        </div>
        <!-- h5详情点击兑换点券 -->
        <div class="model-item" *ngIf="modeldata.type==='coupon'">
            <div class="title">{{modeldata.title}}</div>
            <div class="button">
                <div class="button-item" (click)="operation('close')">{{modeldata.buttonone}}</div>
                <div class="button-item confirm" (click)="operation('coupon')">{{modeldata.buttontwo}}</div>
            </div>
        </div>
        <!--人工确认取货 -->
        <div class="model-item claimgoods" *ngIf="modeldata.type==='artclaimgoods'">
            <div class="title left">{{modeldata.title}}</div>
            <div class="centen art" (click)="operation('claimgoodsno')">
                    <div class="text">{{modeldata.centen.centen}}</div>
            </div>
            <div class="button">
                <div class="button-item" (click)="operation('close')">{{modeldata.buttonone}}</div>
                <div class="button-item confirm" (click)="operation('artclaimgoods')">{{modeldata.buttontwo}}</div>
            </div>
        </div>
        <!--推广补领 -->
        <div class="model-item replace" *ngIf="modeldata.type==='replace'">
            <div class="head">提示</div>
            <div class="title left" [innerHTML]="modeldata.title">
            </div>
            <div class="button">
                <div class="button-item" (click)="operation('close')">{{modeldata.buttonone}}</div>
                <div class="button-item confirm" (click)="operation('confirm')">{{modeldata.buttontwo}}</div>
            </div>
        </div>
        <!--天天领金贝里面的推广 -->
        <div class="model-item replace" *ngIf="modeldata.type==='promotion'">
            <div class="head">提示</div>
            <div class="title left" [innerHTML]="modeldata.title">
            </div>
            <div class="button">
                <div class="button-item" (click)="operation('close')">{{modeldata.buttonone}}</div>
                <div class="button-item confirm" (click)="operation('confirm')">{{modeldata.buttontwo}}</div>
            </div>
        </div>
        <!-- 确认换购提示-->
        <div class="model-item" *ngIf="modeldata.type==='optimization'">
            <div class="head">提示</div>
            <div class="title left" [innerHTML]="modeldata.title"></div>
            <div class="button">
                <div class="button-item" (click)="operation('close')">{{modeldata.buttonone}}</div>
                <div class="button-item confirm" (click)="operation('optimization')">{{modeldata.buttontwo}}</div>
            </div>
        </div>
        <!-- 有效期已过期-->
        <div class="model-item" *ngIf="modeldata.type==='selection'">
            <div class="title left" [innerHTML]="modeldata.title"></div>
            <div class="button">
                <div class="button-item" (click)="operation('close')">{{modeldata.buttonone}}</div>
                <div class="button-item confirm" (click)="operation('selection')">{{modeldata.buttontwo}}</div>
            </div>
        </div>
               <!-- 消费点券不足，不能下单-->
        <div class="model-item inform" *ngIf="modeldata.type==='inform'">
            <div class="head">提示</div>
            <div class="title left">{{modeldata.title}}</div>
            <div class="button">
                <div class="button-item inform" (click)="operation('close')">{{modeldata.buttonone}}</div>
            </div>
        </div>
    </div>
</div>