import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from 'ng-zorro-antd-mobile';
import { ComputedService } from 'src/app/code/service/computed/computed.service';
import { PlatformService } from 'src/app/code/service/platform/platform.service';
import { ScrollService } from 'src/app/code/service/scroll/scroll.service';
import { WechatService } from 'src/app/code/service/wechat/wechat.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-buy-ticket',
  templateUrl: './buy-ticket.component.html',
  styleUrls: ['./buy-ticket.component.less']
})
export class BuyTicketComponent implements OnInit {

  constructor(
    private title: Title,
    private router: Router,
    private route: ActivatedRoute,
    private _toast: ToastService,
    private http: HttpClient,
    private platform: PlatformService,
    private computed: ComputedService,
    private wechat: WechatService,
    private scroll: ScrollService,
  ) {
    title.setTitle('购买易券');
  }

  dataList = [2500,5000,10000,20000,50000,100000]

  itemIndex = 0

  isAndroid = false
  // 最大输入值
  limitMaxNum: any = 999999
  // 最小输入值
  limitMinNum = 1
  // 比例
  limitScaleNum = 1
  // 输入提示内容
  inputPlaceholder = ''
  // 输入的值
  inputNum = null

  isChecked = false

  isShowPop = false
  // 选择的支付方式--默认0
  payWayIndex = 0
  // 支付类型数组
  payWayArr = []
  // 平台环境
  envPlatform = 'app'
  // 待支付金额
  toPaidMoney = 0
  // 支付订单号
  orderNo = null
  // 弹窗参数
  popData: any = {}

  ngOnInit(): void {
    this.isAndroid = this.platform.CheckIsAndroid()
    this.getData()
    this.nowEnvironment()
    this.getPayEnv()
    // 支付成功和失败的回调
    const w = window as any;
    w.notifyWebPaySuccess = this.notifyWebPaySuccess.bind(this)
    w.notifyWebPayError = this.notifyWebPayError.bind(this)
    w.wechatSeverPaySuccess = this.wechatSeverPaySuccess.bind(this)// 走微信支付的回调
  }

  ngOnDestroy(): void {
    const w = window as any;
    delete w.notifyWebPaySuccess;
    delete w.notifyWebPayError;
    delete w.wechatSeverPaySuccess;
  }

  // app回调支付成功
  notifyWebPaySuccess(){
    this.paySuccess()
  }
  // app回调支付失败
  notifyWebPayError(){}
  // app走微信服务商失败和成功的回调
  wechatSeverPaySuccess(){
    this.paySuccess()
  }

  // 获取输入框的精度
  getInputScale(scale){
    if(!scale)return
    this.limitScaleNum = scale.split(':')[1] / scale.split(':')[0]
    this.limitMinNum = Math.ceil(this.limitScaleNum * 0.01)
    this.limitMaxNum = ~~(this.limitScaleNum) > 500 ? 10000000 : ~~(this.limitScaleNum * 20000) 
    this.inputPlaceholder = `输入采购数量(${this.limitMinNum}~${this.limitMaxNum})`
    this.getPaymentAmount(this.dataList[this.itemIndex])
  }

  // 获取支付环境
  getPayEnv(){
    this.http.post('/moxibustion/api/getherbalpayenv', {  }).subscribe(
      (res: { data }) => {
        this.payWayArr = res.data
        this.payWayIndex = this.payWayArr.filter(item => item.env === this.envPlatform).map(item=>item.type)[0]
      }
    )
  }

  //当前环境判断
  nowEnvironment() {
    this.platform.check(
      null,
      () => this.envPlatform = 'app',
      () => this.envPlatform = 'app',
      () => this.envPlatform = 'h5'
    );
  }

  // 选择支付方式
  paywayClick(index){
    this.payWayIndex = index
  }

  // 获取数据
  getData() {
    this.http.post('/moxibustion/api/getmerchantsyiticketinfo', {}).subscribe(
      (res: any) => {
        this.getInputScale(res.data.cash_to_yi_ticket_ratio)
      }
    )
  }

  // 数量点击
  itemClick(index,item){
    if(!this.inputPlaceholder)return
    this.itemIndex = index
    this.getPaymentAmount(item)
  }

  // 手动输入值变更
  modelChange(e){
    if(e)
      e = this.inputNum = e.toString().replace(/\D/g, '')
    if (Number(e) > Number(this.limitMaxNum)) {
      (document.getElementById("inputVal") as HTMLInputElement).value = this.limitMaxNum
      this.inputNum = Number(this.limitMaxNum)
    }else{
      this.inputNum = e?Number(e):null
    }
    this.getPaymentAmount(this.inputNum)
  }

  modelBlur(e: Event){
    const input = e.target as HTMLInputElement
    if(input.value){
      const val = parseFloat(input.value)
      if(isNaN(val)){
        (document.getElementById("inputVal") as HTMLInputElement).value = (val>this.limitMinNum?val:this.limitMinNum)+''
        this.inputNum = val>this.limitMinNum?val:this.limitMinNum
      }
    }
    this.getPaymentAmount(this.inputNum)
  }

  // 计算获取待支付金额
  getPaymentAmount(num){
    if(!num){
      this.toPaidMoney = 0
      return
    }
    const count = this.computed.subtract(num,this.limitScaleNum,4)
    this.toPaidMoney = count%1===0?count:count.toFixed(2)
  }

  // 协议点击
  agreementClick(){
    window.location.href = `${environment.h5Url}travel_procure.html`
  }

  // 购买记录
  recordClick(){
    this.router.navigate(['/simpleherbalzone/entitybuyrecord'], { })
  }

  // 购买
  buyClick(){
    if(!this.inputPlaceholder)return
    if(this.toPaidMoney<=0){
      this._toast.show('请输入采购数量')
      return
    }
    if(!this.isChecked){
      this._toast.show('请先同意和合易券采购协议')
      return
    }
    const buy_yi_ticket = this.itemIndex==6?this.inputNum:this.dataList[this.itemIndex]
    this.http.post('/moxibustion/api/merchantsbuyyiticketsubmitorder', {buy_yi_ticket}).subscribe(
      (res: any) => {
        this.orderNo = res.data.order_no
        this.isShowPop = true
      }
    )
  }

  // 确认支付
  payClick(){
    this.platform.check(
      null,
      () => this.appPay(),
      () => this.appPay(),
      () => this.jsapiPay()
    )
  }

  // app支付
  appPay(){
    if(this.orderNo){
      const dataparam = {
        order_no: this.orderNo,
        pay_type: this.payWayIndex,
        client_type: 3
      }
      this.http.post('/moxibustion/api/merchantsbuyyiticketorderpaymentsignature', dataparam).subscribe((res: any) => {
        if (res.code === 0) {// pay_type是支付为0，不跳支付
          this.isShowPop = false
          if (res.data.pay_type === 0) {
            this.paySuccess()
          } else {
            this.showPopUp()
            if (res.data.pay_type === 2 || res.data.pay_type === 10) {//支付宝原生支付
              this.platform.check(
                null,
                () => window['android'].aliPayOther(res.data.order_no, res.data.body),
                () => window['webkit'].messageHandlers.aliPayOther.postMessage(JSON.stringify(res.data)),
                null
              );
            } else if(res.data.pay_type === 11 || res.data.pay_type === 12){
              this.platform.check(
                null,
                () => window['android'].openBrowser(res.data.paySignData),
                () => window['webkit'].messageHandlers.openBrowser.postMessage(res.data.paySignData),
                null
              );
            } else {
              this.platform.check(
                null,
                () => window['android'].aliPayFast(res.data.order_no, res.data.paySignData),
                () => window['webkit'].messageHandlers.aliPayFast.postMessage(JSON.stringify(res.data)),
                null
              );
            }
          }
        } else if (res.code === 4711) {
          this._toast.show(res.msg)
          setTimeout(() => {
            history.go(-1)
          }, 1000);
        }
      });
    }
  }

  // h5支付
  jsapiPay(){
    if (this.orderNo) {
      const dataparam = {
        order_no: this.orderNo,
        pay_type: this.payWayIndex,
        client_type: 1,
        open_id: localStorage.getItem('openId')
      }
      this.http.post('/moxibustion/api/merchantsbuyyiticketorderpaymentsignature', dataparam).subscribe((res: any) => {
        this.isShowPop = false
        if (res.data.pay_type === 0) {//全额抵扣
          this.paySuccess()
        }else{// 支付
          this.showPopUp()
          if(res.data.pay_type===1){
            this.wechat.pay(res.data, res.data.order_no, () => {
              if (window.localStorage.getItem('paymentstatus')) {// 微信取消支付
                window.localStorage.removeItem('paymentstatus');
                this._toast.fail('支付失败')
              } else {// 微信h5支付--->跳转成功无法带参数
                this.paySuccess()
              }
            })
          } else if(res.data.pay_type === 11){
            window.location.href = res.data.paySignData;
          } else if(res.data.pay_type === 12){
            this.router.navigate(['/simpleherbalzone/alipayh5'], { queryParams: { orderNo: encodeURIComponent(res.data.paySignData) } }).then()
          }
        }
      })
    }
  }

  // 显示支付弹窗公共方法
  showPopUp(){
    this.popData = {
      isShow: true,
      data: 1,//完成支付
      text: '请在微信或支付宝内完成支付，如您已支付成功，请点击“完成支付”按钮',
      okText: '完成支付'
    }
  }

  // 发起支付后查询订单支付状态
  queryOrderStatus(){
    this.http.post('/moxibustion/api/getmerchantsbuyyiticketorderstatusafterinitiatepayment', { order_no: this.orderNo }).subscribe(
      (res: { data }) => {
        if(res.data.pay_status){
          this.paySuccess()
        }else{
          this._toast.fail('支付失败')
        }
      }
    )
  }

  // 弹窗确认返回
  getReturnData() {
    this.popData.isShow = false
    this.queryOrderStatus()
  }

  paySuccess(){
    this._toast.show('购买成功')
    setTimeout(() => {
      history.go(-1)
     }, 500);
  }

}
