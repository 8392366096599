import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router'
import { HttpClient } from "@angular/common/http";
import { ToastService } from 'ng-zorro-antd-mobile';
import { WechatService } from '../../../code/service/wechat/wechat.service';
import { Title } from '@angular/platform-browser';
import { AuthService } from "../../../code/service/auth/auth.service";
import { UserInfoService } from "../../../code/service/user/user.service";
import { PlatformService } from "../../../code/service/platform/platform.service";
@Component({
  selector: 'app-ydgivedetails',
  templateUrl: './ydgivedetails.component.html',
  styleUrls: ['./ydgivedetails.component.less']
})
export class YdgivedetailsComponent implements OnInit {

  constructor(
    private title: Title,
    private router: Router,
    private _toast: ToastService,
    private http: HttpClient,
    public wechat: WechatService,
    private route: ActivatedRoute,
    private auth: AuthService,
    private platform: PlatformService,
    private user: UserInfoService) {
    title.setTitle('购物送易点');
  }
  // 活动线路详情
  routedetails;
  // 轮播图的下标
  dotsdata = []

  //商品id
  easy_point_goods_config_id
  // 是否在微信环境
  iswechat = false
  ngOnInit(): void {
      this.router.navigate(['/ydgivedetails'], {
        queryParams: {
          ...this.route.snapshot.queryParams,
        },
        replaceUrl:true
      });
      let routprams = this.route.snapshot.queryParams;
      if (routprams.data) {
        this.easy_point_goods_config_id = JSON.parse(routprams.data).easy_point_goods_config_id
        this.getrouteData(this.easy_point_goods_config_id)
      }
      // iswechat=yes判断是否在h5环境
      if (this.route.snapshot.queryParams.iswechat) {
        this.iswechat = true;
      }
      // 平台框架兼容,如果sell字段存在，则是uniapp框架进来,并保存在本地告诉平台页面
      if (this.route.snapshot.queryParams.sell) {
        window.localStorage.setItem('sell', 'uniapp')
      }
      // 这里写微信分享页的时路由带的参数
      if (routprams.easy_point_goods_config_id) {
        this.easy_point_goods_config_id = routprams.easy_point_goods_config_id
        this.getrouteData(this.easy_point_goods_config_id)
      }
      if (this.route.snapshot.queryParams.uniappdata) {
        window.localStorage.setItem('uniappdata', 'yes')
      }
      window.localStorage.setItem('sell', 'uniapp')
  }

  // 获取详情
  getrouteData(e) {
    // 获取详情
    this.http.post('/seckill/api/getbuygoodsgiveeasypointdetails', { easy_point_goods_config_id: e }).subscribe(
      (res: { data }) => {
        this.routedetails = res.data
        //dotsdata为轮播图下标循环的数组
        if (this.routedetails.imgs) {
          this.routedetails.imgs.forEach(element => {
            this.dotsdata.push(element)
          });
        }
      }
    );
  }
  // 点击去购买按钮
  gocomPurch(){
    this.platform.check(
      null,
      () => this.goPurchase(),
      () => this.goPurchase(),
      () => this.jsgoH5()
    );
  }
  // h5判断是否登陆
  jsgoH5(){
    if (!this.auth.getToken()) {
      this.auth.redirectUrl = this.router.url;
      this.router.navigateByUrl('/user/register/phone').then();
    }else{
      this.goPurchase()
    }
  }
  // 去购买
  goPurchase() {
    this.routedetails.hometype = 'purchase'
    let param = encodeURI(JSON.stringify(this.routedetails))
    param = encodeURIComponent(param) // 第二次加密
    //this.router.navigate(['/newgoldactive/order-confirm' + '/' + param]).then()
    this.router.navigate(['/ydorder-confirm'], {
      queryParams: {
        param: param,
      },
    }).then()
  }
  // 去分享
  goDetailsshare() {
    let activityid = {
      easy_point_goods_config_id: this.easy_point_goods_config_id
    }
    // @ts-ignore
    window.uni.postMessage({
      data: {
        type: 'ydgiveshare',
        data: JSON.stringify(activityid)
      }
    });
  }
  //滑动锚点
  bannerIndex = 0
  onBannerIndexChange(index: number): void {
    this.bannerIndex = index;
  }
}

