import { Component, OnInit } from '@angular/core';
import { Output,EventEmitter} from '@angular/core';
import {Input} from '@angular/core';
@Component({
  selector: 'app-joinmodel',
  templateUrl: './joinmodel.component.html',
  styleUrls: ['./joinmodel.component.less']
})
export class JoinmodelComponent implements OnInit {
  @Output() private joinouter = new EventEmitter()
  @Input() joindata
  constructor() { }

  ngOnInit(): void {
    console.log('是什么',this.joindata)
  }
  // 子组件的点击事件
  operation(e){
    let data={
      type:'join',
      info:e
    }
    this.joinouter.emit(data)
  }
  close(){
    let data={
      type:'close'
    }
    this.joinouter.emit(data)
  }
}
