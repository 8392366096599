<app-commodel *ngIf="showmodel" (outer)="getModeldata($event)" [modeldata]="modeldata"></app-commodel>
<div class="shopdetails" *ngIf="routedetails">
    <div class="head">
        <div class="banner-view">
            <Carousel *ngIf="routedetails.imgs.length>0" [infinite]="true" [dots]="false"
                style='height: 100vw;object-fit: cover;' (afterChange)="onBannerIndexChange($event)">
                <CarouselSlide dotPosition='top' dots *ngFor="let item of routedetails.imgs">
                    <img [src]="item+'?x-oss-process=image/resize,w_800,h_800,m_lfit'" alt="" style='height: 100vw;object-fit: cover;'>
                </CarouselSlide>
            </Carousel>
            <div class="dots-all" *ngIf="dotsdata.length>1">
                <div class="dots">
                    <div *ngFor="let item of dotsdata;let index = index"
                        [ngClass]="{active:bannerIndex==index,noactive:bannerIndex!==index}"></div>
                </div>
            </div>
        </div>
    </div>
    <div class="title-info">
        <div class="identifying"><img src="https://base-assets.ddgeyou.com/newgold/golddetaititle.png" />
            <div class="rule" >
                <div (click)="goRule()" *ngIf="isPromotion">规则</div>
            </div>
        </div>
        <div class="all">
            <div class="shoptitle omits"><label
                    *ngIf="routedetails.grade_type>0">等级{{routedetails.grade_type}}</label>{{routedetails.goods_name}}
            </div>
            <div class="priceinfo">
                <div class="price-current"><label>￥</label>{{routedetails.selling_price}}</div>
                <div class="original">原价:<label>{{routedetails.scribing_price}}</label></div>
            </div>
            <div class="otherinfo">
                <!-- <div class="express"><label *ngIf="!isPromotion">配送方式：快递</label></div> -->
                <div class="sales">销量：{{routedetails.sales}}</div>
            </div>
        </div>
    </div>
    <div class="shop-info">
        <div class="header-title"><label class="titleicon"></label>金贝优选福利</div>
        <div class="award-info">
            <div class="award-item" *ngIf="!isPromotion">
                <div class="left">
                    <div class="img"><img src="https://base-assets.ddgeyou.com/newgold/promotion.png" /></div>
                    <div class="text">
                        <label>{{routedetails.need_gold}}</label>金贝转<label>{{routedetails.reback_money}}</label>元现金 +
                        <label>{{routedetails.reback_tickets}}</label>
                        <span *ngIf="routedetails.reback_tickets_type===1">
                            消费点券
                        </span>
                        <span *ngIf="routedetails.reback_tickets_type===2">
                            旅游点券
                        </span>
                    </div>
                </div>
                <div class="right">
                    <div class="button" (click)="goPromotion()">推广</div>
                </div>
            </div>
            <div class="award-item">
                <div class="left">
                    <div class="img"><img src="https://base-assets.ddgeyou.com/newgold/purchase.png" /></div>
                    <div class="text">赠送<label>{{routedetails.buy_give_gold}}</label>金贝 +
                        <label>{{routedetails.buy_reback_tickets}}</label>
                        <span *ngIf="routedetails.buy_reback_tickets_type===1">
                            消费点券
                        </span>
                        <span *ngIf="routedetails.buy_reback_tickets_type===2">
                            旅游点券
                        </span>
                    </div>
                    <div class="text2">(<span *ngFor="let item of routedetails.privilege_title;let index=index">
                            <span *ngIf="!index==0">、</span>{{item}}
                        </span>)</div>
                </div>
                <div class="right">
                    <div class="button" (click)="goPurchase()">购买</div>
                </div>
            </div>
        </div>
    </div>
    <div class="shop-info white" *ngIf="routedetails.tickets_goods.length>0">
        <div class="header-title"><label class="titleicon"></label>消费点券兑换</div>
        <div class="recommend-center">
            <div class="recommend-shop" *ngFor="let item of routedetails.tickets_goods;let index=index"
                (click)="goshopDetails(item)">
                <div class="img">
                    <img [src]="item.img" alt="" onerror="this.src='https://base-assets.ddgeyou.com/gold/defult.png'" />
                </div>
                <div class="shoptitle omits">{{item.name}}</div>
                <div class="price">
                    <img src="https://base-assets.ddgeyou.com/newgold/conversion.png" />
                    {{item.need_tickets}}点券
                </div>
            </div>
        </div>
    </div>
    <div class="shop-info">
        <div class="header-title" style="margin-bottom: 1.5rem;"><label class="titleicon"></label>商品详情</div>
        <div class="detail-view">
            <div class="content">
                <ng-template ngFor let-item [ngForOf]="routedetails.details">
                    <p *ngIf="item.type === 1">{{ item.content }}</p>
                    <div *ngIf="item.type === 2">
                        <div *ngIf="item.url">
                            <a [href]="item.url">
                                <img [src]="item.content" alt="" />
                            </a>
                        </div>
                        <div *ngIf="!item.url">
                            <img [src]="item.content" alt="" />
                        </div>
                    </div>
                    <video controls *ngIf="item.type === 3" [src]="item.content"></video>
                </ng-template>
            </div>
        </div>
    </div>
    <div class="shop-footer">
        <div class="shop-footer-all" *ngIf="!isPromotion">
            <div class="buttonone" (click)="goPromotion()">
                分享推广
            </div>
            <div class="buttontwo" (click)="goPurchase()">
                <div>
                    <div class="text1">购买</div>
                    <div class="text2">限时送{{routedetails.buy_reback_tickets}}
                        <span *ngIf="routedetails.buy_reback_tickets_type===1">
                            消费点券
                        </span>
                        <span *ngIf="routedetails.buy_reback_tickets_type===2">
                            旅游点券
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="shop-footer-all" *ngIf="isPromotion">
            <div class="buttontwo" style="width:100%" (click)="goPurchase()">
                <div>
                    <div class="text1">购买</div>
                    <div class="text2">限时送{{routedetails.buy_reback_tickets}}
                        <span *ngIf="routedetails.buy_reback_tickets_type===1">
                            消费点券
                        </span>
                        <span *ngIf="routedetails.buy_reback_tickets_type===2">
                            旅游点券
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="add-model" *ngIf="showdetailsmodel&&isPromotion&&routedetails">
    <div class="add-model-item">
        <div class="model-conten">
            <div class="conten-all">
                <div class="bg">
                    <img src='https://base-assets.ddgeyou.com/newgold/diamond.png' />
                </div>
                <div class="conten">
                    <div class="text">现在下单，限时赠送：</div>
                    <div class="property-all">
                        <div class="property-item" *ngIf="routedetails.buy_give_gold>0">
                            <img src="https://base-assets.ddgeyou.com/newgold/gold.png" />
                            <label>{{routedetails.buy_give_gold}}</label>
                            金贝
                        </div>
                        <div class="property-item" *ngIf="routedetails.buy_reback_tickets>0">
                            <img src="https://base-assets.ddgeyou.com/newgold/point.png" />
                            <label>{{routedetails.buy_reback_tickets}}</label>
                            <span *ngIf="routedetails.buy_reback_tickets_type===1">
                                消费点券
                            </span>
                            <span *ngIf="routedetails.buy_reback_tickets_type===2">
                                旅游点券
                            </span>
                        </div>
                    </div>
                    <div class="button" (click)="closemodel()">商品详情</div>
                </div>
            </div>
            <div class="closemodel">
                <img src="https://base-assets.ddgeyou.com/newgold/closemodel.png" (click)="closemodel()" />
            </div>
        </div>
    </div>
</div>