<!--<Drawer class="my-drawer">-->
<!--    position="bottom"-->
<!--    [sidebar]="sidebar"-->
<!--    [open]="sidebarWindowStatus"-->
<!--    (onOpenChange)="changeSidebarWindowStatus()">-->
<div *ngIf="detail">
    <div class="container">

        <div class="top-view">
            确认订单
        </div>

        <!-- 商品详情 -->
        <div class="detail-view">
            <div class="detail-image">
                <img [src]="detail.img.file_url" alt="">
            </div>
            <div class="detail-content">
                <p class="route-title"><span class="tag guide-tag" *ngIf="detail.owner_type === 1">达人线路</span><span class="tag travel-agency-tag" *ngIf="detail.owner_type === 2">旅社优选</span> {{ detail.route_title }}</p>
                <p class="route-destination">目 的 地 ：{{ detail.destination }}</p>
                <p class="route-days">行程天数：{{ detail.days }}天</p>
            </div>
            <div class="route-price">
                <p class="price-text"
                   *ngIf="detail.owner_type === 1 || (detail.owner_type === 2 && guideInfo.guide_id && type === 2) || (detail.owner_type === 2 && !guideInfo.guide_id)">{{ guideInfo.selling_price }}</p>
                <p class="price-text"
                   *ngIf="detail.owner_type === 2 && guideInfo.guide_id && type === 1">{{ guideInfo.selling_price - guideInfo.need_to_add_price }}</p>
                <p>X{{ travelerInfo.traveler_people_amount }}</p>
            </div>
        </div>

        <!--    导游信息-->
        <div class="guide-view">
            <div class="guide-info">
                <p>导游信息</p>
                <div class="default-guide" *ngIf="!guideInfo.guide_id">
                    <p *ngIf="detail.is_has_default_guide == 0">自由行无导游</p>
                    <p *ngIf="detail.is_has_default_guide == 1">默认导游</p>
                </div>
                <div class="guide-avatar" *ngIf="guideInfo.guide_id">
                    <img [src]="guideInfo.avatar" alt="">
                    <p>{{ guideInfo.name }}</p>
                </div>
            </div>
            <div class="guide-price" *ngIf="guideInfo.guide_id && detail.owner_type === 2">
                <p class="price-text">{{ guideInfo.need_to_add_price }}</p>
                <p>专属导游服务费</p>
            </div>
        </div>

        <div class="card-info">
            <div class="order-title">
                <p>订单信息</p>
            </div>
            <div class="left-bottom"></div>
            <div class="right-bottom"></div>
            <div class="order-other-info">
                <p>出行日期：<span>{{ travelerInfo.travel_date }}</span></p>
                <p>出行人数：<span>{{ travelerInfo.traveler_people_amount }}人</span></p>
                <p>联系电话：<span>{{ travelerInfo.contact_phone }}</span></p>
            </div>
        </div>

        <div class="card-info">
            <div class="order-title">
                <p>出行人信息</p>
            </div>
            <div class="left-bottom"></div>
            <div class="right-bottom"></div>
            <div class="traveler-list">
                <div class="flex"  *ngFor="let item of travelerInfo.traveler_list">
                    <p>{{ item.name }}</p>
                    <p>{{ item.id_number }}</p>
                </div>

            </div>
        </div>

        <div class="message">
            <div class="message-title">留言</div>
            <textarea placeholder="输入内容" [(ngModel)]="message" (ngModelChange)="getMessage($event)" [maxLength]="messageMaxLength"></textarea>
            <span>{{ this.messageMinLength }}/{{ this.messageMaxLength }}</span>
        </div>

        <div class="agree-item flex justify-between">
            <p class="agree-icon" [ngClass]="{agreensuccess: agreementStatus}" (click)="clickAgreementStatus()"></p>
            <p class="agree" (click)="clickAgreementStatus()">已阅读并同意《滴咚个游旅游服务协议》</p>
        </div>

    </div>

    <div class="choice-confirm flex justify-between">
        <p>合计</p>
        <p class="price-text">{{ totalPrice }}</p>
        <button (click)="goToPay()">去付款</button>
    </div>
</div>

