<div class="container">
    <p class="title">付款金额</p>
    <p style="font-size: 30px;">¥ <span style="font-size: 60px;">100.00</span></p>

    <button type="button" class="pay-button" (click)="pay()">付款</button>

<!--    <div class="item flex align-center justify-between">-->
<!--        <p class="">¥</p>-->
<!--        <p class="link align-center"-->
<!--           (click)="pay()">-->
<!--            去支付-->
<!--&lt;!&ndash;            <Icon [type]="'right'" color="#006AEF" size="16"></Icon>&ndash;&gt;-->
<!--        </p>-->
<!--    </div>-->
</div>
