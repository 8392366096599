import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-avatar',
    templateUrl: './avatar.component.html',
    styleUrls: ['./avatar.component.less']
})
export class AvatarComponent implements OnInit {
    @Input() name: string;
    @Input() avatar: string;
    // 0 未邀请没图标 1 未邀请有图标 2 已有邀请人 3 无法邀请
    @Input() status: number;

    constructor() {
    }

    ngOnInit(): void {
    }
}
