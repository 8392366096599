import { Component, OnInit, NgZone, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router'
import { HttpClient } from "@angular/common/http";
import { ToastService } from 'ng-zorro-antd-mobile';
import { WechatService } from '../../../../code/service/wechat/wechat.service';
import { Title } from '@angular/platform-browser';
import { AuthService } from "../../../../code/service/auth/auth.service";
type orderInfo = {
  order_no: string,
  body: string,
  pay_type: number
};
@Component({
  selector: 'app-newuser-special',
  templateUrl: './newuser-special.component.html',
  styleUrls: ['./newuser-special.component.less']
})

export class NewuserSpecialComponent implements OnInit {
  // 社区信息
  seckillinfo
  // 弹窗值展示
  showmodelpop = false
  //弹窗类型
  datatype = 'jurisdiction'
  //弹窗要传的值
  showmodelpopdata = {}
  // 滚动条节点
  @ViewChild('text1') text1: ElementRef;
  @ViewChild('text2') text2: ElementRef;
  constructor(
    private title: Title,
    private router: Router,
    private _toast: ToastService,
    private http: HttpClient,
    public wechat: WechatService,
    private route: ActivatedRoute,
    private ngZone: NgZone,
    private auth: AuthService,
    private el: ElementRef,
  ) {
    title.setTitle('体验专场');
  }
  // 字体样式
  fontclass
  //  判断如果是分享页面进来出来弹窗
  formintroduce = false
  // 判断是分享页面进来切关闭了抢购机会的弹窗字段
  showcontseckill = true
  //免抢购购买按钮防抖节流
  buttonfalse = true
  //订单信息
  unpaidinfo
  //是否下载弹窗
  showdownapp = false
  // 判断是否易场
  ifyichang = false
  ngOnInit(): void {
    let routprams;
    if (window.localStorage.getItem('introducedata')) {
      console.log('进来这里', window.localStorage.getItem('introducedata'))
      routprams = JSON.parse(window.localStorage.getItem('introducedata'))
    } else {
      routprams = this.route.snapshot.queryParams;
    }
    if (routprams.introduce) {
      this.formintroduce = true
    }
    if (window.localStorage.getItem('sell') === 'uniapp') {
      this.ifyichang = true
    }
    if (!this.formintroduce) {
      // 简化社区获取社区首页信息
      this.goSeckillinfo()
    } else {
      // 带有邀请码的简化社区获取社区首页信息
      this.goSeckillinfo2()
    }
    // 平台框架兼容,如果sell字段存在，则是uniapp框架进来,并保存在本地告诉平台页面
    if (this.route.snapshot.queryParams.sell) {
      window.localStorage.setItem('sell', 'uniapp')
    } else {
      window.localStorage.setItem('sell', 'angular')
    }
  }
  // 简化社区获取社区首页信息
  goSeckillinfo() {
    this.http.post('/seckill/api/getcommunityindexinfo', {}).subscribe(
      (res: { data, code }) => {
        if (res.code === 0) {
          this.seckillinfo = res.data;
          setTimeout(() => {
            this.fontCom()
          }, 100);
        }
      }
    );
  }
  // 带有邀请码的简化社区获取社区首页信息
  goSeckillinfo2() {
    this.http.post('/seckill/api/getcommunityindexinfoinviteversion', {}).subscribe(
      (res: { data, code }) => {
        if (res.code === 0) {
          this.seckillinfo = res.data
          setTimeout(() => {
            this.fontCom()
          }, 100);
          console.log('社区信息', this.seckillinfo)
        }
      }
    );
  }
  // 进入新手抢购
  goNewseckill() {
    //this.formintroduce存在是微信分享页，只能下载app
    if (this.formintroduce) {
      this.showdownapp = true
    } else {
      //去查看
      /* this.router.navigateByUrl('/newuserseckill/settled/1') */
      if (window.localStorage.getItem('sell') === 'uniapp') {
        this.router.navigate(['/newuserseckill/settled/1'], {
          queryParams: {
            sell: 'uniapp'
          },
        });
      } else {
        this.router.navigateByUrl('/newuserseckill/settled/1')
      }

    }
  }
  // 进入提现
  goWithdrawpage(event: Event) {
    event.stopPropagation();
    //this.formintroduce存在是微信分享页，只能下载app
    if (this.formintroduce) {
      this.showdownapp = true
    } else {
      //去查看
      this.router.navigateByUrl('/newuserseckill/bankcardwithdraw')
    }
  }
  // 马上补领
  rightReplace() {
    if (this.formintroduce) {
      return
    }
    //弹窗类型
    this.datatype = 'earningsreplace'
    this.showmodelpop = true;
    this.showmodelpopdata = {
      head: '限时补领',
      text: `去邀请 (${this.seckillinfo.invite_seckill_people_amount ? this.seckillinfo.invite_seckill_people_amount : 0}/2)`,
      incident: 'goEarningsreplace'
    }
  }
  // 溢出任务
  goTask(){
    if (this.formintroduce) {
      return
    }
    //弹窗类型
    this.datatype = 'spilltash'
    this.showmodelpop = true;
    this.showmodelpopdata = {
      text: '转售收益权任务',
      head: `每场转售前完成以下任务<span style="color:rgba(66, 84, 212, 1);">(3选1)</span>，转售时可全额获得溢价部分50%奖励`,
      textdata:[
        {
          tash:'任务一',
          text:'邀请一个商家入驻和合商圈',
          incident: 'godownload',
        },
        {
          tash:'任务二',
          text:'邀请一个好友参与抢购',
          incident: 'goinviteTask',
        },
        {
          tash:'任务三',
          text:'购买和合商圈APP任一款商品',
          incident: 'godownload',
        },
      ],
    }
  }
  // 点击邀请
  gouserShare() {
    if (window.localStorage.getItem('sell') === 'uniapp') {
      console.log('进去unapp')
      // @ts-ignore
      window.uni.postMessage({
        data: {
          type: 'newseckillshare',
          data: ''
        }
      });
    } else {
      if (this.formintroduce) {
        return
      } else {
        //去邀请分享
        this.http.post('/seckill/api/getcommunityinviteshareinfoinviteversion', {}).subscribe(
          (res: { data }) => {
            this.wechat.shareFriend(1, {
              title: res.data.title,
              subtitle: res.data.desc,
              icon_url: res.data.img,
              jump_url: res.data.url,
              app_id: '',
            });
          }
        )
      }
    }
  }
  // 弹窗自组建回来的值
  getModeldata(e) {
    console.log('是的sd',e)
    this.showmodelpop = false;
    if (e === 'replaceinvite') {
      // 去邀请
      /* this.inviteClick() */
      this.gouserShare()
    } else if (e === 'replacepurchase') {
      console.log('去买')
      // 去3199团购
      /* this.goreplaceInvite(this.travelcardinfo); */
    } else if (e === 'givebule') {
      console.log(localStorage.getItem('givebulechecked'))
      if (localStorage.getItem('givebulechecked') === 'yes') {
        this.setWithdrawal()
      }
      this.gouserShare()
    } else if(e==='godownload'){
      //this._toast.show('提示：请下载和合商圈APP进行操作')
      this.goDownloaduniapp()
    } else if(e==='goinviteTask'){
      this.gouserShare()
    }
  }
  goDownloaduniapp(){
    if (window.localStorage.getItem('sell') === 'uniapp') {
      console.log('进去unapp')
      // @ts-ignore
      window.uni.postMessage({
        data: {
          type: 'openhhapp',
          data: ''
        }
      });
    }
  }
  //设置抢购不再提醒
  setWithdrawal() {
    //去邀请分享
    this.http.post('/seckill/api/settipsgivebluescalloptofriendnoshow', {}).subscribe(
      (res: { data, code }) => {
        if (res.code === 0) {
          this.seckillinfo.is_show_tips_bs = 1
        }
      }
    )
  }
  // 下载app
  goDownload() {
    if (this.ifyichang) {
      window.location.href = 'https://assets.ddgeyou.com/ycindex.html'
    } else {
      window.location.href = 'https://assets.ddgeyou.com/index.html'
    }
  }
  //点击弹窗内的关闭
  closePop() {
    this.showdownapp = false
  }
  // 新人放弃抢购
  giveUp() {
    this.showcontseckill = false
  }
  // 打开一次性的抢购弹窗
  getshowSeckill() {
    this.showcontseckill = true
  }
  //免抢购抢单
  getPurchase(event) {
    event.stopPropagation();
    if (this.buttonfalse === true) {
      this.buttonfalse = false
      //提交订单
      this.http.post('/seckill/api/inviteuserexperienceseckillsubmitcommunityorder', {}).subscribe(
        (res: { data, code }) => {
          this.buttonfalse = true;
          if (res.code === 0) {
            console.log(res.data, '有什么')
            this.unpaidinfo = res.data;
            this.jsapiPay()
          }
          /*  this.router.navigateByUrl('/newuserseckill/newuser-purchase').then(); */
        }
      )
    }
    setTimeout(() => {
      this.buttonfalse = true
    }, 10000);
  }
  // h5页面
  jsapiPay(): void {
    if (this.unpaidinfo.order_no) {
      let url = '/seckill/api/communityorderpaymentsignature'
      this.http.post(url, {
        order_no: this.unpaidinfo.order_no,
        pay_type: 1,
        client_type: 1,
        np_wallet_amount: 0,
        open_id: localStorage.getItem('openId')
      }).subscribe((res: { code: number, data: orderInfo }) => {
        this.showcontseckill = false;
        if (res.data.pay_type === 0) {
          this.router.navigateByUrl('/newuserseckill/newuser-purchase').then();
        } else {
          if (res.code === 0) {
            this.wechat.pay(res.data, res.data.order_no, (res) => {
              if (window.localStorage.getItem('paymentstatus')) {
                this.showcontseckill = false;
                window.localStorage.removeItem('paymentstatus');
                console.log('进入哪里1')
              } else {
                console.log('进入哪里2')
                this.showcontseckill = false;
                this.ngZone.run(() => this.router.navigateByUrl('/newuserseckill/newuser-purchase')).then();
              }
            });
          }
        }
      });
    }
  }
  // 看规则
  goRule() {
    window.location.href = 'https://assets.ddgeyou.com/web/newuserseckillrule.html'
  }
  goShopintroduce() {
    window.location.href = 'https://assets.ddgeyou.com/web/shopintroduce.html'
  }
  // 钱包字体缩小方法
  fontCom() {
    if (this.formintroduce === true) {
      return
    }
    if (this.text1.nativeElement.offsetWidth < this.text2.nativeElement.offsetWidth) {
      let width = this.text2.nativeElement.offsetWidth - this.text1.nativeElement.offsetWidth;
      if (width < 4) {
        this.text2.nativeElement.style.fontSize = '1.9rem'
      } else if (width >= 4 && width < 10) {
        this.text2.nativeElement.style.fontSize = '1.8rem'
      }
      else if (width >= 10 && width < 20) {
        this.text2.nativeElement.style.fontSize = '1.7rem'
      }
      else if (width >= 20 && width < 30) {
        this.text2.nativeElement.style.fontSize = '1.6rem'
      }
      else if (width >= 30 && width < 40) {
        this.text2.nativeElement.style.fontSize = '1.5rem'
      }
      else if (width >= 40 && width < 50) {
        this.text2.nativeElement.style.fontSize = '1.4rem'
      } else {
        this.text2.nativeElement.style.fontSize = '1.2rem'
      }
    }
  }
  //送出按钮
  goGive() {
    //is_show_tips_bs为0表示弹出弹窗
    if (this.seckillinfo.is_show_tips_bs === 0) {
      //弹窗类型
      this.datatype = 'givebule'
      this.showmodelpop = true;
      this.showmodelpopdata = {
        head: '如何送好友蓝滴咚贝？',
        title: '邀请新用户注册并完成抢购，下一轮统计结束后，好友即可瓜分蓝贝奖励，如未及时送出，奖励将自动清零。',
        text: '立即邀请',
        texttip: '注：新用户为本活动上线后注册的用户',
        incident: 'goinvite'
      }
    } else {
      //邀请
      this.gouserShare()
    }
  }
  // 去好友列表
  goFriendlist() {
    this.router.navigateByUrl('/newuserseckill/friendlist')
  }
  // 去抢购收益
  goReturnsdetail() {
    this.router.navigateByUrl('/newuserseckill/returnsdetail')
  }
  //钱包收益
  goWalletdetails(event: Event) {
    event.stopPropagation()
    this.router.navigateByUrl('/newuserseckill/walletdetails')
  }
  //蓝贝收益
  goBluedetails() {
    this.router.navigateByUrl('/newuserseckill/bluedetails')
  }
  // 去奖励规则
  goawardRule() {
    window.location.href = 'https://assets.ddgeyou.com/web/newuserawardRule.html'
  }
  // 查看奖励记录
  gainBlueexamine(){
    this.router.navigateByUrl('/newuserseckill/gainblue-record')
  }
  // 去抢购
  goRush(){
    this.goNewseckill()
  }
}

