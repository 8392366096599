<app-popmodel (outer)="getReturnData($event)" *ngIf="popData.isShow" [popdata]="popData"></app-popmodel>
<div class="order-pay">
    <div class="order-info">
        <div class="lable">待支付</div>
        <div class="lable2">￥<label>{{toPaidMoney}}</label></div>
        <div class="order-item">
            <div class="choiceblue flex">
                <div class="flex">
                    <div class="changeicon " [ngClass]="{changeicon:selectBlue,changeiconno:!selectBlue}"
                        (click)="changblueclick()"></div>
                    <div class="text">使用蓝滴咚贝抵扣</div>
                </div>
                <input placeholder="0" [(ngModel)]="buckleBlue" value="" type="number" id="imgtalk"
                    oninput="value=value.replace(/[^0-9-]+/,'');if(value<=0)value='';if(value>this.limitBlue)value=this.limitBlue;"
                    (ngModelChange)="modelChange($event)">
            </div>
            <div class="text2">(蓝贝余额：{{usableBlue}}枚)</div>
            <div class="choiceblue flex">
                <div class="flex">
                    <div class="changeicon " [ngClass]="{changeicon:selectMoney,changeiconno:!selectMoney}" (click)="moneyclick()"></div>
                    <div class="text">使用商圈钱包余额支付</div>
                </div>
            </div>
            <div class="text2">(可用余额：¥{{usableMoney}}<label *ngIf="buckleMoney">，已抵扣：¥{{buckleMoney}}</label>)</div>
        </div>
        <div class="allprice">还需现金支付：<label>¥{{requiredMoney}}</label></div>
    </div>
    <!-- 支付环境显示 -->
    <div class="pay-type">
        <div class="pay-type-item" *ngFor="let item of payWayArr;let index = index">
            <div class="flex_item" *ngIf="envPlatform==item.env">
                <div class="pay-item">
                    <img [src]="item.imgurl" />
                    <div class="text">{{item.name}}</div>
                </div>
                <div class="item_icon" [ngClass]="{icon_active:payWayIndex==item.type}" (click)="paywayClick(item.type)"></div>
            </div>
        </div>
    </div>
    <div class="homepage-button">
        <div class="pay-btn" (click)="payClick()">支付</div>
    </div>
</div>