<div class="order-succeed">
    <div class="order-succeed-all">
        <div class="succeed-info">
            <div class="title flex">
                <div class="text"></div>
                <div class="text1">抢购成功</div>
            </div>
            <div class="title-info"><p>下单成功后请联系客服预约报名 </p>客服微信 <label id="detail">HeHeInterMktServ</label> <label
                data-clipboard-text="HeHeInterMktServ" data-clipboard-action="copy" data-clipboard-target="#detail" class="button">复制</label></div>
        </div>
        <div class="suceed-model" *ngIf="typepage!=='gold'">
            <div class="suceed-model-item">
                <div class="title">恭喜你已开启组团资格！</div>
                <div class="center">组团成功，团长即享超值奖励，真正实现免费旅游</div>
                <div class="button">
                    <a 
                    style="color: #fff;"
                    href="https://assets.ddgeyou.com/index.html">
                    下载app开团
                    </a>
                </div>
                <div class="text" (click)="goOrder()">查看订单</div>
            </div>
        </div>
        <div *ngIf="typepage==='gold'">
            <div class="buttongo">
                <a 
                style="color: #fff;"
                href="https://assets.ddgeyou.com/index.html">
                    下载APP查看订单
                </a>
            </div>
            <div  class="buttontext" (click)="goOrder()">直接查看订单</div>
        </div>
    </div>
</div>