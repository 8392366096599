<div class="mcommodel">
    <div class="commodel-all">
        <div class="joinlist">
            <div class="closeimg" (click)="close()"><img src="https://base-assets.ddgeyou.com/multiactive/ycclose.png" /></div>
            <div class="joinlist-all">
                <div class="shop-details">
                    <div class="details-item">
                        <div class="list-joining">
                            <div class="joining-item"  *ngFor="let item of joindata;let index = index">
                                <div class="join-info">
                                    <div class="join-left">
                                        <div class="josnimg">
                                            <img [src]="item.img" />
                                        </div>
                                        <div class="titleinfo">
                                            <div class="titles omit">{{item.goods_name}}</div>
                                            <div class="price-all">
                                                <div class="price">{{item.price}}元</div>
                                                <div class="label">{{item.quantity}}人竞抢</div>
                                            </div>
                                            <div class="imginfo">
                                                <div class="imgall" *ngFor="let it of item.snap_up_user_list;let index = index">
                                                    <div class="img"><img [src]="it.avatar" /></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="joining">
                                        <div class="button" (click)="operation(item)">加入竞抢</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="list-tip">最多显示前10条竞抢中记录</div>
            </div>
        </div>
    </div>
</div>
