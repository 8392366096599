import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router'
import { HttpClient } from "@angular/common/http";
import { Output,EventEmitter} from '@angular/core';
@Component({
  selector: 'app-compop',
  templateUrl: './compop.component.html',
  styleUrls: ['./compop.component.less']
})
export class CompopComponent implements OnInit {
  @Output() private outer = new EventEmitter()
  constructor(
    private router: Router,
    private http: HttpClient,
    ) { }
  // 父id
    areapid=0;
  // 级别
  level=1;
  // 当前显示的区域容器
  nowlist
  // 选择区上的具体值
  changearealist=[{'name':'国内',type:true,level:0}]
  // 当前点击的值
  changdata
  ngOnInit(): void {
    this.getcitylist(this.areapid,this.level)
  }
  // 获取组团购路线数据
  getcitylist(e,e2) {
    this.nowlist=[]
    this.http.post('/travel/api/getcitylist', {pid:e,level:e2}).subscribe(
      (res: { data }) => {
        if(res.data.length>0){
          this.nowlist=res.data
        }
      }
    )
  }
  getchangelist(item){
    if(item.name!=='国内'){
      this.getcitylist(item.id,item.level+1)
    }
  }
  // 获取列表
  getArealist(item){
    console.log('看看',item)
    if(item.level===1){
      /* this.changearealist=[] */
    }
    // 如果字is_has_next_level为false，不再请求接口
    if(item.is_has_next_level===false){
      let data={
        type:'1',
        data:this.changearealist,
        last:item
      }
      console.log('最后是这个吗？',item)
      this.outer.emit(data)
    }else{
      item.type=true
      this.changearealist.push(item)
      this.getcitylist(item.id,item.level+1);
      this.changdata=item;
      this.changdatacolortype(item)
    }
  }
  changdatacolor(item){
    let nowinde
    this.changearealist.forEach((element,index)=>{
     if(element.name===item.name){
      element.type=true;
      nowinde=index
      this.getcitylist(item.id,item.level+1);
     }else{
      element.type=false
     }
    })
    this.changearealist=this.changearealist.slice(0,nowinde+1)
  }
  // 选择字体变色
  changdatacolortype(item){
    this.changearealist.forEach((element)=>{
     if(element.name===item.name){
      element.type=true;
     }else{
      element.type=false
     }
    })
  }
  // 点击关闭
  close(){
    this.outer.emit('close')
  }
}
