import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router'
import { HttpClient } from "@angular/common/http";
import { ToastService } from 'ng-zorro-antd-mobile';
import { ScrollService } from '../../../code/service/scroll/scroll.service';
import { GroupPopModelComponent } from '../grouppopmodel/grouppopmodel.component';

@Component({
  selector: 'app-order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.less'],
})

export class OrderListComponent implements OnInit {

  @ViewChild('grouppopmodel') grouppopmodel: GroupPopModelComponent;

  constructor(
    private title: Title,
    private router: Router,
    private http: HttpClient,
    private _toast: ToastService,
    private scroll: ScrollService
  ) {
    title.setTitle('组团购订单');
  }
  childdata = [{
    'text': '全部',
    active: true,
    number: '',
    field:'all'
  }, {
    'text': '待付款',
    active: false,
    number: 10,
    field:'toBePaid'
  }, {
    'text': '待使用',
    active: false,
    number: 10,
    field:'toBeUsed'
  }, {
    'text': '已完成',
    active: false,
    number: 10,
    field:'completed'
  }]
  // 订单状态
  status='all';
  // 订单页码数量
  page=0;
  page_size=10;
  childchange = "1";//其中选择的一个子元素
  // 组团购订单
  orderlistdata=[];
  // 弹窗类型
  datatype='order'
  //是否出现弹窗和弹窗传送数据
  showmodelpop=false
  showmodelpopdata={}
  //删除或者取消时候选择的数据
  selecteddata
  // 当前选择
  nowchange='all'
  //是否显示到底了字段
  datahint=false
  ngOnInit(): void {
    this.getorderList(this.status)
    this.scroll.listen(() => {
        this.getorderList(this.nowchange);
    });
  }

  childchenked(e) {
    this.childdata.forEach((element) => {
      if (element.text === e.text) {
        element.active = true;
        this.nowchange=e.field;
        this.page=0
        this.page_size=10
        this.orderlistdata=[]
        this.datahint=false
        this.getorderList(e.field)
      } else {
        element.active = false;
      }
    });
  }
  //订单列表
    getorderList(e) {
      this.page += 1
      this.http.post('/travel/api/getgrouprouteorderlist', {
        status:e,
        page:this.page,
        page_size:this.page_size
      }).subscribe(
        (res: { data }) => {
          if (res.data.list.length !== 0) {
            res.data.list.forEach(element => {
              this.orderlistdata.push(element)
            });
          }else{
            if(this.page>1&&this.orderlistdata.length>=3){
              this.datahint=true
            }
          }
        }
      )
    }
  // 去订单详情
  goorderdetails(item){
    let param = encodeURI(JSON.stringify(item.order_no))
    if(item.order_status===0){
      this.http.post('/travel/api/grouprouteorderlistclicktopaytogetinformation', { order_no:item.order_no}).subscribe(
        (res: { data,code}) => {
          if(res.code===0){
            this.router.navigate(['/groupbook/order-details' + '/' + param]).then()
          }
        }
      );
    }else{
      this.router.navigate(['/groupbook/order-details' + '/' + param]).then()
    }
  }
  // 取消订单
  cancelorder(item, event: Event){
    event.stopPropagation();
    this.showmodelpop=true;
    this.selecteddata=item;
    this.datatype = 'order'
    this.showmodelpopdata={
      title:'取消订单',
      text:'不取消',
      text2:'确定',
      incident:'postCancelorder'
    }
  }
  postCancelorder(item){
    this.http.post('/travel/api/canceltobepaidgrouprouteorder', { order_no:item.order_no}).subscribe(
      (res: { data,code}) => {
        if(res.code===0){
          this._toast.success('取消成功！', 1000, () => {
          });
          setTimeout(() => {
           window.location.reload()
          }, 500);
        }
      }
    );
  }
//删除订单
deleteorder(item, event: Event){
  event.stopPropagation();
    this.showmodelpop=true;
    this.selecteddata=item;
    this.datatype = 'order'
    this.showmodelpopdata={
      title:'删除订单',
      text:'取消',
      text2:'确定',
      incident:'postDeleteorder'
    }
}
  //删除订单
  postDeleteorder(item){
    this.http.post('/travel/api/consumerdeletedgrouprouteorder', { order_no:item.order_no}).subscribe(
      (res: { data,code}) => {
        if(res.code===0){
          this._toast.success('删除成功！', 1000, () => {
          });
          setTimeout(() => {
           window.location.reload()
          }, 500);
        }
      }
    );
  }
  // 弹窗回来的值
  getModeldata(e){
    this.showmodelpop=false;
    if(e==='postCancelorder'){
        this.postCancelorder(this.selecteddata)
    }
    if(e==='postDeleteorder'){
        this.postDeleteorder(this.selecteddata)
    }
    if(e==='postPickupOrder'){
      this.postPickupOrder(this.selecteddata)
    }
    if(e==='pickPopModel'){//合并取货
      this.grouppopmodel.popShow()
    }
  }

  // 自由取货
  pickupOrder(item, event: Event){
    event.stopPropagation();
    this.showmodelpop=true;
    this.selecteddata=item;
    this.datatype = 'showTips'
    this.showmodelpopdata={
      title:'如原商品不合适，可改为选择指定额度以内的其他货品自由取货，确认自由取货？',
      text:'取消',
      text2:'自由取货',
      incident:'postPickupOrder',
      incident1:'pickPopModel',
      price:item.pickup_worth//取货最大值
    }
  }
  //确认取货
  postPickupOrder(item){
    window.localStorage.setItem('pickup_info', '')
    let data = {
      order_no:item.order_no,
      pickup_worth:item.pickup_worth,
      pickup_area:'group_pickup'
    }
    let param = encodeURI(JSON.stringify(data))
    this.router.navigate(['/groupbook/pickup-list'], {
      queryParams: {
        param: param
      },
    }).then()
  }
}
