<app-popmodel (outer)="getReturnData($event)" *ngIf="popData.isShow" [popdata]="popData"></app-popmodel>
<div class="container_box">
  <div class="buy_tip">和合易券为商家提供灵活的促销支持，国内旅游、美业、美食等众多福利，为你的销售无限赋能。</div>
  <div class="buy_box">
    <div class="buy_title">请选择采购数量：</div>
    <div class="buy_list">
      <div class="buy_item" *ngFor="let item of dataList;let index = index" [class]="{'active':itemIndex==index}" (click)="itemClick(index,item)">
        <div class="item_img"><img src="https://base-assets.ddgeyou.com/simpleherbalzone/icon_true.png" /></div>
        <div class="item_txt"><span>{{item}}</span>易券</div>
      </div>
    </div>
    <div class="buy_input" [class]="{'active':itemIndex==6}" (click)="itemClick(6,inputNum)">
      <div class="item_img"><img src="https://base-assets.ddgeyou.com/simpleherbalzone/icon_true.png" /></div>
      <input id="inputVal" *ngIf="isAndroid" type="tel" [placeholder]="inputPlaceholder" [(ngModel)]="inputNum" autocomplete="off" (ngModelChange)="modelChange($event)" (blur)="modelBlur($event)"
      oninput="value=value.replace(/\D/g, '');if(value<0)value='';if(value>this.limitMaxNum)value=this.limitMaxNum"/>
      <input id="inputVal" *ngIf="!isAndroid" type="number" [placeholder]="inputPlaceholder" [(ngModel)]="inputNum" autocomplete="off" (ngModelChange)="modelChange($event)" (blur)="modelBlur($event)"
      oninput="value=value.replace(/\D/g, '');if(value<0)value='';if(value>this.limitMaxNum)value=this.limitMaxNum"/>
    </div>
    <div class="buy_check">
      <img [src]="isChecked?'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAAAXNSR0IArs4c6QAABBdJREFUaEPtmU9oFFccx7+/2dlmRjxUCNmJ6EFUNNBDDxY8BEpP1ZueK5iDgiKFlmqpJGTfpE0V2tJCBVv0EMGe9aaeIqgH0YNQaBQLOahxVwLmEJhJd91febNZGXfnz3u7bw2BzHV+7/2+n/d78+a97yOs84fWuX5sAKx1Bc1UQMzaxdqejwsF63Mi7GPwXgJ5AD5cBVxicIVAj5nxkJhuB4V/7kN8Vu91AHoCGJio7kQBJyzwEQBSsM5TYWCG6vaFYHrwhU7DeGx3AN8sDDqbrXMAxgiwu00u2zE4BDAT1iyBH0tV3b60AZzJV0eJGr/FpoduzpR4XmS2vgqnSn/pdKgOIOYdh93fCTimk0A3lsF/hBR+DbFDVib3UQOIxDvXCHQgt0cDAQy+GVJ4WAUiH+A9i2/xq0LkAjjllxcJdMLAwGp3wcDl0PeOZzXMBHAmq18Q8VXtzAYbMFtj4dTQlbQu0wHOzHvuJvdvAIMG9aR2xaC7AN8mYKItaClYbuzGL1sXkxqnArzPqSPFh0QHIYaWnclqmYhFXGzWVEoEcL99vo3dwlMCOf0e/bj4Vq52CAbqvFIbWTm//d92PYkATrlyjoDv1kK8zJlUhQbo5xW/dCYfQMzaLo8862Jvo8WbNPJp4lc7rgQ0t719A9hRAWd8YZRs646WGs3gLsRHGer1xie16a0P4+k6ASYr40T4QVOTcni34mUCZkyEU950JoBbrlwDcEhZkUZgL+JX01wPfO9wdgXKL+cItDdNFwPnARol8KiGdhgQL7fej0N/eCSvAq/TtsrMJMKpkg/xarPDfEMVwoT4VdFLge9tyQPgpJF9K771UhHCoPgoc+B773y3HR+xW5Ynvc6nA0CG5ECYFq8KkD+F4nwpEP0QDyB/Cjl5H3HrO8iA6JN45Y84dxnNmk6Sq7UxizMmbQ90VjH1ZVTxR5YGESUSQ8t9EK/2IyuOL+yzbeuByugkQrQ1NDTyzV4bNBp8X7qXuYxCczOXBWFUPKC2mZN0A+XqTxb4tEoVZEwShGHxkDuA0PfOtmtKPA8MiGe7iItzOq5bHMK8eA6pWNwVTHRakBlHysolXRNLQsgRaj8SqlYyLS4yu/zhk0nv0w/1YmHQZeupeQtRF4cXgw+sjzCe7Jvm2CqRDzqjm9JkPDMdyfJLFYwt/alkCiBr6rRy5AJgvVuLzT/rejZ3W7VqQvzab580MrEo+FLFmY5WPN352vRL5QUHmbYcl5oXHOk+qN4ymkUm5j2HnXLziqk39066btEVEzXOQiT7n1lStCsQ78wVz7cx26cIGOvCCKs0QFdB//25IjotQ9WZ0RPA2yRi1nZqe/ajYH2qcs365k3jVq345NGaX7OqjlI/48xUoJ8Kc/reAFjDwY9S/w/90i9POcVfTAAAAABJRU5ErkJggg==':'https://base-assets.ddgeyou.com/simpleherbalzone/icon_uncheck.png'" (click)="isChecked=!isChecked" />
      <div>我已同意<span (click)="agreementClick()">《和合易券采购协议》</span></div>
    </div>
    <div class="buy_btn" (click)="buyClick()">支付金额<span *ngIf="toPaidMoney">￥{{ toPaidMoney }}</span></div>
    <div class="buy_txt"><span (click)="recordClick()">购买记录</span></div>
  </div>
</div>
<div class="popup">
  <div [class]="{'mask':isShowPop}" (click)="isShowPop=false"></div>
  <div class="popup_center" [class]="{'on':isShowPop}">
    <div class="popup_title">
      <div class="title_text">选择支付方式</div>
      <img src="https://base-assets.ddgeyou.com/simpleherbalzone/icon_colse.png" (click)="isShowPop=false" />
    </div>
    <div class="popup_list">
      <div *ngFor="let item of payWayArr;let index = index">
        <div class="popup_item" *ngIf="envPlatform==item.env">
          <img class="popup_img" [src]="item.imgurl" />
          <div class="popup_right">
            <div class="popup_txt">{{item.name}}</div>
            <div class="popup_icon" [ngClass]="{icon_active:payWayIndex==item.type}" (click)="paywayClick(item.type)"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="popup_footer">
      <div class="popup_btn" (click)="payClick()">支付<span *ngIf="toPaidMoney">￥{{ toPaidMoney }}</span></div>
    </div>
  </div>
</div>